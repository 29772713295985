// Template for the "Log In" button.

import {Platform, StyleSheet} from "react-native";
import "react-native-reanimated";
import {useState} from "react";
import {LinearGradient} from "expo-linear-gradient";
import {CompDimensions} from "../../util/Util";
import Animated from "react-native-reanimated";
import {headerFadeIn, headerFadeOut, mainDarkColor} from "../../Main";
import SquishyButton from "../reusable/SquishyButton";

export function LoginBtn(props: {
    dims: CompDimensions,
    yOffset: number,
    onPress: any,
}) {

    // Get component dimensions.
    const cd = props.dims;

    // Calculate font size.
    const fontSize = 0.9 * Math.sqrt(cd.loginBtnWidth * cd.loginBtnHeight / "Log In".length);

    // Create state var for hover effect.
    let isHoveredBool = false;
    const [isHovered, setIsHovered] = useState(isHoveredBool);

    // Create reusable styles.
    const styles = StyleSheet.create({
        loginBtnShadow: {
            // @ts-ignore
            position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
            width: cd.loginBtnWidth + cd.loginBtnWidth * 0.02,
            height: cd.smallScreen ? cd.loginBtnHeight * 0.57 : cd.loginBtnHeight * 0.59,
            marginLeft: ((cd.screenWidth - cd.loginBtnWidth) * 0.5) - cd.loginBtnWidth * 0.01,
            marginTop: (cd.smallScreen ? cd.loginBtnHeight * 0.46 : cd.loginBtnHeight * 0.43) + (cd.smallScreen
                ? cd.loginBtnTopMargin - props.yOffset - (props.yOffset > 0 ? cd.loginBtnHeight * 0.3 : 0)
                : cd.loginBtnTopMargin),
            overflow: "hidden",
            borderRadius: cd.loginBtnBorderRadius * 1.2,
            backgroundColor: "#e3f1fcb9",
        },
        loginBtnContainer: {
            // @ts-ignore
            position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
            width: cd.loginBtnWidth,
            height: cd.loginBtnHeight,
            marginLeft: (cd.screenWidth - cd.loginBtnWidth) * 0.5,
            marginTop: cd.smallScreen
                ? cd.loginBtnTopMargin - props.yOffset - (props.yOffset > 0 ? cd.loginBtnHeight * 0.3 : 0)
                : cd.loginBtnTopMargin,
            overflow: "hidden",
            borderRadius: cd.loginBtnBorderRadius,
        },
        loginBtnPressable: {
            width: cd.loginBtnWidth,
            height: cd.loginBtnHeight,
        },
        loginBtnGradient: {
            width: cd.loginBtnWidth,
            height: cd.loginBtnHeight,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: cd.loginBtnBorderRadius * 1.2,
            borderWidth: cd.smallScreen ? cd.loginBtnHeight * 0.06 : cd.loginBtnHeight * 0.08,
            borderColor: mainDarkColor,
        },
        loginBtnText: {
            fontSize: fontSize,
            color: isHovered && !cd.smallScreen ? "white" : "#faf9f5",
            fontWeight: "bold",
        },
    });

    return (
        <>
            {/**
             Login Button Shadow.
             */}
            <Animated.View
                entering={headerFadeIn}
                exiting={headerFadeOut}
                style={styles.loginBtnShadow}/>

            {/**
             Login Button.
             */}
            <SquishyButton
                isSmallScreen={cd.smallScreen}
                pressFunc={() => props.onPress(cd)}
                isHovered={isHovered}
                setHoveredFunc={setIsHovered}
                scaleTo={0.95}
                squishInDuration={150}
                squishOutDelay={100}
                squishOutDuration={100}
                containerStyle={styles.loginBtnContainer}
                buttonStyle={styles.loginBtnPressable}
                containerOtherProps={{
                    entering: headerFadeIn, exiting: headerFadeOut
                }}>
                <LinearGradient
                    colors={isHovered && !cd.smallScreen ?
                        ["#fac96e", "#fac96e", "#fcd181", "#fecf79", "#fac96e"] :
                        ["#fad591", "#fad184", "#f7d088", "#f7d086", "#face7d"]}
                    style={styles.loginBtnGradient}
                >
                    <Animated.Text
                        entering={headerFadeIn}
                        exiting={headerFadeOut}
                        style={styles.loginBtnText}
                        selectable={false}>Log In</Animated.Text>
                </LinearGradient>
            </SquishyButton>
        </>
    );
}
