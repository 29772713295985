/* eslint-disable no-inner-declarations */
// @ts-ignore
import { canUseDOM } from 'fbjs/lib/ExecutionEnvironment'
import "react-native-reanimated";


let isEnabled = false

if (canUseDOM) {
    /**
     * Web browsers emulate mouse events (and hover states) after touch events.
     * This code infers when the currently-in-use modality supports hover
     * (including for multi-modality devices) and considers "hover" to be enabled
     * if a mouse movement occurs more than 1 second after the last touch event.
     * This threshold is long enough to account for longer delays between the
     * browser firing touch and mouse events on low-powered devices.
     */
    const HOVER_THRESHOLD_MS = 1000
    let lastTouchTimestamp = 0

    // @ts-ignore
    function enableHover() {
        if (isEnabled || Date.now() - lastTouchTimestamp < HOVER_THRESHOLD_MS) {
            return
        }
        isEnabled = true
    }

    // @ts-ignore
    function disableHover() {
        lastTouchTimestamp = Date.now()
        if (isEnabled) {
            isEnabled = false
        }
    }

    document.addEventListener('touchstart', disableHover, true)
    document.addEventListener('touchmove', disableHover, true)
    document.addEventListener('mousemove', enableHover, true)
}

function isHoverEnabled(): boolean {
    return isEnabled
}

import React, { useCallback, ReactChild, useRef } from 'react'
import {
    useSharedValue,
    useAnimatedReaction,
    runOnJS,
} from 'react-native-reanimated'
import { Platform } from 'react-native'

export interface HoverableProps {
    onHoverIn?: () => void
        onHoverOut?: () => void
        onPressIn?: () => void
        onPressOut?: () => void
        children: NonNullable<ReactChild>
}

export default function Hoverable({
                                      onHoverIn,
                                      onHoverOut,
                                      children,
                                      onPressIn,
                                      onPressOut,
                                  }: HoverableProps) {
    const showHover = useSharedValue(true)
    const isHovered = useSharedValue(false)

    const hoverIn = useRef<undefined | (() => void)>(() => onHoverIn?.())
    const hoverOut = useRef<undefined | (() => void)>(() => onHoverOut?.())
    const pressIn = useRef<undefined | (() => void)>(() => onPressIn?.())
    const pressOut = useRef<undefined | (() => void)>(() => onPressOut?.())

    hoverIn.current = onHoverIn
    hoverOut.current = onHoverOut
    pressIn.current = onPressIn
    pressOut.current = onPressOut

    useAnimatedReaction(
        // Only check for hover events on web.
        () => {
            return Platform.OS === 'web' && showHover.value && isHovered.value
        },

        // Check whether hover state changed.
        (hovered, previouslyHovered) => {
            if (hovered !== previouslyHovered) {

                // Call hoverIn/Out on the JS thread, avoiding thread errors on mobile.
                if (hovered && hoverIn.current) {
                    runOnJS(hoverIn.current)()
                } else if (hoverOut.current) {
                    runOnJS(hoverOut.current)()
                }
            }
        },
        []
    )

    const handleMouseEnter = useCallback(() => {
        if (isHoverEnabled() && !isHovered.value) {
            isHovered.value = true
        }
    }, [isHovered])

    const handleMouseLeave = useCallback(() => {
        if (isHovered.value) {
            isHovered.value = false
        }
    }, [isHovered])

    const handleGrant = useCallback(() => {
        showHover.value = false
        pressIn.current?.()
    }, [showHover])

    const handleRelease = useCallback(() => {
        showHover.value = true
        pressOut.current?.()
    }, [showHover])

    let webProps = {}
    if (Platform.OS === 'web') {
        webProps = {
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
            // prevent hover showing while responder
            onResponderGrant: handleGrant,
            onResponderRelease: handleRelease,
        }
    }

    return React.cloneElement(React.Children.only(children) as any, {
        ...webProps,
        // if child is Touchable
        onPressIn: handleGrant,
        onPressOut: handleRelease,
    })
}