import React, {Component} from "react";
import {
    Image,
    Modal,
    Platform,
    Pressable,
    ScrollView,
    StyleSheet,
    Text,
    TextInput,
    TouchableWithoutFeedback,
    View
} from "react-native";
import {CompDimensions, ConditionalWrapper, handleLineBreaks} from "../../../util/Util";
import Animated, {Easing, FadeIn, FadeOut, SlideInDown} from 'react-native-reanimated';
import {QuoteData} from "../../../types/QuoteData";
import Hoverable from "../../reusable/Hoverable";
// @ts-ignore
import up_arrow from '../../../../assets/up_arrow.png';
// @ts-ignore
import saved_icon from '../../../../assets/saved_icon.png';
// @ts-ignore
import share_icon from '../../../../assets/share_icon.png';
// @ts-ignore
import link_icon from '../../../../assets/link_icon.png';
// @ts-ignore
import tweet_icon from '../../../../assets/twitter_icon.png';
// @ts-ignore
import facebook_icon from '../../../../assets/facebook_icon.png';
// @ts-ignore
import instagram_icon from '../../../../assets/instagram_icon.png';
// @ts-ignore
import report_icon from '../../../../assets/report_icon.png';
import WebView from "react-native-webview";
import {LinearGradient} from "expo-linear-gradient";
import * as ScreenOrientation from 'expo-screen-orientation';
import {borderColors, CategoryTag} from "./CategoryTag";
import ThankBtn from "./ThankBtn";

const reportInstructions = "If you've spotted an inaccuracy in this quote, please let us know.";
const reportInstructions2 = "Examples include grammatical errors, misattributed quotations, and " +
    "misleading descriptions of authors and their statements. ";
const reportInstructions3 = "Do NOT report a quote for expressing opinions or making contentious " +
    "claims.";
const initReportInput = "Please explain the issue here...";

export class QuoteModal extends Component<{
    dims: CompDimensions,
    visible: boolean,
    quoteData: QuoteData,
    onClose: any,
}> {

    state = {
        reportId: '',
        closeBtnHovered: false,
        thankBtnHovered: false,
        thank2BtnHovered: false,
        shareBtnHovered: false,
        shareBtnPopping: false,
        bookmarkBtnHovered: false,
        bookmarkBtnPopping: false,
        reportBtnHovered: false,
        reportBtnPopping: false,
        copyBtnHovered: false,
        tweetBtnHovered: false,
        facebookBtnHovered: false,
        instagramBtnHovered: false,
        suggestTagsBtnHovered: false,
        reportCancelHovered: false,
        reportCancelPopping: false,
        reportSubmitHovered: false,
        reportSubmitPopping: false,
        bookmarkSelected: false,
        shareSelected: false,
        reportBtnSelected: false,
        copyModalVisible: false,
        thanksAdded: 0,
        thanksSuggestionRhyme: '',
        screenRotated: false,
        mobileTweetHeight: 300,
        sourceImgHeight: 300,
        tagDisplayHeight: 0,
        suggestingTagEdits: false,
        tagsSuggestDeleted: [],
        tagsSuggestNew: [],
        newTagSuggestion: "New Tag",
        userReportedQuote: false,
        reportInput: initReportInput,
    }

    outerScrollRef: any;

    constructor(props) {
        super(props);

        this.rotateScreenHorizontal = this.rotateScreenHorizontal.bind(this);
        this.rotateScreenVertical = this.rotateScreenVertical.bind(this);
        this.resizeTweetContainerDesktop = this.resizeTweetContainerDesktop.bind(this);
        this.resizeTweetContainerMobile = this.resizeTweetContainerMobile.bind(this);
    }

    resizeTweetContainerDesktop(extraHeight: number) {
        try {
            const frame = document.getElementById("twitterIFrame");
            // @ts-ignore
            frame.style.height = (frame.contentWindow.document.body.scrollHeight + extraHeight) + 'px';
            // @ts-ignore
            this.setState({mobileTweetHeight: frame.contentWindow.document.body.scrollHeight + extraHeight});
        } catch (ignored) {
        }
    }

    resizeTweetContainerMobile(event) {
        //jsEvaluationValue contains result of injected JS, which is scrollHeight.
        this.setState({mobileTweetHeight: parseInt(event.jsEvaluationValue)});
    }

    rotateScreenHorizontal = async () => {
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_LEFT).then(() => this.setState({screenRotated: true}));
    }

    rotateScreenVertical = async () => {
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP).then(() => this.setState({screenRotated: false}));
    }

    shouldComponentUpdate(nextProps, nextState, nextContext: any): boolean {
        // Reset state if the modal is being used to display a new quote.
        if (!nextProps.quoteData || nextProps.quoteData.reportId === this.state.reportId) {
            return true;
        }

        // Re-initialize state vars.
        this.setState({
            reportId: nextProps.quoteData.reportId,
            thanksAdded: 0,
            bookmarkSelected: false,
            shareSelected: false,
            suggestingTagEdits: false,
            tagsSuggestDeleted: [],
            tagsSuggestNew: [],
            newTagSuggestion: "New Tag",
            userReportedQuote: false,
            reportInput: initReportInput,
        });

        // Choose a random rhyme to encourage tipping.
        let rhyme = '';
        const rand = Math.random();
        if (rand < 0.3) rhyme = 'Promote the Quote';
        else if (rand < 0.6) rhyme = 'Support the Report';
        else if (rand < 0.7) rhyme = "Float Quoters' Boats";
        else if (rand < 0.8) rhyme = 'Bolster the Quotester';
        else if (rand < 0.85) rhyme = 'Upvote the Quote';
        else if (rand < 0.9) rhyme = 'Proffer the Author';
        else if (rand < 0.95) rhyme = 'Favor the Creator';
        else rhyme = 'Cater to the Curator';
        this.setState({thanksSuggestionRhyme: rhyme});

        // TODO Load thanksAdded from database.
        // TODO Load bookmarked from database.

        return true;
    }

    componentDidMount() {
        // Update state with quote's report id.
        if (this.props.quoteData) {
            this.setState({reportId: this.props.quoteData.report_id});
        }

        // Choose a random rhyme to encourage tipping.
        let rhyme = '';
        const rand = Math.random();
        if (rand < 0.5) rhyme = 'Promote the Quote';
        else rhyme = 'Support the Report';
        this.setState({thanksSuggestionRhyme: rhyme});

        // TODO Load thanksAdded from database.
        // TODO Load bookmarked from database.
    }

    render() {
        if (!this.props.quoteData || !this.props.quoteData.quoter) return null;
        const cd = this.props.dims;

        // Define text elements.
        let thankRow2Text = 'Promote the Quote.  Support the Report...  With:'
        if (cd.smallScreen) {
            thankRow2Text = this.state.thanksSuggestionRhyme + '...  With:'
        }

        // Get and calculate component dimensions.
        const horizontalMargin = cd.quoteModalWidth * 0.025;
        const verticalMargin = cd.quoteModalHeight * 0.02;
        const textPaddingHorizontal = horizontalMargin;
        const dividerHeight = cd.quoteModalHeight * 0.015;
        const dividerBarHeight = cd.quoteModalHeight * 0.007;
        const dividerBarWidth = cd.quoteModalWidth * 0.6;
        const thankRowHeight = cd.quoteModalHeight * 0.092;
        const thankBtnHeight = thankRowHeight * 0.9;
        const thankBtnWidth = thankBtnHeight * 2.4;
        const shareRowHeight = thankRowHeight;
        const shareBtnHeight = shareRowHeight * 0.6;
        const closeBtnWidth = cd.smallScreen ? cd.quoteModalWidth * 0.65 : cd.quoteModalWidth * 0.38;
        const closeBtnHeight = cd.smallScreen ? closeBtnWidth * 0.17 : closeBtnWidth * 0.18;
        const copyModalWidth = cd.smallScreen ? cd.quoteModalWidth * 0.6 : cd.quoteModalWidth * 0.4;
        const copyModalHeight = thankRowHeight * 0.6;
        const thankRow2VerticalMargin = cd.smallScreen ? verticalMargin : verticalMargin * 2;
        const tagHeight = verticalMargin * 3;
        const tagTopBottomMargin = tagHeight * 0.2;
        const tagFontSize = (cd.smallScreen ? 0.8 : 0.55) * Math.sqrt(cd.quoteModalWidth * 0.4 * tagHeight / 12);
        let sourceTextTopMargin = cd.smallScreen ? verticalMargin * 4.5 : verticalMargin * 3.8;
        if (cd.smallScreen && this.props.quoteData.source_link.includes("twitter.com/")) {
            sourceTextTopMargin *= (Platform.OS === 'web' ? 0.8 : 0.78);
        } else if (cd.smallScreen && this.props.quoteData.source_link.includes("youtube.com/")) {
            sourceTextTopMargin *= (Platform.OS === 'web' ? 0.85 : 0.82);
        }
        const sourceTextBottomMargin = cd.smallScreen ? (Platform.OS === 'web' ? verticalMargin * 1.1 : 0) : verticalMargin;
        const sourceTextFontHeight = verticalMargin * 2.5;
        let platformSourceTextBottomMargin = sourceTextBottomMargin;
        if (this.props.quoteData.source_link.includes("twitter.com/")) {
            platformSourceTextBottomMargin = 0;
        }
        const sourceTextAreaHeight = sourceTextTopMargin + platformSourceTextBottomMargin + sourceTextFontHeight;
        let tagsTextTopMargin = sourceTextTopMargin;
        if (!cd.smallScreen && this.props.quoteData.source_link.includes("twitter.com/")) {
            tagsTextTopMargin *= (Platform.OS === 'web' ? 0.55 : 0.45);
        } else if (cd.smallScreen && this.props.quoteData.source_link.includes("twitter.com/")) {
            tagsTextTopMargin *= (Platform.OS === 'web' ? 0.69 : 0.71);
        } else if (cd.smallScreen && this.props.quoteData.source_link.includes("youtube.com/")) {
            tagsTextTopMargin *= (Platform.OS === 'web' ? 0.92 : 0.89);
        } else if (!cd.smallScreen) {
            tagsTextTopMargin *= 0.69;
        }
        const tagsSuggestTopMargin = cd.smallScreen ? verticalMargin * 0.7 : verticalMargin * 0.6;
        const tagsSuggestBottomMargin = cd.smallScreen ? verticalMargin * 1.18 : verticalMargin * 1.1;
        const tagsSuggestFontHeight = cd.smallScreen ? sourceTextFontHeight * 0.65 : sourceTextFontHeight * 0.6;
        const newTagBoxTopMargin = cd.smallScreen ? tagsSuggestTopMargin * 0.6 : tagsSuggestTopMargin * 0.7;
        const newTagBoxBottomMargin = tagsSuggestTopMargin * 0.8;
        const newTagBoxHeight = cd.smallScreen ? verticalMargin * 4 : verticalMargin * 3;
        let closeBtnTopMargin = cd.smallScreen ? verticalMargin * 6 : verticalMargin * 2.3;
        if (this.props.quoteData.source_link.includes("youtube.com/")) {
            closeBtnTopMargin *= (Platform.OS !== 'web' ? (cd.smallScreen ? 0.66 : 0.67) : (cd.smallScreen ? 0.48 : 0.35));
        } else if (Platform.OS !== 'web' && this.props.quoteData.source_link.includes("twitter.com/")) {
            closeBtnTopMargin *= (cd.smallScreen ? 0.56 : 0.6);
        } else if (Platform.OS === 'web' && this.props.quoteData.source_link.includes("twitter.com/")) {
            closeBtnTopMargin *= (cd.smallScreen ? 0.38 : 0.48);
        }
        const reportInputBoxWidth = cd.smallScreen ? cd.quoteModalWidth * 0.92 : cd.quoteModalWidth * 0.5;
        const reportInputBoxHeight = cd.smallScreen ? verticalMargin * 14 : verticalMargin * 20;
        const reportInstructionsWidth = cd.smallScreen ? cd.quoteModalWidth : cd.quoteModalWidth - reportInputBoxWidth - horizontalMargin * 2;
        const reportInstructionsHeight = cd.smallScreen ? reportInputBoxHeight * 0.7 : reportInputBoxHeight * 0.9;

        // Calculate font sizes.
        const headlineSoftHeight = cd.quoteModalHeight * 0.2;
        let headlineFontSize = (cd.smallScreen ? 1.08 : 1) * Math.sqrt((cd.quoteModalWidth * headlineSoftHeight) / "Normal size headline text like this and like that yo to fill up enough space".length);
        if (cd.smallScreen && Platform.OS === 'web') headlineFontSize = headlineFontSize * 1.05;
        let fullReportFontSize = (cd.smallScreen ? 0.75 : 0.65) * headlineFontSize;
        if (cd.smallScreen && Platform.OS === 'web') fullReportFontSize = fullReportFontSize * 1.02;
        const quoterTitleFontSize = Math.sqrt((cd.quoteModalWidth * 0.2 * thankRowHeight) / "Brought to you by: ".length);
        const quoteNameFontSize = Math.min(0.7 * thankRowHeight, Math.max(1.05 * quoterTitleFontSize, 0.95 * Math.sqrt((cd.quoteModalWidth * 0.3 * thankRowHeight) / this.props.quoteData.quoter.length)));
        const thankBtnFontSize = 0.9 * Math.sqrt((thankBtnWidth * thankBtnHeight) / "+0 THANKs ".length);
        const closeBtnFontSize = 0.75 * Math.sqrt((closeBtnWidth * closeBtnHeight) / "Close".length);
        const thankRow2FontSize = (cd.smallScreen ? 0.8 : 0.88) * Math.sqrt(((cd.quoteModalWidth - thankBtnWidth) * thankRowHeight) / thankRow2Text.length);
        const copyModalFontSize = Math.sqrt((copyModalWidth * copyModalHeight) / "Copied to Clipboard".length);
        const newTagBoxFontSize = cd.smallScreen ? 0.4 * newTagBoxHeight : 0.52 * newTagBoxHeight;
        const reportInstructionsFontSize = Math.min(sourceTextFontHeight * 0.9, 1.1 * Math.sqrt((reportInstructionsWidth * reportInputBoxHeight) / (reportInstructions.length + reportInstructions2.length + reportInstructions3.length)));
        const foundByText = cd.smallScreen
            ? (this.props.quoteData.quoter.length > 12 ? "BY:  " : "FOUND BY:  ")
            : "BROUGHT TO YOU BY:  "

        // Define colors.
        const gray = "#a8e5ff03";
        const fadedBlue = "#fafdff45";
        const fadedBrightBlue = "#8ad4ff11";
        const dividerBarColor = "#f2f9fcc6";
        const offWhite = "#fafafa";
        let thankBtnColor = this.state.thanksAdded === 0
            ? "#c7ced4fd"
            : (this.state.thanksAdded === 1 ? "#c7ced4fd" : "#c7ced4fd");
        let thank2BtnColor = thankBtnColor;
        if (this.state.thankBtnHovered) {
            thankBtnColor = this.state.thanksAdded === 0
                ? "#c0c9d1fd"
                : (this.state.thanksAdded === 1 ? "#c0c9d1fd" : "#c0c9d1fd");
        }
        if (this.state.thank2BtnHovered) {
            thank2BtnColor = this.state.thanksAdded === 0
                ? "#c0c9d1fd"
                : (this.state.thanksAdded === 1 ? "#c0c9d1fd" : "#c0c9d1fd");
        }
        let thankBtnBorderColor = "#f7fbfc12";
        let thankBtnTextColor = "#f7fcff";
        let sourceGradientBg1 = fadedBlue;
        let sourceGradientBg2 = fadedBrightBlue;
        let sourceGradientBg3 = "#fafdff13";

        // Format url.
        let resourceLink = this.props.quoteData.source_link;
        let twitterHTML = '';

        // Youtube link.
        if (resourceLink.includes('youtube.com')) {

            // Youtube link on a browser (iframe).
            if (Platform.OS !== 'web') {
                resourceLink = resourceLink.replace('m.', 'www.').replace('?app=desktop&', '?');
                resourceLink = resourceLink.replace('/watch?v=', '/embed/');
                resourceLink = resourceLink.replace('&t=', '?start=');
                if (resourceLink.substring(resourceLink.length - 1) === 's') {
                    resourceLink = resourceLink.substring(0, resourceLink.length - 1);
                }
            }

            // Youtube link on a phone (WebView).
            else {
                resourceLink = resourceLink.replace('m.', 'www.').replace('?app=desktop&', '?');
                resourceLink = resourceLink.replace('/watch?v=', '/embed/');
                resourceLink = resourceLink.replace('&t=', '?start=');
                if (resourceLink.substring(resourceLink.length - 1) === 's') {
                    resourceLink = resourceLink.substring(0, resourceLink.length - 1);
                }
                resourceLink = resourceLink + (resourceLink.includes('?') ? '&' : '?') + 'showinfo=0&controls=0';
            }
        }

        // Twitter HTML.
        else if (resourceLink.includes('twitter.com')) {
            resourceLink = resourceLink.replace('<blockquote ', '<blockquote align="center"');
            twitterHTML = `<!DOCTYPE html>\
        <html>\
          <head>\
            <meta charset="utf-8">\
            <meta name="viewport" content="width=device-width, initial-scale=1.0">\
            </head>\
            <body>\
              ${resourceLink}\
            </body>\
        </html>`
        }

        // Create reusable styles.
        const styles = StyleSheet.create({
            containerMobile: {
                borderTopLeftRadius: cd.quoteModalHeight * 0.05,
                borderTopRightRadius: cd.quoteModalHeight * 0.05,
                backgroundColor: "white",
                overflow: "hidden",
            },
            containerDesktop: {
                marginTop: (cd.screenHeight - cd.quoteModalHeight) * 0.5,
                marginLeft: (cd.screenWidth - cd.quoteModalWidth) * 0.5,
                marginRight: (cd.screenWidth - cd.quoteModalWidth) * 0.5,
                borderRadius: cd.quoteModalWidth * 0.02,
                backgroundColor: "white",
                overflow: "hidden",
                shadowOpacity: 0.9,
                shadowColor: "#e5f4fff8",
                shadowRadius: cd.quoteModalHeight * 0.3,
                shadowOffset: {
                    width: 0,
                    height: 0,
                },
            },
            innerScrollContainer: {
                width: cd.quoteModalWidth,
                height: cd.quoteModalHeight,
            },
            contentContainer: {
                width: cd.quoteModalWidth,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
            },
            dividerContainer: {
                width: cd.quoteModalWidth,
                height: dividerHeight,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflow: "visible",
            },
            dividerBar: {
                width: dividerBarWidth,
                height: dividerBarHeight * 1.5,
                borderRadius: dividerHeight * 0.4,
                marginBottom: dividerBarHeight * 0.2,
                backgroundColor: dividerBarColor,
                shadowColor: gray,
                shadowRadius: cd.smallScreen ? dividerBarHeight * 0.2 : dividerBarHeight * 0.2,
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? dividerBarHeight * 0.04 : 0,
                },
                shadowOpacity: cd.smallScreen ? 1 : 1,
                overflow: "visible",
            },
            headlineArea: {
                width: cd.quoteModalWidth,
                backgroundColor: fadedBrightBlue,
                paddingBottom: verticalMargin,
            },
            headlineGradient: {
                width: cd.quoteModalWidth,
                height: verticalMargin,
            },
            headlineSpeakerText: {
                paddingTop: cd.smallScreen ? cd.quoteModalHeight * 0.03 : cd.quoteModalHeight * 0.03,
                paddingLeft: textPaddingHorizontal,
                paddingRight: textPaddingHorizontal,
                fontSize: headlineFontSize,
                lineHeight: cd.smallScreen ? headlineFontSize * 1.4 : headlineFontSize * 1.3,
                fontFamily: "RobotoCondensed_700Bold",
                textAlign: "center",
                textShadowColor: "#bdbcb9",
                textShadowRadius: cd.smallScreen ? headlineFontSize * 0.06 : headlineFontSize * 0.08,
            },
            headlineQuoteText: {
                fontSize: headlineFontSize,
                lineHeight: cd.smallScreen ? headlineFontSize * 1.4 : headlineFontSize * 1.3,
                fontFamily: "RobotoCondensed_400Regular",
                textShadowColor: "#bdbcb9",
                textShadowRadius: cd.smallScreen ? headlineFontSize * 0.35 : headlineFontSize * 0.4,
            },
            thankRow: {
                width: cd.quoteModalWidth,
                height: thankRowHeight,
                flexDirection: "row",
                justifyContent: "space-between",
            },
            thankRowShadow: {
                width: cd.quoteModalWidth,
                height: (verticalMargin * 2),
            },
            quoterArea: {
                height: thankRowHeight,
                flexDirection: "row",
                alignItems: "flex-start",
            },
            quoterTextArea: {
                height: thankRowHeight,
            },
            quoterTitleText: {
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: horizontalMargin,
                fontSize: quoterTitleFontSize,
                fontWeight: "normal",
            },
            quoterNameText: {
                paddingTop: cd.smallScreen ? thankRowHeight * 0.05 : thankRowHeight * 0.08,
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: cd.smallScreen ? horizontalMargin * 0.1 : horizontalMargin * 0.2,
                fontSize: quoteNameFontSize,
                fontWeight: "normal",
                fontFamily: "NothingYouCouldDo_400Regular"
            },
            thankBtnContainer: {
                width: thankBtnWidth,
                height: thankBtnHeight,
                marginRight: horizontalMargin,
                marginTop: "auto",
                marginBottom: "auto",
                borderWidth: thankBtnHeight * 0.06,
                borderRadius: thankBtnHeight * 0.2,
                borderColor: thankBtnBorderColor,
                shadowColor: thankBtnColor,
                shadowRadius: cd.smallScreen ? thankBtnHeight * 0.05 : thankBtnHeight * 0.08,
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? thankBtnHeight * 0.015 : 0,
                },
                shadowOpacity: cd.smallScreen ? 0.2 : 0.4,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "gray",
            },
            thankBtnPressable: {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
            },
            thankBtn: {
                width: thankBtnWidth,
                height: thankBtnHeight,
                marginRight: horizontalMargin,
                marginTop: "auto",
                marginBottom: "auto",
                borderWidth: thankBtnHeight * 0.06,
                borderRadius: thankBtnHeight * 0.2,
                borderColor: thankBtnBorderColor,
                shadowColor: thankBtnColor,
                shadowRadius: cd.smallScreen ? thankBtnHeight * 0.05 : thankBtnHeight * 0.08,
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? thankBtnHeight * 0.015 : 0,
                },
                shadowOpacity: cd.smallScreen ? 0.2 : 0.4,
                justifyContent: "center",
                alignItems: "center",
            },
            thankBtnHover: {
                transform: [{scale: cd.smallScreen ? 1.015 : 1.01}],
            },
            thankBtnText: {
                fontSize: thankBtnFontSize,
                fontWeight: "bold",
                color: thankBtnTextColor,
            },
            fullReportTextArea: {
                paddingTop: verticalMargin * 1.8,
                paddingBottom: verticalMargin * 0.2,
                paddingLeft: textPaddingHorizontal * 2,
                paddingRight: textPaddingHorizontal * 2,
                backgroundColor: "white",
                flexDirection: "column",
            },
            fullReportText: {
                fontSize: fullReportFontSize,
                fontFamily: "Montserrat_500Bold",
                textAlign: "left",
                lineHeight: cd.smallScreen ? fullReportFontSize * 1.9 : fullReportFontSize * 1.7,
            },
            shareRowOuter: {
                backgroundColor: this.state.shareSelected ? gray : "white",
            },
            shareRow: {
                width: cd.quoteModalWidth,
                marginTop: cd.smallScreen ? verticalMargin * 0.9 : verticalMargin,
                marginBottom: this.state.shareSelected ? verticalMargin * 1.2 : thankRow2VerticalMargin * 1.45,
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            },
            copyModalContainer: {
                position: "absolute",
                width: cd.quoteModalWidth,
                height: copyModalHeight,
                marginTop: (thankRowHeight - copyModalHeight) * 0.5,
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            },
            copyModalArea: {
                marginLeft: horizontalMargin + shareBtnHeight * 0.5,
                borderRadius: copyModalHeight * 0.15,
                backgroundColor: "#404647",
            },
            copyModalText: {
                marginLeft: copyModalHeight * 0.6,
                marginRight: copyModalHeight * 0.6,
                marginTop: copyModalHeight * 0.08,
                marginBottom: copyModalHeight * 0.08,
                fontSize: copyModalFontSize,
                color: "white"
            },
            shareBtn: {
                transform: [{scale: this.state.shareBtnPopping ? 1.05 : 1}],
                height: shareBtnHeight,
                width: shareBtnHeight,
                marginLeft: horizontalMargin,
                //flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: this.state.shareBtnHovered || this.state.shareSelected ? "#f7fcffd7" : "white",
                borderRadius: shareBtnHeight * 0.5,
                borderColor: "#cbcfd1d7",
                borderWidth: shareBtnHeight * 0.05,
            },
            bookmarkBtn: {
                transform: [{scale: this.state.bookmarkBtnPopping ? 1.03 : 1}],
                height: shareBtnHeight,
                marginLeft: horizontalMargin,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: this.state.bookmarkSelected ? "#fff6d4d7" : (this.state.bookmarkBtnHovered ? "#f7fcffd7" : "white"),
                borderRadius: shareBtnHeight * 0.4,
                borderColor: "#cbcfd1d7",
                borderWidth: shareBtnHeight * 0.045,
            },
            shareBtnIcon: {
                width: shareBtnHeight * 0.7,
                height: shareBtnHeight * 0.7,
                //margin: shareBtnHeight * 0.05,
            },
            bookmarkBtnIcon: {
                width: shareBtnHeight * 0.8,
                height: shareBtnHeight * 0.8,
                margin: shareBtnHeight * 0.05,
            },
            bookmarkBtnText: {
                fontSize: shareBtnHeight * 0.55,
                fontWeight: "bold",
                color: "#454139",
                marginRight: shareBtnHeight * 0.25,
            },
            reportBtn: {
                transform: [{scale: this.state.reportBtnPopping ? 1.03 : 1}],
                height: shareBtnHeight,
                marginLeft: "auto",
                marginRight: horizontalMargin,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: this.state.reportBtnSelected ? "#c9e9ff9b" : (this.state.reportBtnHovered ? "#f7fcffd7" : "white"),
                borderRadius: shareBtnHeight * 0.4,
                borderColor: "#cbcfd1d7",
                borderWidth: shareBtnHeight * 0.045,
            },
            reportBtnText: {
                fontSize: shareBtnHeight * 0.55,
                fontWeight: "bold",
                color: "#454139",
                marginLeft: shareBtnHeight * 0.25,
                marginRight: shareBtnHeight * 0.15,
            },
            reportBtnIcon: {
                width: shareBtnHeight * 0.75,
                height: shareBtnHeight * 0.75,
                margin: shareBtnHeight * 0.04,
                marginRight: shareBtnHeight * 0.16,
            },
            shareExpansionRow: {
                width: cd.quoteModalWidth,
                backgroundColor: gray,
                marginBottom: thankRow2VerticalMargin * 1.8,
            },
            shareExpansionRowHalf: {
                width: cd.quoteModalWidth * 0.8,
                marginLeft: horizontalMargin,
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "flex-start",
            },
            shareOptionArea: {
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: shareRowHeight * 0.1,
                marginRight: shareBtnHeight * 0.7,
            },
            shareOptionIcon: {
                width: shareRowHeight * 0.6,
                height: shareRowHeight * 0.6,
                marginBottom: shareRowHeight * 0.05,
            },
            shareOptionText: {
                fontSize: shareRowHeight * 0.25,
                color: "#918c7d",
            },
            reportInnerContainer: {
                width: cd.quoteModalWidth,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
            },
            reportTitleText: {
                marginTop: verticalMargin,
                marginLeft: "auto",
                marginRight: "auto",
                fontSize: sourceTextFontHeight,
                fontWeight: "bold",
                textDecorationLine: "underline",
                color: "#313538",
                textShadowColor: "#bdbcb9",
                textShadowRadius: sourceTextFontHeight * 0.4,
            },
            reportInstructionsInvitationText: {
                marginTop: cd.smallScreen ? verticalMargin * 1.5 : verticalMargin,
                marginLeft: cd.smallScreen ? horizontalMargin * 0.9 : "auto",
                marginRight: cd.smallScreen ? horizontalMargin * 0.9 : "auto",
                fontSize: reportInstructionsFontSize,
                lineHeight: reportInstructionsFontSize * 1.4,
                textAlign: "center",
                color: "#17181a",
                textShadowColor: "#bdbcb9",
                textShadowRadius: sourceTextFontHeight * 0.1,
            },
            reportInstructionsText: {
                marginLeft: cd.smallScreen ? horizontalMargin * 0.6 : "auto",
                marginRight: cd.smallScreen ? horizontalMargin * 0.6 : "auto",
                fontSize: reportInstructionsFontSize,
                lineHeight: reportInstructionsFontSize * 1.8,
                textAlign: "center",
                color: "black",
                textShadowColor: "#bdbcb9",
                textShadowRadius: sourceTextFontHeight * 0.1,
            },
            reportInputBoxContainer: {
                width: reportInputBoxWidth,
                marginTop: cd.smallScreen ? verticalMargin * 1.5 : "auto",
                marginBottom: cd.smallScreen ? 0 : "auto",
                marginRight: cd.smallScreen ? 0 : horizontalMargin * 0.5,
            },
            reportInputBox: {
                width: reportInputBoxWidth,
                height: reportInputBoxHeight,
                paddingTop: cd.smallScreen ? verticalMargin * 0.45 : verticalMargin * 0.25,
                paddingLeft: cd.smallScreen ? horizontalMargin * 0.8 : horizontalMargin * 0.5,
                fontSize: reportInstructionsFontSize * 1.07,
                color: "#273827",
                borderRadius: verticalMargin * 0.5,
                borderWidth: verticalMargin * 0.3,
                borderColor: "#c0d6eb",
                backgroundColor: "#fafbfc",
            },
            cancelNSubmitContainer: {
                width: cd.quoteModalWidth,
                flexDirection: "row",
                marginTop: verticalMargin * 2,
                marginBottom: verticalMargin,
            },
            reportCancelBtn: {
                transform: [{scale: this.state.reportCancelPopping ? 1.03 : 1}],
                marginLeft: "auto",
                marginRight: cd.smallScreen ? verticalMargin * 2.5 : verticalMargin,
                borderRadius: cd.smallScreen ? verticalMargin * 1.8 : verticalMargin * 1.25,
                borderWidth: verticalMargin * 0.25,
                borderColor: "gray",
                backgroundColor: this.state.reportCancelHovered ? (cd.smallScreen ? "#edf0ed" : "#f7f7f7") : "white",
            },
            reportCancelBtnText: {
                fontSize: cd.smallScreen ? sourceTextFontHeight * 1.03 : sourceTextFontHeight * 0.82,
                fontWeight: "bold",
                color: "black",
                marginTop: cd.smallScreen ? verticalMargin * 0.23 : verticalMargin * 0.2,
                marginBottom: cd.smallScreen ? verticalMargin * 0.23 : verticalMargin * 0.2,
                marginLeft: cd.smallScreen ? horizontalMargin * 1.3 : horizontalMargin * 0.82,
                marginRight: cd.smallScreen ? horizontalMargin * 1.3 : horizontalMargin * 0.82,
            },
            reportSubmitBtn: {
                transform: [{scale: this.state.reportSubmitPopping ? 1.03 : 1}],
                marginLeft: verticalMargin,
                marginRight: "auto",
                borderRadius: cd.smallScreen ? verticalMargin * 1.8 : verticalMargin * 1.25,
                borderWidth: verticalMargin * 0.25,
                borderColor: "#29b32e",
                backgroundColor: this.state.reportSubmitHovered ? (cd.smallScreen ? "#c5fcc7" : "#ccffce") : "#dbffdc",
            },
            thankRow2: {
                width: cd.quoteModalWidth,
                height: shareRowHeight,
                flexDirection: "row",
                justifyContent: "flex-end",
                //backgroundColor: fadedBlue,
            },
            thankRow2Text: {
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: cd.smallScreen ? horizontalMargin * 1.7 : horizontalMargin * 1.5,
                color: "#02283dec",
                fontSize: thankRow2FontSize,
            },
            sourceArea: {
                width: cd.quoteModalWidth,
                //marginTop: thankRow2VerticalMargin,
                backgroundColor: "white",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            sourceTextArea: {
                width: cd.quoteModalWidth,
                marginTop: sourceTextTopMargin,
                marginBottom: platformSourceTextBottomMargin,
            },
            sourceText: {
                fontSize: sourceTextFontHeight,
                fontWeight: "bold",
                color: "#262c30",
                textAlign: "center",
                textShadowColor: "#bdbcb9",
                textShadowRadius: cd.smallScreen ? sourceTextFontHeight * 0.16 : sourceTextFontHeight * 0.18,
            },
            sourceContentYoutube: {
                width: cd.quoteModalWidth * 0.9,
                height: (cd.quoteModalWidth * 0.9) * (1080 / 1920),
                //marginTop: thankRow2VerticalMargin,
            },
            sourceContentTwitter: {
                width: cd.quoteModalWidth * 0.85,
                //marginTop: verticalMargin * 5,
            },
            tagsAreaContainer: {
                width: cd.quoteModalWidth,
                height: this.state.tagDisplayHeight + thankRow2VerticalMargin + sourceTextAreaHeight
                    + tagsSuggestTopMargin * 1.2 + tagsSuggestBottomMargin + tagsSuggestFontHeight
                    + (this.state.suggestingTagEdits ? newTagBoxTopMargin + newTagBoxBottomMargin + newTagBoxHeight : 0),
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
            },
            tagsTitleContainer: {
                width: cd.quoteModalWidth,
                marginTop: tagsTextTopMargin * (cd.smallScreen ? 1.2 : 1.05),
            },
            tagsTitleText: {
                fontSize: sourceTextFontHeight,
                fontWeight: "bold",
                color: "#262c30",
                textAlign: "center",
                textShadowColor: "#bdbcb9",
                textShadowRadius: cd.smallScreen ? sourceTextFontHeight * 0.16 : sourceTextFontHeight * 0.18,
            },
            tagsEditTextContainer: {
                marginTop: tagsSuggestTopMargin,
                marginBottom: tagsSuggestBottomMargin,
            },
            tagsEditText: {
                transform: [{scale: this.state.suggestTagsBtnHovered ? 1.03 : 1}],
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: verticalMargin * 0.03,
                marginBottom: verticalMargin * 0.03,
                fontSize: tagsSuggestFontHeight,
                color: this.state.suggestTagsBtnHovered ? "#262c30" : "#7c8082",
                textDecorationLine: "underline",
                textAlign: "center",
            },
            newTagTextBox: {
                width: cd.smallScreen ? cd.quoteModalWidth * 0.35 : cd.quoteModalWidth * 0.2,
                height: newTagBoxHeight,
                paddingLeft: verticalMargin,
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: newTagBoxTopMargin,
                marginBottom: newTagBoxBottomMargin,
                fontSize: newTagBoxFontSize,
                color: "#84848ad7",
                borderWidth: verticalMargin * 0.2,
                borderColor: "lightgray",
                borderRadius: verticalMargin * 0.32,
                backgroundColor: "#fafffb",
            },
            closeRow: {
                width: cd.quoteModalWidth,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: sourceGradientBg2,
            },
            closeBtnContainer: {
                height: closeBtnHeight * 1.2,
                marginTop: closeBtnTopMargin,
                marginBottom: cd.smallScreen ? verticalMargin * 2.5 : verticalMargin * 2,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: this.state.closeBtnHovered ? "#c7eeff" : "#e3f7ff",
                borderRadius: closeBtnHeight * 0.5,
                shadowColor: "#fff",
                shadowRadius: cd.smallScreen ? closeBtnHeight * 0.04 : closeBtnHeight * 0.08,
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? closeBtnHeight * 0.015 : 0,
                },
                shadowOpacity: cd.smallScreen ? 0.2 : 0.45,
            },
            closeBtnImg: {
                width: closeBtnFontSize * 0.8,
                height: closeBtnFontSize * 0.8,
                marginLeft: closeBtnHeight * 0.5,
                transform: [{rotate: "270deg"}],
                tintColor: this.state.closeBtnHovered ? null : "#2a3b42",
            },
            closeBtnText: {
                marginLeft: closeBtnHeight * 0.2,
                marginRight: closeBtnHeight * 0.5,
                fontSize: closeBtnFontSize,
                textAlign: "center",
                fontWeight: "bold",
                color: this.state.closeBtnHovered ? "#fff" : "#2a3b42" ,
                textShadowColor: "#ebf2f5",
                textShadowRadius: closeBtnFontSize * 0.025,
            },
        });

        return <View>
            <Modal
                animationType={"none"}
                onDismiss={this.props.onClose}
                onRequestClose={this.props.onClose}
                transparent={true}
                visible={this.props.visible}>
                <View>

                    {/**
                     Gray Screen Content Behind Modal
                     */}
                    <Pressable
                        onPress={this.props.onClose}
                        isTVSelectable={false}
                        style={{
                            position: "absolute",
                            width: cd.screenWidth,
                            height: cd.screenHeight,
                            backgroundColor: "rgba(192,194,207,0.94)",
                            // @ts-ignore
                            cursor: "default",
                        }}>

                    </Pressable>


                    {/**
                     Swipe-to-Dismiss Container
                     */}
                    <ConditionalWrapper
                        condition={cd.smallScreen}
                        wrapper={children =>
                            <ScrollView
                                ref={(ref) => {
                                    this.outerScrollRef = ref;
                                    if (Platform.OS === 'web' && cd.smallScreen) {
                                        this.outerScrollRef?.scrollTo({animated: false, y: cd.quoteModalHeight});
                                    }
                                }}
                                contentContainerStyle={{}}
                                style={{
                                    width: cd.quoteModalWidth,
                                    height: cd.quoteModalHeight,
                                    marginTop: cd.screenHeight - cd.quoteModalHeight,
                                }}
                                showsVerticalScrollIndicator={false}
                                pagingEnabled={true}
                                contentOffset={{x: 0, y: cd.quoteModalHeight}}
                                onScroll={(event) => {
                                    if (Platform.OS !== 'web') {
                                        if (this.state.screenRotated) {
                                            this.rotateScreenVertical();
                                        }
                                        return;
                                    }
                                    if (event.nativeEvent.contentOffset.y < cd.quoteModalHeight * 0.05) {
                                        this.props.onClose();
                                    }
                                }}
                                onMomentumScrollEnd={(event) => {
                                    if (event.nativeEvent.contentOffset.y < cd.quoteModalHeight * 0.05) {
                                        this.props.onClose();
                                    }
                                }}
                                scrollEventThrottle={60}
                                bounces={false}>
                                <View
                                    style={{
                                        width: cd.quoteModalWidth,
                                        height: cd.quoteModalHeight,
                                    }}/>
                                {children}
                            </ScrollView>
                        }>


                        {/**
                         Outer Container
                         */}
                        <Animated.View
                            entering={SlideInDown.duration(300)}
                            style={cd.smallScreen ? styles.containerMobile : styles.containerDesktop}>

                            {/**
                             Main Container
                             */}
                            <View
                                style={{
                                    width: cd.quoteModalWidth,
                                    height: cd.quoteModalHeight,
                                    // marginTop: cd.smallScreen ? verticalMargin * 2 : verticalMargin * 2,
                                    //flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}>

                                {/**
                                 Inner Scroll Area
                                 */}
                                <ScrollView
                                    style={styles.innerScrollContainer}
                                    showsVerticalScrollIndicator={false}
                                    scrollEventThrottle={60}
                                    bounces={false}>

                                    {/**
                                     Content Container
                                     */}
                                    <View
                                        style={styles.contentContainer}>

                                        {/**
                                         Headline
                                         */}
                                        <View
                                            style={styles.headlineArea}>
                                            <Text
                                                style={styles.headlineSpeakerText}>{this.props.quoteData.speaker + ": "}<Text
                                                style={styles.headlineQuoteText}>{this.props.quoteData.headline}</Text></Text>
                                        </View>

                                        {/**
                                         THANKs Row
                                         */}
                                        <LinearGradient
                                            colors={[fadedBrightBlue, fadedBlue]}
                                            style={styles.thankRow}>

                                            {/**
                                             Quoter text
                                             */}
                                            <View
                                                style={styles.quoterArea}>
                                                <View
                                                    style={styles.quoterTextArea}>
                                                    <Text
                                                        style={styles.quoterTitleText}>{foundByText}</Text>
                                                </View>
                                                <View
                                                    style={styles.quoterTextArea}>
                                                    <Text
                                                        style={styles.quoterNameText}>{this.props.quoteData.quoter}</Text>
                                                </View>
                                            </View>

                                            {/**
                                             THANK button
                                             */}
                                            <ThankBtn
                                                dims={cd}
                                                isHovered={this.state.thankBtnHovered}
                                                setIsHovered={(newBool) => this.setState({thankBtnHovered: newBool})}
                                                onPress={() => {
                                                    // TODO Add thank(s) to the backend.
                                                    this.setState({thanksAdded: this.state.thanksAdded === 2 ? 0 : this.state.thanksAdded + 1})
                                                }}
                                                marginRight={horizontalMargin}
                                                width={thankBtnWidth}
                                                height={thankBtnHeight}
                                                thanksAdded={this.state.thanksAdded}/>

                                        </LinearGradient>
                                        <LinearGradient
                                            colors={[fadedBlue, "white", "white", "white", "white"]}
                                            style={styles.thankRowShadow}>
                                        </LinearGradient>

                                        {/**
                                         Divider line
                                         */}
                                        <View
                                            style={[styles.dividerContainer,
                                                {
                                                    height: dividerBarHeight * 4,
                                                    justifyContent: "flex-end",
                                                    backgroundColor: "white",
                                                }]}>
                                            <View
                                                style={styles.dividerBar}>
                                            </View>
                                        </View>

                                        {/**
                                         Full Report
                                         */}
                                        <View
                                            style={styles.fullReportTextArea}>
                                            {handleLineBreaks(this.props.quoteData.full_report, styles.fullReportText, verticalMargin)}
                                        </View>

                                        {/**
                                         Bookmark & Share Button Row
                                         */}
                                        <View
                                            style={styles.shareRowOuter}>

                                            <View
                                                style={styles.shareRow}>

                                                {/**
                                                 Share Button
                                                 */}
                                                <Hoverable
                                                    onHoverIn={() => {
                                                        this.setState({shareBtnHovered: true})
                                                    }}
                                                    onHoverOut={() => {
                                                        this.setState({shareBtnHovered: false})
                                                    }}
                                                    onPressIn={() => {
                                                        this.setState({shareBtnHovered: true})
                                                    }}
                                                    onPressOut={() => setTimeout(() => {
                                                        if (!cd.smallScreen) return;
                                                        this.setState({shareBtnHovered: false});
                                                    }, 25)}
                                                >
                                                    <Pressable
                                                        style={styles.shareBtn}
                                                        onPress={() => {
                                                            this.setState({
                                                                shareSelected: !this.state.shareSelected,
                                                                reportBtnSelected: false,
                                                            });
                                                            if (!this.state.shareBtnPopping) {
                                                                this.setState({shareBtnPopping: true});
                                                                setTimeout(() => this.setState({shareBtnPopping: false}), 200);
                                                            }
                                                        }}
                                                    >
                                                        <Image
                                                            style={styles.shareBtnIcon}
                                                            source={share_icon}/>
                                                    </Pressable>
                                                </Hoverable>

                                                {/**
                                                 Bookmark Button
                                                 */}
                                                <Hoverable
                                                    onHoverIn={() => {
                                                        this.setState({bookmarkBtnHovered: true})
                                                    }}
                                                    onHoverOut={() => {
                                                        this.setState({bookmarkBtnHovered: false})
                                                    }}
                                                    onPressIn={() => {
                                                        this.setState({bookmarkBtnHovered: true})
                                                    }}
                                                    onPressOut={() => setTimeout(() => {
                                                        if (Platform.OS === 'web') return;
                                                        this.setState({bookmarkBtnHovered: false});
                                                    }, 25)}
                                                >
                                                    <Pressable
                                                        style={styles.bookmarkBtn}
                                                        onPress={() => {
                                                            this.setState({bookmarkSelected: !this.state.bookmarkSelected});
                                                            if (!this.state.bookmarkBtnPopping) {
                                                                this.setState({bookmarkBtnPopping: true});
                                                                setTimeout(() => this.setState({bookmarkBtnPopping: false}), 150);
                                                            }
                                                            // TODO Add/remove bookmark to the backend.
                                                        }}
                                                    >
                                                        <Image
                                                            style={styles.bookmarkBtnIcon}
                                                            source={saved_icon}/>
                                                        <Text
                                                            style={[styles.bookmarkBtnText, {
                                                                color: this.state.bookmarkBtnHovered ? "#454441" : "#4d4c49",
                                                            }]}
                                                            selectable={false}>Bookmark</Text>
                                                    </Pressable>
                                                </Hoverable>

                                                {/**
                                                 Report Button
                                                 */}
                                                {!this.state.userReportedQuote &&
                                                    <Hoverable
                                                        onHoverIn={() => {
                                                            this.setState({reportBtnHovered: true})
                                                        }}
                                                        onHoverOut={() => {
                                                            this.setState({reportBtnHovered: false})
                                                        }}
                                                        onPressIn={() => {
                                                            this.setState({reportBtnHovered: true})
                                                        }}
                                                        onPressOut={() => setTimeout(() => {
                                                            if (!cd.smallScreen) return;
                                                            this.setState({reportBtnHovered: false});
                                                        }, 25)}
                                                    >
                                                        <Pressable
                                                            style={styles.reportBtn}
                                                            onPress={() => {
                                                                this.setState({
                                                                    reportBtnSelected: !this.state.reportBtnSelected,
                                                                    shareSelected: false,
                                                                });
                                                                if (!this.state.reportBtnPopping) {
                                                                    this.setState({reportBtnPopping: true});
                                                                    setTimeout(() => this.setState({reportBtnPopping: false}), 150);
                                                                }
                                                            }}
                                                        >
                                                            <Text
                                                                style={[styles.reportBtnText, {
                                                                    color: this.state.reportBtnHovered ? "#454441" : "#4d4c49",
                                                                }]}
                                                                selectable={false}>Report</Text>
                                                            <Image
                                                                style={styles.reportBtnIcon}
                                                                source={report_icon}/>

                                                        </Pressable>
                                                    </Hoverable>
                                                }
                                            </View>
                                        </View>

                                        {/**
                                         Share Expansion Row
                                         */}
                                        {this.state.shareSelected &&
                                            <View
                                                style={styles.shareExpansionRow}>

                                                {/**
                                                 Share Icons Area
                                                 */}
                                                <View
                                                    style={styles.shareExpansionRowHalf}>

                                                    {/**
                                                     Copy Link
                                                     */}
                                                    <Hoverable
                                                        onHoverIn={() => {
                                                            this.setState({copyBtnHovered: true})
                                                        }}
                                                        onHoverOut={() => {
                                                            this.setState({copyBtnHovered: false})
                                                        }}
                                                        onPressIn={() => {
                                                            this.setState({copyBtnHovered: true})
                                                        }}
                                                        onPressOut={() => setTimeout(() => {
                                                            if (!cd.smallScreen && Platform.OS === 'web') return;
                                                            this.setState({copyBtnHovered: false});
                                                        }, 25)}
                                                    >
                                                        <Pressable
                                                            style={[styles.shareOptionArea, {marginRight: shareBtnHeight * 0.6,}]}
                                                            onPress={() => {
                                                                // TODO Copy quote link to clipboard.

                                                                // Show copy modal and hide share options.
                                                                this.setState({
                                                                    copyModalVisible: true,
                                                                    shareSelected: false,
                                                                });
                                                                setTimeout(() => this.setState({copyModalVisible: false}), 2500);
                                                            }}
                                                        >
                                                            <Image
                                                                style={[styles.shareOptionIcon,
                                                                    {
                                                                        transform: [{scale: this.state.copyBtnHovered ? 1.04 : 1}],
                                                                    }]}
                                                                source={link_icon}/>
                                                            <Text
                                                                style={[styles.shareOptionText,
                                                                    {
                                                                        color: this.state.copyBtnHovered ? "#615f5b" : "#918c7d",
                                                                    }]}>Copy It</Text>
                                                        </Pressable>
                                                    </Hoverable>

                                                    {/**
                                                     Tweet Link
                                                     */}
                                                    <Hoverable
                                                        onHoverIn={() => {
                                                            this.setState({tweetBtnHovered: true})
                                                        }}
                                                        onHoverOut={() => {
                                                            this.setState({tweetBtnHovered: false})
                                                        }}
                                                        onPressIn={() => {
                                                            this.setState({tweetBtnHovered: true})
                                                        }}
                                                        onPressOut={() => setTimeout(() => {
                                                            if (!cd.smallScreen && Platform.OS === 'web') return;
                                                            this.setState({tweetBtnHovered: false});
                                                        }, 25)}
                                                    >
                                                        <Pressable
                                                            style={styles.shareOptionArea}
                                                            onPress={() => {
                                                                this.setState({
                                                                    // TODO Open twitter app/website.
                                                                });
                                                                setTimeout(() => this.setState({shareSelected: false}), 200);
                                                            }}
                                                        >
                                                            <Image
                                                                style={[styles.shareOptionIcon,
                                                                    {
                                                                        transform: [{scale: this.state.tweetBtnHovered ? 1.04 : 1}],
                                                                    }]}
                                                                source={tweet_icon}/>
                                                            <Text
                                                                style={[styles.shareOptionText,
                                                                    {
                                                                        color: this.state.tweetBtnHovered ? "#615f5b" : "#918c7d",
                                                                    }]}>Tweet It</Text>
                                                        </Pressable>
                                                    </Hoverable>

                                                    {/**
                                                     Post Link on Facebook
                                                     */}
                                                    <Hoverable
                                                        onHoverIn={() => {
                                                            this.setState({facebookBtnHovered: true})
                                                        }}
                                                        onHoverOut={() => {
                                                            this.setState({facebookBtnHovered: false})
                                                        }}
                                                        onPressIn={() => {
                                                            this.setState({facebookBtnHovered: true})
                                                        }}
                                                        onPressOut={() => setTimeout(() => {
                                                            if (!cd.smallScreen && Platform.OS === 'web') return;
                                                            this.setState({facebookBtnHovered: false});
                                                        }, 25)}
                                                    >
                                                        <Pressable
                                                            style={styles.shareOptionArea}
                                                            onPress={() => {
                                                                this.setState({
                                                                    // TODO Open facebook app/website.
                                                                });
                                                                setTimeout(() => this.setState({shareSelected: false}), 200);
                                                            }}
                                                        >
                                                            <Image
                                                                style={[styles.shareOptionIcon,
                                                                    {
                                                                        transform: [{scale: this.state.facebookBtnHovered ? 1.04 : 1}],
                                                                    }]}
                                                                source={facebook_icon}/>
                                                            <Text
                                                                style={[styles.shareOptionText,
                                                                    {
                                                                        color: this.state.facebookBtnHovered ? "#615f5b" : "#918c7d",
                                                                    }]}>Post It</Text>
                                                        </Pressable>
                                                    </Hoverable>

                                                    {/**
                                                     Post Link on Instagram
                                                     */}
                                                    <Hoverable
                                                        onHoverIn={() => {
                                                            this.setState({instagramBtnHovered: true})
                                                        }}
                                                        onHoverOut={() => {
                                                            this.setState({instagramBtnHovered: false})
                                                        }}
                                                        onPressIn={() => {
                                                            this.setState({instagramBtnHovered: true})
                                                        }}
                                                        onPressOut={() => setTimeout(() => {
                                                            if (!cd.smallScreen && Platform.OS === 'web') return;
                                                            this.setState({instagramBtnHovered: false});
                                                        }, 25)}
                                                    >
                                                        <Pressable
                                                            style={styles.shareOptionArea}
                                                            onPress={() => {
                                                                this.setState({
                                                                    // TODO Open instagram app/website.
                                                                });
                                                                setTimeout(() => this.setState({shareSelected: false}), 200);
                                                            }}
                                                        >
                                                            <Image
                                                                style={[styles.shareOptionIcon,
                                                                    {
                                                                        transform: [{scale: this.state.instagramBtnHovered ? 1.04 : 1}],
                                                                    }]}
                                                                source={instagram_icon}/>
                                                            <Text
                                                                style={[styles.shareOptionText,
                                                                    {
                                                                        color: this.state.instagramBtnHovered ? "#615f5b" : "#918c7d",
                                                                    }]}>'Gram It</Text>
                                                        </Pressable>
                                                    </Hoverable>

                                                </View>
                                            </View>
                                        }

                                        {/**
                                         "Report Quote" Expansion Row
                                         */}
                                        {(this.state.reportBtnSelected && !this.state.shareSelected) &&
                                            <View
                                                style={[styles.shareExpansionRow,
                                                    {}]}>
                                                <View
                                                    style={[styles.reportInnerContainer, {
                                                        marginTop: cd.smallScreen ? verticalMargin : 0,
                                                        backgroundColor: "#c9e9ff9b",
                                                    }]}>

                                                    {/**
                                                     "Report Quote" Text
                                                     */}
                                                    <Text
                                                        style={styles.reportTitleText}>Report Inaccurate Quote</Text>

                                                    <View
                                                        style={{
                                                            width: cd.quoteModalWidth,
                                                            flexDirection: "column",
                                                            justifyContent: cd.smallScreen ? "flex-start" : "space-between",
                                                            alignItems: "center",
                                                        }}>
                                                        <Text
                                                            style={styles.reportInstructionsInvitationText}>{reportInstructions}</Text>
                                                    </View>

                                                    {/**
                                                     Instructions and Input Box Container
                                                     */}
                                                    <View
                                                        style={{
                                                            width: cd.quoteModalWidth,
                                                            marginTop: verticalMargin * 2,
                                                            flexDirection: cd.smallScreen ? "column" : "row",
                                                            justifyContent: cd.smallScreen ? "flex-start" : "space-between",
                                                            alignItems: cd.smallScreen ? "center" : "center",
                                                        }}>

                                                        {/**
                                                         "Report Quote" Instructions
                                                         */}
                                                        <View
                                                            style={{
                                                                width: reportInstructionsWidth,
                                                                marginLeft: horizontalMargin * 0.5,
                                                                marginRight: cd.smallScreen ? horizontalMargin * 0.5 : horizontalMargin,
                                                            }}>
                                                            <Text
                                                                style={styles.reportInstructionsText}>{reportInstructions2}<Text
                                                                style={{fontWeight: "bold"}}>{reportInstructions3}</Text></Text>
                                                        </View>

                                                        {/**
                                                         "Report Quote" Input Box
                                                         */}
                                                        <View
                                                            style={styles.reportInputBoxContainer}>
                                                            <TextInput
                                                                multiline={true}
                                                                scrollEnabled={true}
                                                                maxLength={350}
                                                                allowFontScaling={true}
                                                                autoCapitalize={"none"}
                                                                autoComplete={"off"}
                                                                clearButtonMode={"while-editing"}
                                                                underlineColorAndroid={"transparent"}
                                                                enablesReturnKeyAutomatically={true}
                                                                onChangeText={(input) => {
                                                                    this.setState({reportInput: input})
                                                                }}
                                                                value={this.state.reportInput}
                                                                onSubmitEditing={() => {
                                                                    if (this.state.reportInput.trim().length < 3) {
                                                                        this.setState({reportInput: initReportInput});
                                                                    }
                                                                }}
                                                                onFocus={() => {
                                                                    if (this.state.reportInput === initReportInput) {
                                                                        this.setState({reportInput: ""});
                                                                    }
                                                                }}
                                                                style={styles.reportInputBox}>
                                                            </TextInput>
                                                        </View>
                                                    </View>

                                                    {/**
                                                     "Report Quote" Cancel & Submit Buttons Container
                                                     */}
                                                    <View
                                                        style={styles.cancelNSubmitContainer}>

                                                        {/**
                                                         "Report Quote" Cancel Button
                                                         */}
                                                        <Hoverable
                                                            onHoverIn={() => {
                                                                this.setState({reportCancelHovered: true})
                                                            }}
                                                            onHoverOut={() => {
                                                                this.setState({reportCancelHovered: false})
                                                            }}
                                                            onPressIn={() => {
                                                                this.setState({reportCancelHovered: true})
                                                            }}
                                                            onPressOut={() => setTimeout(() => {
                                                                if (!cd.smallScreen && Platform.OS === 'web') return;
                                                                this.setState({reportCancelHovered: false});
                                                            }, 25)}
                                                        >
                                                            <Pressable
                                                                style={styles.reportCancelBtn}
                                                                onPress={() => {
                                                                    // Hide report area; display button pop-out effect.
                                                                    if (!this.state.reportCancelPopping) {
                                                                        this.setState({reportCancelPopping: true});
                                                                        setTimeout(() => this.setState({reportCancelPopping: false}), 150);
                                                                        setTimeout(() => this.setState({reportBtnSelected: false}), 250);
                                                                    }
                                                                }}
                                                            >
                                                                <Text
                                                                    style={styles.reportCancelBtnText}
                                                                    selectable={false}>Cancel</Text>
                                                            </Pressable>
                                                        </Hoverable>

                                                        {/**
                                                         "Report Quote" Submit Button
                                                         */}
                                                        <Hoverable
                                                            onHoverIn={() => {
                                                                this.setState({reportSubmitHovered: true})
                                                            }}
                                                            onHoverOut={() => {
                                                                this.setState({reportSubmitHovered: false})
                                                            }}
                                                            onPressIn={() => {
                                                                this.setState({reportSubmitHovered: true})
                                                            }}
                                                            onPressOut={() => setTimeout(() => {
                                                                if (!cd.smallScreen && Platform.OS === 'web') return;
                                                                this.setState({reportSubmitHovered: false});
                                                            }, 25)}
                                                        >
                                                            <Pressable
                                                                style={styles.reportSubmitBtn}
                                                                onPress={() => {
                                                                    // Validate input.
                                                                    if (this.state.reportInput.trim().length < 5 || this.state.reportInput.trim() === initReportInput) {
                                                                        this.setState({reportSubmitPopping: true});
                                                                        setTimeout(() => this.setState({reportSubmitPopping: false}), 150);
                                                                        return;
                                                                    }

                                                                    // Close the report area and hide the report button; display button pop-out effect.
                                                                    if (!this.state.reportSubmitPopping) {
                                                                        this.setState({reportSubmitPopping: true});
                                                                        setTimeout(() => this.setState({reportSubmitPopping: false}), 150);
                                                                        setTimeout(() => this.setState({
                                                                            reportBtnSelected: false,
                                                                            userReportedQuote: true,
                                                                        }), 250);
                                                                    }

                                                                    // TODO Send the report to the backend.
                                                                }}
                                                            >
                                                                <Text
                                                                    style={styles.reportCancelBtnText}
                                                                    selectable={false}>Submit</Text>
                                                            </Pressable>
                                                        </Hoverable>
                                                    </View>

                                                </View>
                                            </View>
                                        }

                                        {/**
                                         Divider line
                                         */}
                                        <View
                                            style={[styles.dividerContainer,
                                                {
                                                    height: cd.smallScreen ? dividerBarHeight * 4.5 : dividerBarHeight * 2,
                                                    justifyContent: "flex-end",
                                                    backgroundColor: "white",
                                                }]}>
                                            <View
                                                style={styles.dividerBar}>
                                            </View>
                                        </View>

                                        {/**
                                         Second THANK Row
                                         */}
                                        <LinearGradient
                                            colors={["white", "white", "white", "white", fadedBlue]}
                                            style={[styles.thankRowShadow,
                                                {
                                                    marginTop: cd.smallScreen ? verticalMargin * 0.8 : verticalMargin * 1.2,
                                                }]}>
                                        </LinearGradient>
                                        <LinearGradient
                                            colors={[sourceGradientBg1, sourceGradientBg2, sourceGradientBg3]}
                                            nativeID={"thankRow2"}
                                            style={styles.thankRow2}>

                                            {/**
                                             THANK Info Text
                                             */}
                                            <Text
                                                style={styles.thankRow2Text}>{thankRow2Text}
                                            </Text>

                                            {/**
                                             THANK button
                                             */}
                                            <ThankBtn
                                                dims={cd}
                                                isHovered={this.state.thank2BtnHovered}
                                                setIsHovered={(newBool) => this.setState({thank2BtnHovered: newBool})}
                                                onPress={() => {
                                                    // TODO Add thank(s) to the backend.
                                                    this.setState({thanksAdded: this.state.thanksAdded === 2 ? 0 : this.state.thanksAdded + 1})
                                                }}
                                                marginRight={horizontalMargin}
                                                width={thankBtnWidth}
                                                height={thankBtnHeight}
                                                thanksAdded={this.state.thanksAdded}/>

                                            {/**
                                             Copied Link Banner
                                             */}
                                            {this.state.copyModalVisible &&
                                                <Animated.View
                                                    style={styles.copyModalContainer}>
                                                    <Animated.View
                                                        entering={FadeIn.duration(100).easing(Easing.exp)}
                                                        exiting={FadeOut.duration(400).easing(Easing.exp)}
                                                        style={styles.copyModalArea}>
                                                        <Animated.Text
                                                            entering={FadeIn.duration(100).easing(Easing.exp)}
                                                            exiting={FadeOut.duration(400).easing(Easing.exp)}
                                                            style={styles.copyModalText}>Copied to
                                                            Clipboard</Animated.Text>
                                                    </Animated.View>
                                                </Animated.View>
                                            }
                                        </LinearGradient>

                                        {/**
                                         Quote Source Area (YouTube, mobile)
                                         */}
                                        {Platform.OS !== 'web' && this.props.quoteData.source_link.includes("youtube.com/") &&
                                            <LinearGradient
                                                colors={[sourceGradientBg3, sourceGradientBg2, sourceGradientBg1, sourceGradientBg1]}
                                                style={{
                                                    width: cd.quoteModalWidth,
                                                    height: (cd.quoteModalWidth * 0.9) * (1080 / 1920) + thankRow2VerticalMargin + sourceTextAreaHeight,
                                                    flexDirection: "column",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                }}>
                                                <View
                                                    style={styles.sourceTextArea}>
                                                    <Text
                                                        style={styles.sourceText}>SOURCE</Text>
                                                </View>
                                                <TouchableWithoutFeedback
                                                    style={{
                                                        width: cd.quoteModalWidth * 0.9,
                                                        height: (cd.quoteModalWidth * 0.9) * (1080 / 1920) + thankRow2VerticalMargin,
                                                        marginLeft: cd.quoteModalWidth * 0.05,
                                                        marginRight: cd.quoteModalWidth * 0.05,
                                                    }}
                                                    onPress={this.rotateScreenHorizontal}>
                                                    <WebView
                                                        style={{
                                                            flex: 1,
                                                        }}
                                                        containerStyle={{
                                                            width: cd.quoteModalWidth * 0.9,
                                                            height: (cd.quoteModalWidth * 0.9) * (1080 / 1920),
                                                            marginTop: thankRow2VerticalMargin * 0.7,
                                                            borderRadius: (cd.quoteModalWidth * 0.9) * 0.02,
                                                        }}
                                                        javaScriptEnabled={true}
                                                        scrollEnabled={false}
                                                        source={{uri: resourceLink}}>
                                                    </WebView>
                                                </TouchableWithoutFeedback>
                                            </LinearGradient>
                                        }

                                        {/**
                                         Quote Source Area (YouTube, web)
                                         */}
                                        {Platform.OS === 'web' && this.props.quoteData.source_link.includes("youtube.com/") &&
                                            <LinearGradient
                                                nativeID={"youTubeContainer"}
                                                colors={[sourceGradientBg3, sourceGradientBg2, sourceGradientBg1, sourceGradientBg1]}
                                                style={{
                                                    width: cd.quoteModalWidth,
                                                    height: ((cd.smallScreen ? cd.quoteModalWidth * 0.9 : cd.quoteModalWidth * 0.7) * (1080 / 1920)) + thankRow2VerticalMargin + sourceTextAreaHeight,
                                                    flexDirection: "column",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                }}>
                                                <View
                                                    style={styles.sourceTextArea}>
                                                    <Text
                                                        style={styles.sourceText}>SOURCE</Text>
                                                </View>
                                                <View
                                                    style={{
                                                        width: cd.smallScreen ? cd.quoteModalWidth * 0.9 : cd.quoteModalWidth * 0.7,
                                                        height: (cd.smallScreen ? cd.quoteModalWidth * 0.9 : cd.quoteModalWidth * 0.7) * (1080 / 1920),
                                                        borderRadius: (cd.smallScreen ? cd.quoteModalWidth * 0.9 : cd.quoteModalWidth * 0.7) * 0.02,
                                                        shadowColor: "#808d96",
                                                        shadowRadius: (cd.quoteModalWidth * 0.9) * 0.01,
                                                        shadowOpacity: 0.7,
                                                    }}>
                                                    <iframe
                                                        width={cd.smallScreen ? cd.quoteModalWidth * 0.9 : cd.quoteModalWidth * 0.7}
                                                        height={(cd.smallScreen ? cd.quoteModalWidth * 0.9 : cd.quoteModalWidth * 0.7) * (1080 / 1920)}
                                                        style={{
                                                            borderRadius: (cd.smallScreen ? cd.quoteModalWidth * 0.9 : cd.quoteModalWidth * 0.7) * 0.02,
                                                        }}
                                                        src={resourceLink}
                                                        frameBorder={0}>
                                                    </iframe>
                                                </View>
                                            </LinearGradient>
                                        }

                                        {/**
                                         Quote Source Area (Twitter, mobile)
                                         */}
                                        {Platform.OS !== 'web' && this.props.quoteData.source_link.includes("twitter.com/") &&
                                            <LinearGradient
                                                colors={[sourceGradientBg3, sourceGradientBg2, sourceGradientBg1, sourceGradientBg1]}
                                                style={{
                                                    width: cd.quoteModalWidth,
                                                    height: this.state.mobileTweetHeight + thankRow2VerticalMargin + sourceTextAreaHeight,
                                                    flexDirection: "column",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                }}>
                                                <View
                                                    style={styles.sourceTextArea}>
                                                    <Text
                                                        style={styles.sourceText}>SOURCE</Text>
                                                </View>
                                                <WebView
                                                    style={{
                                                        width: cd.quoteModalWidth * 0.85,
                                                        height: this.state.mobileTweetHeight,
                                                        backgroundColor: "#fff0",
                                                        shadowColor: "#808d96",
                                                        shadowRadius: (cd.quoteModalWidth * 0.85) * 0.007,
                                                        shadowOpacity: 0.3,
                                                        shadowOffset: {
                                                            width: 0,
                                                            height: verticalMargin * 0.05,
                                                        },
                                                    }}
                                                    javaScriptEnabled={true}
                                                    source={{
                                                        html: twitterHTML + ` <script>
                                                                                      setTimeout(function () {
                                                                                          let scrollHeight = document.body.scrollHeight;
                                                                                        window.ReactNativeWebView.postMessage(scrollHeight);
                                                                                      }, 500);
                                                                                      setTimeout(function () {
                                                                                          let scrollHeight = document.body.scrollHeight;
                                                                                        window.ReactNativeWebView.postMessage(scrollHeight);
                                                                                      }, 2000);
                                                                                      setTimeout(function () {
                                                                                          let scrollHeight = document.body.scrollHeight;
                                                                                        window.ReactNativeWebView.postMessage(scrollHeight);
                                                                                      }, 5000);
                                                                                 </script>`
                                                    }}
                                                    scrollEnabled={false}
                                                    onMessage={(event) => {
                                                        this.setState({mobileTweetHeight: parseInt(event.nativeEvent.data) + verticalMargin * 1.5});
                                                    }}>
                                                </WebView>
                                            </LinearGradient>
                                        }

                                        {/**
                                         Quote Source Area (Twitter, web)
                                         */}
                                        {Platform.OS === 'web' && this.props.quoteData.source_link.includes("twitter.com/") &&
                                            <LinearGradient
                                                colors={[sourceGradientBg3, sourceGradientBg2, sourceGradientBg1, sourceGradientBg1]}
                                                style={{
                                                    width: cd.quoteModalWidth,
                                                    height: this.state.mobileTweetHeight + thankRow2VerticalMargin * 0.9 + sourceTextAreaHeight,
                                                    flexDirection: "column",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                }}>
                                                <View
                                                    style={styles.sourceTextArea}>
                                                    <Text
                                                        style={styles.sourceText}>SOURCE</Text>
                                                </View>
                                                <iframe
                                                    width={cd.smallScreen ? cd.quoteModalWidth * 0.85 : cd.quoteModalWidth * 0.7}
                                                    height={(cd.smallScreen ? cd.quoteModalWidth * 0.85 : cd.quoteModalWidth * 0.7) * 0.9}
                                                    style={{
                                                        height: "100vh",
                                                    }}
                                                    srcDoc={resourceLink}
                                                    frameBorder={0}
                                                    onLoad={() => {
                                                        setTimeout(() => {
                                                            this.resizeTweetContainerDesktop(verticalMargin * 1.5);
                                                        }, 300);
                                                        setTimeout(() => {
                                                            this.resizeTweetContainerDesktop(verticalMargin * 1.5);
                                                        }, 700);
                                                        setTimeout(() => {
                                                            this.resizeTweetContainerDesktop(verticalMargin * 1.5);
                                                        }, 1500);
                                                        setTimeout(() => {
                                                            this.resizeTweetContainerDesktop(verticalMargin * 1.5);
                                                        }, 2500);
                                                        setTimeout(() => {
                                                            this.resizeTweetContainerDesktop(verticalMargin * 1.5);
                                                        }, 3500);
                                                        setTimeout(() => {
                                                            this.resizeTweetContainerDesktop(verticalMargin * 1.5);
                                                        }, 5000);
                                                    }}
                                                    id={"twitterIFrame"}>
                                                </iframe>
                                            </LinearGradient>
                                        }

                                        {/**
                                         Quote Source Area (Image)
                                         */}
                                        {!this.props.quoteData.source_link.includes("youtube.com/") && !this.props.quoteData.source_link.includes("twitter.com/") &&
                                            <LinearGradient
                                                colors={[sourceGradientBg3, sourceGradientBg2, sourceGradientBg1, sourceGradientBg1]}
                                                style={{
                                                    width: cd.quoteModalWidth,
                                                    height: this.state.sourceImgHeight + thankRow2VerticalMargin + sourceTextAreaHeight,
                                                    flexDirection: "column",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}>
                                                <View
                                                    style={styles.sourceTextArea}>
                                                    <Text
                                                        style={styles.sourceText}>SOURCE</Text>
                                                </View>
                                                <TouchableWithoutFeedback
                                                    style={{
                                                        width: cd.smallScreen ? cd.quoteModalWidth * 0.8 : cd.quoteModalWidth * 0.7,
                                                        height: 200,
                                                        overflow: "visible",
                                                    }}
                                                    onLayout={(event) => {
                                                        this.setState({sourceImgHeight: event.nativeEvent.layout.height});
                                                    }}
                                                    onPress={() => {
                                                        // TODO Open sourceLink in another browser window.
                                                    }}>
                                                    <Image

                                                        style={{
                                                            width: cd.smallScreen ? cd.quoteModalWidth * 0.8 : cd.quoteModalWidth * 0.7,
                                                            height: this.state.sourceImgHeight,
                                                            marginTop: "auto",
                                                            borderRadius: (cd.smallScreen ? cd.quoteModalWidth * 0.8 : cd.quoteModalWidth * 0.7) * 0.02,
                                                            shadowColor: "#808d96",
                                                            shadowRadius: (cd.quoteModalWidth * 0.9) * 0.01,
                                                            shadowOpacity: 0.7,
                                                        }}
                                                        source={{uri: resourceLink}}
                                                        resizeMode={"contain"}>
                                                    </Image>
                                                </TouchableWithoutFeedback>
                                            </LinearGradient>
                                        }

                                        {/**
                                         Tags Area
                                         */}
                                        <LinearGradient
                                            colors={[sourceGradientBg1, sourceGradientBg2]}
                                            style={styles.tagsAreaContainer}>

                                            {/**
                                             Tags Title
                                             */}
                                            <View
                                                style={styles.tagsTitleContainer}>
                                                <Text
                                                    style={styles.tagsTitleText}>TAGS</Text>
                                            </View>

                                            {/**
                                             "Suggest Edits" Text
                                             */}
                                            <Hoverable
                                                onHoverIn={() => this.setState({suggestTagsBtnHovered: true})}
                                                onHoverOut={() => this.setState({suggestTagsBtnHovered: false})}
                                                onPressIn={() => this.setState({suggestTagsBtnHovered: true})}
                                                onPressOut={() => setTimeout(() => {
                                                    if (Platform.OS === 'web') return;
                                                    this.setState({suggestTagsBtnHovered: false});
                                                }, 25)}
                                            >
                                                <Pressable
                                                    style={styles.tagsEditTextContainer}
                                                    hitSlop={{
                                                        top: tagsSuggestFontHeight * 0.6,
                                                        bottom: tagsSuggestFontHeight * 0.6,
                                                        left: tagsSuggestFontHeight,
                                                        right: tagsSuggestFontHeight
                                                    }}
                                                    onPress={() => this.setState({suggestingTagEdits: !this.state.suggestingTagEdits})}
                                                >
                                                    <Text
                                                        style={styles.tagsEditText}>{this.state.suggestingTagEdits ? "Done Editing" : "Suggest Edits"}</Text>
                                                </Pressable>
                                            </Hoverable>

                                            {/**
                                             New Tag Text Box
                                             */}
                                            {this.state.suggestingTagEdits &&
                                                <TextInput
                                                    maxLength={20}
                                                    allowFontScaling={false}
                                                    autoCapitalize={"none"}
                                                    autoComplete={"off"}
                                                    clearButtonMode={"while-editing"}
                                                    underlineColorAndroid={"transparent"}
                                                    onChangeText={(input) => {
                                                        this.setState({newTagSuggestion: input})
                                                    }}
                                                    value={this.state.newTagSuggestion}
                                                    onFocus={() => {
                                                        if (this.state.newTagSuggestion.toLowerCase() === "new tag") {
                                                            this.setState({newTagSuggestion: ""});
                                                        }
                                                    }}
                                                    onSubmitEditing={(event) => {
                                                        const newTag = event.nativeEvent.text.trim();
                                                        if (newTag.length < 3) {
                                                            this.setState({newTagSuggestion: "New Tag"});
                                                            return;
                                                        }
                                                        const suggestedNewTags = [...this.state.tagsSuggestNew];

                                                        // Remove tag if it exists.
                                                        for (let existingTag of [...this.props.quoteData.tags, ...this.state.tagsSuggestNew]) {
                                                            if (existingTag.trim().toLowerCase() == newTag.toLowerCase()) {
                                                                // Disregard suggestion if quote already has the suggested tag.
                                                                if (this.props.quoteData.tags.includes(existingTag)) {
                                                                    this.setState({newTagSuggestion: ""});
                                                                    return;
                                                                }

                                                                // Remove similar/same tag from suggestions if user already suggested it.
                                                                else {
                                                                    suggestedNewTags.splice(suggestedNewTags.indexOf(existingTag), 1);
                                                                    this.setState({tagsSuggestNew: suggestedNewTags});
                                                                }
                                                            }
                                                        }

                                                        // Add the suggested tag to list of suggestions.
                                                        suggestedNewTags.push(newTag);
                                                        this.setState({
                                                            tagsSuggestNew: suggestedNewTags,
                                                            newTagSuggestion: "New Tag"
                                                        });
                                                    }}
                                                    style={styles.newTagTextBox}>
                                                </TextInput>
                                            }


                                            {/**
                                             Category tags content
                                             */}
                                            <ConditionalWrapper
                                                condition={true}
                                                wrapper={children => {
                                                    if (Platform.OS === 'web') {
                                                        return <View
                                                            style={{
                                                                width: cd.quoteModalWidth * 0.8,
                                                                height: this.state.tagDisplayHeight,
                                                                //borderTopLeftRadius: cd.smallScreen ? 0 : verticalMargin * 0.2,
                                                                //borderTopRightRadius: cd.smallScreen ? 0 : verticalMargin * 0.2,
                                                            }}>
                                                            <View
                                                                onLayout={(event) => {
                                                                    this.setState({tagDisplayHeight: event.nativeEvent.layout.height});
                                                                }}
                                                                style={{
                                                                    width: cd.quoteModalWidth * 0.8,
                                                                    flexDirection: "row",
                                                                    justifyContent: "center",
                                                                    alignItems: "flex-start",
                                                                    flexWrap: "wrap",
                                                                }}>{children}</View>
                                                        </View>;
                                                    } else {
                                                        return <ScrollView
                                                            style={{
                                                                width: cd.quoteModalWidth * 0.95,
                                                                height: this.state.tagDisplayHeight,
                                                            }}
                                                            showsVerticalScrollIndicator={false}
                                                            scrollEventThrottle={100}
                                                            bounces={false}
                                                            scrollEnabled={false}
                                                            scrollsToTop={false}
                                                            onContentSizeChange={(contentWidth, contentHeight) => {
                                                                this.setState({tagDisplayHeight: contentHeight});
                                                            }}>
                                                            <View

                                                                style={{
                                                                    width: cd.quoteModalWidth * 0.95,
                                                                    //marginLeft: this.state.suggestingTagEdits ? 0 : (cd.quoteModalWidth - cd.quoteModalWidth * 0.95) * 0.5,
                                                                    flexDirection: "row",
                                                                    justifyContent: "center",
                                                                    alignItems: "flex-start",
                                                                    flexWrap: "wrap",
                                                                }}
                                                            >{children}
                                                            </View>
                                                        </ScrollView>;
                                                    }
                                                }}>

                                                {[...this.props.quoteData.tags, ...(this.state.suggestingTagEdits ? this.state.tagsSuggestNew : [])].map((item: string, key: number) => {
                                                    item = item.trim();
                                                    return <CategoryTag
                                                        key={item}
                                                        itemName={item}
                                                        selected={this.state.suggestingTagEdits}
                                                        deleted={this.state.tagsSuggestDeleted.includes(item)}
                                                        itemHeight={tagHeight}
                                                        containerWidth={cd.quoteModalWidth}
                                                        marginTopBottom={tagTopBottomMargin}
                                                        fontSize={tagFontSize}
                                                        dims={cd}
                                                        deleteFunc={() => {
                                                            // If tag was suggested by user, remove from list.
                                                            if (this.state.tagsSuggestNew.includes(item)) {
                                                                const tagsSuggestNew = [...this.state.tagsSuggestNew];
                                                                tagsSuggestNew.splice(tagsSuggestNew.indexOf(item), 1);
                                                                this.setState({tagsSuggestNew: tagsSuggestNew});
                                                                return;
                                                            }

                                                            // Mark tag locally as suggested to be deleted.
                                                            const deletedTags = [...this.state.tagsSuggestDeleted];
                                                            if (!deletedTags.includes(item)) deletedTags.push(item);
                                                            this.setState({tagsSuggestDeleted: deletedTags});

                                                            // TODO Tell redis that someone wants to remove the tag.
                                                        }}
                                                        undeleteFunc={() => {
                                                            // Mark tag locally as suggested to be deleted.
                                                            let deletedTags = [...this.state.tagsSuggestDeleted];
                                                            let idx = deletedTags.indexOf(item);
                                                            if (idx !== -1) deletedTags.splice(idx, 1);
                                                            this.setState({tagsSuggestDeleted: deletedTags});

                                                            // TODO Tell redis that 1 fewer user wants to remove the tag.
                                                        }}
                                                        borderColor={borderColors[key]}/>;
                                                })}
                                            </ConditionalWrapper>
                                        </LinearGradient>

                                        {/**
                                         TODO Related Quotes Area
                                         */}

                                        {/**
                                         Close Button Area
                                         */}
                                        <View

                                            style={styles.closeRow}>

                                            {/**
                                             Close Button
                                             */}
                                            <Hoverable

                                                onHoverIn={() => this.setState({closeBtnHovered: true})}
                                                onHoverOut={() => this.setState({closeBtnHovered: false})}
                                                onPressIn={() => this.setState({closeBtnHovered: true})}
                                                onPressOut={() => setTimeout(() => {
                                                    if (Platform.OS === 'web') return;
                                                    this.setState({closeBtnHovered: false});
                                                }, 25)}
                                            >
                                                <Pressable

                                                    style={styles.closeBtnContainer}
                                                    onPress={() => {
                                                        this.props.onClose();
                                                    }}
                                                >
                                                    <Image source={up_arrow}
                                                           style={styles.closeBtnImg}/>
                                                    <Text
                                                        style={styles.closeBtnText}
                                                        selectable={false}>Go Back</Text>
                                                </Pressable>
                                            </Hoverable>
                                        </View>
                                    </View>
                                </ScrollView>

                            </View>
                        </Animated.View>
                    </ConditionalWrapper>
                </View>
            </Modal>
        </View>
    }
}
