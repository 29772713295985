// Template for the user controls box at the top of the screen.

import {Image, Platform} from "react-native";
import {CompDimensions} from "../../util/Util";
import {Component} from "react";
import Animated from "react-native-reanimated";
import {headerFadeIn, mainDarkColor} from "../../Main";
// @ts-ignore
import haya_logo from '../../../assets/haya_logo.png';
import {LinearGradient} from "expo-linear-gradient";

export class TitleBoxArea extends Component <{
    dims: CompDimensions,
    yOffset: number,
    exactYOffset: number,
    userName: string | null,
}> {


    render() {
        // Get component dimensions.
        const cd = this.props.dims;

        // Calculate component dimensions.
        const height = cd.titleBoxHeight + (cd.smallScreen ? cd.loginBtnHeight * 0.04 : 0) - (this.props.yOffset > 0 ? cd.loginBtnHeight * 0.3 : 0);
        const width = this.props.yOffset > 0 ? cd.loginBtnWidth * 1.3 : cd.titleBoxWidth;
        const leftMargin = cd.appBorderWidth + cd.headerSideBtnsWidth + cd.titleBoxLeftMargin + (cd.titleBoxWidth - width) * 0.5;
        const paddingBottom = cd.smallScreen ? (Platform.OS === 'web' ? cd.loginBtnHeight * 0.55 : cd.loginBtnHeight * 0.6) : cd.loginBtnHeight * 0.45;
        const logoHidden = cd.smallScreen && this.props.yOffset > 0;
        let logoHeight = cd.titleBoxHeight - cd.loginBtnHeight * 0.5 - (cd.smallScreen ? (Platform.OS === 'web' ? paddingBottom * 0.3 : paddingBottom * 0.15) : paddingBottom * 0.1);
        let logoWidth = (3231 / 918) * logoHeight;
        if (logoWidth > cd.titleBoxWidth * 0.95) {
            logoWidth = cd.titleBoxWidth * 0.95;
            logoHeight = (918 / 3231) * logoWidth;
        }

        return (

            <>
                {/**
                 Background shadow (mobile)
                 */}
                {cd.smallScreen && this.props.yOffset === 0 &&
                    <Animated.View
                        entering={headerFadeIn}
                        // @ts-ignore
                        style={{
                            position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
                            width: width + width * 0.02,
                            height: height + height * 0.02 - cd.appBorderWidth,
                            marginTop: - this.props.yOffset + cd.appBorderWidth,
                            marginLeft: leftMargin - width * 0.01,
                            borderBottomLeftRadius: cd.titleBoxHeight * 0.16,
                            borderBottomRightRadius: cd.titleBoxHeight * 0.16,
                            backgroundColor: "#e3f1fcb9",
                            overflow: "visible",
                        }}/>
                }
                {cd.smallScreen && this.props.yOffset > 0 &&
                    <Animated.View
                        entering={headerFadeIn}
                        // @ts-ignore
                        style={{
                            position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
                            width: width + width * 0.02,
                            height: height + height * 0.02 - cd.appBorderWidth - (Platform.OS !== 'web' ? this.props.yOffset - cd.appBorderWidth : 0),
                            marginTop: - this.props.yOffset + cd.appBorderWidth + (Platform.OS !== 'web' ? this.props.yOffset - cd.appBorderWidth : 0),
                            marginLeft: leftMargin - width * 0.01,
                            borderBottomLeftRadius: cd.titleBoxHeight * 0.16,
                            borderBottomRightRadius: cd.titleBoxHeight * 0.16,
                            backgroundColor: "#e3f1fcb9",
                            overflow: "visible",
                        }}/>
                }
                <Animated.View
                    nativeID={"appTitleAreaContainer"}
                    entering={headerFadeIn}
                    // @ts-ignore
                    style={{
                        position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
                        width: width,
                        height: height,
                        marginTop: cd.smallScreen
                            ? - this.props.yOffset
                            : 0,
                        marginLeft: leftMargin,
                        borderBottomLeftRadius: cd.titleBoxHeight * 0.15,
                        borderBottomRightRadius: cd.titleBoxHeight * 0.15,
                        backgroundColor: mainDarkColor,
                        shadowOpacity: cd.smallScreen ? 0 : 0.15,
                        shadowColor: "#a1c3e1",
                        shadowRadius: cd.titleBoxHeight * 0.15,
                        shadowOffset: {width: 0, height: cd.appBorderWidth},
                        overflow: "visible",
                    }}>
                    <LinearGradient colors={this.props.yOffset > 0
                        ? [mainDarkColor, mainDarkColor, "#79addb"]
                        : [mainDarkColor, mainDarkColor]}
                                    style={{
                                        width: width,
                                        height: height,
                                        paddingBottom: paddingBottom,
                                        borderBottomLeftRadius: cd.titleBoxHeight * 0.15,
                                        borderBottomRightRadius: cd.titleBoxHeight * 0.15,
                                        flexDirection: "column",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}>
                        {!logoHidden &&
                            <Image
                                //entering={FadeIn.duration(100).easing(Easing.quad)}
                                source={haya_logo}
                                style={{
                                    width: logoWidth,
                                    height: logoHeight,
                                    overflow: "visible",
                                }}
                            />
                        }
                    </LinearGradient>
                </Animated.View>
            </>
        );
    }
}
