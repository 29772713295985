import React, {Component, createRef} from "react";
import {CompDimensions, lightenHex} from "../../../util/Util";
import {Platform, Pressable, StyleSheet, Text, View} from "react-native";
import {SavedCatTag} from "./SavedCatTag";
import Animated, {EasingNode} from "react-native-reanimated";
// @ts-ignore
import up_arrow from '../../../../assets/up_arrow.png';
import Hoverable from "../../reusable/Hoverable";
import {TapGestureHandler} from "react-native-gesture-handler";
import {createSafeAnimatedValue} from "../../../util/PatchedAnimatedValue";

const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export class SavedSearchEntry extends Component<{
    dims: CompDimensions,
    catSearch,
    containerWidth: number,
    scrollContentWidth: number,
    scrollContainerLeftBorderWidth: number;
    entryFilterTopMargin: number,
    entryFilterBottomMargin: number,
    entrySoftHeight: number,
    entryBottomMargin: number,
    entryTopBottomBorderWidth: number,
    entryRightBorderWidth: number,
    entryFilterFontSize: number,
    filterDirections,
    filterSelected: number,
    filterIconWidth: number,
    filterIconRightMargin: number,
    filterSideMargins: number,
}> {
    state = {
        entryHovered: false,
        expanded: false,
        filterTitleHovered: -1,
        filterIconHovered: -1,
        animating: false,
        editBtnHovered: false,
        deleteBtnHovered: false,
        goBtnHovered: false,
    }

    translateX: any;
    expanded: any;
    maxDragDistance: number;
    tapGestureHandler: any;
    panGestureHandler: any;

    constructor(props) {
        super(props);
        this.tapGestureHandler = createRef();
        this.panGestureHandler = createRef();
        this.translateX = createSafeAnimatedValue(0);
        this.expanded = createSafeAnimatedValue(0);
        this.maxDragDistance = this.props.dims.smallScreen ? this.props.containerWidth * -0.5 : this.props.containerWidth * -0.28;
    }

    render() {
        // Define dimensions.
        const cd = this.props.dims;
        const containerWidth = this.props.containerWidth;
        const searchGoWidth = cd.smallScreen ? this.props.containerWidth * 0.2 : this.props.containerWidth * 0.15;
        const entryDividerWidth = this.props.entryTopBottomBorderWidth * 0.6;
        const tagHeight = this.props.entrySoftHeight * 0.25;
        const searchGoFontSize = Math.sqrt((searchGoWidth * 0.5 * this.props.entrySoftHeight * 0.2) / "Search".length);
        const btnTextLeftMargin = this.props.entrySoftHeight * 0.1;

        // Define color theme.
        const whitishColor = "#f2f7fa";
        const grayColor = "#e8eaeb"
        const lightColor = lightenHex("#a5d2fa", 13);
        const darkColor = "#9ccbf7";
        const editBgColor = "#e6f0fa";
        const deleteBgColor = "#f0cccc";
        const goBgColor = "#dceffa";

        // Make pretty date string.
        const dateSaved = new Date(this.props.catSearch.timeSaved * 1000);
        const dateStr = month_names_short[dateSaved.getMonth()] + ' ' + dateSaved.getDate() + ', ' + dateSaved.getFullYear();

        // Create reusable styles.
        const styles = StyleSheet.create({
            entryFilterContainer: {
                width: "100%",
                marginTop: this.props.entryFilterTopMargin,
                marginLeft: "auto",
                marginRight: "auto",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
            },
            entryFilterBlock: {
                flex: 1/3,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            entryFilterBlockColumn: {
                marginBottom: this.props.entryFilterBottomMargin,
                //marginLeft: this.props.filterIconWidth,
                flexDirection: "column",
                justifyContent: "center",
                //alignItems: "center",
            },
            entryFilterValueText: {
                //marginLeft: this.props.filterIconWidth + this.props.filterIconRightMargin,
                //textAlign: "center",
                fontSize: this.props.entryFilterFontSize,
                color: "#3f4040",
            },
            entryContainer: {
                width: this.props.scrollContentWidth,
                marginBottom: this.props.entryBottomMargin,
                borderTopWidth: this.props.entryTopBottomBorderWidth,
                borderBottomWidth: this.props.entryTopBottomBorderWidth,
                borderRightWidth: this.props.entryRightBorderWidth,
                borderTopColor: darkColor,
                borderBottomColor: darkColor,
                borderRightColor: "#f2faff",
                backgroundColor: this.state.entryHovered || this.state.expanded ? "#def3fcda" : "#fcfeffda",
                shadowOpacity: cd.smallScreen ? 0.4 : 0.6,
                shadowColor: "#d4ecfa",
                shadowOffset: {
                    width: cd.smallScreen ? -containerWidth * 0.02 : -containerWidth * 0.01,
                    height: cd.smallScreen ? containerWidth * 0.02 : containerWidth * 0.01,
                },
                shadowRadius: cd.smallScreen ? containerWidth * 0.05 : containerWidth * 0.03,
            },
            tagsArea: {
                width: this.props.scrollContentWidth - this.props.entryTopBottomBorderWidth * 2,
                marginTop: this.props.entrySoftHeight * 0.08,
                marginBottom: this.props.entrySoftHeight * 0.08,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
                flexWrap: "wrap",
            },
            buttonsArea: {
                // @ts-ignore
                transform: [{translateX: Animated.add(this.translateX, -this.maxDragDistance, -this.props.scrollContainerLeftBorderWidth + this.props.entryRightBorderWidth)}],
                position: "absolute",
                height: "100%",
                marginLeft: "auto",
                //marginRight: entryDividerWidth * 6,
                borderLeftWidth: entryDividerWidth,
                borderLeftColor: darkColor,
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                alignSelf: "flex-end",
                backgroundColor: "#f7fcff",
            },
            editBtnArea: {
                width: -0.333 * this.maxDragDistance,
                height: "100%",
                backgroundColor: editBgColor,
                justifyContent: "center",
                alignItems: "center",
            },
            deleteBtnArea: {
                width: -0.333 * this.maxDragDistance,
                height: "100%",
                borderLeftWidth: entryDividerWidth,
                borderLeftColor: darkColor,
                backgroundColor: deleteBgColor,
                justifyContent: "center",
                alignItems: "center",
            },
            goBtnArea: {
                width: -0.333 * this.maxDragDistance,
                height: "100%",
                borderLeftWidth: entryDividerWidth,
                borderLeftColor: darkColor,
                backgroundColor: goBgColor,
                justifyContent: "center",
                alignItems: "center",
            },
            btnText: {
                textDecorationLine: "underline",
                fontSize: searchGoFontSize,
                color: "#5b717d",
            },
            btnTextHovered: {
                color: "#fcfeff",
                fontSize: searchGoFontSize * 0.94,
                textShadowColor: "#4d4e4fae",
                textShadowRadius: cd.smallScreen ? this.props.entrySoftHeight * 0.01 : this.props.entrySoftHeight * 0.06,
            },
        });

        return (
            <View
                key={this.props.catSearch.hash}>

                {/**
                 Filter Row
                 */}
                <View
                    style={styles.entryFilterContainer}>

                    {/**
                     Left Filter Area
                     */}
                    <View
                        style={styles.entryFilterBlock}>

                        {/**
                         Left Filter Text Column
                         */}
                        <View
                            style={styles.entryFilterBlockColumn}>
                            <Text
                                style={styles.entryFilterValueText}>{dateStr}</Text>
                        </View>
                    </View>

                    {/**
                     Middle Filter Area
                     */}
                    <View
                        style={styles.entryFilterBlock}>

                        {/**
                         Middle Filter Text Column
                         */}
                        <View
                            style={styles.entryFilterBlockColumn}>
                            <Text
                                style={styles.entryFilterValueText}>{'' + this.props.catSearch.tags.length + ' Tags'}</Text>
                        </View>
                    </View>

                    {/**
                     Right Filter Area
                     */}
                    <View
                        style={styles.entryFilterBlock}>

                        {/**
                         Right Filter Text Column
                         */}
                        <View
                            style={styles.entryFilterBlockColumn}>
                            <Text
                                style={styles.entryFilterValueText}>{Math.round(100 * this.props.catSearch.personalizationLevel / 10) + '% Personalized'}</Text>
                        </View>
                    </View>
                </View>

                {/**
                 Search Row
                 */}
                <TapGestureHandler
                    ref={this.tapGestureHandler}
                    simultaneousHandlers={this.panGestureHandler}
                    onBegan={() => {
                        //this.setState({entryHovered: true});
                    }}
                    onEnded={() => {
                        if (this.state.animating) return;
                        setTimeout(() => {
                            //if (true) return;
                            if (this.state.animating) return;
                            this.setState(({animating: true}));
                            let newDragDist = this.state.expanded ? 0 : this.maxDragDistance;
                            this.expanded.setValue((newDragDist === 0) ? 0 : 1);
                            this.setState({
                                expanded: newDragDist !== 0,
                                entryHovered: newDragDist !== 0,
                            });
                            Animated.timing(this.translateX, {
                                toValue: newDragDist,
                                duration: 300,
                                easing: EasingNode.in(EasingNode.linear),
                            }).start(() => {
                                this.setState({animating: false});
                            });
                        }, 50);
                    }}
                    onFailed={() => {
                        ///setTimeout(() => this.setState({entryHovered: false}), cd.smallScreen ? 300 : 100);
                    }}
                    maxDurationMs={2000}
                    maxDist={this.props.entrySoftHeight * 0.01}>

                    <Animated.View
                        style={styles.entryContainer}>

                        {/**
                         Category Tags
                         */}
                        <Hoverable
                            onHoverIn={() => {
                                this.setState({entryHovered: true})
                            }}
                            onHoverOut={() => {
                                this.setState({entryHovered: false})
                            }}
                            onPressIn={() => {
                                this.setState({entryHovered: true})
                            }}
                            onPressOut={() => setTimeout(() => {
                                if (Platform.OS === 'web' || this.state.expanded) return;
                                this.setState({entryHovered: false});
                            }, 150)}>
                            <Pressable
                                onPress={() => {
                                    //this.setState({entryHovered: false});
                                }}
                                style={styles.tagsArea}>{this.props.catSearch.tags.map((catName) => (
                                <SavedCatTag
                                    itemName={catName}
                                    itemHeight={tagHeight}
                                    containerWidth={this.props.containerWidth}
                                    marginTopBottom={this.props.entrySoftHeight * (cd.smallScreen ? 0.03 : 0.03)}
                                    fontSize={cd.smallScreen ? tagHeight * 0.5 : tagHeight * 0.6}
                                    dims={cd}
                                    translateX={this.translateX}
                                    highlighted={this.state.entryHovered || this.state.expanded}/>
                            ))}</Pressable>
                        </Hoverable>

                        {/**
                         Buttons Area
                         */}
                        <Animated.View
                            style={styles.buttonsArea}>

                            {/**
                             Edit Button
                             */}
                            <Hoverable
                                onHoverIn={() => {
                                    this.setState({editBtnHovered: true})
                                }}
                                onHoverOut={() => {
                                    this.setState({editBtnHovered: false})
                                }}
                                onPressIn={() => {
                                    this.setState({editBtnHovered: true})
                                }}
                                onPressOut={() => setTimeout(() => {
                                    if (Platform.OS === 'web') return;
                                    this.setState({editBtnHovered: false});
                                }, 25)}>
                                <Pressable
                                    onPress={() => {
                                        // TODO Call parent function to open search modal in edit mode.
                                    }}
                                    style={styles.editBtnArea}>

                                    <Text
                                        style={[styles.btnText, this.state.editBtnHovered ? styles.btnTextHovered : null]}>Edit</Text>

                                </Pressable>
                            </Hoverable>

                            {/**
                             Delete Button
                             */}
                            <Hoverable
                                onHoverIn={() => {
                                    this.setState({deleteBtnHovered: true})
                                }}
                                onHoverOut={() => {
                                    this.setState({deleteBtnHovered: false})
                                }}
                                onPressIn={() => {
                                    this.setState({deleteBtnHovered: true})
                                }}
                                onPressOut={() => setTimeout(() => {
                                    if (Platform.OS === 'web') return;
                                    this.setState({deleteBtnHovered: false});
                                }, 25)}>
                                <Pressable
                                    onPress={() => {
                                        // TODO Call parent function to open search modal in edit mode.
                                    }}
                                    style={styles.deleteBtnArea}>

                                    <Text
                                        style={[styles.btnText, this.state.deleteBtnHovered ? styles.btnTextHovered : null]}>Delete</Text>

                                </Pressable>
                            </Hoverable>

                            {/**
                             Go Button
                             */}
                            <Hoverable
                                onHoverIn={() => {
                                    this.setState({goBtnHovered: true})
                                }}
                                onHoverOut={() => {
                                    this.setState({goBtnHovered: false})
                                }}
                                onPressIn={() => {
                                    this.setState({goBtnHovered: true})
                                }}
                                onPressOut={() => setTimeout(() => {
                                    if (Platform.OS === 'web') return;
                                    this.setState({goBtnHovered: false});
                                }, 25)}>
                                <Pressable
                                    onPress={() => {
                                        // TODO Call parent function to run search.
                                    }}
                                    style={styles.goBtnArea}>

                                    <Text
                                        style={[styles.btnText, this.state.goBtnHovered ? styles.btnTextHovered : null]}>Search</Text>
                                </Pressable>
                            </Hoverable>

                        </Animated.View>
                    </Animated.View>
                </TapGestureHandler>
            </View>
        )
    }
}