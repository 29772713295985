// Template for a circular squishy header button.

import {Platform, StyleSheet} from "react-native";
import {CompDimensions} from "../../util/Util";
import Animated from "react-native-reanimated";
import {headerFadeIn, headerFadeOut, mainDarkColor, mainLightColor} from "../../Main";
import SquishyButton from "../reusable/SquishyButton";

function HeaderSideButton(props: {
    dims: CompDimensions,
    isHovered: boolean,
    setIsHovered: any,
    imageSrc: any,
    onPress: any,
    marginLeft: number,
    marginTop: number,
    yOffset: number,
    hitSlop: any,
}) {

    const cd = props.dims;

    // Create reusable styles.
    const styles = StyleSheet.create({
        headerSideBtnDims: {
            width: cd.headerSideBtnsWidth,
            height: cd.headerSideBtnsHeight,
            justifyContent: "center",
            alignItems: "center",
        },
        headerSideBtnContainer: {
            // @ts-ignore
            position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
            marginLeft: props.marginLeft,
            marginTop: props.marginTop,
            width: cd.headerSideBtnsWidth,
            height: cd.headerSideBtnsHeight,
            justifyContent: "center",
            alignItems: "center",
        },
        headerSideBtnImgDims: {
            width: cd.headerSideBtnsWidth * 0.9,
            height: cd.headerSideBtnsHeight * 0.9,
            tintColor: "#fafdff",
        },
        hoveredDesktop: {
            transform: [{scale: props.isHovered ? (cd.smallScreen ? 1.05 : 0.99) : 1}],
            tintColor: (Platform.OS === 'web' && cd.smallScreen) ? "#fafdff" : "#f5fbffff",
        },
        innerAreaDims: {
            justifyContent: "center",
            alignItems: "center",
        }
    });

    return (
        <SquishyButton isSmallScreen={cd.smallScreen}
                       pressFunc={props.onPress}
                       isHovered={props.isHovered}
                       setHoveredFunc={props.setIsHovered}
                       scaleTo={0.93}
                       squishInDuration={150}
                       squishOutDelay={100}
                       squishOutDuration={100}
                       containerStyle={styles.headerSideBtnContainer}
                       buttonStyle={styles.headerSideBtnDims}
                       containerOtherProps={{
                           entering: headerFadeIn, exiting: headerFadeOut
                       }}
                       buttonOtherProps={{
                           hitSlop: props.hitSlop
                       }}>
            <Animated.View
                entering={headerFadeIn}
                exiting={headerFadeOut}
                style={[
                    styles.headerSideBtnDims,
                    styles.innerAreaDims,
                    cd.smallScreen ?
                        {
                            backgroundColor: props.yOffset > 0
                                ? (props.isHovered && !cd.smallScreen ? mainDarkColor : "#9dc2e3")
                                : (props.isHovered && !cd.smallScreen ? "#99bfe0" : "#a3c6e5"),
                            borderRadius: cd.headerSideBtnsWidth * 0.5,
                            borderWidth: cd.headerSideBtnsWidth * 0.05,
                            borderColor: "#f7fafc28",
                        } : {
                            backgroundColor: props.isHovered && !cd.smallScreen ? mainDarkColor : mainLightColor,
                            transform: [{scale: props.isHovered && !cd.smallScreen ? 0.94 : 0.9}],
                            borderRadius: cd.headerSideBtnsWidth * 0.5,
                            borderWidth: cd.headerSideBtnsWidth * 0.04,
                            borderColor: "#fcfeff8e",
                        },
                    props.yOffset > 0 ? {
                        shadowColor: "#aecbe5",
                        shadowRadius: cd.headerSideBtnsWidth * 0.04,
                        shadowOffset: {
                            width: 0,
                            height: -cd.headerSideBtnsWidth * 0.001,
                        },
                        shadowOpacity: 0.3,
                    } : null,]}>
                {// @ts-ignore
                    <Animated.Image
                        entering={headerFadeIn}
                        exiting={headerFadeOut}
                        source={props.imageSrc}
                        style={[styles.headerSideBtnImgDims,
                            props.isHovered && !cd.smallScreen ? styles.hoveredDesktop : null]}
                    />
                }
            </Animated.View>
        </SquishyButton>
    );
}

export default HeaderSideButton;