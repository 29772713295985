import {Platform, ScrollView, View} from "react-native";
import {LinearGradient} from "expo-linear-gradient";
import {
    CompDimensions,
    ConditionalWrapper,
    defaultLoadingCards,
    getPCQuotes,
    getRandomQuotes,
    loadQuoteDatasFromCsv,
    randomCardColors
} from "../../util/Util";
import {SettingsModal} from "../modal/SettingsModal";
import {LoginModal} from "../modal/LoginModal";
import {SearchModal} from "../modal/SearchModal";
import {SavedModal} from "../modal/saved_modal/SavedModal";
import {QuoteModal} from "../modal/quote_modal/QuoteModal";
import {AboutBtnArea} from "./AboutBtnArea";
import {FeedbackBtnArea} from "./FeedbackBtnArea";
import {QuoteCard} from "./QuoteCard";
import {MoreButton} from "./MoreButton";
import {DragArea} from "./DragArea";
import Animated from "react-native-reanimated";
import {TitleBoxArea} from "./TitleBoxArea";
import {SearchBtnArea} from "./SearchBtnArea";
import {SavedBtnArea} from "./SavedBtnArea";
import {LoginBtn} from "./LoginBtn";
import {SettingsBtn} from "./SettingsBtn";
import React, {Component, createRef} from "react";
import {headerFadeIn, headerFadeOut, mainDarkColor} from "../../Main";
import {SafeAreaView} from "react-native-safe-area-context";
import {QuoteData} from "../../types/QuoteData";
import {BlurView} from "expo-blur";

const iosScrollNormal = 0.996;
const iosScrollFast = 0.99;
const androidScrollNormal = 0.97;
const androidScrollFast = 0.9;

export class HomeScreen extends Component<{
    dims: CompDimensions,
    horizontalMobileBrowser: boolean,
    authenticated: boolean,
    authenticateUserWrapper: any,
    registerUserWrapper: any,
}> {
    state = {
        initialCardsLoaded: false,
        reports: defaultLoadingCards,
        allLoadedQuotes: [],
        cardBgColors: randomCardColors(6),
        showSettingsModal: false,
        showSearchModal: false,
        showLoginModal: false,
        showSavedModal: false,
        showQuoteModal: false,
        shownQuoteData: null,
        headerYOffset: 0,
        lastYOffset: 0,
        yOffsetAtDragStart: 0,
        yOffsetDeltaSinceHeaderUpdate: 0,
        dragging: false,
        scrollEnabled: true,
        scrollDecelerationRate: Platform.OS === 'ios' ? iosScrollNormal : androidScrollNormal,
        headerVisible: true,
        headerChangeDelaying: false,
        dragAreaEnabled: false,
        loadingMoreCards: false,
        mobileBrowserScrollHandleTimer: null,
    }

    scrollRef: any;
    bodyRef: any;

    constructor(props) {
        super(props);

        // Create reference to ScrollView to power "scroll to top" functionality.
        this.scrollRef = createRef();
        this.bodyRef = createRef();

        // Bind functions to this.
        this.showLoginModal = this.showLoginModal.bind(this);
        this.showSettingsModal = this.showSettingsModal.bind(this);
        this.showSearchModal = this.showSearchModal.bind(this);
        this.showSavedModal = this.showSavedModal.bind(this);
        this.showQuoteModal = this.showQuoteModal.bind(this);
        this.hideLoginModal = this.hideLoginModal.bind(this);
        this.hideSettingsModal = this.hideSettingsModal.bind(this);
        this.hideSearchModal = this.hideSearchModal.bind(this);
        this.hideSavedModal = this.hideSavedModal.bind(this);
        this.hideQuoteModal = this.hideQuoteModal.bind(this);
        this.showNextCards = this.showNextCards.bind(this);
        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDragEnd = this.handleDragEnd.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleGlideEnd = this.handleGlideEnd.bind(this);
        this.removeHeaderChangeDelay = this.removeHeaderChangeDelay.bind(this);
        this.handlePressAbout = this.handlePressAbout.bind(this);
        this.handlePressFeedback = this.handlePressFeedback.bind(this);
    }

    // Define function to open settings popup.
    showSettingsModal() {
        this.setState({
            showSettingsModal: true,
            showSearchModal: false,
            showSavedModal: false,
            showLoginModal: false,
            showQuoteModal: false,
        });
    }

    // Define function to open login popup.
    showLoginModal() {
        this.setState({
            showSettingsModal: false,
            showSearchModal: false,
            showSavedModal: false,
            showLoginModal: true,
            showQuoteModal: false,
        });
    }

    // Define function to open search popup.
    showSearchModal(dims: CompDimensions) {
        this.setState({
            showSettingsModal: false,
            showSearchModal: true,
            showSavedModal: false,
            showLoginModal: false,
            showQuoteModal: false,
        });
    }

    // Define function to open saved popup.
    showSavedModal() {
        this.setState({
            showSettingsModal: false,
            showSearchModal: false,
            showSavedModal: true,
            showLoginModal: false,
            showQuoteModal: false,
        });
    }

    // Define function to open quote card popup.
    showQuoteModal(quoteData: QuoteData) {
        this.setState({
            shownQuoteData: quoteData,
            showSettingsModal: false,
            showSearchModal: false,
            showSavedModal: false,
            showLoginModal: false,
            showQuoteModal: true,
        })
    }

    // Define function to close login popup.
    hideLoginModal() {
        this.setState({showLoginModal: false})
    }

    // Define function to close search popup.
    hideSettingsModal() {
        this.setState({showSettingsModal: false})
    }

    // Define function to close search popup.
    hideSearchModal() {
        this.setState({showSearchModal: false})
    }

    // Define function to close search popup.
    hideSavedModal() {
        this.setState({showSavedModal: false})
    }

    // Define function to close quote card popup.
    hideQuoteModal() {
        this.setState({showQuoteModal: false})
    }

    // Define function to show "About" screen.
    handlePressAbout() {
        // @ts-ignore
        this.props.navigation.navigate('About');
    }

    // Define function to show "Feedback" screen.
    handlePressFeedback() {
        // @ts-ignore
        this.props.navigation.navigate('Feedback');
    }

    // Define function to display newly loaded cards.
    showNextCards(dims: CompDimensions, baseTimeout: number, intervalTimeout: number) {

        // Init loading card vars.
        this.setState({loadingMoreCards: true});
        const curCards = [...this.state.reports];
        const shownCards = [];
        for (let i = 0; i < curCards.length; i++) {
            const loadingCard: QuoteData = {
                report_id: curCards[i].report_id,
                speaker: curCards[i].speaker,
                headline: null,
                summary: curCards[i].summary,
                full_report: curCards[i].full_report,
                quoter: curCards[i].quoter,
                snapshot: curCards[i].snapshot,
                source_link: curCards[i].source_link,
                tags: curCards[i].tags,
            };
            shownCards.push(loadingCard);
        }

        // Define the timing of each step in the sequence.
        const cd = dims;
        const showCardsLoadingDelay = cd.smallScreen ? 50 : 100;
        const disappearCardsDelay = baseTimeout + intervalTimeout * 0.5;
        const fadeInFirstCardDelay = disappearCardsDelay * 1.9;
        const scrollUpDelay = disappearCardsDelay * 1.8;

        // Show all cards as loading/blank.
        const newScrollY = cd.smallScreen
            ? cd.titleBoxHeight + cd.spaceBelowHeader * (Platform.OS === 'web' ? 0.5 : 1.07)
            : 0;
        const newBgColors = randomCardColors(6);
        const shownBgColors = new Array(6).fill(newBgColors[0]);
        this.setState({
            scrollEnabled: false,
            headerVisible: false,
        });
        setTimeout(() => this.setState({
            reports: shownCards,
            cardBgColors: shownBgColors,
            headerVisible: false,
            scrollEnabled: false,
            lastYOffset: newScrollY,
            headerYOffset: 0,
            yOffsetDeltaSinceHeaderUpdate: cd.loginBtnHeight * 0.5,
        }), showCardsLoadingDelay);

        // TODO Fetch new batch of quotes from the API.
        const newCards = getRandomQuotes(this.state.allLoadedQuotes, 6);

        // TODO Copy/paste directly the entire rest of this function into the callback.

        // Schedule loading cards to disappear entirely on mobile.
        if (cd.smallScreen) {
            setTimeout(() => {
                for (let i = 0; i < newCards.length; i++) {
                    shownCards[i].summary = null;
                }
                this.setState({reports: shownCards});
            }, disappearCardsDelay);
        }

        // Schedule loaded cards to fade in one-by-one.
        setTimeout(() => {
            for (let i = 0; i < newCards.length; i++) {
                setTimeout(() => {

                    // Show next card.
                    shownCards[i] = newCards[i];
                    shownBgColors[i] = newBgColors[i];
                    this.setState({
                        reports: shownCards,
                        cardBgColors: shownBgColors,
                        loadingMoreCards: i !== newCards.length - 1,
                    });

                    // Show header if user scrolled up.
                    if (i > 0 && this.state.lastYOffset < newScrollY * 0.8) {
                        this.setState({headerVisible: true});
                    } else if (this.state.lastYOffset > cd.screenHeight * 0.7) {
                        this.setState({headerVisible: false});
                    }
                }, intervalTimeout * i);
            }

            // Scroll up to loading cards early on desktop.
            if (!cd.smallScreen) {
                this.scrollRef.current?.scrollTo({
                    y: newScrollY,
                    animated: !(cd.smallScreen),
                });
                this.setState({
                    headerVisible: false,
                    lastYOffset: newScrollY,
                    headerYOffset: 0,
                    scrollEnabled: true,
                    yOffsetDeltaSinceHeaderUpdate: cd.loginBtnHeight * 0.5,
                });
            }

        }, fadeInFirstCardDelay);

        // Scroll up to loading cards later on mobile.
        if (cd.smallScreen) {
            setTimeout(() => {
                this.setState({
                    headerVisible: false,
                    lastYOffset: newScrollY,
                    headerYOffset: 0,
                    scrollEnabled: false,
                    scrollDecelerationRate: iosScrollNormal,
                    dragAreaEnabled: false,
                });
                if (cd.smallScreen && Platform.OS === 'web') {
                    window.scrollTo({left: 0, top: newScrollY * 1.25, behavior: 'auto'});
                } else {
                    this.scrollRef.current?.scrollTo({
                        y: newScrollY,
                        animated: !(cd.smallScreen),
                    });
                }
                setTimeout(() => this.setState({
                    headerVisible: false,
                    lastYOffset: newScrollY,
                    headerYOffset: 0,
                    scrollEnabled: true,
                    yOffsetDeltaSinceHeaderUpdate: cd.loginBtnHeight * 0.5,
                }), 300);
            }, scrollUpDelay);
        }
    }

    // Respond to scroll start.
    handleDragStart(event) {

        // Update scroll vars.
        this.setState({
            dragging: true,
            yOffsetAtDragStart: event.nativeEvent.contentOffset.y,
        });
    }

    // Respond to user lifting finger after scroll (scrolling can still glide further after finger is lifted).
    handleDragEnd(event) {
        // Update scroll vars.
        const yOffset = event.nativeEvent.contentOffset.y
        this.setState({
            dragging: false,
        });

        // Bounce back to bottom of screen if below it.
        if (Platform.OS !== 'ios') return;
        const cd = this.props.dims;
        const endCardArea = cd.statusBarHeight + cd.scrollAreaHeight - cd.screenHeight;
        if (yOffset > endCardArea && yOffset >= this.state.lastYOffset && this.state.scrollEnabled) {
            setTimeout(() => {
                if (this.state.scrollDecelerationRate === 1 || this.state.loadingMoreCards) {
                    return;
                }
                this.setState({scrollEnabled: false, scrollDecelerationRate: 1});
                this.scrollRef.current?.scrollTo({y: endCardArea, animated: true});
                setTimeout(() => this.setState({scrollEnabled: true, scrollDecelerationRate: iosScrollNormal}),
                    1);
            }, 5);
        }
    }

    // Define function to respond to scroll gliding to a stop.
    handleGlideEnd(event) {
        // Define threshold offsets.
        const yOffset = event.nativeEvent.contentOffset.y
        const cd = this.props.dims;
        const endCardArea = cd.statusBarHeight + cd.scrollAreaHeight - cd.screenHeight;

        // Enable bottom scroll area (mobile only) if bottom reached.
        if (Platform.OS !== 'ios') return;
        if (cd.smallScreen && !this.state.dragAreaEnabled && yOffset > endCardArea * 0.97 && !this.state.loadingMoreCards) {
            this.setState({scrollDecelerationRate: 1, scrollEnabled: false});
            this.scrollRef.current?.scrollTo({y: endCardArea, animated: true});
            setTimeout(() => this.setState({
                dragAreaEnabled: true,
                scrollDecelerationRate: iosScrollNormal,
                scrollEnabled: true,
            }), 1);
        }

        // Bounce back to bottom of screen if below it.
        if (yOffset > endCardArea && yOffset >= this.state.lastYOffset && this.state.scrollEnabled && !this.state.loadingMoreCards) {
            this.setState({scrollEnabled: false, scrollDecelerationRate: 1});
            this.scrollRef.current?.scrollTo({y: endCardArea, animated: true});
            setTimeout(() => this.setState({
                    scrollEnabled: true,
                    scrollDecelerationRate: iosScrollNormal
                }),
                1);
        }
    }

    // Define function to respond to scrolling frame updates.
    handleScroll(y) {

        // Update state with new yOffset variables.
        const cd = this.props.dims;
        const prevYOffset = this.state.lastYOffset;
        const yOffset = y;
        const yOffsetSinceDrag = Math.abs(yOffset - this.state.yOffsetAtDragStart);
        const scrollingUp = yOffset < prevYOffset;
        const scrollingDown = yOffset > prevYOffset;
        this.setState({
            yOffsetDeltaSinceHeaderUpdate: yOffset - this.state.lastYOffset,
            lastYOffset: yOffset,
        });

        // Snap header up/down when user scrolls.
        let headerYOffset = y;
        if (headerYOffset > cd.headerAreaHeight * 2) {
            headerYOffset = cd.maxHeaderYOffset;
        } else {
            headerYOffset = 0;
        }
        this.setState({
            headerYOffset: headerYOffset,
        });

        // Ignore scroll event triggered from loading more cards.
        if (!this.state.scrollEnabled || this.state.loadingMoreCards) return;

        // Define threshold offsets.
        const endCardArea = cd.statusBarHeight + cd.scrollAreaHeight - cd.screenHeight;
        const startDragArea = cd.statusBarHeight + cd.scrollAreaHeight - cd.screenHeight + cd.dragAreaHeight * 0.95;
        const endDragArea = cd.statusBarHeight + cd.scrollAreaHeight - cd.screenHeight + cd.dragAreaHeight;
        const pagingAlreadyHandled = Platform.OS === 'web' && !cd.smallScreen;

        // Enable bottom scroll area (mobile only) if not dragging and bottom reached.
        if (cd.smallScreen && scrollingDown && !this.state.dragAreaEnabled && yOffset > endCardArea * 0.97) {
            if (Platform.OS === 'web') {
                this.setState({
                    scrollDecelerationRate: 1,
                    scrollEnabled: false,
                });
                this.scrollRef.current?.scrollTo({y: endCardArea, animated: true});
            }
            setTimeout(() => {
                this.setState({
                    dragAreaEnabled: true,
                });
                if (Platform.OS === 'web') {
                    this.setState({
                        scrollDecelerationRate: iosScrollNormal,
                        scrollEnabled: true,
                        yOffset: endCardArea * 0.999,
                        dragAreaEnabled: true,
                    });
                }
            }, 100);
        }

        // Disable bottom scroll area (mobile only) if position is high and moving up.
        if (cd.smallScreen && scrollingUp && this.state.dragAreaEnabled && yOffset < endCardArea * 0.9) {
            this.setState({dragAreaEnabled: false});
        }

        // Scroll back up if user scrolled into drag area while loading quotes.
        if (this.state.loadingMoreCards && scrollingDown && yOffset >= startDragArea) {
            this.setState({scrollEnabled: false, scrollDecelerationRate: 1});
            this.scrollRef.current?.scrollTo({y: endCardArea, animated: true});
            setTimeout(() => this.setState({scrollEnabled: true, scrollDecelerationRate: iosScrollNormal}),
                100);
        }

        // Check to load more quotes.
        if (!this.state.loadingMoreCards && yOffset >= startDragArea) {

            // Prevent accidental scrolling while reading a quote pop-up on some mobile browsers.
            if (this.state.showQuoteModal) {
                window.scrollTo(0, startDragArea * 0.9);
            } else {

                // Disable user scroll.
                this.setState({
                    dragAreaEnabled: true,
                    headerVisible: false,
                    scrollEnabled: false,
                    scrollDecelerationRate: 1,
                    lastYOffset: endDragArea,
                    yOffsetDeltaSinceHeaderUpdate: 0,
                    loadingMoreCards: true,
                });

                // Perform scroll-to-bottom and card loading animation.
                if (cd.smallScreen && Platform.OS === 'web') {
                    document.body.style.overflow = "hidden";
                    window.scrollTo(0, endDragArea);
                }
                setTimeout(() => {
                    this.scrollRef.current?.scrollTo({y: endDragArea, animated: true});
                    if (cd.smallScreen && Platform.OS === 'web') {
                        setTimeout(() => window.scrollTo(0, endDragArea), 500);
                        setTimeout(() => document.body.style.overflow = "visible", 2500);
                    }
                    this.showNextCards(cd, 1000, 600);
                    this.setState({headerVisible: false});
                }, 10);


                // Re-enable user scroll, delayed.
                setTimeout(() => {
                    this.setState({
                        scrollEnabled: true,
                        scrollDecelerationRate: iosScrollNormal,
                    });
                }, 1500);

                return;
            }
        }


        // Handle sticky header (mobile only).
        if (Platform.OS === 'web' && !cd.smallScreen) return;
        if (this.state.loadingMoreCards || this.state.headerChangeDelaying) return;

        // Show/hide header when user scrolls down/up.
        const scrollIsHighUp = yOffset < cd.headerAreaHeight * 0.6;
        const scrollNotHighUp = yOffset > cd.screenHeight * 0.7;
        const deltaGreaterThanThreshold1 = Math.max(Math.abs(yOffsetSinceDrag), Math.abs(this.state.yOffsetDeltaSinceHeaderUpdate)) > cd.loginBtnHeight * 1.3;
        const deltaGreaterThanThreshold2 = Math.max(Math.abs(yOffsetSinceDrag), Math.abs(this.state.yOffsetDeltaSinceHeaderUpdate)) > cd.loginBtnHeight * 0.9;
        if (scrollIsHighUp && !this.state.headerVisible) {
            this.setState({
                headerVisible: true,
                yOffsetDeltaSinceHeaderUpdate: 0,
                headerYOffset: 0,
                headerChangeDelaying: true,
            });
            this.removeHeaderChangeDelay(cd);
        } else if (scrollingDown && scrollNotHighUp && this.state.headerVisible
            && deltaGreaterThanThreshold1) {
            this.setState({
                headerVisible: false,
                yOffsetDeltaSinceHeaderUpdate: 0,
                headerChangeDelaying: true,
            });
            this.removeHeaderChangeDelay(cd);
        } else if (scrollingUp && !this.state.headerVisible && deltaGreaterThanThreshold2 && yOffset < endCardArea * 0.93 && !this.state.loadingMoreCards) {
            this.setState({
                headerVisible: true,
                yOffsetDeltaSinceHeaderUpdate: 0,
                headerChangeDelaying: true,
            });
            this.removeHeaderChangeDelay(cd);
        }
    }

    removeHeaderChangeDelay(dims: CompDimensions) {
        setTimeout(() => {
            this.setState({headerChangeDelaying: false});
            if (this.state.lastYOffset < dims.titleBoxHeight * 0.85) {
                this.setState({
                    headerVisible: true,
                    yOffsetDeltaSinceHeaderUpdate: 0,
                    headerChangeDelaying: true,
                });
                this.removeHeaderChangeDelay(dims);
            }
        }, 300);
    }

    // Respond to component initializing.
    componentDidMount() {

        // Load quotes from csv file.
        if (!this.state.initialCardsLoaded) {
            loadQuoteDatasFromCsv().then((loadedQuotes: Array<QuoteData>) => {
                if (this.state.initialCardsLoaded) return;
                this.setState({
                    allLoadedQuotes: loadedQuotes,
                    reports: getPCQuotes(loadedQuotes),
                    initialCardsLoaded: true
                });
            });
        }

        // Start interval to check for scroll on mobile browser.
        if (!(this.props.dims.screenWidth < this.props.dims.screenHeight && Platform.OS === 'web')) return;
        if (this.state.mobileBrowserScrollHandleTimer) {
            clearInterval(this.state.mobileBrowserScrollHandleTimer);
        }
        this.setState({
            mobileBrowserScrollHandleTimer: setInterval(() => {
                this.bodyRef.current?.measureInWindow((x, y, width, height) => {
                    if (-y !== this.state.lastYOffset) {
                        this.handleScroll(-y);
                    }
                });
            }, 300)
        });
    }

    render() {

        // Calculate dimensions of each component.
        const cd = this.props.dims;
        const mobileBrowserExtraHeight = 400;

        // Color of pre- and post-scroll areas.
        const topMostColor = "white";

        // Define reusable styles.
        // TODO See if it breaks if I change this to StyleSheet.create({});
        const styles = {
            headerArea: {
                position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
                width: cd.screenWidth,
                height: cd.headerAreaHeight,
                marginTop: cd.smallScreen
                    ? -this.state.headerYOffset - (Platform.OS !== 'web' && this.state.headerYOffset > 0 ? cd.appBorderWidth : 0)
                    : 0,
                borderTopWidth: Platform.OS === 'web'
                    ? cd.appBorderWidth
                    : this.state.headerYOffset + (cd.smallScreen && this.state.headerYOffset > 0 ? cd.appBorderWidth : 0),
                borderTopColor: mainDarkColor,
                borderTopLeftRadius: cd.smallScreen ? 0 : Math.min(cd.screenWidth, cd.screenHeight) * 0.01,
                borderTopRightRadius: cd.smallScreen ? 0 : Math.min(cd.screenWidth, cd.screenHeight) * 0.01,
                //shadowColor: "#e6ecf0ea",
                //backgroundColor: "transparent",
                //shadowRadius: cd.smallScreen ? cd.headerAreaHeight * 0.17 : cd.headerAreaHeight * 0.1,
                //shadowOffset: {
                //    width: 0,
                //    height: cd.smallScreen ? cd.headerAreaHeight * 0.055 : 0,
                //},
                //shadowOpacity: cd.smallScreen && this.state.headerYOffset > 0 ? 0.8 : 0,
            }
        }

        // Show the home screen.
        return (
            <>
                {!this.props.horizontalMobileBrowser &&
                    <>
                        {/**
                         Page Container
                         **/}
                        <SafeAreaView
                            edges={["left", "right",]}
                            style={[(cd.smallScreen && Platform.OS === 'web')
                                ? {
                                    width: cd.screenWidth,
                                    height: cd.screenHeight + mobileBrowserExtraHeight,
                                }
                                : {
                                    flex: 1,
                                    backgroundColor: topMostColor,
                                }, Platform.OS !== 'web' ? {} : null]}>

                            {/**
                             Scrolling Container
                             **/}
                            <ConditionalWrapper
                                condition={!(cd.smallScreen && Platform.OS === 'web')}
                                wrapper={children =>
                                    <ScrollView
                                        nativeID="cardAreaScrollView"
                                        scrollEnabled={this.state.scrollEnabled}
                                        scrollEventThrottle={16}
                                        ref={this.scrollRef}
                                        onScroll={(event) => {
                                            this.handleScroll(event.nativeEvent.contentOffset.y);
                                        }}
                                        onScrollBeginDrag={this.handleDragStart}
                                        onScrollEndDrag={this.handleDragEnd}
                                        onMomentumScrollEnd={this.handleGlideEnd}
                                        bounces={false}
                                        scrollsToTop={false}
                                        pagingEnabled={Platform.OS === 'web' && !cd.smallScreen}
                                        snapToStart={false}
                                        snapToEnd={true}
                                        decelerationRate={this.state.scrollDecelerationRate}
                                        showsVerticalScrollIndicator={false}
                                        keyboardShouldPersistTaps={"always"}
                                        style={{
                                            backgroundColor: topMostColor,
                                            flex: 1,
                                        }}
                                        contentContainerStyle={[{
                                            width: cd.screenWidth,
                                            backgroundColor: topMostColor,
                                        },
                                            Platform.OS === 'web' ? {
                                                height: cd.smallScreen ? cd.screenHeight - cd.headerAreaHeight : cd.scrollAreaHeight,
                                            } : null]}
                                    >{children}</ScrollView>
                                }>


                                {/**
                                 Main Container with Borders
                                 */}
                                <View
                                    ref={this.bodyRef}
                                    collapsable={false}
                                    onLayout={({nativeEvent}) => {

                                    }}
                                    style={{
                                        height: cd.scrollAreaHeight,
                                        borderTopWidth: cd.smallScreen ? 0 : cd.appBorderWidth,
                                        borderTopColor: "white",
                                        borderTopLeftRadius: cd.smallScreen ? 0 : Math.min(cd.screenWidth, cd.screenHeight) * 0.01,
                                        borderTopRightRadius: cd.smallScreen ? 0 : Math.min(cd.screenWidth, cd.screenHeight) * 0.01,
                                        //backgroundColor: "white",
                                        overflow: "hidden",
                                    }}>
                                    {/** @ts-ignore **/}
                                    <LinearGradient
                                        colors={cd.smallScreen
                                            ? ["#e6ebeda4", "#e6ebeda4"]
                                            : ["#faf0f07a", "#faf0fa7a", "#f0fafa7a", "#f7faf963", "#faf6f059", "#fafdff"]}
                                        start={[1, 0]}
                                        end={[0, 1.4]}
                                        style={{
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            flexDirection: "column",
                                            width: cd.screenWidth,
                                            height: cd.scrollAreaHeight,
                                            overflow: "hidden",
                                        }}>


                                        {/**
                                         Popup Modals (hidden until activated)
                                         */}
                                        <SettingsModal
                                            dims={cd}
                                            yOffset={this.state.headerYOffset}
                                            visible={this.state.showSettingsModal}
                                            onClose={this.hideSettingsModal}/>
                                        <LoginModal
                                            dims={cd}
                                            yOffset={this.state.headerYOffset}
                                            visible={this.state.showLoginModal}
                                            onClose={this.hideLoginModal}
                                            authFunc={this.props.authenticateUserWrapper}
                                            signUpFunc={this.props.registerUserWrapper}/>
                                        <SearchModal
                                            visible={this.state.showSearchModal}
                                            yOffset={this.state.headerYOffset}
                                            dims={cd}
                                            onClose={this.hideSearchModal}
                                            showNextCards={this.showNextCards}
                                            showLoginModal={this.showLoginModal}
                                            authenticated={this.props.authenticated}/>
                                        <SavedModal
                                            visible={this.state.showSavedModal}
                                            yOffset={this.state.headerYOffset}
                                            dims={cd}
                                            onClose={this.hideSavedModal}/>
                                        <QuoteModal
                                            key={this.state.shownQuoteData ? this.state.shownQuoteData.reportId : 'blank-quote-modal+'}
                                            visible={this.state.showQuoteModal}
                                            quoteData={this.state.shownQuoteData}
                                            dims={cd}
                                            onClose={this.hideQuoteModal}/>


                                        {/**
                                         Header Placeholder
                                         */}
                                        <View
                                            nativeID={"dummyHeader"}
                                            style={{
                                                width: cd.screenWidth,
                                                height: cd.headerAreaHeight,
                                            }}/>

                                        {/**
                                         Space Below Header (behind "About" & "Feedback" buttons)
                                         */}
                                        {cd.smallScreen &&
                                            <View
                                                style={{
                                                    width: cd.screenWidth,
                                                    height: cd.spaceBelowHeader,
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}>

                                                {/**
                                                 Mobile About Button
                                                 */}
                                                {(cd.smallScreen && this.state.lastYOffset < cd.headerAreaHeight) &&
                                                    <AboutBtnArea
                                                        dims={cd}
                                                        onPress={this.handlePressAbout}/>
                                                }

                                                {/**
                                                 Mobile Feedback Button
                                                 */}
                                                {(cd.smallScreen && this.state.lastYOffset < cd.headerAreaHeight) &&
                                                    <FeedbackBtnArea
                                                        dims={cd}
                                                        onPress={this.handlePressFeedback}/>
                                                }
                                            </View>
                                        }

                                        {/**
                                         Quote Cards
                                         */}
                                        <View
                                            nativeID="horizontalView"
                                            style={{
                                                flexDirection: "row",
                                                width: cd.cardAreaWidth,
                                                height: cd.cardAreaHeight,
                                                justifyContent: "flex-start",
                                                alignItems: "flex-start",
                                            }}>

                                            <View
                                                nativeID="cardsView"
                                                style={{
                                                    width: cd.cardAreaWidth,
                                                    height: cd.cardAreaHeight,
                                                    flexGrow: 0,
                                                    flexShrink: 0,
                                                    flexDirection: "row",
                                                    flexWrap: "wrap",
                                                    justifyContent: "flex-start",
                                                    alignItems: "flex-start",
                                                }}>
                                                {this.state.reports.map((item, idx) => (
                                                    <QuoteCard
                                                        idx={idx}
                                                        key={item.report_id}
                                                        dims={cd}
                                                        quoteData={item}
                                                        bgColor={this.state.cardBgColors[idx]}
                                                        dragging={this.state.dragging}
                                                        openModal={() => this.showQuoteModal(item)}/>
                                                ))}
                                            </View>
                                        </View>


                                        {/**
                                         More Button
                                         */}
                                        <MoreButton
                                            dims={cd}
                                            areaWidth={cd.moreBtnWidth}
                                            areaHeight={cd.moreBtnHeight}
                                            loadingMoreCards={this.state.loadingMoreCards}/>
                                    </LinearGradient>
                                </View>


                                {/**
                                 Loading More Area
                                 */}
                                {(!cd.smallScreen || this.state.dragAreaEnabled) &&
                                    <DragArea
                                        width={cd.screenWidth}
                                        height={cd.dragAreaHeight}
                                        smallScreen={cd.smallScreen}
                                        borderWidth={cd.appBorderWidth}/>
                                }

                            </ConditionalWrapper>

                        </SafeAreaView>
                    </>
                }


                {!this.props.horizontalMobileBrowser &&
                    <>

                        {/**
                         Floating Header Container with Light Blue BG
                         */}
                        {!cd.smallScreen &&
                            <BlurView
                                nativeID="headerView"
                                entering={headerFadeIn}
                                exiting={headerFadeOut}
                                // @ts-ignore
                                style={styles.headerArea}>
                            </BlurView>
                        }
                        {cd.smallScreen && this.state.headerVisible &&
                            <Animated.View
                                nativeID="headerView"
                                entering={headerFadeIn}
                                exiting={headerFadeOut}
                                // @ts-ignore
                                style={[styles.headerArea,
                                    this.state.lastYOffset > cd.loginBtnHeight ?
                                        {
                                            backgroundColor: "#f7f9faf9",
                                        } : null,
                                    this.state.headerYOffset > 0 ?
                                        {
                                            height: cd.headerAreaHeight + cd.loginBtnHeight * 0.1
                                        } : null]}>
                            </Animated.View>
                        }

                        {/**
                         Title Area with Dark Blue BG
                         */}
                        {(!cd.smallScreen || this.state.headerVisible) &&
                            <TitleBoxArea
                                dims={cd}
                                yOffset={this.state.headerYOffset}
                                exactYOffset={this.state.lastYOffset}
                                userName={"Guest"}/>
                        }


                        {/**
                         Search Button (free floating)
                         */}
                        {(!cd.smallScreen || this.state.headerVisible) &&
                            <SearchBtnArea
                                dims={cd}
                                yOffset={this.state.headerYOffset}
                                onPress={this.showSearchModal}/>}

                        {/**
                         Saved Button (free floating)
                         */}
                        {(!cd.smallScreen || this.state.headerVisible) &&
                            <SavedBtnArea
                                dims={cd}
                                yOffset={this.state.headerYOffset}
                                onPress={() => {
                                    if (this.props.authenticated || true) {
                                        this.showSavedModal();
                                    } else {
                                        this.showLoginModal();
                                    }
                                }}/>
                        }


                        {/**
                         Login Button (free floating)
                         */}
                        {((!cd.smallScreen || this.state.headerVisible) && !this.props.authenticated) &&
                            <LoginBtn
                                dims={cd}
                                yOffset={this.state.headerYOffset}
                                onPress={this.showLoginModal}/>
                        }

                        {/**
                         Settings Button (free floating)
                         */}
                        {((!cd.smallScreen || this.state.headerVisible) && this.props.authenticated) &&
                            <SettingsBtn
                                dims={cd}
                                yOffset={this.state.headerYOffset}
                                onPress={this.showSettingsModal}/>
                        }

                        {/**
                         Desktop About Button (free floating)
                         */}
                        {!cd.smallScreen &&
                            <AboutBtnArea
                                dims={cd}
                                onPress={this.handlePressAbout}/>
                        }

                        {/**
                         Desktop Feedback Button (free floating)
                         */}
                        {!cd.smallScreen &&
                            <FeedbackBtnArea
                                dims={cd}
                                onPress={this.handlePressFeedback}/>
                        }
                    </>
                }
            </>
        );
    }
}