// Enum-like List of tags (sub-categories) for each category of content.
export class CategoryTags {

    static readonly TOPICS = new CategoryTags('Topics', [
        'Popular',
        //'Court Battles',
        //'Crime',
        'Science',
        'Business',
        'Philosophy & Religion',
        'Entertainment',
        'Economy',
        //'Natural Environment',
        //'LGBT+',
        //'Legal',
        'Culture',
        'Comedy & Satire',
        'Politics',
        'Left-Side Politics',
        'Right-Side Politics',
        //'ESG',
        //'International',
        //'Sports',
        'Education',
        'Motivation',
        'Social Commentary',
        //'Parenting',
        'Confrontation',
        'Mistakes',
        'Scams',
        'Cancer',
        'China',
        'Aliens',
        'Relationships'
    ]);

    static readonly EVENTS = new CategoryTags('Events', [
        //'Heard-Depp Trial',
        '2022 U.S. Midterm Election',
        'Russia-Ukraine War',
    ]);

    static readonly PEOPLE_AND_SOURCES = new CategoryTags('People & Sources', [
        'Companies',
        'Politicians',
        'Organizations & Non-profits',
        'Youtube',
        'Governments',
        'Celebrities',
        'Documentaries',
        'Court Rulings',
        'Blogs',
        'Podcasts',
        'Tweets',
        'Interviews',
        'Debates',
        'Speeches',
        'Elon Musk',
        'Ro Khanna',
        'Ethan Nicolle',
        'Cenk Uygur',
        'Joe Rogan',
        'Matt Walsh',
        'Michael Knowles',
        'Marc Andreesen',
        'Tucker Carlson',
        'Pierre Polievre',
        'Peter Thiel',
        'Joe Scarborough',
        'Jordan Peterson',
        'Alex Stein',
        'Ron DeSantis',
        'Kari Lake',
        'Andrew Shulz',
        'Bill Maher',
        'Kurzgesagt',
    ]);

    static readonly QUOTERS = new CategoryTags('Quoters', [
        'Ash Salazar',
        'Jeffrey Lee',
        'Joni Silky',
        'Paul Crewdeet',
        'Christina Lakehart',
        'Maxwell Ilie',
        'Ransher Madison',
        'Andrew Seemey',
        'Jamie Timmons',
        'Leo Andrews',
    ]);

    private constructor(private readonly key: string, public readonly value: Array<String>) {
    }

    toString() {
        return this.key;
    }
}