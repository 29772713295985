// Template for the saved button (bookmark icon on top-right).

import {Platform, StyleSheet} from "react-native";
import {CompDimensions} from "../../util/Util";
// @ts-ignore
import saved_icon from '../../../assets/saved_icon.png';
import Animated, {Easing, FadeInUp} from "react-native-reanimated";
import {useState} from "react";
import {headerBtnColor, mainDarkColor, mainLightColor} from "../../Main";
import {LinearGradient} from "expo-linear-gradient";
import HeaderSideButton from "./HeaderSideButton";


export function SavedBtnArea(props: {
    dims: CompDimensions,
    yOffset: number,
    onPress: any,
}) {

    // Get and calculate component dimensions.
    const cd = props.dims;
    const bgStripWidthDesktop = cd.smallScreen ? cd.headerSideBtnsWidth : cd.searchBtnLeftMargin + cd.aboutBtnAreaWidth;
    const bgStripWidthMobile = cd.headerSideBtnsWidth * 0.17;
    const bgStripHeight = cd.smallScreen ? cd.headerSideBtnsTopMargin + cd.headerSideBtnsHeight * 0.6 : cd.headerSideBtnsHeight;

    // Create state vars.
    const [isHovered, setIsHovered] = useState(false);

    // Create reusable styles.
    const styles = StyleSheet.create({
        bgStrip: {
            // @ts-ignore
            position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
            width: bgStripWidthDesktop,
            height: bgStripHeight,
            marginTop: cd.smallScreen
                ? -props.yOffset
                : cd.appBorderWidth + cd.headerSideBtnsTopMargin,
            marginLeft: cd.smallScreen ? cd.savedBtnLeftMargin : cd.screenWidth - bgStripWidthDesktop,
            backgroundColor: cd.smallScreen ? mainDarkColor : headerBtnColor,
            borderTopLeftRadius: cd.smallScreen ? 0 : cd.loginBtnBorderRadius,
            borderBottomRightRadius: cd.smallScreen ? cd.headerSideBtnsWidth * 0.5 : 0,
            borderBottomLeftRadius: cd.smallScreen ? cd.headerSideBtnsWidth * 0.5 : cd.loginBtnBorderRadius,
            shadowOpacity: cd.smallScreen ? 0 : 0.7,
            shadowColor: mainLightColor,
            shadowRadius: cd.aboutBtnAreaHeight * 0.06,
        },
        mobileBgStrip1: {
            // @ts-ignore
            position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute", width: bgStripWidthMobile,
            height: bgStripHeight,
            marginTop: -props.yOffset,
            marginLeft: cd.savedBtnLeftMargin + cd.headerSideBtnsWidth * 0.005,
            backgroundColor: mainDarkColor,
            borderBottomRightRadius: cd.smallScreen ? cd.headerSideBtnsWidth * 0.5 : cd.loginBtnBorderRadius,
            borderBottomLeftRadius: cd.smallScreen ? cd.headerSideBtnsWidth * 0.5 : 0,
        },
        mobileBgStrip2: {
            // @ts-ignore
            position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute", width: bgStripWidthMobile,
            height: bgStripHeight,
            marginTop: -props.yOffset,
            marginLeft: cd.savedBtnLeftMargin + (cd.headerSideBtnsWidth - bgStripWidthMobile) - cd.headerSideBtnsWidth * 0.005,
            backgroundColor: mainDarkColor,
            borderBottomRightRadius: cd.smallScreen ? cd.headerSideBtnsWidth * 0.5 : cd.loginBtnBorderRadius,
            borderBottomLeftRadius: cd.smallScreen ? cd.headerSideBtnsWidth * 0.5 : 0,
        },
        mobileBgStripShadow: {
            shadowOpacity: cd.smallScreen ? 0.5 : 0,
            shadowColor: "#aecbe5",
            shadowRadius: bgStripWidthMobile * 0.3,
            shadowOffset: {width: 0, height: bgStripWidthMobile * 0.05}
        },
    });

    return (
        <>
            {!cd.smallScreen &&
                <Animated.View
                    entering={FadeInUp.duration(100).easing(Easing.linear)}
                    style={styles.bgStrip}>
                </Animated.View>
            }
            {(cd.smallScreen && props.yOffset === 0) &&
                <Animated.View
                    entering={FadeInUp.duration(100).easing(Easing.linear)}
                    style={[styles.mobileBgStrip1, styles.mobileBgStripShadow]}>
                    <LinearGradient
                        colors={[mainDarkColor, mainDarkColor, mainDarkColor, mainLightColor, mainLightColor]}
                        style={{
                            width: bgStripWidthMobile,
                            height: bgStripHeight,
                            borderBottomRightRadius: cd.headerSideBtnsWidth * 0.5,
                            borderBottomLeftRadius: cd.headerSideBtnsWidth * 0.5,
                        }}/>
                </Animated.View>
            }
            {(cd.smallScreen && props.yOffset === 0) &&
                <Animated.View
                    entering={FadeInUp.duration(100).easing(Easing.linear)}
                    style={[styles.mobileBgStrip2, styles.mobileBgStripShadow]}>
                    <LinearGradient
                        colors={[mainDarkColor, mainDarkColor, mainDarkColor, mainLightColor, mainLightColor]}
                        style={{
                            width: bgStripWidthMobile,
                            height: bgStripHeight,
                            borderBottomRightRadius: cd.headerSideBtnsWidth * 0.5,
                            borderBottomLeftRadius: cd.headerSideBtnsWidth * 0.5,
                        }}/>
                </Animated.View>
            }
            <HeaderSideButton
                dims={cd}
                isHovered={isHovered}
                setIsHovered={setIsHovered}
                imageSrc={saved_icon}
                onPress={props.onPress}
                marginLeft={cd.smallScreen ? cd.savedBtnLeftMargin : cd.desktopSavedBtnLeftMargin}
                marginTop={cd.smallScreen ? cd.headerSideBtnsTopMargin - props.yOffset : cd.appBorderWidth + cd.headerSideBtnsTopMargin}
                yOffset={props.yOffset}
                hitSlop={{bottom: cd.headerSideBtnsHeight * 0.3, right: cd.headerSideBtnsWidth * 0.3}}/>
        </>);
}
