// Template for the saved button (saved icon on top-left).

import {StyleSheet, Text} from "react-native";
import React from "react";
import {CompDimensions} from "../../../util/Util";
import SquishyButton from "../../reusable/SquishyButton";

function ThankBtn(props: {
    dims: CompDimensions,
    isHovered: boolean,
    setIsHovered: any,
    onPress: any,
    marginRight: number,
    width: number,
    height: number,
    thanksAdded: number,
}) {

    // Define dimensions and colors based on state.
    const cd = props.dims;
    let thankBtnColor = props.thanksAdded === 0
        ? "#c7ced4fd"
        : (props.thanksAdded === 1 ? "#c7ced4fd" : "#c7ced4fd");
    if (props.isHovered && !cd.smallScreen) {
        thankBtnColor = props.thanksAdded === 0
            ? "#c0c9d1fd"
            : (props.thanksAdded === 1 ? "#c0c9d1fd" : "#c0c9d1fd");
    }
    let thankBtnBorderColor = "#f7fbfc12";
    let thankBtnTextColor = "#f7fcff";
    const thankBtnFontSize = 0.9 * Math.sqrt((props.width * props.height) / "+0 THANKs ".length);

    // Create reusable styles.
    const styles = StyleSheet.create({
        thankBtnContainer: {
            transform: [{scale: props.isHovered && !cd.smallScreen ? 1.01 : 1}],
            width: props.width,
            height: props.height,
            marginRight: props.marginRight,
            marginTop: "auto",
            marginBottom: "auto",
            borderWidth: props.height * 0.06,
            borderRadius: props.height * 0.2,
            borderColor: thankBtnBorderColor,
            shadowColor: thankBtnColor,
            shadowRadius: cd.smallScreen ? props.height * 0.05 : props.height * 0.08,
            shadowOffset: {
                width: 0,
                height: cd.smallScreen ? props.height * 0.015 : 0,
            },
            shadowOpacity: cd.smallScreen ? 0.2 : 0.4,
            backgroundColor: thankBtnColor,
        },
        thankBtnPressable: {
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
        },
        thankBtnText: {
            fontSize: thankBtnFontSize,
            fontWeight: "bold",
            color: thankBtnTextColor,
        },
    });

    return (
        /**
         THANK button
         */
        <SquishyButton
            isSmallScreen={cd.smallScreen}
            pressFunc={props.onPress}
            isHovered={props.isHovered}
            setHoveredFunc={props.setIsHovered}
            scaleTo={0.96}
            squishInDuration={125}
            squishOutDelay={75}
            squishOutDuration={100}
            pressFuncDelay={cd.smallScreen ? 150 : 10}
            unhoverDelay={50}
            containerStyle={styles.thankBtnContainer}
            buttonStyle={styles.thankBtnPressable}
        >
            <Text
                style={styles.thankBtnText}
                selectable={false}>{props.thanksAdded + (props.thanksAdded === 1 ? " THANK" : " THANKs")}</Text>
        </SquishyButton>
    );
}

export default ThankBtn;