// Template for a news report.
import {Platform, Pressable, StyleSheet, View} from "react-native";
import React, {Component, createRef} from "react";
import Hoverable from "../reusable/Hoverable";
import {addAlpha, CompDimensions, lightenHex} from "../../util/Util";
import Animated, {Easing, EasingNode, FadeIn, FadeOut} from 'react-native-reanimated';
import {QuoteData} from "../../types/QuoteData";
import {boldifyInput, renderBoldifiedText} from "../../util/Boldify";
import {LinearGradient} from "expo-linear-gradient";
import {TapGestureHandler} from 'react-native-gesture-handler';
import {createSafeAnimatedValue} from "../../util/PatchedAnimatedValue";

export class QuoteCard extends Component<{
    dims: CompDimensions,
    quoteData: QuoteData,
    bgColor: any,
    dragging: boolean,
    openModal: any,
    idx: any,
}> {

    state = {
        isPressed: false,
        isHovered: false,
        unHoverScheduled: false,
        leftHover: false,
        leftHoverActivated: false,
        leftHoverCoolingDown: false,
        rightHover: false,
        rightHoverActivated: false,
        rightHoverCoolingDown: false,
        xOffset: 0,
        maxScroll: 0,
        animating: false,
    }
    scrollRef: any;
    scrollHandleTimer: any;
    scaleVal: any;

    constructor(props) {
        super(props);

        this.scrollRef = createRef();
        this.scaleVal = createSafeAnimatedValue(1.0);

        this.handleLeftAutoScroll = this.handleLeftAutoScroll.bind(this);
        this.handleRightAutoScroll = this.handleRightAutoScroll.bind(this);
        this.cardPressIn = this.cardPressIn.bind(this);
        this.cardPressOut = this.cardPressOut.bind(this);
        this.checkForUnpress = this.checkForUnpress.bind(this);
        this.checkForDragEnd = this.checkForDragEnd.bind(this);
    }

    // Automatically scroll when hovering over left area of tag strip.
    handleLeftAutoScroll() {
        // Check if scroll is activated.
        if (!this.state.leftHover || !this.state.leftHoverActivated || this.state.leftHoverCoolingDown) return;

        // Check that scheduled scroll is valid.
        const containerWidth = this.props.dims.cardWidth;
        if (!this.state.leftHover || this.state.xOffset <= 0.001) return;

        // Scroll left.
        let newScrollX = Math.max(0, this.state.xOffset - containerWidth * 0.35);
        if (newScrollX < containerWidth * 0.3) newScrollX = 0;
        this.scrollRef.current?.scrollTo({
            x: newScrollX,
            animated: true,
        });

        // Cool down so that scroll is only performed every two cycles.
        this.setState({leftHoverCoolingDown: true});
        setTimeout(() => {
            this.setState({leftHoverCoolingDown: false});
        }, 400);

        // Schedule next scroll or cancel event.
        if (this.state.xOffset <= 0.001) {
            this.setState({
                leftHover: false,
                leftHoverActivated: false,
            });
        }
    }

    // Automatically scroll when hovering over right area of tag strip.
    handleRightAutoScroll() {
        // Check if scroll is activated.
        if (!this.state.rightHover || !this.state.rightHoverActivated || this.state.rightHoverCoolingDown) return;

        // Check that scheduled scroll is valid.
        const containerWidth = this.props.dims.cardWidth;
        if (!this.state.rightHover || this.state.xOffset >= this.state.maxScroll - containerWidth * 0.995) return;

        // Scroll right.
        let newScrollX = Math.min(this.state.maxScroll - containerWidth, this.state.xOffset + containerWidth * 0.35);
        if (newScrollX > this.state.maxScroll - containerWidth - containerWidth * 0.3) newScrollX = this.state.maxScroll - containerWidth;
        this.scrollRef.current?.scrollTo({
            x: newScrollX,
            animated: true,
        });

        // Cool down so that scroll is only performed every two cycles.
        this.setState({rightHoverCoolingDown: true});
        setTimeout(() => {
            this.setState({rightHoverCoolingDown: false});
        }, 400);

        // Schedule next scroll or cancel event.
        if (this.state.xOffset >= this.state.maxScroll - containerWidth * 0.995) {
            this.setState({
                rightHover: false,
                rightHoverActivated: false,
            });
        }
    }

    checkForDragEnd() {
        // Check again later if still pressing.
        if (this.props.dragging) {
            setTimeout(this.checkForDragEnd, 10);
            return;
        }

        // Remove hover effect if no longer pressing.
        this.setState({isHovered: false});
    }

    checkForUnpress() {
        // Check again later if still pressing.
        if (this.state.isPressed) {
            setTimeout(this.checkForUnpress, 10);
            return;
        }

        // Remove hover effect if no longer pressing.
        this.setState({isHovered: false});
    }

    cardPressIn() {
        // Use normal hover logic on desktop.
        if (!this.props.dims.smallScreen) {
            this.setState({isHovered: true});
            return;
        }

        // Use different hover logic on mobile.
        this.setState({
            isPressed: true,
            isHovered: true,
        });
        setTimeout(() => {

            // If dragging after press, unhover when drag ends.
            if (this.props.dragging) {
                this.checkForDragEnd();
            }

            // If holding after press, unhover when finger lifted.
            else {
                this.checkForUnpress();
            }
        }, 200);
    }

    cardPressOut() {
        // Use normal unhover logic on desktop.
        if (!this.props.dims.smallScreen) {
            this.setState({isHovered: false});
            return;
        }

        // Use different unhover logic on mobile.
        this.setState({isPressed: false});
    }

    componentDidMount() {
        this.scrollHandleTimer = setInterval(() => {
            this.handleRightAutoScroll();
            this.handleLeftAutoScroll();
        }, 300);
    }

    componentWillUnmount() {
        if (this.scrollHandleTimer) {
            clearInterval(this.scrollHandleTimer);
        }
    }

    render() {
        // Get component dimensions.
        const cd = this.props.dims;

        // If null summary, return blank space.
        if (!this.props.quoteData.summary) {
            return <View
                style={{
                    height: cd.cardHeight - (cd.smallScreen ? cd.cardWidth * 0.012 : cd.cardWidth * 0.01),
                    width: cd.cardWidth,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: cd.smallScreen ? 0 : cd.cardTopMargin,
                    marginBottom: cd.smallScreen ? cd.cardTopMargin * 1.1 : "auto",
                }}
            />
        }


        // If null headline, return muted loading quote card.
        else if (!this.props.quoteData.headline) {
            return (
                <Animated.View
                    key={this.props.quoteData.report_id}
                    entering={FadeIn.duration(400).easing(Easing.linear)}
                    exiting={FadeOut.duration(400).easing(Easing.linear)}
                    style={{
                        height: cd.cardHeight - (cd.smallScreen ? cd.cardWidth * 0.012 : cd.cardWidth * 0.01),
                        width: cd.cardWidth,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: cd.smallScreen ? 0 : cd.cardTopMargin,
                        marginBottom: cd.smallScreen ? cd.cardTopMargin * 1.1 : "auto",
                        borderRadius: cd.cardWidth * 0.015,
                        shadowColor: this.props.bgColor,
                        shadowRadius: Platform.OS !== 'web' ? cd.cardWidth * 0.01 : cd.cardWidth * 0.02,
                        shadowOffset: {
                            width: 0,
                            height: cd.smallScreen ? cd.cardWidth * 0.003 : cd.cardWidth * 0.007,
                        },
                        shadowOpacity: 1,
                        elevation: 16,
                        borderStyle: "solid",
                        backgroundColor: this.props.bgColor,
                    }}/>
            );
        }

        // Calculate cardDimensions.
        const topBottomMargin = cd.smallScreen ? cd.cardHeight * 0.02 : cd.cardHeight * 0.023;
        const cardBorderWidth = cd.smallScreen ? cd.cardWidth * 0.012 : cd.cardWidth * 0.01;
        //const hoveredBgColor = lightenHex(this.props.bgColor, 15);
        const hoveredBgColor = this.props.bgColor;

        // Calculate headline dimensions.
        const headlineWidth = cd.cardWidth * 0.98;
        const headlineHeight = cd.smallScreen ? cd.cardHeight * 0.45 : cd.cardHeight * 0.43;
        let headlineFontSize = 1.06 * Math.sqrt(headlineWidth * headlineHeight / this.props.quoteData.headline.length);

        // Calculate summary dimensions.
        const summaryWidth = cd.cardWidth - cardBorderWidth * 2;
        const summaryHeight = cd.smallScreen ? cd.cardHeight * 0.32 : cd.cardHeight * 0.32;
        let summaryFontSize = (cd.smallScreen
            ? ((Platform.OS === 'web' ? 1.013 : 1.02) - this.props.quoteData.summary.length * 0.00028)
            : (0.93 + this.props.quoteData.summary.length * 0.0006)) * Math.sqrt((summaryWidth * summaryHeight) / this.props.quoteData.summary.length);

        // Calculate tags strip dimensions.
        const tagsContainerWidth = cd.cardWidth;
        const tagsContainerHeight = cd.cardHeight - (topBottomMargin * 1.5 + headlineHeight + topBottomMargin * 2 + summaryHeight);
        const tagsAreaWidth = cd.cardWidth;
        const tagsTopBottomBorderWidth = cd.smallScreen ? tagsContainerHeight * 0.045 : tagsContainerHeight * 0.04;
        const tagsAreaHeight = tagsContainerHeight - tagsTopBottomBorderWidth * 2;

        // Define styles.
        const styles = StyleSheet.create({
            cardOuterContainer: {
                // @ts-ignore
                transform: [{scale: this.scaleVal}],
                flexBasis: cd.cardWidth,
                width: cd.cardWidth - cardBorderWidth,
                height: cd.cardHeight - cardBorderWidth,
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: cd.smallScreen ? 0 : cd.cardTopMargin,
                marginBottom: cd.smallScreen ? cd.cardTopMargin * 1.1 : "auto",
                borderRadius: cd.cardWidth * 0.022,
                shadowColor: this.props.bgColor,
                shadowRadius: Platform.OS !== 'web' ? cd.cardWidth * 0.01 : cd.cardWidth * 0.02,
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? cd.cardWidth * 0.003 : cd.cardWidth * 0.007,
                },
                shadowOpacity: 1,
                elevation: 16,
            },
            cardAreaContainer: {
                width: cd.cardWidth,
                height: cd.cardHeight,
                flexDirection: "column",
                alignItems: "center",
                borderRadius: this.state.isHovered ? cd.cardWidth * 0.023 : cd.cardWidth * 0.02,
                borderWidth: cardBorderWidth,
                borderColor: lightenHex(this.props.bgColor, -6),
                backgroundColor: this.state.isHovered ? hoveredBgColor : this.props.bgColor,
            },
            cardBackgroundPressable: {
                position: "absolute",
                width: cd.cardWidth,
                height: cd.cardHeight,
            },
            pressableContainer: {
                width: cd.cardWidth,
            },
            headlineText: {
                width: headlineWidth,
                fontSize: headlineFontSize,
                marginTop: topBottomMargin,
                paddingLeft: cd.cardWidth * 0.01,
                paddingRight: cd.cardWidth * 0.01,
                //color: "#2d313b",
                fontWeight: this.props.idx % 2 === 0 ? "bold" : "normal",
                color: this.props.idx % 2 === 0 ? lightenHex(this.props.bgColor, -100) : "#2d313b",
                textShadowColor: this.props.idx % 2 === 0 ? lightenHex(this.props.bgColor, 60) : "#bdbcb9",
                //textShadowOffset: {width: headlineWidth * 0.01, height: headlineWidth * 0.01},
                textShadowRadius: this.props.idx % 2 === 0
                    ? (headlineHeight * 0.05)
                    : (cd.smallScreen ? headlineHeight * 0.035 : headlineHeight * 0.045),
                textAlign: "center",
            },
            tagsAreaContainer: {
                width: tagsAreaWidth,
                height: tagsAreaHeight + tagsTopBottomBorderWidth * 2,
                shadowColor: lightenHex(this.props.bgColor, 8),
                shadowRadius: cd.smallScreen ? tagsContainerHeight * 0.007 : tagsContainerHeight * 0.025,
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? tagsContainerHeight * 0.007 : 0,
                },
                shadowOpacity: 0.8,
                flexDirection: "column",
            },
            tagsScrollBoxBorderStrip: {
                width: tagsAreaWidth - cardBorderWidth * 2,
                height: tagsTopBottomBorderWidth,
                marginLeft: cardBorderWidth,
                backgroundColor: lightenHex(this.props.bgColor, 6),
            },
            tagsScrollBox: {
                width: tagsAreaWidth - cardBorderWidth * 2,
                height: tagsAreaHeight - tagsTopBottomBorderWidth * 2,
                marginLeft: cardBorderWidth,
                backgroundColor: lightenHex(this.props.bgColor, 7),
            },
            tagsContainer: {
                height: tagsAreaHeight - tagsTopBottomBorderWidth * 2,
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            },
            speakerText: {
                color: lightenHex(this.props.bgColor, -75),
                paddingLeft: tagsAreaWidth * 0.03,
                paddingRight: tagsAreaWidth * 0.03,
                fontSize: tagsAreaHeight * 0.6,
                fontWeight: "bold",
                textShadowColor: "#383837",
                textShadowRadius: cd.smallScreen ? tagsAreaHeight * 0.007 : tagsAreaHeight * 0.008,
            },
            sourceText: {
                color: lightenHex(this.props.bgColor, -50),
                paddingLeft: tagsAreaWidth * 0.03,
                paddingRight: tagsAreaWidth * 0.03,
                paddingTop: cd.smallScreen ? tagsAreaHeight * 0.03 : tagsAreaHeight * 0.01,
                fontSize: tagsAreaHeight * 0.55,
                fontStyle: "italic",
                textShadowColor: "#ababab",
                textShadowRadius: cd.smallScreen ? tagsAreaHeight * 0.012 : tagsAreaHeight * 0.015,
            },
            tagText: {
                color: lightenHex(this.props.bgColor, -65),
                paddingLeft: tagsAreaWidth * 0.03,
                paddingRight: tagsAreaWidth * 0.03,
                fontSize: tagsAreaHeight * 0.55,
            },
            summaryTextContainer: {
                width: summaryWidth,
                borderRadius: cd.smallScreen ? cd.cardWidth * 0.015 : cd.cardWidth * 0.015,
                backgroundColor: this.state.isHovered ? "white" : "#fafbfc",
            },
            summaryText: {
                fontSize: summaryFontSize,
                textAlign: "center",
                paddingTop: cd.cardHeight * 0.03,
                paddingBottom: cd.cardHeight * 0.03,
                paddingLeft: cd.cardWidth * 0.01,
                paddingRight: cd.cardWidth * 0.01,
            },
            hoveredBg: {
                backgroundColor: hoveredBgColor,
            },
            hoveredShadow: {
                //shadowColor: "lightgray",
                //shadowRadius: cd.cardWidth * 0.02,
            },
        });

        // Return loaded quote card.
        return (

            /**
             * Card Outer Container
             */
            <TapGestureHandler
                onEnded={() => {
                    this.props.openModal(this.props.quoteData);
                    if (this.state.animating) {
                        setTimeout(() => {
                            Animated.timing(this.scaleVal, {
                                toValue: 1,
                                duration: cd.smallScreen ? 100 : 100,
                                easing: EasingNode.in(EasingNode.ease),
                            }).start(() => this.setState({animating: false}));
                        }, cd.smallScreen ? 50 : 100);
                    }
                }}
                onBegan={() => {
                    if (this.state.animating) return;
                    this.setState({animating: true});
                    Animated.timing(this.scaleVal, {
                        toValue: cd.smallScreen ? 0.97 : 0.97,
                        duration: cd.smallScreen ? 100 : 150,
                        easing: EasingNode.in(EasingNode.exp),
                    }).start();
                }}
                onFailed={() => {
                    if (this.state.animating) {
                        setTimeout(() => {
                            Animated.timing(this.scaleVal, {
                                toValue: 1,
                                duration: cd.smallScreen ? 100 : 100,
                                easing: EasingNode.in(EasingNode.ease),
                            }).start(() => this.setState({animating: false}));
                        }, cd.smallScreen ? 50 : 100);
                    }
                }}
                maxDurationMs={2000}
                maxDist={cd.cardHeight * 0.1}>
                <Animated.View
                    entering={FadeIn.duration(750).easing(Easing.linear)}
                    //exiting={FadeOut.duration(750).easing(Easing.linear)}
                    style={styles.cardOuterContainer}>

                    {/**
                     Card Area Container
                     */}
                    <Animated.View
                        entering={FadeIn.duration(250).easing(Easing.linear)}
                        exiting={FadeOut.duration(350).easing(Easing.linear)}
                        style={styles.cardAreaContainer}>


                        {/**
                         Pressable Area Behind the Content (absolute)
                         */}
                        <Pressable
                            style={styles.cardBackgroundPressable}
                            onPressIn={this.cardPressIn}
                            onPressOut={this.cardPressOut}
                            onPress={() => {
                                //this.props.openModal(this.props.quoteData);
                            }}
                        />


                        {/**
                         Quote Headline Container
                         */}
                        <Animated.View
                            entering={FadeIn.duration(500).easing(Easing.exp)}
                            style={{
                                marginBottom: "auto",
                            }}
                            pointerEvents={"none"}>

                            {/**
                             Quote Headline
                             */}
                            <Animated.Text
                                entering={FadeIn.duration(500).easing(Easing.exp)}
                                style={styles.headlineText}
                                selectable={false}
                                //>{renderBoldifiedText(boldifyInput(this.props.quoteData.headline), "RobotoCondensed_400Regular", "RobotoCondensed_700Bold")}
                            >{this.props.quoteData.headline}
                            </Animated.Text>
                        </Animated.View>


                        {/**
                         Quote Author & Tags Container
                         */}
                        <Animated.View
                            entering={FadeIn.duration(500).easing(Easing.exp)}
                            style={styles.tagsAreaContainer}>

                            {/**
                             Quote Author & Tags Strip
                             */}
                            <Animated.View
                                entering={FadeIn.duration(500).easing(Easing.exp)}
                                style={styles.tagsScrollBoxBorderStrip}/>

                            <Animated.ScrollView
                                entering={FadeIn.duration(500).easing(Easing.exp)}
                                style={styles.tagsScrollBox}
                                ref={this.scrollRef}
                                scrollEventThrottle={60}
                                horizontal={true}
                                showsHorizontalScrollIndicator={false}
                                keyboardShouldPersistTaps={'always'}
                                onContentSizeChange={(contentWidth, contentHeight) => {
                                    this.setState({maxScroll: contentWidth});
                                }}
                                onScroll={(event) => this.setState({xOffset: event.nativeEvent.contentOffset.x})}
                            >

                                {/**
                                 Quote Speaker & Tags Inner Container
                                 */}
                                <Pressable
                                    //entering={FadeIn.duration(500).easing(Easing.exp)}
                                    style={styles.tagsContainer}
                                    onPress={() => {
                                        //this.props.openModal(this.props.quoteData);
                                    }}>

                                    {/**
                                     Speaker and Tags Text
                                     */}
                                    <Animated.Text
                                        style={styles.speakerText}
                                        selectable={false}>{this.props.quoteData.speaker}</Animated.Text>
                                    {this.props.quoteData.tags.length >= 2 &&
                                        <Animated.Text
                                            style={styles.sourceText}
                                            selectable={false}>{this.props.quoteData.tags[1]}</Animated.Text>
                                    }
                                    {
                                        this.props.quoteData.tags.map((tagName, idx) => {
                                            if (idx < 2) return;
                                            // Make quote author more prominent than other tags.
                                            return <Animated.Text
                                                key={tagName}
                                                style={styles.tagText}
                                                selectable={false}>{tagName}</Animated.Text>;
                                        })
                                    }
                                </Pressable>
                            </Animated.ScrollView>

                            <Animated.View
                                entering={FadeIn.duration(500).easing(Easing.exp)}
                                style={styles.tagsScrollBoxBorderStrip}
                            />

                            {/**
                             Left Scroll Fade Effect
                             */}
                            {this.state.xOffset > 0 && !this.state.isHovered &&
                                <Pressable
                                    pointerEvents={cd.smallScreen ? "none" : "auto"}
                                    style={{
                                        position: "absolute",
                                        width: tagsAreaWidth * 0.15,
                                        height: tagsAreaHeight,
                                    }}>
                                    <Hoverable
                                        onHoverIn={() => {
                                            if (Platform.OS !== 'web' || this.props.dims.smallScreen) return;
                                            this.setState({
                                                leftHover: true,
                                                leftHoverActivated: false,
                                            });
                                            setTimeout(() => {
                                                if (this.state.leftHover) {
                                                    this.setState({leftHoverActivated: true});
                                                }
                                            }, 400);
                                        }}
                                        onHoverOut={() => {
                                            this.setState({
                                                leftHover: false,
                                                leftHoverActivated: false,
                                            });
                                        }}
                                    >

                                        <LinearGradient
                                            colors={[addAlpha(lightenHex(this.props.bgColor, -10), 0.9), addAlpha(this.props.bgColor, 0.5), addAlpha(this.props.bgColor, 0.1)]}
                                            start={[0, 0]}
                                            end={[1, 0]}
                                            style={{
                                                width: tagsAreaWidth * 0.15,
                                                height: tagsAreaHeight + tagsTopBottomBorderWidth * 2,
                                            }}/>

                                    </Hoverable>
                                </Pressable>
                            }

                            {/**
                             Right Scroll Fade Effect
                             */}
                            {this.state.xOffset < this.state.maxScroll - tagsAreaWidth * 1.02 && !this.state.isHovered &&
                                <Pressable
                                    pointerEvents={cd.smallScreen ? "none" : "auto"}
                                    style={{
                                        position: "absolute",
                                        width: tagsAreaWidth * 0.15,
                                        height: tagsAreaHeight,
                                        marginLeft: tagsAreaWidth - (tagsAreaWidth * 0.15),
                                    }}>

                                    <Hoverable
                                        onHoverIn={() => {
                                            if (Platform.OS !== 'web' || this.props.dims.smallScreen) return;
                                            this.setState({
                                                rightHover: true,
                                                rightHoverActivated: false,
                                            });
                                            setTimeout(() => {
                                                if (this.state.rightHover) {
                                                    this.setState({rightHoverActivated: true});
                                                }
                                            }, 400);
                                        }}
                                        onHoverOut={() => {
                                            this.setState({
                                                rightHover: false,
                                                rightHoverActivated: false,
                                            });
                                        }}
                                    >
                                        <LinearGradient
                                            colors={[addAlpha(this.props.bgColor, 0.1), addAlpha(this.props.bgColor, 0.5), addAlpha(lightenHex(this.props.bgColor, -10), 0.9)]}
                                            start={[0, 0]}
                                            end={[1, 0]}
                                            style={{
                                                width: tagsAreaWidth * 0.15,
                                                height: tagsAreaHeight + tagsTopBottomBorderWidth * 2,
                                            }}/>
                                    </Hoverable>
                                </Pressable>
                            }

                        </Animated.View>

                        {/**
                         Quote Summary
                         */}
                        <Animated.View
                            entering={FadeIn.duration(500).easing(Easing.exp)}
                            style={{
                                marginTop: "auto",
                                //marginBottom: topBottomMargin,
                            }}
                            pointerEvents={"none"}>
                            <Animated.View
                                entering={FadeIn.duration(500).easing(Easing.exp)}
                                style={styles.summaryTextContainer}>
                                <Animated.Text
                                    entering={FadeIn.duration(500).easing(Easing.exp)}
                                    style={styles.summaryText}
                                    selectable={false}
                                >{renderBoldifiedText(boldifyInput(this.props.quoteData.summary), "Montserrat_400Regular", "Montserrat_500Medium")}
                                </Animated.Text>
                            </Animated.View>
                        </Animated.View>

                    </Animated.View>
                </Animated.View>
            </TapGestureHandler>
        );
    }
}
