import React, {Component, createRef} from "react";
import {Image, Modal, Platform, Pressable, ScrollView, StyleSheet, Text, View} from "react-native";
import Hoverable from "../reusable/Hoverable";
import {LinearGradient} from "expo-linear-gradient";
import {CompDimensions, lightenHex} from "../../util/Util";
import {CurrentlySelectedTag} from "./CurrentlySelectedTag";
import {CategoryTags} from "../../constants/CategoryTags";
import {CategorySelTag} from "./CategorySelTag";
import {CategoryDropdown} from "./CategoryDropdown";
// @ts-ignore
import up_arrow from '../../../assets/up_arrow.png';
// @ts-ignore
import close_icon from '../../../assets/close_icon.png';
import {Slider} from "@rneui/themed";
import SquishyButton from "../reusable/SquishyButton";

const unInitializedDropdownText = "Select...";
const maxTagsPerCategory = 8;

export class SearchModal extends Component<{
    dims: CompDimensions,
    yOffset: number,
    visible: boolean,
    onClose: any,
    showNextCards: any,
    showLoginModal: any,
    authenticated: boolean
}> {

    state = {
        closeBtnHovered: false,
        clearBtnHovered: false,
        goBtnHovered: false,
        curSelLeftArrowHovered: false,
        curSelRightArrowHovered: false,
        catSelTopArrowHovered: false,
        catSelBottomArrowHovered: false,
        dropdownExpanded: false,
        newSelection: [],
        catDropdownSelected: unInitializedDropdownText,
        newSelectionInCat: [],
        catTagsAvailable: [],
        curSelScrollX: 0,
        curSelMaxScroll: 0,
        catSelScrollY: 0,
        catSelMaxScroll: 0,
        leftArrowPressedIn: false,
        leftArrowWasSnapped: false,
        rightArrowPressedIn: false,
        rightArrowWasSnapped: false,
        upArrowPressedIn: false,
        upArrowWasSnapped: false,
        downArrowPressedIn: false,
        downArrowWasSnapped: false,
        personalizationLevel: 3.5,
    }

    curSelScrollRef: any;
    catSelScrollRef: any;

    constructor(props) {
        super(props);

        this.curSelScrollRef = createRef();
        this.catSelScrollRef = createRef();

        this.selectNewCategory = this.selectNewCategory.bind(this);
        this.clearCatSelection = this.clearCatSelection.bind(this);
        this.getCatNamesInOrder = this.getCatNamesInOrder.bind(this);
        this.selectCatTag = this.selectCatTag.bind(this);
        this.unselectCatTag = this.unselectCatTag.bind(this);
        this.handleCatSelScroll = this.handleCatSelScroll.bind(this);
        this.handleCurSelScroll = this.handleCurSelScroll.bind(this);
        this.startLeftScroll = this.startLeftScroll.bind(this);
        this.startRightScroll = this.startRightScroll.bind(this);
        this.startUpScroll = this.startUpScroll.bind(this);
        this.startDownScroll = this.startDownScroll.bind(this);
        this.enterSearch = this.enterSearch.bind(this);
    }

    handleCurSelScroll(event) {

        // Update state with new xOffset variables.
        const xOffset = event.nativeEvent.contentOffset.x;
        this.setState({
            curSelScrollX: xOffset,
        });
    }

    handleCatSelScroll(event) {

        // Update state with new yOffset variables.
        const yOffset = event.nativeEvent.contentOffset.y;
        this.setState({
            catSelScrollY: yOffset,
        });
    }

    startLeftScroll(containerWidth: number) {
        if (this.state.leftArrowPressedIn && !this.state.leftArrowWasSnapped && this.state.curSelScrollX > 0) {
            const newScrollX = Math.max(0, this.state.curSelScrollX - containerWidth * 0.004);
            this.curSelScrollRef.current?.scrollTo({
                x: newScrollX,
                animated: false,
            });
            if (newScrollX === 0.001) {
                this.setState({upArrowPressedIn: false});
                return;
            }
            setTimeout(() => {
                this.startLeftScroll(containerWidth)
            }, 8);
        }
    }

    startRightScroll(containerWidth: number) {
        if (this.state.rightArrowPressedIn && !this.state.rightArrowWasSnapped && this.state.curSelScrollX + containerWidth < this.state.curSelMaxScroll) {
            const newScrollX = Math.min(this.state.curSelMaxScroll - containerWidth, this.state.curSelScrollX + containerWidth * 0.004);
            this.curSelScrollRef.current?.scrollTo({
                x: newScrollX,
                animated: false,
            });
            if (newScrollX >= (containerWidth + this.state.curSelMaxScroll) * 0.999999) {
                this.setState({rightArrowPressedIn: false});
                return;
            }
            setTimeout(() => {
                this.startRightScroll(containerWidth)
            }, 8);
        }
    }

    startUpScroll(containerHeight: number) {
        if (this.state.upArrowPressedIn && !this.state.upArrowWasSnapped && this.state.catSelScrollY > 0) {
            const newScrollY = Math.max(0, this.state.catSelScrollY - containerHeight * 0.014);
            this.catSelScrollRef.current?.scrollTo({
                y: newScrollY,
                animated: false,
            });
            if (newScrollY <= 0.001) {
                this.setState({upArrowPressedIn: false});
                return;
            }
            setTimeout(() => {
                this.startUpScroll(containerHeight)
            }, 30);
        } else {
        }
    }

    startDownScroll(containerHeight: number) {
        if (this.state.downArrowPressedIn && !this.state.downArrowWasSnapped && this.state.catSelScrollY + containerHeight < this.state.catSelMaxScroll) {
            const newScrollY = Math.min(this.state.catSelMaxScroll - containerHeight, this.state.catSelScrollY + containerHeight * 0.014);
            this.catSelScrollRef.current?.scrollTo({
                y: newScrollY,
                animated: false,
            });
            if (newScrollY >= (this.state.catSelMaxScroll - containerHeight) * 0.999) {
                this.setState({downArrowPressedIn: false});
                return;
            }
            setTimeout(() => {
                this.startDownScroll(containerHeight);
            }, 30);
        } else {
        }
    }

    selectNewCategory(newCategory: string) {
        /**
         * Displays tags from the new category selected from the dropdown.
         */
        if (newCategory === this.state.catDropdownSelected) return;

        // Get tags available in the new category.
        let newCatClass = null;
        Object.keys(CategoryTags).forEach((catEnumName) => {
            if (CategoryTags[catEnumName].toString() === newCategory) {
                newCatClass = CategoryTags[catEnumName];
            }
        });

        // Get currently selected tags in category.
        const tagsSelectedInNewCat = [];
        this.state.newSelection.forEach((tag) => {
            if (newCatClass.value.includes(tag)) {
                tagsSelectedInNewCat.push(tag);
            }
        });

        // Select first tag in category if this is the first category to get selected.
        const newSelection = [...this.state.newSelection];
        if (this.state.catDropdownSelected === unInitializedDropdownText) {
            tagsSelectedInNewCat.push(newCatClass.value[0]);
            newSelection.push(newCatClass.value[0]);
        }

        // Update state.
        this.setState({
            catDropdownSelected: newCategory,
            catTagsAvailable: newCatClass.value,
            newSelectionInCat: tagsSelectedInNewCat,
            newSelection: newSelection,
            dropdownExpanded: false,
        });

        // Scroll to top of category selection box.
        this.catSelScrollRef.current?.scrollTo({
            y: 0,
            animated: true
        });
    }

    getCatNamesInOrder(firstCategory?: string) {
        /**
         * Returns a list of category names, with firstCategory shown first.
         * If no category chosen, unInitializedDropdownText is the first member of the array.
         */
        const allCatNames = Object.keys(CategoryTags).map((catEnumName: string) => {
            return CategoryTags[catEnumName].toString()
        });
        if (!firstCategory || firstCategory === unInitializedDropdownText) {
            return (!this.state.catDropdownSelected || this.state.catDropdownSelected === unInitializedDropdownText) ? [unInitializedDropdownText, ...allCatNames] : allCatNames;
        }
        if (firstCategory) {
            const idx = allCatNames.indexOf(firstCategory);
            if (idx === -1) {
                return allCatNames
            } else {
                allCatNames.splice(idx, 1);
                return [firstCategory, ...allCatNames];
            }
        }
    }

    clearCatSelection() {
        /**
         * Updates state to clear all tags from current selection and category selection.
         */
        let newCurSelection = [...this.state.newSelection];
        for (let idx in this.state.newSelectionInCat) {
            let idxToRemove = newCurSelection.indexOf(this.state.newSelectionInCat[idx]);
            if (idxToRemove !== -1) newCurSelection.splice(idxToRemove, 1);
        }
        this.setState({
            newSelectionInCat: [],
            newSelection: newCurSelection,
        });
        this.catSelScrollRef.current?.scrollTo({
            y: 0,
            animated: true
        });
    }

    selectCatTag(tag: string) {
        /**
         * Updates state to add tag to current selection and category selection.
         */
        if (this.state.newSelection.includes(tag)) return;
        this.setState({
            newSelectionInCat: [...this.state.newSelectionInCat, tag],
            newSelection: [...this.state.newSelection, tag],
        })
    }

    unselectCatTag(tag: string) {
        /**
         * Updates state to remove tag from current selection and category selection.
         */
        let newCatSelection = [...this.state.newSelectionInCat];
        let newCurSelection = [...this.state.newSelection];

        let idxToRemove = newCatSelection.indexOf(tag);
        if (idxToRemove !== -1) newCatSelection.splice(idxToRemove, 1);
        idxToRemove = newCurSelection.indexOf(tag);
        if (idxToRemove !== -1) newCurSelection.splice(idxToRemove, 1);

        this.setState({
            newSelectionInCat: newCatSelection,
            newSelection: newCurSelection,
        })
    }

    enterSearch(dims: CompDimensions) {
        /**
         * Closes search modal and shows new quotes.
         */
        if (this.props.authenticated) {
            this.props.onClose();
            this.props.showNextCards(dims, 500, 600);
        } else {
            this.props.showLoginModal();
        }
    }

    render() {

        // Get and calculate component dimensions.
        const cd = this.props.dims;

        // General dimensions.
        const modalContainerBorderWidth = cd.loginBtnBorderRadius * 0.5;
        const selectionBoxBordersWidth = cd.smallScreen ? cd.searchModalWidth * 0.008 : cd.searchModalHeight * 0.005;
        const containerTopMargin = cd.smallScreen
            ? cd.statusBarHeight + cd.headerSideBtnsTopMargin + (cd.headerSideBtnsHeight * 0.4) - this.props.yOffset
            : cd.appBorderWidth + cd.headerSideBtnsTopMargin + (cd.headerSideBtnsHeight * 0.4);
        const containerLeftMargin = (cd.smallScreen ? cd.searchBtnLeftMargin : cd.desktopSearchBtnLeftMargin) + cd.headerSideBtnsWidth * 0.4;
        const dividerHeight = cd.smallScreen ? cd.searchModalHeight * 0.005 : cd.searchModalHeight * 0.01;

        // First row.
        const titleHeight = cd.searchModalHeight * 0.07;
        const titleTopMargin = cd.smallScreen ? cd.searchModalHeight * 0.03 : cd.searchModalHeight * 0.033;
        const titleFontSize = 0.8 * Math.sqrt(cd.searchModalWidth * titleHeight / "Choose Categories".length);

        // Second row.
        const curSelectionTopMargin = cd.smallScreen ? cd.searchModalHeight * 0.01 : cd.searchModalHeight * 0.033;
        const curSelectionWidth = cd.searchModalWidth - (cd.smallScreen ? modalContainerBorderWidth * 2 : modalContainerBorderWidth * 2);
        const curSelectionHeight = cd.smallScreen ? cd.searchModalHeight * 0.09 : cd.searchModalHeight * 0.12;
        const curSelectionTitleWidth = cd.smallScreen ? curSelectionWidth * 0.2 : curSelectionWidth * 0.15;
        const curSelectionTitleHeight = curSelectionHeight;
        const curSelectionBoxWidth = curSelectionWidth - curSelectionTitleWidth;
        const curSelectionBoxHeight = curSelectionHeight - selectionBoxBordersWidth;
        const curSelectionTitleFontSize = (cd.smallScreen ? 0.9 : 0.8) * Math.sqrt(curSelectionTitleWidth * curSelectionTitleHeight / "Quotes to Show".length);
        const curSelArrowWidth = cd.smallScreen ? selectionBoxBordersWidth * 5 : selectionBoxBordersWidth * 7;
        const curSelArrowHeight = curSelectionHeight - (Platform.OS === 'web' ? selectionBoxBordersWidth * 2.5 : selectionBoxBordersWidth * 2.5);

        // Container for rows 3 thru 5.
        const middleAreaTopMargin = cd.smallScreen ? cd.searchModalHeight * 0.03 : cd.searchModalHeight * 0.033;
        const middleAreaHeight = dividerHeight * 3 + (cd.smallScreen ? cd.searchModalHeight * 0.59 : cd.searchModalHeight * 0.47);

        // Third row.
        const summaryRowHeight = cd.smallScreen ? middleAreaHeight * 0.18 : middleAreaHeight * 0.12;

        // Fourth row.
        const catDropdownOptionHeight = cd.smallScreen ? (Platform.OS === 'web' ? middleAreaHeight * 0.15 : middleAreaHeight * 0.13) : middleAreaHeight * 0.22;
        const catDropdownWidth = catDropdownOptionHeight * 3;
        const catTagControlsTopMargin = dividerHeight;
        const clearBtnHeight = catDropdownOptionHeight;
        const clearBtnWidth = cd.smallScreen ? clearBtnHeight * 1.4 : clearBtnHeight * 1.67;
        const clearBtnTopMargin = (catDropdownOptionHeight - clearBtnHeight) * 0.5;
        const clearBtnBorderRadius = cd.smallScreen ? clearBtnWidth * 0.1 : clearBtnWidth * 0.1;
        const clearBtnFontSize = 0.6 * Math.sqrt(clearBtnWidth * clearBtnHeight / "Clear".length);

        // Fifth row.
        const catSelArrowHeight = cd.smallScreen ? selectionBoxBordersWidth * 6 : selectionBoxBordersWidth * 6;
        const catSelBoxWidth = cd.smallScreen ? cd.searchModalWidth * 0.8 : cd.searchModalWidth * 0.9;
        const catSelBoxTopMargin = dividerHeight;
        const catSelBoxContainerHeight = middleAreaHeight - summaryRowHeight - catTagControlsTopMargin - clearBtnTopMargin - clearBtnHeight - catSelBoxTopMargin;
        const catSelBoxHeight = catSelBoxContainerHeight
        const catTagHeight = cd.smallScreen ? catSelBoxHeight * 0.16 : catSelBoxHeight * 0.25;
        const catTagTopBottomMargin = cd.smallScreen ? catSelBoxHeight * 0.03 : catSelBoxHeight * 0.04;
        const catSelArrowWidth = catSelBoxWidth;

        // Sixth row.
        const bottomRowTopMargin = cd.smallScreen ? Math.min(middleAreaTopMargin, cd.searchModalHeight * 0.01) : cd.searchModalHeight * 0.033;
        const bottomRowHeight = cd.searchModalHeight - titleTopMargin - titleHeight - curSelectionTopMargin - curSelectionHeight - middleAreaTopMargin - middleAreaHeight - bottomRowTopMargin;

        // Personalization slider.
        const sliderAreaWidth = cd.smallScreen ? cd.searchModalWidth * 0.45 : cd.searchModalWidth * 0.4;
        const sliderAreaHeight = cd.smallScreen ? bottomRowHeight * 0.25 : bottomRowHeight * 0.25;
        const sliderAreaLeftMargin = cd.smallScreen ? cd.searchModalWidth * 0.02 : 0;
        const sliderButtonHeight = cd.smallScreen ? sliderAreaHeight * 0.85 : sliderAreaHeight;
        const sliderLineWidth = cd.smallScreen ? sliderAreaWidth * 0.85 : sliderAreaWidth * 0.8;
        const sliderLineHeight = cd.smallScreen ? sliderButtonHeight * 0.1 : sliderButtonHeight * 0.11;
        const sliderTextHeight = cd.smallScreen ? bottomRowHeight * 0.22 : bottomRowHeight * 0.25;
        const sliderTextWidth = sliderAreaWidth;
        const sliderText = "Personalize to My History";
        const sliderTextFontSize = (cd.smallScreen ? 1.05 : 0.93) * Math.sqrt(sliderTextWidth * sliderTextHeight / sliderText.length);

        // Go button.
        const goBtnWidth = cd.smallScreen ? cd.searchModalWidth * 0.25 : cd.searchModalWidth * 0.2;
        const goBtnHeight = cd.smallScreen ? bottomRowHeight : bottomRowHeight * 0.85;
        const goBtnFontSize = 0.75 * Math.sqrt(goBtnWidth * goBtnHeight / "Show Me".length);
        const goBtnRightMargin = cd.smallScreen ? cd.searchModalWidth * 0.05 : cd.searchModalWidth * 0.04;
        const goBtnBorderRadius = cd.smallScreen ? goBtnWidth * 0.3 : goBtnWidth * 0.2;

        // Define text elements.
        let catTitle = this.state.catDropdownSelected;
        if (this.state.newSelectionInCat.length === 1) {
            catTitle = catTitle.slice(0, catTitle.length - 1).replace("People & ", "Person/");
        }
        const summaryRow1Text = "" + this.state.newSelectionInCat.length + " " + catTitle + " Chosen"
        const summaryRow2Text = cd.smallScreen ? "" + (maxTagsPerCategory - this.state.newSelectionInCat.length) + " Remaining" : "Choose Up To " + (maxTagsPerCategory - this.state.newSelectionInCat.length) + " More";
        const summaryRowText = summaryRow1Text + ".  " + summaryRow2Text + ".";
        const summaryRowFontSize = 0.92 * Math.sqrt(catSelBoxWidth * (cd.smallScreen ? summaryRowHeight * 0.45 : summaryRowHeight) / (cd.smallScreen ? summaryRow1Text.length : summaryRowText.length));
        const catDropdownStartText = "Choose a Category to Expand Your Search";
        const catDropdownStartTextFontSize = 0.95 * Math.sqrt(cd.searchModalWidth * 0.5 * (cd.smallScreen ? summaryRowHeight * 0.4 : summaryRowHeight) / catDropdownStartText.length);
        //const clearBtnLeftMargin = cd.searchModalWidth - clearBtnWidth - (cd.smallScreen ? cd.searchModalWidth * 0.03 : cd.searchModalWidth * 0.03);
        const clearBtnLeftMargin = (cd.searchModalWidth - catSelBoxWidth) * 0.5 + catSelBoxWidth - clearBtnWidth;
        const catDropdownLeftMargin = cd.smallScreen ? (cd.searchModalWidth - catSelBoxWidth) * 0.5 : (cd.searchModalWidth - catDropdownWidth) * 0.5;
        const sliderAreaTopMargin = (bottomRowHeight - goBtnHeight) * (cd.smallScreen ? 0.48 : 0.0);

        // Calculate colors.
        const mainColor = "#a5d2fa";
        const lightColor = lightenHex("#a5d2fa", 13);
        const dividerColor = "#9ccbf7";

        // Create reusable styles.
        const styles = StyleSheet.create({
            grayBg: {
                position: "absolute",
                width: cd.screenWidth,
                height: cd.screenHeight,
                backgroundColor: "rgba(192,194,207,0.94)",
                // @ts-ignore
                cursor: "default",
            },
            searchModalContainer: {
                width: cd.searchModalWidth,
                height: cd.searchModalHeight,
                marginTop: containerTopMargin,
                marginLeft: containerLeftMargin,
                backgroundColor: "#fcfffffc",
                borderColor: "#e4edf293",
                borderWidth: modalContainerBorderWidth,
                borderRadius: cd.loginBtnBorderRadius,
                shadowColor: "#e4edf293",
                shadowRadius: cd.smallScreen ? cd.searchModalWidth * 0.1 : cd.searchModalWidth * 0.01,
                elevation: 5,
                overflow: "visible",
            },
            modalInnerContainer: {
                width: cd.searchModalWidth,
                height: cd.searchModalHeight,
                flex: 1,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                overflow: "visible",
            },
            modalTitle: {
                width: cd.searchModalWidth - modalContainerBorderWidth * 2,
                height: titleHeight,
                marginTop: titleTopMargin,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
            },
            modalTitleText: {
                fontSize: titleFontSize,
                fontWeight: "bold",
            },
            curSelectionContainer: {
                width: curSelectionWidth,
                height: curSelectionHeight,
                marginTop: curSelectionTopMargin,
                flexDirection: "row",
                //justifyContent: "space-between",
                alignItems: "center",
                overflow: "visible",
            },
            curSelTitle: {
                width: curSelectionTitleWidth,
                height: curSelectionBoxHeight,
                backgroundColor: "white",
                justifyContent: "center",
                alignItems: "center",
                overflow: "visible",
            },
            curSelTitleText: {
                fontSize: curSelectionTitleFontSize,
                fontWeight: "bold",
                color: "black",
                textAlign: "center",
                overflow: "hidden",
            },
            curSelBoxContainer: {
                width: curSelectionBoxWidth,
                height: curSelectionBoxHeight,
                paddingLeft: curSelectionBoxWidth * 0.02,
                backgroundColor: mainColor,
                borderTopColor: dividerColor,
                borderTopWidth: selectionBoxBordersWidth,
                borderLeftColor: dividerColor,
                borderLeftWidth: selectionBoxBordersWidth,
                borderBottomColor: dividerColor,
                borderBottomWidth: selectionBoxBordersWidth,
                borderTopRightRadius: selectionBoxBordersWidth * 0.5,
                borderBottomRightRadius: selectionBoxBordersWidth * 0.5,
                overflow: "visible",
            },
            curSelScrollView: {
                height: curSelectionBoxHeight,
                overflow: "hidden",
            },
            leftArrowContainer: {
                position: "absolute",
                width: curSelArrowWidth - selectionBoxBordersWidth,
                height: curSelectionHeight - selectionBoxBordersWidth,
                marginLeft: curSelectionTitleWidth + selectionBoxBordersWidth - (cd.smallScreen ? 0 : curSelArrowWidth * 0.01),
                justifyContent: "center",
            },
            leftArrowPressable: {
                width: curSelArrowWidth + (Platform.OS === 'web' ? modalContainerBorderWidth * 2 : modalContainerBorderWidth),
                height: curSelArrowHeight,
                backgroundColor: cd.smallScreen ? "#acb4c2bc" : this.state.curSelLeftArrowHovered ? "#acb4c2e9" : "#acb4c2cd",
                justifyContent: "center",
                alignItems: "center",
            },
            leftArrowImg: {
                transform: [{rotate: "270deg"}],
                width: 0.8 * curSelArrowWidth,
                height: 0.8 * curSelArrowWidth,
            },
            rightArrowContainer: {
                position: "absolute",
                width: curSelArrowWidth,
                height: curSelectionHeight - selectionBoxBordersWidth,
                marginLeft: curSelectionWidth - curSelArrowWidth - modalContainerBorderWidth * (Platform.OS === 'web' ? 2 : 1),
                justifyContent: "center",
            },
            rightArrowPressable: {
                width: curSelArrowWidth + (Platform.OS === 'web' ? modalContainerBorderWidth * 2 : modalContainerBorderWidth),
                height: curSelArrowHeight,
                backgroundColor: cd.smallScreen ? "#acb4c2bc" : this.state.curSelRightArrowHovered ? "#acb4c2e9" : "#acb4c2cd",
                justifyContent: "center",
                alignItems: "center",
            },
            rightArrowImg: {
                transform: [{rotate: "90deg"}],
                width: 0.8 * curSelArrowWidth,
                height: 0.8 * curSelArrowWidth,
            },
            upArrowContainer: {
                position: "absolute",
                width: catSelArrowWidth,
                height: catSelArrowHeight,
                borderTopLeftRadius: cd.smallScreen ? 0 : catSelArrowHeight * 0.2,
                borderTopRightRadius: cd.smallScreen ? 0 : catSelArrowHeight * 0.2,
            },
            upArrowPressable: {
                width: catSelArrowWidth,
                height: catSelArrowHeight,
                backgroundColor: cd.smallScreen ? "#acb4c2bc" : this.state.catSelTopArrowHovered ? "#aab2bfcd" : "#b3bbc9cd",
                borderTopLeftRadius: cd.smallScreen ? 0 : catSelArrowHeight * 0.2,
                borderTopRightRadius: cd.smallScreen ? 0 : catSelArrowHeight * 0.2,
                justifyContent: "center",
                alignItems: "center",
            },
            upArrowImg: {
                width: 0.7 * catSelArrowHeight,
                height: 0.7 * catSelArrowHeight,
            },
            downArrowContainer: {
                position: "absolute",
                width: catSelArrowWidth,
                height: catSelArrowHeight,
                marginTop: catSelBoxHeight - catSelArrowHeight,
            },
            downArrowPressable: {
                width: catSelArrowWidth,
                height: catSelArrowHeight,
                backgroundColor: cd.smallScreen ? "#acb4c2bc" : this.state.catSelBottomArrowHovered ? "#aab2bfcd" : "#b3bbc9cd",
                justifyContent: "center",
                alignItems: "center",
            },
            downArrowImg: {
                transform: [{rotate: "180deg"}],
                width: 0.7 * catSelArrowHeight,
                height: 0.7 * catSelArrowHeight,
            },
            dropdownPlaceholder: {
                width: cd.searchModalWidth,
                height: middleAreaHeight,
                marginTop: middleAreaTopMargin,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            middleAreaContainer: {
                width: cd.searchModalWidth - modalContainerBorderWidth * 2,
                height: middleAreaHeight,
                marginTop: middleAreaTopMargin,
                flexDirection: "column",
                backgroundColor: "white",
                shadowOpacity: cd.smallScreen ? 0.4 : 0.8,
                shadowColor: "#88bff2",
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? middleAreaHeight * 0.03 : middleAreaHeight * 0.05,
                },
                shadowRadius: cd.smallScreen ? middleAreaHeight * 0.01 : middleAreaHeight * 0.04,
                overflow: "visible",
            },
            middleAreaTopFill: {
                width: cd.searchModalWidth - modalContainerBorderWidth * 2,
                height: dividerHeight,
                marginLeft: "auto",
                marginRight: "auto",
                backgroundColor: dividerColor,
            },
            summaryVerticalContainer: {
                width: cd.searchModalWidth - modalContainerBorderWidth * 2,
                height: summaryRowHeight + catTagControlsTopMargin + dividerHeight + catDropdownOptionHeight + catSelBoxTopMargin,
                flexDirection: "column",
                backgroundColor: mainColor,
            },
            summaryTextContainerDesktop: {
                width: cd.searchModalWidth - modalContainerBorderWidth * 2,
                height: summaryRowHeight,
                alignItems: "center",
                justifyContent: "center",
            },
            summaryTextDesktop: {
                fontSize: summaryRowFontSize,
                fontWeight: "bold",
                color: "white",
            },
            summaryAreaMobile: {
                width: cd.searchModalWidth - modalContainerBorderWidth * 2,
                height: summaryRowHeight,
            },
            summaryTextContainerMobile: {
                width: cd.searchModalWidth - modalContainerBorderWidth * 2,
                height: (summaryRowHeight - dividerHeight) * 0.5,
                alignItems: "center",
                justifyContent: "center",
            },
            summaryTextMobile: {
                fontSize: summaryRowFontSize,
                fontWeight: "bold",
                color: "#fafafc",
            },
            dividerLine: {
                width: cd.smallScreen ? (cd.searchModalWidth - modalContainerBorderWidth * 2) * 0.5 : catDropdownWidth * 2.5,
                height: dividerHeight,
                marginLeft: "auto",
                marginRight: "auto",
                backgroundColor: dividerColor,
            },
            clearBtnPressable: {
                width: clearBtnWidth,
                height: clearBtnHeight,
                marginLeft: clearBtnLeftMargin,
                marginTop: catTagControlsTopMargin + clearBtnTopMargin,
            },
            clearBtnTextContainer: {
                width: clearBtnWidth,
                height: clearBtnHeight,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: mainColor,
            },
            clearBtnText: {
                fontSize: clearBtnFontSize,
                color: this.state.clearBtnHovered ? "#f7f5ed" : "#fcfafa",
                textDecorationLine: this.state.clearBtnHovered ? "underline" : "none",
            },
            catSelTopFill: {
                width: catSelBoxWidth,
                height: catSelBoxTopMargin,
                marginLeft: "auto",
                marginRight: "auto",
                backgroundColor: mainColor,
            },
            catSelHorizontalContainer: {
                width: cd.searchModalWidth - modalContainerBorderWidth * 2,
                height: catSelBoxContainerHeight,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: mainColor,
            },
            catSelVerticalContainer: {
                width: catSelBoxWidth,
                height: catSelBoxContainerHeight,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
            },
            catSelContainer: {
                width: catSelBoxWidth,
                height: catSelBoxHeight,
                backgroundColor: lightColor,
                borderTopLeftRadius: cd.smallScreen ? 0 : catSelArrowHeight * 0.2,
                borderTopRightRadius: cd.smallScreen ? 0 : catSelArrowHeight * 0.2,
                overflow: "visible",
            },
            catSelScrollView: {
                width: catSelBoxWidth,
                height: catSelBoxHeight,
                backgroundColor: lightColor,
                borderTopLeftRadius: cd.smallScreen ? 0 : catSelArrowHeight * 0.2,
                borderTopRightRadius: cd.smallScreen ? 0 : catSelArrowHeight * 0.2,
                overflow: "hidden",
            },
            catSelContentContainer: {
                width: catSelBoxWidth,
                flexDirection: cd.smallScreen ? "column" : "row",
                justifyContent: cd.smallScreen ? "flex-start" : "center",
                alignItems: cd.smallScreen ? "flex-start" : "flex-start",
                flexWrap: cd.smallScreen ? "nowrap" : "wrap",
            },
            bottomAreaContainer: {
                width: cd.searchModalWidth,
                height: bottomRowHeight,
                marginTop: bottomRowTopMargin,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            },
            personalizationArea: {
                width: sliderAreaWidth,
                height: sliderAreaHeight,
                marginLeft: sliderAreaLeftMargin,
                marginTop: sliderAreaTopMargin,
                flexDirection: "column",
                justifyContent: "flex-start",
            },
            personalizationSliderContainer: {
                width: sliderAreaWidth,
                height: sliderButtonHeight,
                flexDirection: "row",
                justifyContent: "center",
            },
            personalizationSlider: {
                width: sliderLineWidth,
                height: sliderButtonHeight,
            },
            sliderLine: {
                width: sliderLineWidth,
                height: sliderLineHeight,
                borderRadius: sliderLineHeight * 0.5,
                shadowOpacity: cd.smallScreen ? 0.55 : 0.55,
                shadowColor: "#ccdbe8",
                shadowRadius: cd.smallScreen ? sliderButtonHeight * 0.05 : sliderButtonHeight * 0.1,
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? sliderLineHeight * 0.01 : sliderLineHeight * 0.002,
                },
            },
            sliderBtn: {
                width: sliderButtonHeight,
                height: sliderButtonHeight,
                shadowOpacity: cd.smallScreen ? 0.45 : 0.6,
                shadowColor: "#418fe8",
                shadowRadius: cd.smallScreen ? sliderButtonHeight * 0.1 : sliderButtonHeight * 0.2,
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? sliderButtonHeight * 0.01 : sliderButtonHeight * 0.005,
                },
            },
            personalizationTextContainer: {
                width: sliderAreaWidth,
                height: sliderTextHeight,
                justifyContent: "center",
                alignItems: "center",
            },
            personalizationText: {
                fontSize: sliderTextFontSize,
                textAlign: "center",
                color: "#4d4f4f",
            },
            goBtn: {
                marginLeft: "auto",
                marginRight: goBtnRightMargin,
                borderRadius: goBtnBorderRadius,
                alignSelf: "center",
                overflow: "visible",
                shadowOpacity: cd.smallScreen ? 0.5 : 0.65,
                shadowColor: "#7dbaff",
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? goBtnHeight * 0.0005 : 0,
                },
                shadowRadius: cd.smallScreen ? goBtnHeight * 0.03 : goBtnHeight * 0.04,
            },
            goBtnGradient: {
                borderRadius: goBtnBorderRadius,
                borderWidth: cd.smallScreen ? goBtnHeight * 0.023 : goBtnHeight * 0.03,
                borderColor: this.state.goBtnHovered ? "#7fb2f0c2" : "#7fb2f0e6",
                //overflow: "visible",
            },
            goBtnText: {
                fontSize: goBtnFontSize,
                fontWeight: "bold",
                color: "white",
                marginLeft: goBtnWidth * 0.08,
                marginRight: goBtnWidth * 0.08,
                marginTop: goBtnHeight * 0.08,
                marginBottom: goBtnHeight * 0.08,
            },
            initialDropdownArea: {
                position: "absolute",
                width: cd.searchModalWidth,
                height: middleAreaHeight,
                marginTop: containerTopMargin + titleTopMargin + titleHeight + curSelectionTopMargin + curSelectionBoxHeight + middleAreaTopMargin * 2,
                marginLeft: (cd.smallScreen ? cd.searchBtnLeftMargin : cd.desktopSearchBtnLeftMargin) + cd.headerSideBtnsWidth * 0.4,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
            },
            initialDropdownSuggestionContainer: {
                width: cd.searchModalWidth,
                height: summaryRowHeight,
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "center",
            },
            initialDropdownSuggestionText: {
                fontSize: 1.35 * catDropdownStartTextFontSize,
                fontWeight: "bold",
                color: "#4f4f50",
            },
            initialDropdownContainer: {
                width: catDropdownWidth,
                height: catDropdownOptionHeight,
                marginTop: cd.smallScreen ? middleAreaHeight * 0.04 : middleAreaHeight * 0.07,
                overflow: "visible",
            },
            dropdownContainer: {
                position: "absolute",
                width: catDropdownWidth,
                height: catDropdownOptionHeight,
                marginTop: containerTopMargin + modalContainerBorderWidth + titleTopMargin + titleHeight + curSelectionTopMargin
                    + curSelectionHeight + middleAreaTopMargin + dividerHeight + summaryRowHeight + dividerHeight + catTagControlsTopMargin,
                marginLeft: containerLeftMargin + catDropdownLeftMargin,
                overflow: "visible",
            },
            closeBtnContainer: {
                position: "absolute",
                width: cd.headerSideBtnsWidth,
                height: cd.smallScreen ? cd.headerSideBtnsHeight * 1.01 : cd.headerSideBtnsHeight,
                marginTop: cd.smallScreen
                    ? cd.statusBarHeight + cd.headerSideBtnsTopMargin - this.props.yOffset
                    : cd.appBorderWidth + cd.headerSideBtnsTopMargin,
                marginLeft: (cd.smallScreen ? cd.searchBtnLeftMargin : cd.desktopSearchBtnLeftMargin),
                borderRadius: cd.headerSideBtnsWidth * 0.5,
            },
            closeBtnPressable: {
                width: cd.headerSideBtnsWidth,
                height: cd.headerSideBtnsHeight,
            },
            closeBtnGradient: {
                width: cd.headerSideBtnsWidth,
                height: cd.headerSideBtnsHeight,
                borderRadius: cd.headerSideBtnsWidth * 0.5,
                borderColor: "#f0f4f7",
                borderWidth: modalContainerBorderWidth,
                justifyContent: "center",
                alignItems: "center",
            },
            closeBtnImg: {
                width: cd.headerSideBtnsWidth * 0.7,
                height: cd.headerSideBtnsHeight * 0.7,
                borderRadius: cd.headerSideBtnsWidth * 0.5,
                // Mobile browsers are chaotic with tinting, so they must be hardcoded with results of trial-and-error.
                tintColor: (Platform.OS === 'web' && cd.smallScreen)
                    ? "#f7f8fa0f"
                    : (cd.smallScreen ? "#646566d9" : (this.state.closeBtnHovered ?  "#0a375750" : "#fcfcfc0d")),
            },
        });


        return <Modal animationType={cd.smallScreen ? "none" : "none"}
                      onDismiss={this.props.onClose}
                      onRequestClose={this.props.onClose}
                      transparent={true}
                      visible={this.props.visible}
        >
            <View>

                {/**
                 Gray Screen Content Behind Modal
                 */}
                <Pressable
                    onPress={this.props.onClose}
                    isTVSelectable={false}
                    style={styles.grayBg}>
                </Pressable>


                {/**
                 Saved Modal with Border
                 */}
                <View
                    nativeID={"searchModalContainer"}
                    style={styles.searchModalContainer}>

                    {/**
                     Search Modal Inner Container
                     */}
                    <View
                        style={styles.modalInnerContainer}>

                        {/**
                         Row: Modal Title Row
                         */}
                        <View
                            style={styles.modalTitle}>
                            <Text
                                style={styles.modalTitleText}
                                selectable={false}>SEARCH BY TAGS</Text>
                        </View>

                        {/**
                         Row: Currently Selected Tags
                         */}
                        <View
                            style={styles.curSelectionContainer}>

                            {/**
                             Current Selection Title
                             */}
                            <View
                                style={styles.curSelTitle}>
                                <Text
                                    style={styles.curSelTitleText}
                                    selectable={false}>{this.state.catDropdownSelected === unInitializedDropdownText ? "" : "New Search"}</Text>
                            </View>

                            {/**
                             Current Selection Box
                             */}
                            {this.state.catDropdownSelected !== unInitializedDropdownText &&
                                <View
                                    style={styles.curSelBoxContainer}>
                                    <ScrollView
                                        horizontal={true}
                                        showsHorizontalScrollIndicator={false}
                                        scrollEventThrottle={Platform.OS === 'web' ? 4 : 10}
                                        ref={this.curSelScrollRef}
                                        onScroll={this.handleCurSelScroll}
                                        bounces={false}
                                        scrollsToTop={false}
                                        onContentSizeChange={(contentWidth, contentHeight) => {
                                            this.setState({curSelMaxScroll: contentWidth});
                                        }}
                                        style={styles.curSelScrollView}>
                                        {this.state.newSelection.map((item: string, key: number) => {
                                            return <CurrentlySelectedTag
                                                key={item}
                                                itemName={item}
                                                itemHeight={curSelectionBoxHeight * 0.65}
                                                marginTop={(curSelectionBoxHeight - curSelectionBoxHeight * 0.7) * 0.5}
                                                dims={cd}
                                                removeFunc={() => {
                                                    this.unselectCatTag(item);
                                                }}
                                            />;
                                        })}
                                    </ScrollView>

                                </View>
                            }

                            {/**
                             Left scroll arrow for current selection
                             */}
                            {this.state.curSelScrollX > 0 &&
                                <View style={styles.leftArrowContainer}>
                                    <Hoverable
                                        onHoverIn={() => {
                                            if (cd.smallScreen) return;
                                            this.setState({curSelLeftArrowHovered: true})
                                        }}
                                        onHoverOut={() => {
                                            if (cd.smallScreen) return;
                                            this.setState({curSelLeftArrowHovered: false})
                                        }}
                                        onPressIn={() => {
                                            if (cd.smallScreen) return;
                                            this.setState({curSelLeftArrowHovered: true});
                                            setTimeout(() => {
                                                if (this.state.leftArrowWasSnapped) return;
                                                this.setState({leftArrowPressedIn: true});
                                                this.startLeftScroll(curSelectionBoxWidth);
                                            }, 300);
                                        }}
                                        onPressOut={() => {
                                            if (cd.smallScreen) return;
                                            this.setState({
                                                curSelLeftArrowHovered: false,
                                            });
                                            setTimeout(() => {
                                                this.setState({leftArrowPressedIn: false});
                                            }, 2);
                                        }}
                                    >
                                        <Pressable
                                            onPress={() => {
                                                if (cd.smallScreen) return;
                                                if (this.state.leftArrowPressedIn) {
                                                    this.setState({leftArrowPressedIn: false});
                                                } else if (!this.state.leftArrowWasSnapped) {
                                                    const newScrollX = 0;
                                                    this.curSelScrollRef.current?.scrollTo({
                                                        x: newScrollX,
                                                        animated: true
                                                    });
                                                    this.setState({leftArrowWasSnapped: true});
                                                    setTimeout(() => {
                                                        this.setState({leftArrowWasSnapped: false});
                                                    }, 300);
                                                }
                                            }}
                                            style={styles.leftArrowPressable}>
                                            <Image
                                                source={up_arrow}
                                                style={styles.leftArrowImg}/>
                                        </Pressable>
                                    </Hoverable>
                                </View>
                            }

                            {/**
                             Right scroll arrow for current selection
                             */}
                            {this.state.curSelScrollX + curSelectionBoxWidth < this.state.curSelMaxScroll * 0.99 &&
                                <View
                                    style={styles.rightArrowContainer}>
                                    <Hoverable
                                        onHoverIn={() => {
                                            if (cd.smallScreen) return;
                                            this.setState({curSelRightArrowHovered: true})
                                        }}
                                        onHoverOut={() => {
                                            if (cd.smallScreen) return;
                                            this.setState({curSelRightArrowHovered: false})
                                        }}
                                        onPressIn={() => {
                                            if (cd.smallScreen) return;
                                            this.setState({curSelRightArrowHovered: true});
                                            setTimeout(() => {
                                                if (this.state.rightArrowWasSnapped) return;
                                                this.setState({rightArrowPressedIn: true});
                                                this.startRightScroll(curSelectionBoxWidth);
                                            }, 450);
                                        }}
                                        onPressOut={() => {
                                            if (cd.smallScreen) return;
                                            this.setState({curSelRightArrowHovered: false});
                                            setTimeout(() => {
                                                this.setState({rightArrowPressedIn: false});
                                            }, 2);
                                        }}
                                    >
                                        <Pressable
                                            onPress={() => {
                                                if (cd.smallScreen) return;
                                                if (this.state.rightArrowPressedIn) {
                                                    this.setState({rightArrowPressedIn: false});
                                                } else if (!this.state.rightArrowWasSnapped) {
                                                    const newScrollX = Math.min(this.state.curSelMaxScroll, this.state.curSelScrollX + curSelectionBoxWidth * 0.7);
                                                    this.curSelScrollRef.current?.scrollTo({
                                                        x: newScrollX,
                                                        animated: true
                                                    });
                                                    this.setState({rightArrowWasSnapped: true});
                                                    setTimeout(() => {
                                                        this.setState({rightArrowWasSnapped: false});
                                                    }, 500);
                                                }
                                            }}
                                            style={styles.rightArrowPressable}>
                                            <Image
                                                source={up_arrow}
                                                style={styles.rightArrowImg}/>
                                        </Pressable>
                                    </Hoverable>
                                </View>
                            }
                        </View>

                        {/**
                         Row: Placeholder for absolute initial category dropdown & suggestion
                         */}
                        {this.state.catDropdownSelected === unInitializedDropdownText &&
                            <View
                                nativeID={"initialDropdownAndSuggestionContainer"}
                                style={styles.dropdownPlaceholder}>
                            </View>
                        }

                        {/**
                         Area: Container for middle components: summary text, clear button, category tags box
                         */}
                        {this.state.catDropdownSelected !== unInitializedDropdownText &&
                            <View
                                style={styles.middleAreaContainer}>

                                {/**
                                 Container's top border
                                 */}
                                <View
                                    style={styles.middleAreaTopFill}/>

                                {/**
                                 Vertical container for summary text and category box controls
                                 */}
                                <View
                                    style={styles.summaryVerticalContainer}>

                                    {/**
                                     Category summary text (desktop)
                                     */}
                                    {!cd.smallScreen &&
                                        <View
                                            style={styles.summaryTextContainerDesktop}>
                                            <Text
                                                style={styles.summaryTextDesktop}
                                                selectable={false}>{summaryRowText}</Text>
                                        </View>
                                    }

                                    {/**
                                     Category summary text (mobile)
                                     */}
                                    {cd.smallScreen &&
                                        <View
                                            style={styles.summaryAreaMobile}>

                                            {/**
                                             Summary Line 1
                                             */}
                                            <View
                                                style={styles.summaryTextContainerMobile}>
                                                <Text
                                                    style={styles.summaryTextMobile}
                                                    selectable={false}>{summaryRow1Text}</Text>
                                            </View>

                                            {/**
                                             Horizontal divider line
                                             */}
                                            <View
                                                style={styles.dividerLine}/>

                                            {/**
                                             Summary Line 2
                                             */}
                                            <View
                                                style={styles.summaryTextContainerMobile}>
                                                <Text
                                                    style={styles.summaryTextMobile}
                                                    selectable={false}>{summaryRow2Text}</Text>
                                            </View>
                                        </View>
                                    }

                                    {/**
                                     Horizontal divider line
                                     */}
                                    <View
                                        style={styles.dividerLine}/>


                                    {/**
                                     Clear button
                                     */}
                                    <Hoverable
                                        onHoverIn={() => this.setState({clearBtnHovered: true})}
                                        onHoverOut={() => this.setState({clearBtnHovered: false})}
                                        onPressIn={() => this.setState({clearBtnHovered: true})}
                                        onPressOut={() => setTimeout(() => this.setState({clearBtnHovered: false}), 75)}
                                    >
                                        <Pressable
                                            onPress={() => {
                                                if (Platform.OS === 'web') {
                                                    this.clearCatSelection();
                                                } else {
                                                    setTimeout(() => this.clearCatSelection(), 250);
                                                }
                                            }}
                                            style={styles.clearBtnPressable}>
                                            <View
                                                style={styles.clearBtnTextContainer}>
                                                <Text
                                                    style={styles.clearBtnText}
                                                    selectable={false}>{this.state.newSelectionInCat.length > 0 || cd.smallScreen ? "CLEAR" : ""}</Text>
                                            </View>
                                        </Pressable>
                                    </Hoverable>

                                    {/**
                                     Horizontal fill
                                     */}
                                    <View
                                        style={styles.catSelTopFill}/>
                                </View>

                                {/**
                                 Category tags outer container (horizontal)
                                 */}
                                <View
                                    style={styles.catSelHorizontalContainer}>

                                    {/**
                                     Category tags inner container (vertical)
                                     */}
                                    <View
                                        style={styles.catSelVerticalContainer}>

                                        {/**
                                         Category tags
                                         */}
                                        <View
                                            style={styles.catSelContainer}>

                                            <ScrollView
                                                showsVerticalScrollIndicator={false}
                                                scrollEventThrottle={20}
                                                ref={this.catSelScrollRef}
                                                onScroll={this.handleCatSelScroll}
                                                scrollsToTop={false}
                                                onContentSizeChange={(contentWidth, contentHeight) => {
                                                    this.setState({catSelMaxScroll: contentHeight});
                                                }}
                                                style={styles.catSelScrollView}>

                                                {/**
                                                 Category tags content
                                                 */}
                                                <View
                                                    style={styles.catSelContentContainer}>
                                                    {this.state.catTagsAvailable.map((item: string, key: number) => {
                                                        return <CategorySelTag
                                                            key={item}
                                                            itemName={item}
                                                            selected={this.state.newSelectionInCat.includes(item)}
                                                            selectionAllowed={this.state.newSelectionInCat.length < 8}
                                                            itemHeight={catTagHeight}
                                                            containerWidth={catSelBoxWidth}
                                                            marginTopBottom={catTagTopBottomMargin}
                                                            dims={cd}
                                                            selectFunc={() => {
                                                                this.selectCatTag(item);
                                                            }}
                                                            unselectFunc={() => {
                                                                this.unselectCatTag(item);
                                                            }}
                                                        />;
                                                    })}
                                                </View>
                                            </ScrollView>
                                        </View>

                                        {/**
                                         Top scroll arrow for category selection
                                         */}
                                        {this.state.catDropdownSelected !== unInitializedDropdownText && this.state.catSelScrollY > 0 &&
                                            <View
                                                style={styles.upArrowContainer}>
                                                <Hoverable
                                                    onHoverIn={() => {
                                                        if (cd.smallScreen) return;
                                                        this.setState({catSelTopArrowHovered: true})
                                                    }}
                                                    onHoverOut={() => {
                                                        if (cd.smallScreen) return;
                                                        this.setState({catSelTopArrowHovered: false})
                                                    }}
                                                    onPressIn={() => {
                                                        if (cd.smallScreen) return;
                                                        this.setState({catSelUpArrowHovered: true});
                                                        setTimeout(() => {
                                                            if (this.state.upArrowWasSnapped) return;
                                                            this.setState({upArrowPressedIn: true});
                                                            this.startUpScroll(catSelBoxHeight);
                                                        }, 450);
                                                    }}
                                                    onPressOut={() => {
                                                        if (cd.smallScreen) return;
                                                        this.setState({catSelTopArrowHovered: false});
                                                        setTimeout(() => {
                                                            this.setState({upArrowPressedIn: false});
                                                        }, 2);
                                                    }}
                                                >
                                                    <Pressable
                                                        onPress={() => {
                                                            if (cd.smallScreen) return;
                                                            if (this.state.upArrowPressedIn) {
                                                                this.setState({upArrowPressedIn: false});
                                                            } else if (!this.state.upArrowWasSnapped) {
                                                                const newScrollY = 0;
                                                                this.catSelScrollRef.current?.scrollTo({
                                                                    y: newScrollY,
                                                                    animated: true
                                                                });
                                                                this.setState({upArrowWasSnapped: true});
                                                                setTimeout(() => {
                                                                    this.setState({upArrowWasSnapped: false});
                                                                }, 500);
                                                            }
                                                        }}
                                                        style={styles.upArrowPressable}>
                                                        <Image
                                                            source={up_arrow}
                                                            style={styles.upArrowImg}/>

                                                    </Pressable>
                                                </Hoverable>
                                            </View>
                                        }

                                        {/**
                                         Bottom scroll arrow for category selection
                                         */}
                                        {this.state.catDropdownSelected !== unInitializedDropdownText && this.state.catSelScrollY + catSelBoxHeight < this.state.catSelMaxScroll * 0.99 &&
                                            <View
                                                style={styles.downArrowContainer}>
                                                <Hoverable
                                                    onHoverIn={() => {
                                                        if (cd.smallScreen) return;
                                                        this.setState({catSelBottomArrowHovered: true})
                                                    }}
                                                    onHoverOut={() => {
                                                        if (cd.smallScreen) return;
                                                        this.setState({catSelBottomArrowHovered: false})
                                                    }}
                                                    onPressIn={() => {
                                                        if (cd.smallScreen) return;
                                                        this.setState({catSelDownArrowHovered: true});
                                                        setTimeout(() => {
                                                            if (this.state.downArrowWasSnapped) return;
                                                            this.setState({downArrowPressedIn: true});
                                                            this.startDownScroll(catSelBoxHeight);
                                                        }, 450);
                                                    }}
                                                    onPressOut={() => {
                                                        if (cd.smallScreen) return;
                                                        this.setState({catSelBottomArrowHovered: false})
                                                        setTimeout(() => {
                                                            this.setState({downArrowPressedIn: false});
                                                        }, 2);
                                                    }}>
                                                    <Pressable
                                                        onPress={() => {
                                                            if (cd.smallScreen) return;
                                                            if (this.state.downArrowPressedIn) {
                                                                this.setState({downArrowPressedIn: false});
                                                            } else if (!this.state.downArrowWasSnapped) {
                                                                const newScrollY = Math.min(catSelBoxHeight + this.state.catSelMaxScroll, this.state.catSelScrollY + catSelBoxHeight * 0.7);
                                                                this.catSelScrollRef.current?.scrollTo({
                                                                    y: newScrollY,
                                                                    animated: true
                                                                });
                                                                this.setState({downArrowWasSnapped: true});
                                                                setTimeout(() => {
                                                                    this.setState({downArrowWasSnapped: false});
                                                                }, 500);
                                                            }
                                                        }}
                                                        style={styles.downArrowPressable}>
                                                        <Image
                                                            source={up_arrow}
                                                            style={styles.downArrowImg}/>
                                                    </Pressable>
                                                </Hoverable>
                                            </View>
                                        }

                                    </View>
                                </View>

                                {/**
                                 Container's bottom border
                                 */}
                                <View
                                    style={styles.middleAreaTopFill}>

                                </View>
                            </View>
                        }

                        {/**
                         * Row: Personalization slider and go button
                         */}
                        <View
                            style={styles.bottomAreaContainer}>

                            {/**
                             * Personalization slider area container
                             */}
                            <View
                                style={styles.personalizationArea}>

                                {/**
                                 * Personalization slider container
                                 */}
                                <View
                                    style={styles.personalizationSliderContainer}>

                                    {/**
                                     * Personalization slider
                                     */}
                                    <Slider
                                        style={styles.personalizationSlider}
                                        trackStyle={styles.sliderLine}
                                        thumbStyle={styles.sliderBtn}
                                        thumbTintColor={"#56acfc"}
                                        minimumTrackTintColor={"#ccdbe8"}
                                        maximumTrackTintColor={"#dfe9f2"}
                                        value={this.state.personalizationLevel}
                                        onValueChange={(value) => this.setState({personalizationLevel: value})}
                                        minimumValue={1.0}
                                        maximumValue={10.0}>
                                    </Slider>
                                </View>

                                {/**
                                 * Personalization slider text
                                 */}
                                <View
                                    style={styles.personalizationTextContainer}>
                                    <Text
                                        style={styles.personalizationText}
                                        selectable={false}>{sliderText}</Text>
                                </View>
                            </View>

                            {/**
                             * Go button
                             */}
                            <Hoverable
                                onHoverIn={() => this.setState({goBtnHovered: true})}
                                onHoverOut={() => this.setState({goBtnHovered: false})}
                                onPressIn={() => this.setState({goBtnHovered: true})}
                                onPressOut={() => setTimeout(() => {
                                    if (!cd.smallScreen && Platform.OS === 'web') return;
                                    this.setState({goBtnHovered: false})
                                }, 75)}
                            >
                                <Pressable
                                    onPress={() => setTimeout(() => this.enterSearch(cd), 175)}
                                    style={styles.goBtn}>
                                    <LinearGradient
                                        colors={this.state.goBtnHovered ?
                                            ["#56b7fca3", "#65b1f7a3", "#55aafaa3", "#50a8faa1", "#61b3ffa1"] :
                                            ["#61abff84", "#67a9f586", "#5ca4f794", "#5db0fc97", "#53a2fca3"]}
                                        start={[1, 0]}
                                        end={[0, 3.5]}
                                        style={styles.goBtnGradient}>
                                        <Text
                                            style={styles.goBtnText}>Show Me</Text>
                                    </LinearGradient>
                                </Pressable>
                            </Hoverable>
                        </View>
                    </View>
                </View>


                {/**
                 Initial category dropdown & suggestion (free-floating)
                 */}
                {this.state.catDropdownSelected === unInitializedDropdownText &&
                    <View
                        nativeID={"initialDropdownAndSuggestionContainer"}
                        style={styles.initialDropdownArea}>
                        <View
                            style={styles.initialDropdownSuggestionContainer}>
                            <Text
                                style={styles.initialDropdownSuggestionText}>{catDropdownStartText}</Text>
                        </View>


                        <View
                            style={styles.initialDropdownContainer}>
                            <CategoryDropdown
                                selectedCategory={this.state.catDropdownSelected}
                                categories={this.getCatNamesInOrder(this.state.catDropdownSelected)}
                                dims={cd}
                                width={catDropdownWidth}
                                optionHeight={catDropdownOptionHeight}
                                shadow={true}
                                selectCategoryFunc={this.selectNewCategory}
                                expandCollapseFunc={() => {
                                }}/>
                        </View>
                    </View>
                }

                {/**
                 Absolute category dropdown menu
                 */}
                {this.state.catDropdownSelected !== unInitializedDropdownText &&

                    <View
                        style={styles.dropdownContainer}>
                        <CategoryDropdown
                            selectedCategory={this.state.catDropdownSelected}
                            categories={this.getCatNamesInOrder(this.state.catDropdownSelected)}
                            dims={cd}
                            width={catDropdownWidth}
                            optionHeight={catDropdownOptionHeight}
                            shadow={false}
                            selectCategoryFunc={this.selectNewCategory}
                            expandCollapseFunc={() => {
                                this.setState({dropdownExpanded: !this.state.dropdownExpanded})
                            }}
                        />
                    </View>
                }


                {/**
                 "Close" Button
                 */}
                <SquishyButton
                    isSmallScreen={cd.smallScreen}
                    pressFunc={this.props.onClose}
                    isHovered={this.state.closeBtnHovered}
                    setHoveredFunc={(newBool) => this.setState({closeBtnHovered: newBool})}
                    scaleTo={0.93}
                    squishInDuration={150}
                    squishOutDelay={100}
                    squishOutDuration={100}
                    containerStyle={styles.closeBtnContainer}
                    buttonStyle={styles.closeBtnPressable}>
                    <LinearGradient
                        colors={this.state.closeBtnHovered && !cd.smallScreen ?
                            ["#fcfffffc", "white", "#fcfffffc",] :
                            ["#fcfffffc", "#fcfffffc",]}
                        style={styles.closeBtnGradient}>
                        <Image
                            source={close_icon}
                            // @ts-ignore
                            style={[styles.closeBtnImg, Platform.OS === 'web' ? {pointerEvents: "none"} : null]}/>
                    </LinearGradient>
                </SquishyButton>

            </View>
        </Modal>
    }
}