// Template for a saved search..
export class CatSearch {

    // The list of categories in this search.
    public tags: string[];
    // Epoch time of date on which the user saved the search (optional).
    public timeSaved: number;
    // The personalization (1 to 10).
    public personalizationLevel: number;
    // The hash to compare two equivalent searches with different orderings of category.
    public hash: string;

    constructor(tags: Array<string>, personalizationLevel: number, timeSaved?: number) {
        this.tags = tags.map((category) => category.trim()).sort();
        this.personalizationLevel = personalizationLevel;
        if (!timeSaved) {
            timeSaved = Math.round(new Date().valueOf() / 1000);
        }
        this.timeSaved = timeSaved;
        this.hash = this.tags.map((category) => category.toLowerCase()).join(',');
    }
}