import React, {Component} from "react";
import {Platform, Pressable, Text, View} from "react-native";
import {CompDimensions} from "../../../util/Util";
import Hoverable from "../../reusable/Hoverable";

export const borderColors = ["#ebdded", "#dedded", "#dde9ed", "#ddedea", "#ddede3", "#e2eddd", "#edebdd", "#ede7dd", "#eddedd"];

export class CategoryTag extends Component<{
    itemName: string,
    itemHeight: number,
    containerWidth: number,
    marginTopBottom: number,
    fontSize: number,
    dims: CompDimensions,
    deleteFunc: any,
    undeleteFunc: any,
    selected: boolean,
    deleted: boolean,
    borderColor: any,
}> {

    state = {
        textHovered: false,
        btnHovered: false,
    }

    constructor(props) {
        super(props);
    }

    render() {
        /**
         * Returns an expandable text item for a quote tag.
         */

            // Calculate font size and element dimensions.
        const cd = this.props.dims;
        const textPadding = cd.smallScreen ? this.props.containerWidth * 0.02 : this.props.containerWidth * 0.01;
        const removeHeight = this.props.itemHeight * 0.65;
        const removeWidth = removeHeight;
        const removeFontSize = 0.8 * Math.sqrt(removeWidth * removeHeight / "X".length);

        let tagTextColor = "black";
        let tagTextBg = this.props.borderColor;
        if (this.props.deleted && this.props.selected) {
            tagTextBg = "transparent";
            if (this.state.textHovered || this.state.btnHovered) {
                tagTextBg = "#c0c1c421";
            }
        } else if (this.props.selected) {
            if (this.state.textHovered || this.state.btnHovered) {
                tagTextBg = "white";
            }
        }


        return (
            <Hoverable
                key={this.props.itemName}
                onHoverIn={() => this.setState({
                    textHovered: true,
                })}
                onHoverOut={() => this.setState({textHovered: false, expanded: false})}
                onPressIn={() => this.setState({textHovered: true})}
                onPressOut={() => {
                    if (Platform.OS === 'web') return;
                    setTimeout(() => this.setState({textHovered: false}), 25);
                }}
            >
                <View style={{
                    height: this.props.itemHeight,
                    marginTop: this.props.marginTopBottom,
                    marginBottom: this.props.marginTopBottom,
                    marginLeft: this.props.selected ? (textPadding * 2) * 0.5 : (removeWidth + textPadding * 2) * 0.5,
                    marginRight: this.props.selected ? (textPadding * 2) * 0.5 : (removeWidth + textPadding * 2) * 0.5,
                    backgroundColor: tagTextBg,
                    borderRadius: this.props.itemHeight * 0.4,
                    borderColor: this.props.selected && this.props.deleted ? "black" : this.props.borderColor,
                    borderWidth: this.props.itemHeight * 0.06,
                    //alignSelf: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    alignItems: "center",
                }}>

                    {/**
                     Tag Name
                     */}
                    <Hoverable
                        onPressIn={() => this.setState({textHovered: true})}
                        onPressOut={() => {
                            if (!cd.smallScreen) return;
                            setTimeout(() => this.setState({textHovered: false}), 25);
                        }}
                    >
                        <Pressable
                            onPress={() => setTimeout(() => {
                                // Suggest the tag to be deleted or undeleted.
                                if (this.props.deleted && this.props.selected) {
                                    this.props.undeleteFunc();
                                } else if (this.props.selected) {
                                    this.props.deleteFunc();
                                }
                            }, 50)}
                            style={{
                                height: this.props.itemHeight,
                                marginTop: textPadding * 0.1,
                                marginBottom: textPadding * 0.1,
                                marginLeft: textPadding,
                                marginRight: textPadding,
                            }}>
                            <View style={{
                                height: this.props.itemHeight,
                                justifyContent: "center",
                            }}>
                                <Text style={{
                                    fontSize: this.props.fontSize,
                                    textDecorationLine: this.props.deleted && this.props.selected ? "line-through" : "none",
                                    color: tagTextColor,
                                    textShadowColor: "#a4c6d2",
                                    textShadowRadius: this.props.selected ? 0 : this.props.itemHeight * 0.035,
                                }}
                                      selectable={false}>{this.props.itemName}</Text>
                            </View>
                        </Pressable>
                    </Hoverable>

                    {/**
                     Plus or X button
                     */}
                    {this.props.selected &&
                        <View style={{
                            width: removeWidth,
                            height: removeHeight,
                            marginRight: textPadding,
                        }}>
                            <Hoverable
                                onHoverIn={() => this.setState({btnHovered: true})}
                                onHoverOut={() => this.setState({btnHovered: false})}
                                onPressIn={() => this.setState({btnHovered: true})}
                                onPressOut={() => {
                                    if (!cd.smallScreen) return;
                                    setTimeout(() => this.setState({btnHovered: false}), 50);
                                }}
                            >
                                <Pressable
                                    onPress={() => {
                                        setTimeout(() => {
                                            // Suggest tag to be deleted or undeleted.
                                            if (this.props.deleted && this.props.selected) {
                                                this.props.undeleteFunc();
                                            } else if (this.props.selected) {
                                                this.props.deleteFunc();
                                            }
                                        }, 100);
                                    }}
                                    style={{
                                        transform: [{scale: this.state.btnHovered ? Platform.OS !== 'web' ? 1.1 : 1.06 : 1}],
                                        width: removeWidth,
                                        height: removeHeight,
                                        backgroundColor: !this.props.deleted ? (this.state.btnHovered ? "#ff4551" : "#fc3d49") : (this.state.btnHovered ? "#5aad45" : "#4fa839ca"),
                                        borderRadius: removeWidth * 0.5,
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <Text style={{
                                        fontSize: !this.props.deleted ? removeFontSize : removeFontSize * 1.2,
                                        fontWeight: "bold",
                                        color: "white",
                                        paddingBottom: !this.props.deleted ? 0 : (Platform.OS === 'ios' ? removeWidth * 1.3 : removeWidth * 0.17),
                                    }}
                                          selectable={false}>{this.props.deleted ? "+" : "X"}</Text>

                                </Pressable>
                            </Hoverable>
                        </View>
                    }
                </View>
            </Hoverable>
        );
    }
}