import React, {Component, createRef} from "react";
import {Image, Modal, Platform, Pressable, ScrollView, StyleSheet, Text, TextInput, View} from "react-native";
import Hoverable from "../reusable/Hoverable";
import {LinearGradient} from "expo-linear-gradient";
import {CompDimensions, ConditionalWrapper} from "../../util/Util";
// @ts-ignore
import up_arrow from '../../../assets/up_arrow.png';
import {mainLightColor} from "../../Main";
import SquishyButton from "../reusable/SquishyButton";

const thanksDescText = 'Give THANKs to reward quoters who bring you interesting quotes.'
const option1ThanksText = "30 THANKs";
const option2ThanksText = "50 THANKs";
const option3ThanksText = "100 THANKs";
const option4ThanksText = "300 THANKs";
const option1PriceText = "$1.99";
const option2PriceText = "$2.99";
const option3PriceText = "$4.99";
const option4PriceText = "$12.99";
const contentCreatorApplicationText1 = "Press the button below to be emailed your access to the Creator's Panel. ";
const contentCreatorApplicationText2 = "In addition to earning 80% of the THANKs given to your quotes, you will also be " +
    "considered for promotional earnings if you have a significant online presence or a history of publishing content.";
const creatorApplicationSubmittedText = "Check your email for instructions on getting started as a Haya quoter."
const creatorDescriptionText = "Open the Creator's Panel to check on your published quotes, and to publish new quotes."

export class SettingsModal extends Component<{
    dims: CompDimensions,
    yOffset: number,
    visible: boolean,
    onClose: any,
}> {

    state = {
        closeBtnHovered: false,
        pageNumber: 0,
        buyBtnHovered: false,
        nextBtnHovered: false,
        prevBtnHovered: false,
        qtyOptionCurHovered: false,
        qtyOption1Hovered: false,
        qtyOption2Hovered: false,
        qtyOption3Hovered: false,
        qtyOption4Hovered: false,
        qtyExpansionHovered: false,
        qtyBoxExpanded: false,
        qtyOptionSelected: 0,
        qtyDropdownX: 0,
        qtyDropdownY: 0,
        changingPassword: false,
        deleteAcctModalShown: false,
        changePasswordHovered: false,
        newPassInput: "",
        confirmNewPassInput: "",
        submitBtnHovered: false,
        cancelBtnHovered: false,
        deleteAcctBtnHovered: false,
        confirmSubmitHovered: false,
        confirmCancelHovered: false,
        appliedForContentCreator: false,
        isContentCreator: false,
        creatorAppInput: "",
        creatorAppSubmitHovered: false,
        launchBtnHovered: false,
    }

    contentScrollRef: any;
    qtyDropdownRef: any;

    constructor(props) {
        super(props);
        this.qtyDropdownRef = createRef();
        this.contentScrollRef = createRef();
        this.onClose = this.onClose.bind(this);
    }

    onClose() {
        // Reset state vars before closing modal.
        this.setState({
            pageNumber: 0,
            deleteAcctModalShown: false,
            changingPassword: false,
            qtyBoxExpanded: false,
            qtyOptionSelected: 0,
        });
        this.props.onClose();
    }

    render() {

        // Get and calculate component dimensions.
        const cd = this.props.dims;
        const borderWidth = cd.loginBtnBorderRadius * 0.5;
        const verticalMargin = cd.smallScreen ? cd.loginModalHeight * 0.015 : cd.loginModalHeight * 0.025;
        const settingsTitleAreaHeight = cd.smallScreen ? cd.loginModalHeight * 0.13 : cd.loginModalHeight * 0.15;
        const settingsTitleFontSize = cd.smallScreen ? settingsTitleAreaHeight * 0.45 : settingsTitleAreaHeight * 0.4;
        const contentHeight = cd.loginModalHeight - settingsTitleAreaHeight - borderWidth * 2;
        const navBtnWidth = cd.smallScreen ? cd.loginModalWidth * 0.15 : cd.loginModalWidth * 0.1;
        const pageTitleFontSize = cd.smallScreen ? settingsTitleFontSize * 0.7 : settingsTitleFontSize * 0.7;
        const mainDividerHeight = settingsTitleFontSize * 0.13;
        const mainDividerWidth = cd.loginModalWidth * 0.55;
        const buyRowHeight = cd.smallScreen ? cd.loginModalHeight * 0.23 : cd.loginModalHeight * 0.2;
        const qtyBoxWidth = cd.smallScreen ? cd.loginModalWidth * 0.35 : cd.loginModalWidth * 0.28;
        const qtyBoxHeight = cd.smallScreen ? buyRowHeight * 0.3 : buyRowHeight * 0.4;
        const buyBtnHeight = cd.smallScreen ? buyRowHeight * 0.35 : buyRowHeight * 0.45;
        const buyBtnWidth = buyBtnHeight * 2.2;
        const buyBtnFontSize = 0.8 * Math.sqrt((buyBtnWidth * buyBtnHeight) / "Buy Now".length);
        const thanksStatsFontSize = cd.smallScreen ? pageTitleFontSize * 0.8 : pageTitleFontSize * 0.8;
        const horizontalInset = cd.quoteModalWidth * 0.15;
        const horizontalInsetSmall = cd.quoteModalWidth * 0.06;
        const nextAreaHeight = navBtnWidth * 1.5;
        const securityFieldHeight = (contentHeight - nextAreaHeight * 2 - pageTitleFontSize) / 5;
        let securityFieldFontSize = Math.sqrt((cd.loginModalWidth * securityFieldHeight) / "Long Username Field Length Times Two".length);
        securityFieldFontSize = Math.min(securityFieldFontSize, pageTitleFontSize * 0.75);

        let closeText = "> <";

        // Create reusable styles.
        const styles = StyleSheet.create({
            grayTapOutArea: {
                // @ts-ignore
                position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
                width: cd.screenWidth,
                height: cd.screenHeight,
                backgroundColor: "rgba(192,194,207,0.89)",
                cursor: "default",
            },
            qtyDropdownTapOutArea: {
                // @ts-ignore
                position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
                width: cd.loginModalWidth,
                height: cd.loginModalHeight,
                marginTop: cd.statusBarHeight + cd.loginBtnTopMargin + cd.loginBtnHeight * 0.5 - this.props.yOffset - (this.props.yOffset > 0 ? cd.loginBtnHeight * 0.3 : 0),
                marginLeft: (cd.screenWidth - cd.loginModalWidth) * 0.5,
                //cursor: "default",
            },
            settingsArea: {
                width: cd.loginModalWidth,
                height: cd.loginModalHeight,
                marginTop: cd.statusBarHeight + cd.loginBtnTopMargin + cd.loginBtnHeight * 0.5 - this.props.yOffset - (this.props.yOffset > 0 ? cd.loginBtnHeight * 0.3 : 0),
                marginLeft: "auto",
                marginRight: "auto",
                backgroundColor: "#fcfffffc",
                borderColor: "#e4edf293",
                borderWidth: borderWidth,
                borderRadius: cd.loginBtnBorderRadius,
                shadowColor: "#e4edf293",
                shadowRadius: cd.smallScreen ? cd.loginModalWidth * 0.1 : cd.loginModalWidth * 0.01,
                elevation: 5,
                overflow: "hidden",
            },
            mainTitleTextArea: {
                width: cd.loginModalWidth,
                height: settingsTitleAreaHeight,
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "center",
                backgroundColor: "#fcfeff",
            },
            mainTitleText: {
                fontSize: settingsTitleAreaHeight * 0.4,
                fontWeight: "bold",
                marginBottom: settingsTitleAreaHeight * 0.1,
                color: "#1f3138",
            },
            mainDividerBar: {
                position: "absolute",
                width: mainDividerWidth,
                height: mainDividerHeight,
                marginTop: settingsTitleAreaHeight - mainDividerHeight * 0.5,
                marginLeft: (cd.loginModalWidth - mainDividerWidth) * 0.5,
                borderRadius: mainDividerHeight,
                backgroundColor: mainLightColor,
            },
            scrollArea: {
                width: cd.loginModalWidth,
                height: contentHeight,
            },
            pageContainer: {
                width: cd.loginModalWidth,
                height: contentHeight,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                backgroundColor: "#fcfeff",
            },
            pageTitle: {
                width: cd.loginModalWidth,
                marginTop: verticalMargin * 2,
            },
            pageTitleText: {
                fontSize: pageTitleFontSize,
                fontWeight: "bold",
                textAlign: "center",
                textDecorationLine: "underline",
            },
            thanksDescArea: {
                width: cd.loginModalWidth,
                marginTop: verticalMargin * 1.6,
                flexDirection: "column",
                alignItems: "center",
            },
            thanksDescText: {
                paddingLeft: cd.loginModalWidth * 0.03,
                paddingRight: cd.loginModalWidth * 0.03,
                fontSize: pageTitleFontSize * 0.7,
                textAlign: "center",
            },
            buyRow: {
                width: cd.loginModalWidth,
                height: buyRowHeight,
                marginTop: cd.smallScreen ? verticalMargin * 1.4 : verticalMargin * 1.8,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
            },
            qtyAreaFixed: {
                // @ts-ignore
                position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
                width: qtyBoxWidth,
                height: qtyBoxHeight * 4,
                marginLeft: this.state.qtyDropdownX,
                marginTop: this.state.qtyDropdownY,
                flexDirection: "column",
            },
            qtyArea: {
                height: buyRowHeight,
                marginLeft: cd.smallScreen ? cd.loginModalWidth * 0.02 : cd.loginModalWidth * 0.01,
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            },
            qtyAreaMobile: {
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
            },
            qtyInnerAreaMobile: {
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            qtyText1: {
                fontSize: cd.smallScreen ? buyRowHeight * 0.14 : buyRowHeight * 0.14,
                fontWeight: cd.smallScreen ? "normal" : "bold",
            },
            qtyText2: {
                marginLeft: cd.loginModalWidth * 0.015,
                marginTop: cd.smallScreen ? verticalMargin * 0.5 : 0,
                fontSize: cd.smallScreen ? buyRowHeight * 0.14 : buyRowHeight * 0.13,
                fontWeight: cd.smallScreen ? "normal" : "bold",
            },
            qtyBoxContainer: {
                width: qtyBoxWidth,
                height: qtyBoxHeight,
                marginLeft: cd.loginModalWidth * 0.015,
                borderRadius: qtyBoxHeight * 0.06,
                borderWidth: qtyBoxHeight * 0.03,
                backgroundColor: "#a7c5e8",
                borderColor: "#78aae3",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
            },
            qtyBoxContainerHovered: {
                borderColor: "gray",
                backgroundColor: "#d9eafc",
            },
            qtyOptionThanksText: {
                marginTop: cd.smallScreen ? qtyBoxHeight * 0.02 : qtyBoxHeight * 0.005,
                fontSize: cd.smallScreen ? qtyBoxHeight * 0.4 : qtyBoxHeight * 0.37,
                fontWeight: "bold",
                color: "white",
                textShadowColor: "gray",
                textShadowRadius: qtyBoxHeight * 0.02,
            },
            qtyOptionPriceText: {
                marginBottom: cd.smallScreen ? qtyBoxHeight * 0.02 : qtyBoxHeight * 0.005,
                fontSize: cd.smallScreen ? qtyBoxHeight * 0.35 : qtyBoxHeight * 0.35,
                fontWeight: "bold",
                color: "white",
                textShadowColor: "gray",
                textShadowRadius: qtyBoxHeight * 0.02,
            },
            qtyOptionTextHovered: {
                color: "#363636",
            },
            buyBtn: {
                transform: [{scale: this.state.buyBtnHovered ? (cd.smallScreen ? 1.02 : 1.02) : 1.0}],
                width: buyBtnWidth,
                height: buyBtnHeight,
                marginRight: cd.smallScreen ? cd.loginModalWidth * 0.04 : cd.loginModalWidth * 0.04,
                borderWidth: buyBtnHeight * 0.05,
                borderRadius: buyBtnHeight * 0.2,
                borderColor: "#fcf5e82b",
                shadowColor: "#c4e0ff",
                shadowRadius: cd.smallScreen ? buyBtnHeight * 0.03 : buyBtnHeight * 0.08,
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? buyBtnHeight * 0.015 : 0,
                },
                shadowOpacity: cd.smallScreen ? 0.2 : 0.4,
                backgroundColor: this.state.buyBtnHovered ? "#fcd181f5" : "#fcd181e8",
                justifyContent: "center",
                alignItems: "center",
            },
            buyBtnText: {
                fontSize: buyBtnFontSize,
                fontWeight: "bold",
                color: "#fffdf5",
            },
            statsContainerDesktop: {
                width: cd.loginModalWidth,
                marginTop: verticalMargin * 0.5,
                flexDirection: "row",
                justifyContent: "space-between",
            },
            statsContainerMobile: {
                width: cd.loginModalWidth,
                marginTop: "auto",
                marginBottom: "auto",
                flexDirection: "column",
            },
            statsLeftColumn: {
                marginLeft: cd.loginModalWidth * 0.05,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
            },
            statsRightColumn: {
                marginRight: cd.loginModalWidth * 0.05,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
            },
            thanksStatsTitleText: {
                fontSize: thanksStatsFontSize,
                color: "#515457",
            },
            thanksStatsValueText: {
                fontSize: thanksStatsFontSize,
                fontWeight: "bold",
            },
            thanksAvailableArea: {
                width: cd.loginModalWidth,
                marginTop: cd.smallScreen ? verticalMargin * 1.1 : verticalMargin * 1.8,
                marginLeft: "auto",
                marginRight: "auto",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-end",
                textAlignVertical: "bottom",
            },
            thanksGivenArea: {
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            },
            vertMarginBig: {
                marginTop: verticalMargin * 1.3,
            },
            vertMarginMed: {
                marginTop: verticalMargin,
            },
            vertMarginSmall: {
                marginTop: cd.smallScreen ? verticalMargin * 0.7 : verticalMargin * 0.8,
            },
            vertMarginTiny: {
                marginTop: verticalMargin * 0.3,
            },
            thanksStatInset: {
                marginLeft: horizontalInset,
            },
            thanksStatInsetSmall: {
                marginLeft: horizontalInsetSmall,
            },
            thanksStatInsetTiny: {
                marginLeft: horizontalInsetSmall * 0.5,
            },
            nameFieldArea: {
                width: cd.loginModalWidth,
                height: securityFieldFontSize * 2,
                marginTop: securityFieldFontSize,
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            },
            emailFieldArea: {
                width: cd.loginModalWidth,
                height: securityFieldFontSize * 2,
                marginTop: securityFieldHeight * 0.05,
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            },
            changePassArea: {
                width: cd.loginModalWidth,
                marginTop: securityFieldFontSize * 1.2,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            changePassText: {
                fontSize: securityFieldFontSize,
                textDecorationLine: "underline",
                color: this.state.changePasswordHovered ? "#262626" : "#3b3b3b",
            },
            changePassOptions: {
                width: cd.loginModalWidth,
                marginTop: "auto",
                marginBottom: "auto",
                flexDirection: "column",
            },
            changePassOptionsInner: {
                width: cd.loginModalWidth,
                marginTop: verticalMargin * 0.5,
                flexDirection: "row",
                alignItems: "center",
            },
            changePassOptionsLeft: {
                flexDirection: "column",
            },
            changePassOptionsRight: {
                flexDirection: "column",
                marginLeft: "auto",
                marginRight: "auto",
            },
            errorArea: {
                width: cd.loginModalWidth * 0.95,
                marginLeft: "auto",
                marginRight: "auto",
            },
            errorText: {
                fontSize: securityFieldFontSize * 0.7,
                fontWeight: "bold",
                textAlign: "center",
                color: "red",
            },
            newPassOptionsTitle: {
                height: securityFieldFontSize * 1.5,
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            },
            newPassOptionsTitleText: {
                marginTop: verticalMargin * 0.5,
                fontSize: securityFieldFontSize * 0.9,
                fontWeight: "bold",
                color: "#2c3742",
            },
            newPassInput: {
                width: cd.smallScreen ? securityFieldHeight * 2.5 : securityFieldHeight * 3,
                height: securityFieldHeight * 0.7,
                marginLeft: horizontalInsetSmall,
                borderRadius: securityFieldHeight * 0.05,
                borderWidth: securityFieldHeight * 0.035,
                borderColor: "lightgray",
                fontSize: securityFieldFontSize * 0.9,
            },
            newPassOptionsConfirmTitle: {
                height: securityFieldFontSize * 1.5,
                marginTop: securityFieldHeight * 0.2,
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            },
            newPassOptionsConfirmTitleText: {
                fontSize: securityFieldFontSize * 0.9,
                fontWeight: "bold",
                color: "#2c3742",
            },
            newPassConfirmInput: {
                width: cd.smallScreen ? securityFieldHeight * 2.5 : securityFieldHeight * 3,
                height: securityFieldHeight * 0.7,
                marginLeft: horizontalInsetSmall,
                borderRadius: securityFieldHeight * 0.05,
                borderWidth: securityFieldHeight * 0.035,
                borderColor: "lightgray",
                fontSize: securityFieldFontSize * 0.9,
            },
            newPassCancelBtn: {
                transform: [{scale: this.state.cancelBtnHovered ? 1.03 : 1}],
                width: cd.smallScreen ? securityFieldHeight * 1.3 : securityFieldHeight * 1.5,
                height: securityFieldHeight * 0.7,
                marginTop: securityFieldHeight * 0.45,
                marginBottom: securityFieldHeight * 0.1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderWidth: securityFieldHeight * 0.05,
                borderRadius: securityFieldHeight * 0.3,
                borderColor: "gray",
                backgroundColor: this.state.cancelBtnHovered ? (cd.smallScreen ? "#edf0ed" : "#f7f7f7") : "white",
            },
            newPassCancelBtnText: {
                fontSize: (cd.smallScreen ? 0.7 : 0.85) * Math.sqrt((securityFieldHeight * 1.5 * securityFieldHeight * 0.7) / "Submit".length),
                color: "#3f474f",
                fontWeight: "bold",
            },
            newPassSubmitBtn: {
                transform: [{scale: this.state.submitBtnHovered ? 1.03 : 1}],
                width: cd.smallScreen ? securityFieldHeight * 1.3 : securityFieldHeight * 1.5,
                height: securityFieldHeight * 0.7,
                marginTop: securityFieldHeight * 0.1,
                marginBottom: "auto",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderWidth: securityFieldHeight * 0.05,
                borderRadius: securityFieldHeight * 0.3,
                borderColor: "#29b32e",
                backgroundColor: this.state.submitBtnHovered ? (cd.smallScreen ? "#c5fcc7" : "#ccffce") : "#dbffdc",
            },
            newPassSubmitBtnText: {
                fontSize: (cd.smallScreen ? 0.7 : 0.85) * Math.sqrt((securityFieldHeight * 1.5 * securityFieldHeight * 0.7) / "Submit".length),
                color: "#3f474f",
                fontWeight: "bold",
            },
            deleteAcctBtn: {
                marginTop: "auto",
                marginBottom: securityFieldFontSize * 2,
                marginLeft: "auto",
                marginRight: "auto",
                backgroundColor: this.state.deleteAcctBtnHovered ? "#e02e2221" : "#e02e2216",
                borderRadius: securityFieldHeight * 0.2,
                borderWidth: securityFieldHeight * 0.03,
                borderColor: "#f07a7a",
                justifyContent: "center",
                alignItems: "center",
            },
            deleteAcctBtnText: {
                fontSize: securityFieldFontSize * 0.9,
                color: this.state.deleteAcctBtnHovered ? "black" : "black",
                marginLeft: securityFieldHeight * 0.15,
                marginRight: securityFieldHeight * 0.15,
                marginTop: securityFieldHeight * 0.06,
                marginBottom: securityFieldHeight * 0.06,
            },
            creatorAppInput: {
                width: cd.smallScreen ? cd.loginModalWidth * 0.92 : cd.loginModalWidth * 0.88,
                height: cd.loginModalHeight * 0.25,
                marginLeft: cd.smallScreen ? (cd.loginModalWidth * (1 - 0.92)) * 0.5 : (cd.loginModalWidth * (1 - 0.88)) * 0.5,
                borderRadius: securityFieldHeight * 0.05,
                borderWidth: securityFieldHeight * 0.035,
                borderColor: "lightgray",
                fontSize: securityFieldFontSize,
                textAlignVertical: "top",
            },
            creatorAppSubmitBtn: {
                width: cd.smallScreen ? securityFieldHeight * 1.3 : securityFieldHeight * 1.5,
                height: securityFieldHeight * 0.7,
                marginTop: securityFieldHeight * 0.1,
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "auto",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderWidth: securityFieldHeight * 0.05,
                borderRadius: securityFieldHeight * 0.3,
                borderColor: "#29b32e",
                backgroundColor: this.state.creatorAppSubmitHovered ? (cd.smallScreen ? "#c5fcc7" : "#ccffce") : "#dbffdc",
            },
            creatorAppSubmitBtnText: {
                fontSize: (cd.smallScreen ? 0.7 : 0.85) * Math.sqrt((securityFieldHeight * 1.5 * securityFieldHeight * 0.7) / "Submit".length),
                color: "#3f474f",
                fontWeight: "bold",
            },
            launchPanelBtn: {
                width: cd.smallScreen ? securityFieldHeight * 3.6 : securityFieldHeight * 4,
                height: securityFieldHeight * 0.7,
                marginTop: verticalMargin * 5,
                marginLeft: "auto",
                marginRight: "auto",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderWidth: securityFieldHeight * 0.05,
                borderRadius: securityFieldHeight * 0.3,
                borderColor: "#29b32e",
                backgroundColor: this.state.launchBtnHovered ? (cd.smallScreen ? "#c5fcc7" : "#ccffce") : "#dbffdc",
            },
            launchPanelBtnText: {
                fontSize: (cd.smallScreen ? 0.7 : 0.85) * Math.sqrt((securityFieldHeight * 1.5 * securityFieldHeight * 0.7) / "Submit".length),
                color: "#3f474f",
                fontWeight: "bold",
            },
            prevAreaPlaceholder: {
                width: cd.loginModalWidth,
                height: nextAreaHeight,
            },
            nextAreaPlaceholder: {
                width: cd.loginModalWidth,
                height: nextAreaHeight,
            },
            prevBtnArea: {
                position: "absolute",
                width: cd.loginModalWidth - borderWidth,
                height: nextAreaHeight,
                marginTop: settingsTitleAreaHeight + mainDividerHeight * 0.5 - borderWidth,
                //marginLeft: (cd.loginModalWidth - navBtnWidth) * 0.5,
                backgroundColor: "#f5f9fc",
                justifyContent: "center",
                alignItems: "center",
            },
            prevBtn: {
                transform: [{scale: this.state.prevBtnHovered ? 1.04 : 1}],
                width: navBtnWidth,
                height: navBtnWidth,
                borderRadius: navBtnWidth * 0.5,
                backgroundColor: this.state.prevBtnHovered ? "#ccc" : "#c4c4c4",
                borderWidth: navBtnWidth * 0.06,
                borderColor: "#dedede74",
                justifyContent: "center",
                alignItems: "center",
            },
            nextBtnArea: {
                position: "absolute",
                width: cd.loginModalWidth - borderWidth,
                height: nextAreaHeight,
                marginTop: cd.loginModalHeight - nextAreaHeight - borderWidth * 1.5,
                //marginLeft: (cd.loginModalWidth - navBtnWidth) * 0.5,
                backgroundColor: "#f5f9fc",
                justifyContent: "center",
                alignItems: "center",
            },
            nextBtn: {
                transform: [{scale: this.state.nextBtnHovered ? 1.04 : 1}],
                width: navBtnWidth,
                height: navBtnWidth,
                borderRadius: navBtnWidth * 0.5,
                backgroundColor: this.state.nextBtnHovered ? "#ccc" : "#c4c4c4",
                borderWidth: navBtnWidth * 0.06,
                borderColor: "#dedede74",
                justifyContent: "center",
                alignItems: "center",
            },
            confirmDeleteArea: {
                flex: 1,
                backgroundColor: "#f51616",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            confirmDeleteWarningArea: {
                width: cd.loginModalWidth * 0.95,
                marginTop: verticalMargin,
                justifyContent: "center",
                alignItems: "center",
            },
            confirmDeleteWarningText: {
                fontSize: securityFieldFontSize * 1.2,
                textAlign: "center",
                color: "#e8e8e8",
            },
            confirmDeleteSubmitBtn: {
                transform: [{scale: this.state.confirmSubmitHovered ? 1.03 : 1}],
                height: securityFieldHeight * 0.7,
                marginTop: verticalMargin * 0.5,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            confirmDeleteSubmitText: {
                fontSize: securityFieldFontSize,
                color: "#e8e8e8",
                fontWeight: "bold",
                textDecorationLine: "underline",
            },
            confirmDeleteCancelBtn: {
                width: cd.smallScreen ? securityFieldHeight * 1.6 : securityFieldHeight * 1.85,
                height: securityFieldHeight * 0.7,
                marginTop: "auto",
                marginBottom: "auto",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderWidth: securityFieldHeight * 0.05,
                borderRadius: securityFieldHeight * 0.3,
                borderColor: "#e8ffef",
                backgroundColor: this.state.confirmCancelHovered ? (cd.smallScreen ? "#8cd4a0" : "#82cf98") : "#68c482",
            },
            confirmDeleteCancelText: {
                fontSize: (cd.smallScreen ? 0.7 : 0.85) * Math.sqrt((securityFieldHeight * 1.5 * securityFieldHeight * 0.7) / "Submit".length),
                color: "white",
                fontWeight: "bold",
            },
            closeBtnContainer: {
                // @ts-ignore
                position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
                width: cd.loginBtnWidth,
                height: cd.smallScreen ? cd.loginBtnHeight * 1.01 : cd.loginBtnHeight,
                marginTop: cd.statusBarHeight + cd.loginBtnTopMargin - this.props.yOffset - (this.props.yOffset > 0 ? cd.loginBtnHeight * 0.3 : 0),
                marginLeft: cd.screenWidth * 0.5 - cd.loginBtnWidth * 0.5,
                borderRadius: cd.headerSideBtnsWidth * 0.5,
            },
            closeBtnPressable: {
                width: cd.loginBtnWidth,
                height: cd.loginBtnHeight,
            },
            closeBtnGradient: {
                width: cd.loginBtnWidth,
                height: cd.loginBtnHeight,
                borderRadius: cd.loginBtnBorderRadius,
                borderColor: "#f0f4f7",
                borderWidth: cd.loginBtnBorderRadius * 0.4,
            },
            closeBtnText: {
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "auto",
                marginBottom: "auto",
                color: this.state.closeBtnHovered && !cd.smallScreen ? "#6b6d73" : "#383b45",
                fontWeight: "bold",
                fontSize: 0.7 * Math.sqrt((cd.loginBtnWidth * cd.loginBtnHeight) / closeText.length),
            },
        });


        return <Modal animationType={cd.smallScreen ? "none" : "none"}
                      onDismiss={this.onClose}
                      onRequestClose={this.onClose}
                      transparent={true}
                      visible={this.props.visible}
        >
            <View>

                {/**
                 Gray Screen Behind Modal
                 */}
                <Pressable
                    onPress={this.onClose}
                    isTVSelectable={false}
                    style={styles.grayTapOutArea}>
                </Pressable>


                {/**
                 Settings Area with Border
                 */}
                <View
                    style={styles.settingsArea}>

                    {/**
                     Delete Account Confirmation
                     */}
                    {this.state.deleteAcctModalShown &&
                        <View
                            style={styles.confirmDeleteArea}>

                            {/**
                             Confirmation Title
                             */}
                            <View
                                style={[styles.mainTitleTextArea,
                                    {
                                        backgroundColor: null,
                                        marginTop: 0,
                                    }]}>
                                <Text
                                    style={[styles.mainTitleText,
                                        {
                                            color: "white",
                                        }]}>Delete Account?</Text>
                            </View>

                            {/**
                             Confirmation Warning
                             */}
                            <View
                                style={styles.confirmDeleteWarningArea}>
                                <Text
                                    style={styles.confirmDeleteWarningText}>WARNING: This cannot be undone. Deleting
                                    your account will permanently erase all
                                    of your bookmarks, and any THANKs remaining in your account can never be
                                    recovered. Are you sure you wish to proceed?</Text>
                            </View>

                            {/**
                             Confirmation Submit Btn
                             */}
                            <Hoverable
                                onHoverIn={() => {
                                    this.setState({confirmSubmitHovered: true})
                                }}
                                onHoverOut={() => {
                                    this.setState({confirmSubmitHovered: false})
                                }}
                                onPressIn={() => {
                                    this.setState({confirmSubmitHovered: true})
                                }}
                                onPressOut={() => setTimeout(() => {
                                    if (!cd.smallScreen && Platform.OS === 'web') return;
                                    this.setState({confirmSubmitHovered: false});
                                }, 25)}
                            >
                                <Pressable
                                    onPress={() => {
                                        // TODO Delete account on the backend.
                                        this.onClose();
                                    }}
                                    style={styles.confirmDeleteSubmitBtn}>
                                    <Text
                                        style={styles.confirmDeleteSubmitText}>Yes, delete my account</Text>
                                </Pressable>
                            </Hoverable>

                            {/**
                             Confirmation Cancel Btn
                             */}
                            <Hoverable
                                onHoverIn={() => {
                                    this.setState({confirmCancelHovered: true})
                                }}
                                onHoverOut={() => {
                                    this.setState({confirmCancelHovered: false})
                                }}
                                onPressIn={() => {
                                    this.setState({confirmCancelHovered: true})
                                }}
                                onPressOut={() => setTimeout(() => {
                                    if (!cd.smallScreen && Platform.OS === 'web') return;
                                    this.setState({confirmCancelHovered: false});
                                }, 25)}
                            >
                                <Pressable
                                    onPress={() => {
                                        this.setState({
                                            deleteAcctModalShown: false,
                                            pageNumber: 0,
                                        });
                                    }}
                                    style={styles.confirmDeleteCancelBtn}>
                                    <Text
                                        style={styles.confirmDeleteCancelText}>Go Back</Text>
                                </Pressable>
                            </Hoverable>

                        </View>
                    }

                    {!this.state.deleteAcctModalShown &&
                        <>
                            {/**
                             Settings Title
                             */}
                            <View
                                style={styles.mainTitleTextArea}>
                                <Text
                                    style={styles.mainTitleText}>Settings</Text>
                            </View>

                            {/**
                             Content Area
                             */}
                            <ScrollView
                                ref={this.contentScrollRef}
                                scrollEventThrottle={100}
                                pagingEnabled
                                scrollEnabled={false}
                                onScroll={(event) => {
                                    const newPage = Math.round(parseFloat(String(event.nativeEvent.contentOffset.y)) / contentHeight);
                                    if (newPage !== this.state.pageNumber) {
                                        this.setState({pageNumber: newPage});
                                    }
                                }}
                                bounces={false}
                                showsVerticalScrollIndicator={false}
                                style={styles.scrollArea}>

                                {/**
                                 First Page
                                 */}
                                <View
                                    style={styles.pageContainer}>

                                    {/**
                                     First Page Title
                                     */}
                                    <View
                                        style={styles.pageTitle}>
                                        <Text
                                            style={styles.pageTitleText}>THANKs</Text>
                                    </View>

                                    {/**
                                     First Page Description
                                     */}
                                    <View
                                        style={styles.thanksDescArea}>
                                        <Text
                                            style={styles.thanksDescText}>{thanksDescText}</Text>
                                    </View>

                                    {/**
                                     Buy Row
                                     */}
                                    <View
                                        style={styles.buyRow}>

                                        {/**
                                         Quantity Area
                                         */}
                                        <View
                                            style={styles.qtyArea}>

                                            <ConditionalWrapper
                                                condition={cd.smallScreen}
                                                wrapper={children =>
                                                    <View
                                                        style={styles.qtyAreaMobile}
                                                    >{children}</View>
                                                }>

                                                <ConditionalWrapper
                                                    condition={cd.smallScreen}
                                                    wrapper={children =>
                                                        <View
                                                            style={styles.qtyInnerAreaMobile}
                                                        >{children}</View>
                                                    }>
                                                    {/**
                                                     Text: "Add"
                                                     */}
                                                    <Text
                                                        style={styles.qtyText2}
                                                        selectable={false}>Add</Text>

                                                    {/**
                                                     Quantity Dropdown
                                                     */}
                                                    <Hoverable
                                                        onHoverIn={() => {
                                                            this.setState({
                                                                qtyOptionCurHovered: true,
                                                                qtyBoxExpanded: true
                                                            });
                                                            setTimeout(() => {
                                                                this.setState({
                                                                    qtyOptionCurHovered: true,
                                                                    qtyBoxExpanded: true
                                                                });
                                                            }, 100);
                                                        }}
                                                        onHoverOut={() => {
                                                            if (cd.smallScreen) return;
                                                            setTimeout(() => {
                                                                if (this.state.qtyExpansionHovered) return;
                                                                this.setState({
                                                                    qtyOptionCurHovered: false,
                                                                    qtyOption1Hovered: false,
                                                                    qtyOption2Hovered: false,
                                                                    qtyOption3Hovered: false,
                                                                    qtyOption4Hovered: false,
                                                                });
                                                                this.setState({qtyBoxExpanded: false});
                                                            }, 100);
                                                        }}
                                                        onPressIn={() => this.setState({qtyOptionCurHovered: true})}
                                                        onPressOut={() => {
                                                            if (!cd.smallScreen && Platform.OS === 'web') return;
                                                            this.setState({qtyOptionCurHovered: false})
                                                        }}>
                                                        <Pressable
                                                            ref={this.qtyDropdownRef}
                                                            onLayout={() => {
                                                                setTimeout(() => {
                                                                    this.qtyDropdownRef.current?.measureInWindow((x, y, width, height) => {
                                                                        this.setState({
                                                                            qtyDropdownX: x,
                                                                            qtyDropdownY: y,
                                                                        })
                                                                    });
                                                                }, 100);
                                                            }}
                                                            onPress={() => {
                                                                // Expand/unexpand dropdown on click/tap.
                                                                this.setState({qtyBoxExpanded: !this.state.qtyBoxExpanded});
                                                            }}>
                                                            <View
                                                                style={[styles.qtyBoxContainer,
                                                                    this.state.qtyOptionCurHovered ? styles.qtyBoxContainerHovered : null]}>
                                                                <Text
                                                                    style={[styles.qtyOptionThanksText,
                                                                        this.state.qtyOptionCurHovered ? styles.qtyOptionTextHovered : null]}>
                                                                    {this.state.qtyOptionSelected === 0 ? option1ThanksText
                                                                        : (this.state.qtyOptionSelected === 1 ? option2ThanksText :
                                                                            (this.state.qtyOptionSelected === 2 ? option3ThanksText
                                                                                : option4ThanksText))}</Text>
                                                                <Text
                                                                    style={[styles.qtyOptionPriceText,
                                                                        this.state.qtyOptionCurHovered ? styles.qtyOptionTextHovered : null]}>
                                                                    {this.state.qtyOptionSelected === 0 ? option1PriceText
                                                                        : (this.state.qtyOptionSelected === 1 ? option2PriceText :
                                                                            (this.state.qtyOptionSelected === 2 ? option3PriceText
                                                                                : option4PriceText))}</Text>
                                                            </View>
                                                        </Pressable>
                                                    </Hoverable>
                                                </ConditionalWrapper>


                                                {/**
                                                 Text: "to my account."
                                                 */}
                                                <Text
                                                    style={styles.qtyText2}
                                                    selectable={false}>to my account.</Text>
                                            </ConditionalWrapper>
                                        </View>

                                        {/**
                                         Buy Button
                                         */}
                                        <Hoverable
                                            onHoverIn={() => {
                                                this.setState({buyBtnHovered: true})
                                            }}
                                            onHoverOut={() => {
                                                this.setState({buyBtnHovered: false})
                                            }}
                                            onPressIn={() => {
                                                this.setState({buyBtnHovered: true})
                                            }}
                                            onPressOut={() => setTimeout(() => {
                                                if (!cd.smallScreen && Platform.OS === 'web') return;
                                                this.setState({buyBtnHovered: false});
                                            }, 25)}
                                        >
                                            <Pressable
                                                style={styles.buyBtn}
                                                onPress={() => {
                                                    // TODO On web platform, pull up credit card options.
                                                    // TODO On iOS, pull up Apple Store payment.
                                                }}
                                            >
                                                <Text
                                                    style={styles.buyBtnText}
                                                    selectable={false}>Buy Now</Text>
                                            </Pressable>
                                        </Hoverable>
                                    </View>

                                    {/**
                                     "THANKs Available" Text
                                     */}
                                    <View
                                        style={styles.thanksAvailableArea}>
                                        <Text
                                            style={[styles.thanksStatsTitleText,
                                                {
                                                    fontWeight: "bold",
                                                    color: "#696b6e",
                                                }]}>{"THANKs Available:"}</Text>
                                        <Text
                                            style={styles.thanksStatsValueText}>{" " + 0}</Text>
                                    </View>

                                    {/**
                                     "THANKs Used" Stats on desktop
                                     */}
                                    {!cd.smallScreen &&
                                        <View
                                            style={styles.statsContainerDesktop}>

                                            {/**
                                             Stats Left Column
                                             */}
                                            <View
                                                style={[styles.vertMarginBig, styles.statsLeftColumn]}>

                                                {/**
                                                 "Total Given" Text
                                                 */}
                                                <View
                                                    style={styles.thanksGivenArea}>
                                                    <Text
                                                        style={styles.thanksStatsTitleText}>{"Total Given:"}</Text>
                                                    <Text
                                                        style={styles.thanksStatsValueText}>{" " + 0}</Text>
                                                </View>

                                                {/**
                                                 "Given Today" Text
                                                 */}
                                                <View
                                                    style={[styles.thanksGivenArea, styles.vertMarginSmall]}>
                                                    <Text
                                                        style={styles.thanksStatsTitleText}>{"Past Day:"}</Text>
                                                    <Text
                                                        style={styles.thanksStatsValueText}>{" " + 0}</Text>
                                                </View>
                                            </View>

                                            {/**
                                             Stats Right Column
                                             */}
                                            <View
                                                style={[styles.vertMarginBig, styles.statsRightColumn]}>

                                                {/**
                                                 "Given Automatically Total" Text
                                                 */}
                                                <View
                                                    style={styles.thanksGivenArea}>
                                                    <Text
                                                        style={styles.thanksStatsTitleText}>{"Given Automatically:"}</Text>
                                                    <Text
                                                        style={styles.thanksStatsValueText}>{" " + 0}</Text>
                                                </View>

                                                {/**
                                                 "Given Automatically Today" Text
                                                 */}
                                                <View
                                                    style={[styles.thanksGivenArea, styles.vertMarginSmall]}>
                                                    <Text
                                                        style={styles.thanksStatsTitleText}>{"Past Day:"}</Text>
                                                    <Text
                                                        style={styles.thanksStatsValueText}>{" " + 0}</Text>
                                                </View>
                                            </View>

                                        </View>
                                    }

                                    {/**
                                     "THANKs Used" Stats on mobile
                                     */}
                                    {cd.smallScreen &&
                                        <View
                                            style={styles.statsContainerMobile}>

                                            {/**
                                             "Total Given" Text
                                             */}
                                            <View
                                                style={[styles.thanksGivenArea, styles.thanksStatInsetSmall]}>
                                                <Text
                                                    style={styles.thanksStatsTitleText}>{"Total Given:"}</Text>
                                                <Text
                                                    style={styles.thanksStatsValueText}>{" " + 0}</Text>
                                            </View>

                                            {/**
                                             "Given Today" Text
                                             */}
                                            <View
                                                style={[styles.thanksGivenArea, styles.vertMarginSmall, styles.thanksStatInset]}>
                                                <Text
                                                    style={styles.thanksStatsTitleText}>{"Past Day:"}</Text>
                                                <Text
                                                    style={styles.thanksStatsValueText}>{" " + 0}</Text>
                                            </View>


                                            {/**
                                             "Given Automatically Total" Text
                                             */}
                                            <View
                                                style={[styles.thanksGivenArea, styles.vertMarginBig, styles.thanksStatInsetSmall]}>
                                                <Text
                                                    style={styles.thanksStatsTitleText}>{"Given Automatically:"}</Text>
                                                <Text
                                                    style={styles.thanksStatsValueText}>{" " + 0}</Text>
                                            </View>

                                            {/**
                                             "Given Automatically Today" Text
                                             */}
                                            <View
                                                style={[styles.thanksGivenArea, styles.vertMarginSmall, styles.thanksStatInset]}>
                                                <Text
                                                    style={styles.thanksStatsTitleText}>{"Past Day:"}</Text>
                                                <Text
                                                    style={styles.thanksStatsValueText}>{" " + 0}</Text>
                                            </View>

                                        </View>
                                    }

                                    {/**
                                     "Next Page" Area Placeholder
                                     */}
                                    {cd.smallScreen &&
                                        <View
                                            style={styles.nextAreaPlaceholder}/>
                                    }
                                </View>


                                {/**
                                 Second Page
                                 */}
                                <View
                                    style={styles.pageContainer}>

                                    {/**
                                     "Previous Page" Area Placeholder
                                     */}
                                    <View
                                        style={styles.prevAreaPlaceholder}/>

                                    {/**
                                     Second Page Title
                                     */}
                                    <View
                                        style={{
                                            width: cd.loginModalWidth,
                                            marginTop: verticalMargin,
                                        }}>
                                        <Text
                                            style={styles.pageTitleText}>Security</Text>
                                    </View>

                                    {/**
                                     Name Field
                                     */}
                                    <View
                                        style={styles.nameFieldArea}>
                                        <Text
                                            style={[styles.thanksStatInsetTiny,
                                                {
                                                    fontSize: securityFieldFontSize,
                                                    fontWeight: "bold",
                                                }]}>First Name: </Text>
                                        <Text
                                            style={{
                                                fontSize: securityFieldFontSize * 1.1,
                                            }}>Max</Text>
                                    </View>

                                    {/**
                                     Email Field
                                     */}
                                    <View
                                        style={styles.emailFieldArea}>
                                        <Text
                                            style={[styles.thanksStatInsetTiny,
                                                {
                                                    fontSize: securityFieldFontSize,
                                                    fontWeight: "bold",
                                                }]}>Email: </Text>
                                        <Text
                                            style={{
                                                width: cd.loginModalWidth * 0.7,
                                                fontSize: securityFieldFontSize * 1.1,
                                            }}>myemail@gmail.com</Text>
                                    </View>

                                    {/**
                                     "Change Password" Field
                                     */}
                                    {!this.state.changingPassword &&
                                        <View
                                            style={styles.changePassArea}>
                                            <Hoverable
                                                onHoverIn={() => {
                                                    this.setState({changePasswordHovered: true})
                                                }}
                                                onHoverOut={() => {
                                                    this.setState({changePasswordHovered: false})
                                                }}
                                                onPressIn={() => {
                                                    this.setState({changePasswordHovered: true})
                                                }}
                                                onPressOut={() => setTimeout(() => {
                                                    if (!cd.smallScreen && Platform.OS === 'web') return;
                                                    this.setState({changePasswordHovered: false});
                                                }, 25)}
                                            >
                                                <Pressable
                                                    onPress={() => {
                                                        this.setState({changingPassword: true});
                                                    }}>
                                                    <Text
                                                        style={styles.changePassText}>Change Password</Text>
                                                </Pressable>
                                            </Hoverable>
                                        </View>
                                    }

                                    {/**
                                     "Change Password" Options Area
                                     */}
                                    {this.state.changingPassword &&
                                        <View
                                            style={styles.changePassOptions}>

                                            {/**
                                             Error Text
                                             */}
                                            <View
                                                style={styles.errorArea}>
                                                <Text
                                                    style={[styles.thanksStatInsetTiny, styles.errorText]}>Error With
                                                    Your New Password</Text>
                                            </View>

                                            {/**
                                             Horizontal Container (fields on left; submit btn on right)
                                             */}
                                            <View
                                                style={styles.changePassOptionsInner}>

                                                {/**
                                                 Input Fields Container
                                                 */}
                                                <View
                                                    style={styles.changePassOptionsLeft}>

                                                    {/**
                                                     "New Password" Title
                                                     */}
                                                    <View
                                                        style={styles.newPassOptionsTitle}>
                                                        <Text
                                                            style={[styles.thanksStatInsetTiny, styles.newPassOptionsTitleText]}>New
                                                            Password</Text>
                                                    </View>

                                                    {/**
                                                     "New Password" Field
                                                     */}
                                                    <TextInput
                                                        secureTextEntry={true}
                                                        textContentType={"password"}
                                                        maxLength={32}
                                                        allowFontScaling={false}
                                                        autoCapitalize={"none"}
                                                        autoComplete={"off"}
                                                        clearButtonMode={"while-editing"}
                                                        underlineColorAndroid={"transparent"}
                                                        onChangeText={(input) => {
                                                            this.setState({newPassInput: input})
                                                        }}
                                                        value={this.state.newPassInput}
                                                        style={styles.newPassInput}>
                                                    </TextInput>

                                                    {/**
                                                     "Confirm" Title
                                                     */}
                                                    <View
                                                        style={styles.newPassOptionsConfirmTitle}>
                                                        <Text
                                                            style={[styles.thanksStatInsetTiny, styles.newPassOptionsConfirmTitleText]}>Confirm
                                                            New Password</Text>
                                                    </View>

                                                    {/**
                                                     "Confirm" Field
                                                     */}
                                                    <TextInput
                                                        secureTextEntry={true}
                                                        textContentType={"password"}
                                                        maxLength={32}
                                                        allowFontScaling={false}
                                                        autoCapitalize={"none"}
                                                        autoComplete={"off"}
                                                        clearButtonMode={"while-editing"}
                                                        underlineColorAndroid={"transparent"}
                                                        onChangeText={(input) => {
                                                            this.setState({confirmNewPassInput: input})
                                                        }}
                                                        value={this.state.confirmNewPassInput}
                                                        style={styles.newPassConfirmInput}>
                                                    </TextInput>
                                                </View>

                                                {/**
                                                 Cancel & Submit Btns
                                                 */}
                                                <View
                                                    style={styles.changePassOptionsRight}>

                                                    {/**
                                                     Cancel Button Container
                                                     */}
                                                    <Hoverable
                                                        onHoverIn={() => {
                                                            this.setState({cancelBtnHovered: true})
                                                        }}
                                                        onHoverOut={() => {
                                                            this.setState({cancelBtnHovered: false})
                                                        }}
                                                        onPressIn={() => {
                                                            this.setState({cancelBtnHovered: true})
                                                        }}
                                                        onPressOut={() => setTimeout(() => {
                                                            if (!cd.smallScreen && Platform.OS === 'web') return;
                                                            this.setState({cancelBtnHovered: false});
                                                        }, 25)}
                                                    >
                                                        <Pressable
                                                            onPress={() => {
                                                                this.setState({changingPassword: false});
                                                            }}
                                                            style={styles.newPassCancelBtn}>
                                                            <Text
                                                                style={styles.newPassCancelBtnText}>Cancel</Text>
                                                        </Pressable>
                                                    </Hoverable>

                                                    {/**
                                                     Submit Button Container
                                                     */}
                                                    <Hoverable
                                                        onHoverIn={() => {
                                                            this.setState({submitBtnHovered: true})
                                                        }}
                                                        onHoverOut={() => {
                                                            this.setState({submitBtnHovered: false})
                                                        }}
                                                        onPressIn={() => {
                                                            this.setState({submitBtnHovered: true})
                                                        }}
                                                        onPressOut={() => setTimeout(() => {
                                                            if (!cd.smallScreen && Platform.OS === 'web') return;
                                                            this.setState({submitBtnHovered: false});
                                                        }, 25)}
                                                    >
                                                        <Pressable
                                                            onPress={() => {
                                                                // TODO Change password on the backend.
                                                                this.setState({changingPassword: false});
                                                            }}
                                                            style={styles.newPassSubmitBtn}>
                                                            <Text
                                                                style={styles.newPassSubmitBtnText}>Submit</Text>
                                                        </Pressable>
                                                    </Hoverable>
                                                </View>

                                            </View>
                                        </View>
                                    }

                                    {/**
                                     "Delete Account" Button
                                     */}
                                    {!this.state.changingPassword &&
                                        <Hoverable
                                            onHoverIn={() => {
                                                this.setState({deleteAcctBtnHovered: true})
                                            }}
                                            onHoverOut={() => {
                                                this.setState({deleteAcctBtnHovered: false})
                                            }}
                                            onPressIn={() => {
                                                this.setState({deleteAcctBtnHovered: true})
                                            }}
                                            onPressOut={() => setTimeout(() => {
                                                if (!cd.smallScreen && Platform.OS === 'web') return;
                                                this.setState({deleteAcctBtnHovered: false});
                                            }, 25)}
                                        >
                                            <Pressable
                                                onPress={() => {
                                                    this.setState({deleteAcctModalShown: true});
                                                }}
                                                style={styles.deleteAcctBtn}>
                                                <Text
                                                    style={styles.deleteAcctBtnText}>Delete Account</Text>
                                            </Pressable>
                                        </Hoverable>
                                    }


                                    {/**
                                     "Next Page" Area Placeholder
                                     */}
                                    <View
                                        style={styles.nextAreaPlaceholder}/>
                                </View>

                                {/**
                                 Third Page
                                 */}
                                <View
                                    style={styles.pageContainer}>

                                    {/**
                                     "Previous Page" Area Placeholder
                                     */}
                                    <View
                                        style={styles.prevAreaPlaceholder}/>

                                    {/**
                                     Third Page Title
                                     */}
                                    <View
                                        style={{
                                            width: cd.loginModalWidth,
                                            marginTop: verticalMargin,
                                        }}>
                                        <Text
                                            style={styles.pageTitleText}>Content Creator</Text>
                                    </View>


                                    {/**
                                     Creator Container
                                     */}
                                    <View
                                        style={{
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                        }}>
                                        {/**
                                         Content for Non-Creators
                                         */}
                                        {!this.state.appliedForContentCreator && !this.state.isContentCreator &&
                                            <>
                                                {/**
                                                 Creator Application Description
                                                 */}
                                                <View
                                                    style={styles.thanksDescArea}>
                                                    <Text
                                                        style={{
                                                            paddingLeft: cd.loginModalWidth * 0.02,
                                                            paddingRight: cd.loginModalWidth * 0.02,
                                                            fontWeight: "bold",
                                                            fontSize: pageTitleFontSize * 0.65,
                                                            textAlign: "center",
                                                        }}>{contentCreatorApplicationText1}<Text
                                                        style={{fontWeight: "normal"}}>{contentCreatorApplicationText2}</Text></Text>
                                                </View>


                                                {/**
                                                 "Tell Us About Yourself" Title
                                                 */}
                                                <Text
                                                    style={[styles.newPassOptionsTitleText,
                                                        {
                                                            marginTop: verticalMargin,
                                                            marginLeft: cd.smallScreen ? (cd.loginModalWidth * (1 - 0.92)) * 0.5 : (cd.loginModalWidth * (1 - 0.88)) * 0.5,
                                                        }]}>About Yourself
                                                    (Optional)</Text>

                                                {/**
                                                 "Tell Us About Yourself" Field
                                                 */}
                                                <TextInput
                                                    multiline={true}
                                                    maxLength={350}
                                                    allowFontScaling={false}
                                                    autoCapitalize={"none"}
                                                    autoComplete={"off"}
                                                    clearButtonMode={"while-editing"}
                                                    underlineColorAndroid={"transparent"}
                                                    onChangeText={(input) => {
                                                        this.setState({creatorAppInput: input})
                                                    }}
                                                    value={this.state.creatorAppInput}
                                                    style={styles.creatorAppInput}>
                                                </TextInput>

                                                {/**
                                                 Submit Button
                                                 */}
                                                <Hoverable
                                                    onHoverIn={() => {
                                                        this.setState({creatorAppSubmitHovered: true})
                                                    }}
                                                    onHoverOut={() => {
                                                        this.setState({creatorAppSubmitHovered: false})
                                                    }}
                                                    onPressIn={() => {
                                                        this.setState({creatorAppSubmitHovered: true})
                                                    }}
                                                    onPressOut={() => setTimeout(() => {
                                                        if (!cd.smallScreen && Platform.OS === 'web') return;
                                                        this.setState({creatorAppSubmitHovered: false});
                                                    }, 25)}
                                                >
                                                    <Pressable
                                                        onPress={() => {
                                                            // TODO Submit application to backend.
                                                            // TODO Update user settings in backend: appliedForContentCreator -> true

                                                            this.setState({appliedForContentCreator: true});
                                                            setTimeout(() => this.setState({isContentCreator: true}), 2000);
                                                        }}
                                                        style={[styles.creatorAppSubmitBtn,
                                                            {
                                                                marginTop: verticalMargin * 1.2,
                                                            }]}>
                                                        <Text
                                                            style={styles.creatorAppSubmitBtnText}>Submit</Text>
                                                    </Pressable>
                                                </Hoverable>
                                            </>
                                        }

                                        {/**
                                         Content for Creator App Submitted
                                         */}
                                        {this.state.appliedForContentCreator && !this.state.isContentCreator &&
                                            <>
                                                {/**
                                                 "Application Submitted" Description
                                                 */}
                                                <View
                                                    style={styles.thanksDescArea}>
                                                    <Text
                                                        style={{
                                                            paddingTop: verticalMargin,
                                                            paddingLeft: cd.loginModalWidth * 0.02,
                                                            paddingRight: cd.loginModalWidth * 0.02,
                                                            fontSize: pageTitleFontSize * 0.75,
                                                            textAlign: "center",
                                                        }}>{creatorApplicationSubmittedText}</Text>
                                                </View>
                                            </>
                                        }

                                        {/**
                                         Content for Creators
                                         */}
                                        {this.state.isContentCreator &&
                                            <>
                                                {/**
                                                 Quoter Description
                                                 */}
                                                <View
                                                    style={styles.thanksDescArea}>
                                                    <Text
                                                        style={{
                                                            paddingTop: verticalMargin,
                                                            paddingLeft: cd.loginModalWidth * 0.03,
                                                            paddingRight: cd.loginModalWidth * 0.03,
                                                            fontSize: pageTitleFontSize * 0.75,
                                                            textAlign: "center",
                                                        }}>{creatorDescriptionText}</Text>
                                                </View>

                                                {/**
                                                 "Launch Panel" Button
                                                 */}
                                                <Hoverable
                                                    onHoverIn={() => {
                                                        this.setState({launchBtnHovered: true})
                                                    }}
                                                    onHoverOut={() => {
                                                        this.setState({launchBtnHovered: false})
                                                    }}
                                                    onPressIn={() => {
                                                        this.setState({launchBtnHovered: true})
                                                    }}
                                                    onPressOut={() => setTimeout(() => {
                                                        if (!cd.smallScreen && Platform.OS === 'web') return;
                                                        this.setState({launchBtnHovered: false});
                                                    }, 25)}
                                                >
                                                    <Pressable
                                                        onPress={() => {
                                                            // TODO Redirect to "/creator" page.
                                                        }}
                                                        style={[styles.launchPanelBtn,
                                                            {
                                                                marginTop: verticalMargin * 1.2,
                                                            }]}>
                                                        <Text
                                                            style={styles.launchPanelBtnText}>Open Creator's
                                                            Panel</Text>
                                                    </Pressable>
                                                </Hoverable>
                                            </>
                                        }
                                    </View>
                                </View>
                            </ScrollView>
                        </>
                    }

                    {/**
                     Previous Page Button (free-floating)
                     */}
                    {this.state.pageNumber > 0 && !this.state.deleteAcctModalShown &&
                        <Hoverable
                            onHoverIn={() => {
                                this.setState({prevBtnHovered: true})
                            }}
                            onHoverOut={() => {
                                this.setState({prevBtnHovered: false})
                            }}
                            onPressIn={() => {
                                this.setState({prevBtnHovered: true})
                            }}
                            onPressOut={() => setTimeout(() => {
                                if (!cd.smallScreen && Platform.OS === 'web') return;
                                this.setState({prevBtnHovered: false});
                            }, 25)}
                        >
                            <Pressable
                                style={styles.prevBtnArea}
                                onPress={() => {
                                    // Scroll to the previous page.
                                    this.contentScrollRef.current?.scrollTo({
                                        y: contentHeight * (this.state.pageNumber - 1),
                                        animated: false,
                                    });
                                }}>
                                <View
                                    style={styles.prevBtn}>
                                    <Image
                                        source={up_arrow}
                                        style={{
                                            width: navBtnWidth * 0.4,
                                            height: navBtnWidth * 0.4,
                                        }}/>
                                </View>
                            </Pressable>
                        </Hoverable>
                    }

                    {/**
                     Next Page Button (free-floating)
                     */}
                    {this.state.pageNumber < 2 && !this.state.deleteAcctModalShown &&
                        <Hoverable
                            onHoverIn={() => {
                                this.setState({nextBtnHovered: true})
                            }}
                            onHoverOut={() => {
                                this.setState({nextBtnHovered: false})
                            }}
                            onPressIn={() => {
                                this.setState({nextBtnHovered: true})
                            }}
                            onPressOut={() => setTimeout(() => {
                                if (!cd.smallScreen && Platform.OS === 'web') return;
                                this.setState({nextBtnHovered: false});
                            }, 25)}
                        >
                            <Pressable
                                style={styles.nextBtnArea}
                                onPress={() => {
                                    // Scroll to the next page.
                                    this.contentScrollRef.current?.scrollTo({
                                        y: contentHeight + (this.state.pageNumber + 1),
                                        animated: false,
                                    });
                                    if (this.state.pageNumber === 1) {
                                        this.contentScrollRef.current?.scrollTo({
                                            y: contentHeight * 2,
                                            animated: false,
                                        });
                                    }
                                }}>
                                <View
                                    style={styles.nextBtn}>
                                    <Image
                                        source={up_arrow}
                                        style={{
                                            transform: [{rotate: "180deg"}],
                                            width: navBtnWidth * 0.4,
                                            height: navBtnWidth * 0.4,
                                        }}/>
                                </View>
                            </Pressable>
                        </Hoverable>
                    }

                    {/**
                     Bar below Settings Title (free-floating)
                     */}
                    {!this.state.deleteAcctModalShown &&
                        <View
                            style={styles.mainDividerBar}>
                        </View>
                    }
                </View>


                {/**
                 "Close" Button
                 */}
                <SquishyButton
                    isSmallScreen={cd.smallScreen}
                    pressFunc={this.onClose}
                    isHovered={this.state.closeBtnHovered}
                    setHoveredFunc={(newBool) => this.setState({closeBtnHovered: newBool})}
                    containerStyle={styles.closeBtnContainer}
                    buttonStyle={styles.closeBtnPressable}>
                    <LinearGradient
                        colors={this.state.closeBtnHovered && !cd.smallScreen ?
                            ["#fafcfcfc", "#fafcfcfc",] :
                            ["#fcfffffc", "#f0f4f7", "#fafcfcfc"]
                        }
                        style={styles.closeBtnGradient}>
                        <Text style={styles.closeBtnText}>{closeText}</Text>
                    </LinearGradient>
                </SquishyButton>

                {/**
                 Qty Dropdown Expansion Area
                 */}
                {this.state.qtyBoxExpanded &&

                    <>
                        {/**
                         Gray Screen Behind Qty Popup
                         */}
                        <Pressable
                            onPress={() =>
                                this.setState({
                                    qtyBoxExpanded: false,
                                    qtyOption1Hovered: false,
                                    qtyOption2Hovered: false,
                                    qtyOption3Hovered: false,
                                    qtyOption4Hovered: false,
                                })}
                            isTVSelectable={false}
                            style={styles.qtyDropdownTapOutArea}>
                        </Pressable>

                        {/**
                         Qty Options
                         */}
                        <Hoverable
                            onHoverIn={() => this.setState({qtyExpansionHovered: true})}
                            onHoverOut={() => {
                                if (cd.smallScreen) return;
                                this.setState({
                                    qtyExpansionHovered: false,
                                    qtyBoxExpanded: false,
                                    qtyOptionCurHovered: false,
                                });
                            }}>
                            <View
                                // @ts-ignore
                                style={styles.qtyAreaFixed}>


                                {/**
                                 Qty Option 1
                                 */}
                                <Hoverable
                                    onHoverIn={() => this.setState({qtyOption1Hovered: true})}
                                    onHoverOut={() => this.setState({qtyOption1Hovered: false})}
                                    onPressIn={() => this.setState({qtyOption1Hovered: true})}
                                    onPressOut={() => {
                                        if (!cd.smallScreen && Platform.OS === 'web') return;
                                        this.setState({qtyOption1Hovered: false})
                                    }}>
                                    <Pressable
                                        onPress={() => {
                                            // Expand/unexpand dropdown on click/tap.
                                            this.setState({qtyBoxExpanded: !this.state.qtyBoxExpanded});

                                            // Select the option if another was previously selected.
                                            if (this.state.qtyOptionSelected !== 0) {
                                                this.setState({
                                                    qtyBoxExpanded: false,
                                                    qtyOptionSelected: 0
                                                });
                                            }

                                            // Unhover elements.
                                            setTimeout(() => this.setState({
                                                qtyOptionCurHovered: false,
                                                qtyOption1Hovered: false,
                                                qtyOption2Hovered: false,
                                                qtyOption3Hovered: false,
                                                qtyOption4Hovered: false,
                                            }), 1);
                                        }}>
                                        <View
                                            style={[styles.qtyBoxContainer,
                                                this.state.qtyOption1Hovered ? styles.qtyBoxContainerHovered : null]}>
                                            <Text
                                                style={[styles.qtyOptionThanksText,
                                                    this.state.qtyOption1Hovered ? styles.qtyOptionTextHovered : null]}>{option1ThanksText}</Text>
                                            <Text
                                                style={[styles.qtyOptionPriceText,
                                                    this.state.qtyOption1Hovered ? styles.qtyOptionTextHovered : null]}>{option1PriceText}</Text>
                                        </View>
                                    </Pressable>
                                </Hoverable>

                                {/**
                                 Qty Option 2
                                 */}
                                <Hoverable
                                    onHoverIn={() => this.setState({qtyOption2Hovered: true})}
                                    onHoverOut={() => this.setState({qtyOption2Hovered: false})}
                                    onPressIn={() => this.setState({qtyOption2Hovered: true})}
                                    onPressOut={() => {
                                        if (!cd.smallScreen && Platform.OS === 'web') return;
                                        this.setState({qtyOption2Hovered: false})
                                    }}>
                                    <Pressable
                                        onPress={() => {
                                            // Expand/unexpand dropdown on click/tap.
                                            this.setState({qtyBoxExpanded: !this.state.qtyBoxExpanded});

                                            // Select the option if another was previously selected.
                                            if (this.state.qtyOptionSelected !== 1) {
                                                this.setState({
                                                    qtyBoxExpanded: false,
                                                    qtyOptionSelected: 1,
                                                });
                                            }

                                            // Unhover elements.
                                            setTimeout(() => this.setState({
                                                qtyOptionCurHovered: false,
                                                qtyOption1Hovered: false,
                                                qtyOption2Hovered: false,
                                                qtyOption3Hovered: false,
                                                qtyOption4Hovered: false,
                                            }), 1);
                                        }}>
                                        <View
                                            style={[styles.qtyBoxContainer,
                                                this.state.qtyOption2Hovered ? styles.qtyBoxContainerHovered : null]}>
                                            <Text
                                                style={[styles.qtyOptionThanksText,
                                                    this.state.qtyOption2Hovered ? styles.qtyOptionTextHovered : null]}>{option2ThanksText}</Text>
                                            <Text
                                                style={[styles.qtyOptionPriceText,
                                                    this.state.qtyOption2Hovered ? styles.qtyOptionTextHovered : null]}>{option2PriceText}</Text>
                                        </View>
                                    </Pressable>
                                </Hoverable>

                                {/**
                                 Qty Option 3
                                 */}
                                <Hoverable
                                    onHoverIn={() => this.setState({qtyOption3Hovered: true})}
                                    onHoverOut={() => this.setState({qtyOption3Hovered: false})}
                                    onPressIn={() => this.setState({qtyOption3Hovered: true})}
                                    onPressOut={() => {
                                        if (!cd.smallScreen && Platform.OS === 'web') return;
                                        this.setState({qtyOption3Hovered: false})
                                    }}>
                                    <Pressable
                                        onPress={() => {
                                            // Expand/unexpand dropdown on click/tap.
                                            this.setState({qtyBoxExpanded: !this.state.qtyBoxExpanded});

                                            // Select the option if another was previously selected.
                                            if (this.state.qtyOptionSelected !== 2) {
                                                this.setState({
                                                    qtyBoxExpanded: false,
                                                    qtyOptionSelected: 2
                                                });
                                            }

                                            // Unhover elements.
                                            setTimeout(() => this.setState({
                                                qtyOptionCurHovered: false,
                                                qtyOption1Hovered: false,
                                                qtyOption2Hovered: false,
                                                qtyOption3Hovered: false,
                                                qtyOption4Hovered: false,
                                            }), 1);
                                        }}>
                                        <View
                                            style={[styles.qtyBoxContainer,
                                                this.state.qtyOption3Hovered ? styles.qtyBoxContainerHovered : null]}>
                                            <Text
                                                style={[styles.qtyOptionThanksText,
                                                    this.state.qtyOption3Hovered ? styles.qtyOptionTextHovered : null]}>{option3ThanksText}</Text>
                                            <Text
                                                style={[styles.qtyOptionPriceText,
                                                    this.state.qtyOption3Hovered ? styles.qtyOptionTextHovered : null]}>{option3PriceText}</Text>
                                        </View>
                                    </Pressable>
                                </Hoverable>

                                {/**
                                 Qty Option 4
                                 */}
                                <Hoverable
                                    onHoverIn={() => this.setState({qtyOption4Hovered: true})}
                                    onHoverOut={() => this.setState({qtyOption4Hovered: false})}
                                    onPressIn={() => this.setState({qtyOption4Hovered: true})}
                                    onPressOut={() => {
                                        if (!cd.smallScreen && Platform.OS === 'web') return;
                                        this.setState({qtyOption4Hovered: false})
                                    }}>
                                    <Pressable
                                        onPress={() => {
                                            // Expand/unexpand dropdown on click/tap.
                                            this.setState({qtyBoxExpanded: !this.state.qtyBoxExpanded});

                                            // Select the option if another was previously selected.
                                            if (this.state.qtyOptionSelected !== 3) {
                                                this.setState({
                                                    qtyBoxExpanded: false,
                                                    qtyOptionSelected: 3
                                                });
                                            }

                                            // Unhover elements.
                                            setTimeout(() => this.setState({
                                                qtyOptionCurHovered: false,
                                                qtyOption1Hovered: false,
                                                qtyOption2Hovered: false,
                                                qtyOption3Hovered: false,
                                                qtyOption4Hovered: false,
                                            }), 1);
                                        }}>
                                        <View
                                            style={[styles.qtyBoxContainer,
                                                this.state.qtyOption4Hovered ? styles.qtyBoxContainerHovered : null]}>
                                            <Text
                                                style={[styles.qtyOptionThanksText,
                                                    this.state.qtyOption4Hovered ? styles.qtyOptionTextHovered : null]}>{option4ThanksText}</Text>
                                            <Text
                                                style={[styles.qtyOptionPriceText,
                                                    this.state.qtyOption4Hovered ? styles.qtyOptionTextHovered : null]}>{option4PriceText}</Text>
                                        </View>
                                    </Pressable>
                                </Hoverable>
                            </View>
                        </Hoverable>
                    </>
                }

            </View>
        </Modal>
    }
}
