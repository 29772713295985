import {registerRootComponent} from 'expo';
import React, {Component} from 'react';
import {Dimensions, Platform, StatusBar, Text} from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import {RobotoCondensed_400Regular, RobotoCondensed_700Bold} from '@expo-google-fonts/roboto-condensed';
import {Montserrat_400Regular, Montserrat_500Medium} from '@expo-google-fonts/montserrat';
import {NothingYouCouldDo_400Regular} from '@expo-google-fonts/nothing-you-could-do';
import * as Font from "expo-font";
import {Easing, FadeIn, FadeOut} from 'react-native-reanimated';
import {LinearGradient} from "expo-linear-gradient";
import * as Linking from 'expo-linking';
import {CompDimensions} from "./util/Util";
import {SafeAreaProvider, SafeAreaView} from "react-native-safe-area-context";
import {DeviceType, getDeviceTypeAsync} from "expo-device";
// Must import gesture handler in Main.
import 'react-native-gesture-handler';
import {authenticateUser, getNewTokens, httpGet, registerUser} from "./util/ApiUtil";
import {HomeScreen} from "./components/home/HomeScreen";
import {createNavigationContainerRef, NavigationContainer} from "@react-navigation/native";
import {AboutScreen} from "./components/about/AboutScreen";
import {FeedbackScreen} from "./components/feedback/FeedbackScreen";
import {createStackNavigator, TransitionPresets} from "@react-navigation/stack";

const iosScrollNormal = 0.996;
const iosScrollFast = 0.99;
const androidScrollNormal = 0.97;
const androidScrollFast = 0.9;

export const headerFadeIn = FadeIn.duration(200).easing(Easing.quad);
export const headerFadeOut = FadeOut.duration(300).easing(Easing.exp);

export const mainDarkColor = "#97bdde";
export const mainLightColor = "#b3cde3";

export const headerBtnColor = "#9fc1e0";
export const headerBtnHoveredColor = "#9cbddb";

export default class Main extends Component {
    state = {
        appInitialized: false,
        dims: null,
        mobileBrowser: false,
        horizontalMobileBrowser: false,
        pageMargin: 0,
        fontsLoaded: false,
        screenWidth: 0,
        screenHeight: 0,
        statusBarColor: mainDarkColor,
        initialScrollDownMobileBrowser: false,
        mobileBrowserScrollHandleTimer: null,
        browserName: null,
        authenticated: false,
        authTokens: null,
        timeLastAuthenticated: Math.round(new Date().getTime() / 1000),
    }

    navigationRef;

    constructor(props) {
        super(props);

        // Create ref to stack navigator.
        this.navigationRef = createNavigationContainerRef();

        // Bind functions to this.
        this.updateDimensions = this.updateDimensions.bind(this);
        this.authenticateUserWrapper = this.authenticateUserWrapper.bind(this);
        this.registerUserWrapper = this.registerUserWrapper.bind(this);
        this._handleOpenURL = this._handleOpenURL.bind(this);
    }

    // Define function to authenticate user.
    async authenticateUserWrapper(email: string, password: string): Promise<boolean | string> {
        /**
         * Returns true or error string.
         * If successful, auth token is stored in Main component state.
         */

        // Validate email and password.
        if (email.length <= 5 || !email.includes('@') || email.split('@').length !== 2 || !email.split('@')[1].includes('.')) {
            return 'Invalid email';
        } else if (password.length < 3) {
            return 'Invalid password'
        }

        // Call backend API.
        const authTokens = await authenticateUser(email, password);

        // Handle unexpected error (probably a network issue).
        if (authTokens == null) {
            console.log('Unexpected error response authenticating user: ' + authTokens);
            return 'Error signing in';
        }

        // Handle expected error message to show the user.
        else if (typeof authTokens === typeof '') {
            this.setState({
                authenticated: false,
                authTokens: null,
            });
            return authTokens as string;
        }

        // Handle receiving valid auth tokens.
        else {
            this.setState({
                authenticated: true,
                authTokens: authTokens,
                timeLastAuthenticated: Math.round(new Date().getTime() / 1000),
            });
            return true;
        }
    }

    // Define function to register a new user.
    async registerUserWrapper(email: string, password: string, nickname: string): Promise<boolean | string> {
        /**
         * Returns true or error string.
         * If successful, auth token is stored in Main component state.
         */
        // Validate parameters.
        if (email.length <= 5 || !email.includes('@') || email.split('@').length !== 2 || !email.split('@')[1].includes('.')) {
            return 'Invalid email';
        } else if (password.length < 3) {
            return 'Invalid password';
        } else if (nickname.length < 2) {
            return 'Invalid First Name / Nickname';
        }

        // Call backend API.
        const registrationResponse = await registerUser(email, password, nickname);

        // Handle unexpected error (probably a network issue).
        if (registrationResponse == null) {
            console.log('Unexpected error response registering user: ' + registrationResponse);
            return 'Server error. Please try again later.';
        }

        // Handle expected error message to show the user.
        else if (typeof registrationResponse === typeof '') {
            return registrationResponse;
        }

        // Handle successful registration.
        return true;
    }

    async _handleOpenURL(event) {
        /**
         * Changes state based on data in the link.
         */

        const url = event.url;
        const {hostname, path, queryParams} = Linking.parse(url);

        // Validate the url.
        console.log('Handling Linking link: ' + url);
        if (path === null || path.trim() === '' || path.trim() === '/') return;
        console.log('Path:  "' + path + '"');
        const components = path.split('/');


        // If "/about", open about page.
        if (path.toLowerCase().startsWith('about')) {
            setTimeout(() => {
                if (this.navigationRef.isReady()) {
                    // @ts-ignore
                    this.navigationRef.navigate('About');
                    this.setState({statusBarColor: "#282a2e"});
                }
            }, 500);

            return;
        }

        // If "/feedback", open about page.
        if (path.toLowerCase().startsWith('feedback')) {
            setTimeout(() => {
                if (this.navigationRef.isReady()) {
                    // @ts-ignore
                    this.navigationRef.navigate('Feedback');
                    this.setState({statusBarColor: "#282a2e"});
                }
            }, 500);

            return;
        }

        // If verifying email, use verification code to get new auth tokens.
        if (path.toLowerCase().startsWith('verify_email') && components.length >= 3) {
            console.log('Using Linking to call verify_email endpoint.');

            const email = components[1];
            const verificationCode = components[2];

            // Mark email as verified in the backend.
            const response = await httpGet('verify_email', {
                email: email,
                verification_code: verificationCode,
            });
            console.log('verify_email response: ');
            console.log(response);

            if (response === null || response.status !== 201) {
                // TODO Show alert saying email verification code invalid.
                if (Platform.OS === 'web') {
                    window.open('https://hayaquotes.com', "_self");
                }
                return;
            }

            // Get auth tokens now that our temp code is valid.
            await this._checkAuthTokensAsync();

            // Redirect to home page.
            if (Platform.OS === 'web') {
                window.open('https://hayaquotes.com', "_self");
            }
            return;
        }

        // TODO If quote requested, load the quote.
        if (path.toLowerCase().startsWith('quote') && path.split('/').length >= 2) {
            const quote_id = path.split('/')[1];
            // TODO Try to load the quote using the API.

            // TODO If quote unavailable, show alert.

            return;
        }

        // If invalid link, redirect to homepage and show alert.
        console.log('redirecting for path: "' + path + '"');
        if (Platform.OS === 'web') {
            //window.open('https://hayaquotes.com' , "_self");
        }
        // TODO Show "not found" alert.
    }

    // Define function to load fonts.
    async _loadFontsAsync() {
        if (this.state.fontsLoaded) return;
        await SplashScreen.preventAutoHideAsync();
        await Font.loadAsync({
            //"Montserrat-Bold": require("../assets/fonts/Montserrat-Bold.ttf"),
            RobotoCondensed_400Regular,
            RobotoCondensed_700Bold,
            Montserrat_400Regular,
            Montserrat_500Medium,
            NothingYouCouldDo_400Regular,
        }).then(() => {
            this.setState({fontsLoaded: true});
            setTimeout(() => SplashScreen.hideAsync(), 400);
        });
    }

    // Define function to check auth tokens on page (re)load.
    async _checkAuthTokensAsync() {
        if (!this.state.authenticated) {
            const authTokens = await getNewTokens();
            if (authTokens !== null) {
                this.setState({
                    authenticated: true,
                    authTokens: authTokens
                });
            }
        }
    }

    // Update dimension state vars.
    updateDimensions(width: number, height: number) {

        // Update layout only if a fullscreen horizontal video is not playing on mobile.
        let dims = new CompDimensions(width, height);
        // @ts-ignore
        if (Platform.OS === 'web' || (Platform.OS !== 'web' && width < height)) {
            this.setState({dims: dims});
        }

        // Get device type.
        getDeviceTypeAsync().then((deviceType) => {
            const deviceTypeMap = {
                [DeviceType.UNKNOWN]: "unknown",
                [DeviceType.PHONE]: "phone",
                [DeviceType.TABLET]: "tablet",
                [DeviceType.DESKTOP]: "desktop",
                [DeviceType.TV]: "tv",
            };

            // Check if viewing horizontally in browser on phone or tablet.
            const deviceTypeString = deviceTypeMap[deviceType];
            const mobileBrowser = (deviceTypeString === 'phone' || deviceTypeString === 'tablet') && Platform.OS === 'web';
            const horizontalMobileBrowser = !dims.smallScreen && mobileBrowser;
            this.setState({
                mobileBrowser: mobileBrowser,
                horizontalMobileBrowser: horizontalMobileBrowser,
            });

            // If mobile, check browser type and account for different address bar behaviors.
            if (Platform.OS === 'web' && (deviceTypeString === 'phone' || deviceTypeString === 'tablet') && !this.state.browserName) {
                let browserName = (function (agent) {
                    switch (true) {
                        case agent.indexOf("edge") > -1:
                            return "edge";
                        case agent.indexOf("edg") > -1:
                            return "chromium based edge (dev or canary)";
                        // @ts-ignore
                        case agent.indexOf("opr") > -1 && !!window.opr:
                            return "opera";
                        // @ts-ignore
                        case agent.indexOf("chrome") > -1 && !!window.chrome:
                            return "chrome";
                        case agent.indexOf("trident") > -1:
                            return "ie";
                        case agent.indexOf("firefox") > -1:
                            return "firefox";
                        case agent.indexOf("safari") > -1:
                            return "safari";
                        default:
                            return "other";
                    }
                })(window.navigator.userAgent.toLowerCase());
                this.setState({browserName: browserName});
            }
        });
    }

    // Update screen dimensions when they change.
    onChange = ({window, screen}) => {
        let height = window.height;
        if (this.state.browserName === 'safari') {
            //height = Math.min(window.width * 2.5, window.height);
        } else if (this.state.browserName === 'chrome') {
            //height = Math.min(window.width * 2.5, window.height);
        }
        if (this.state.mobileBrowser && (this.state.browserName === 'safari' || this.state.browserName === 'chrome')) {
            height = Math.max(height, window.width * 2.05);
        }
        this.updateDimensions(window.width, height);
    };

    // Respond to component initializing.
    componentDidMount() {

        // Load fonts
        if (!this.state.fontsLoaded) {
            this._loadFontsAsync();
        }

        // Get screen dimensions.
        let screenDimensions = Dimensions.get('window');
        this.updateDimensions(screenDimensions.width, screenDimensions.height);

        // Load auth tokens if necessary and possible and if the tokens are valid.
        this._checkAuthTokensAsync();

        // Listen for the next screen resize event.
        Dimensions.addEventListener("change", this.onChange);

        // Handle opening the app/website from a link containing instructions.
        if (!this.state.appInitialized) {
            this.setState({appInitialized: true});
            Linking.getInitialURL()
                .then(url => {
                    if (url) {
                        this._handleOpenURL({url: url});
                    }
                })
                .catch(err => {
                    // TODO Show alert: "Action could not be completed."
                    console.log('Error handling initial link: ');
                    console.error(err);
                });
        }
        // TODO Linking.addEventListener('url', this._handleOpenURL);
    }

    componentWillUnmount() {
        Dimensions.removeEventListener("change", this.onChange);
        // TODO Linking.removeEventListener('url', this._handleOpenURL);
    }

    render() {

        // Show loading screen while fonts load.
        if (!this.state.fontsLoaded) {
            return null;
        }

        // Calculate dimensions of each component.
        const cd = this.state.dims;

        // Show the home screen.
        return (
            <>
                <StatusBar
                    barStyle={"light-content"}/>

                <SafeAreaProvider>
                    {/**
                     * Status Bar
                     */}
                    <SafeAreaView
                        edges={["top"]}
                        style={{
                            flex: 0,
                            //opacity: 0.7,
                            backgroundColor: this.state.statusBarColor,
                        }}>
                    </SafeAreaView>

                    {/**
                     No Content when viewing horizontally on phone browser
                     */}
                    {this.state.horizontalMobileBrowser &&
                        <LinearGradient
                            colors={["#faf0f07a", "#faf0fa76", "#f0fafa8c", "#f7faf97b", "#fafdff7e"]}
                            start={[1, 0]}
                            end={[0, 1.4]}
                            style={{
                                width: cd.screenWidth,
                                height: cd.screenHeight,
                                flexDirection: "row",
                                justifyContent: "center",
                            }}>
                            <Text
                                style={{
                                    marginTop: cd.screenHeight * 0.3,
                                    fontSize: 0.5 * Math.sqrt((cd.screenWidth * cd.screenHeight) / "Rotate Your Screen".length),
                                    fontWeight: "bold",
                                    color: "#58585e",
                                }}>Rotate Your Screen</Text>
                        </LinearGradient>
                    }

                    {/**
                     Screens
                     */}
                    <NavigationContainer
                        ref={this.navigationRef}>
                        <Stack.Navigator
                            initialRouteName="Home"
                            screenOptions={({route, navigation}) => ({
                                headerShown: false,
                            })}>

                            {/**
                             About Screen
                             */}
                            <Stack.Screen
                                name="About"
                                options={{
                                    ...TransitionPresets.SlideFromRightIOS,
                                    gestureDirection: 'horizontal-inverted',
                                }}
                                listeners={{
                                    beforeRemove: (e) => {
                                        this.setState({statusBarColor: mainDarkColor});
                                    },
                                    state: (e) => {
                                        console.log(e);
                                        // @ts-ignore
                                        if (e.data.state.routes.length == 2 && e.data.state.routes[1].name == 'About') {
                                            this.setState({statusBarColor: "#282a2e"});
                                        }
                                    }
                                }}>
                                {(props) => <AboutScreen
                                    {...props}
                                    dims={cd}/>}</Stack.Screen>

                            {/**
                             Home Screen
                             */}
                            <Stack.Screen
                                name="Home">
                                {(props) => <HomeScreen
                                    {...props}
                                    dims={cd}
                                    horizontalMobileBrowser={this.state.horizontalMobileBrowser}
                                    authenticated={this.state.authenticated}
                                    authenticateUserWrapper={this.authenticateUserWrapper}
                                    registerUserWrapper={this.registerUserWrapper}/>}</Stack.Screen>

                            {/**
                             Feedback Screen
                             */}
                            <Stack.Screen
                                name="Feedback"
                                options={{
                                    ...TransitionPresets.SlideFromRightIOS,
                                }}
                                listeners={{
                                    beforeRemove: (e) => {
                                        this.setState({statusBarColor: mainDarkColor});
                                    },
                                    state: (e) => {
                                        console.log(e);
                                        // @ts-ignore
                                        if (e.data.state.routes.length == 2 && e.data.state.routes[1].name == 'Feedback') {
                                            this.setState({statusBarColor: "#282a2e"});
                                        }
                                    }
                                }}>
                                {(props) => <FeedbackScreen
                                    {...props}
                                    dims={cd}/>}</Stack.Screen>
                        </Stack.Navigator>
                    </NavigationContainer>

                </SafeAreaProvider>
            </>
        );
    }
}

const Stack = createStackNavigator();

// Register main class with Expo.
registerRootComponent(Main);

