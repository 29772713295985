import {Platform, Pressable, StyleSheet} from "react-native";
import "react-native-reanimated";
import Hoverable from "./Hoverable";
import {useState} from "react";
import Animated, {EasingNode} from "react-native-reanimated";
import {createSafeAnimatedValue} from "../../util/PatchedAnimatedValue";

interface SquishyButtonProps {
    isSmallScreen: boolean;
    pressFunc: any;
    isHovered: boolean,
    setHoveredFunc: any,
    scaleTo?: number;
    squishInDuration?: number;
    squishOutDelay?: number;
    squishOutDuration?: number;
    pressFuncDelay?: number;
    unhoverDelay?: number;
    animationDuration?: number;
    squishInEasing?: any;
    squishOutEasing?: any;
    containerStyle?: any,
    buttonStyle?: any,
    containerOtherProps?: any,
    buttonOtherProps?: any,
}

const defaultScaleTo = 0.95;
const defaultSquishInDuration = 150;
const defaultSquishOutDelay = 100;
const defaultSquishOutDuration = 100;
const defaultPressFuncDelayMobile = 200;
const defaultPressFuncDelayDesktop = 10;
const defaultUnhoverDelay = 75;
const defaultSquishInEasing = EasingNode.exp;
const defaultSquishOutEasing = EasingNode.ease;
const defaultStyles = StyleSheet.create({
    defaultContainerStyle: {
        width: 300,
        height: 300,
        backgroundColor: "red"
    },
    defaultButtonStyle: {
        flex: 1,
    },
});
const defaultContainerOtherProps = {};
const defaultButtonOtherProps = {};

const SquishyButton: React.FunctionComponent<SquishyButtonProps> = props => {

    // Use default props where needed.
    const {
        isSmallScreen,
        pressFunc,
        isHovered,
        setHoveredFunc,
        scaleTo = defaultScaleTo,
        squishInDuration = defaultSquishInDuration,
        squishOutDelay = defaultSquishOutDelay,
        squishOutDuration = defaultSquishOutDuration,
        pressFuncDelay = props.isSmallScreen ? defaultPressFuncDelayMobile : defaultPressFuncDelayDesktop,
        unhoverDelay = defaultUnhoverDelay,
        squishInEasing = defaultSquishInEasing,
        squishOutEasing = defaultSquishOutEasing,
        containerStyle = defaultStyles.defaultContainerStyle,
        buttonStyle = defaultStyles.defaultButtonStyle,
        containerOtherProps = defaultContainerOtherProps,
        buttonOtherProps = defaultButtonOtherProps,
    } = props;

    // Create state vars for animation.
    let scaleValFloat = createSafeAnimatedValue(1.0);
    const [scaleVal, setScaleVal] = useState(scaleValFloat);
    let isAnimatingBool = false;
    const [isAnimating, setIsAnimating] = useState(isAnimatingBool);

    return (
        /**
         Squishy button container.
         */
        <Animated.View
            {...containerOtherProps}
            style={[
                containerStyle,
                isSmallScreen ? {transform: [{scale: scaleVal}]} : null,
            ]}>

            {/**
             Hover detection.
             */}
            <Hoverable
                onHoverIn={() => setHoveredFunc(true)}
                onHoverOut={() => setHoveredFunc(false)}
                onPressIn={() => {
                    setHoveredFunc(true);
                    if (!isSmallScreen || isAnimating) return;
                    setIsAnimating(true);
                    Animated.timing(scaleVal, {
                        toValue: scaleTo,
                        duration: squishInDuration,
                        easing: EasingNode.in(squishInEasing),
                    }).start(() => {
                        setTimeout(() => {
                            Animated.timing(scaleVal, {
                                toValue: 1,
                                duration: squishOutDuration,
                                easing: EasingNode.in(squishOutEasing),
                            }).start(() => setIsAnimating(false));
                        }, squishOutDelay);

                    });
                }}
                onPressOut={() => setTimeout(() => {
                    if (!isSmallScreen && Platform.OS === 'web') return;
                    setHoveredFunc(false)
                }, unhoverDelay)}>

                {/**
                 Pressable button.
                 */}
                <Pressable
                    {...buttonOtherProps}
                    onPress={() => setTimeout(() => pressFunc(), pressFuncDelay)}
                    style={buttonStyle}>{props.children}
                </Pressable>
            </Hoverable>
        </Animated.View>
    );
};

export default SquishyButton;