import {Platform, Pressable} from "react-native";
import "react-native-reanimated";
import Hoverable from "../reusable/Hoverable";
import {useState} from "react";
import {LinearGradient} from "expo-linear-gradient";
import {CompDimensions} from "../../util/Util";
import Animated from "react-native-reanimated";
import {headerFadeIn, headerFadeOut, mainDarkColor, mainLightColor} from "../../Main";
// @ts-ignore
import settings_icon from '../../../assets/settings_icon.png';

export function SettingsBtn(props: {
    dims: CompDimensions,
    yOffset: number,
    onPress: any,
}) {

    // Get component dimensions.
    const cd = props.dims;

    // Calculate font size.
    const fontSize = 0.9 * Math.sqrt(cd.loginBtnWidth * cd.loginBtnHeight / "Log In".length);

    // Create state var for hover effect.
    let isHoveredBool = false;
    const [isHovered, setIsHovered] = useState(isHoveredBool);

    return (

        <>
            {/**
             Settings button shadow.
             */}
            <Animated.View
                entering={headerFadeIn}
                exiting={headerFadeOut}
                style={[
                    // @ts-ignore
                    {
                        position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
                        // transform: [{scale: isHovered ? Platform.OS !== 'web' ? 1.03 : 1.02 : 1}],
                        width: cd.loginBtnWidth + cd.loginBtnWidth * 0.02,
                        height: cd.smallScreen ? cd.loginBtnHeight * 0.57 : cd.loginBtnHeight * 0.59,
                        marginLeft: ((cd.screenWidth - cd.loginBtnWidth) * 0.5) - cd.loginBtnWidth * 0.01,
                        marginTop: (cd.smallScreen  ? cd.loginBtnHeight * 0.46 : cd.loginBtnHeight * 0.43) + (cd.smallScreen
                            ? cd.loginBtnTopMargin - props.yOffset - (props.yOffset > 0 ? cd.loginBtnHeight * 0.3 : 0)
                            : cd.loginBtnTopMargin),
                        overflow: "hidden",
                        borderRadius: cd.loginBtnBorderRadius * 1.2,
                        backgroundColor: "#e3f1fcb9",
                    }]}/>
            {/**
         Settings button container.
         */}
        <Animated.View
            entering={headerFadeIn}
            exiting={headerFadeOut}
            style={[
                // @ts-ignore
                {
                    position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
                    // transform: [{scale: isHovered ? Platform.OS !== 'web' ? 1.03 : 1.02 : 1}],
                    width: cd.loginBtnWidth,
                    height: cd.loginBtnHeight,
                    marginLeft: (cd.screenWidth - cd.loginBtnWidth) * 0.5,
                    marginTop: cd.smallScreen
                        ? cd.loginBtnTopMargin - props.yOffset - (props.yOffset > 0 ? cd.loginBtnHeight * 0.3 : 0)
                        : cd.loginBtnTopMargin,
                    overflow: "hidden",
                    borderRadius: cd.loginBtnBorderRadius,
                }]}>

            <Hoverable
                onHoverIn={() => setIsHovered(true)}
                onHoverOut={() => setIsHovered(false)}
                onPressIn={() => setIsHovered(true)}
                onPressOut={() => setTimeout(() => {
                    if (!cd.smallScreen && Platform.OS === 'web') return;
                    setIsHovered(false)
                }, 75)}>
                <Pressable
                    onPress={() => setTimeout(() => props.onPress(cd), 175)}
                    style={{
                        width: cd.loginBtnWidth,
                        height: cd.loginBtnHeight,
                    }}>
                    <LinearGradient
                        /**
                        colors={isHovered ?
                            ["#ff2508", "#ff2508"]
                            : ["#f72020d4", "#f72020d4",]}
                        **/
                        colors={isHovered ?
                            ["#fac96e", "#fac96e", "#fcd181", "#fecf79", "#fac96e"] :
                            ["#fcd181", "#fecf79", "#fcd181", "#fcd181", "#fecf79"]}
                        style={[{
                            width: cd.loginBtnWidth,
                            height: cd.loginBtnHeight,
                            //marginLeft: "auto",
                            //marginRight: "auto",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: cd.loginBtnBorderRadius * 1.2,
                            borderWidth: cd.smallScreen ? cd.loginBtnHeight * 0.06 : cd.loginBtnHeight * 0.08,
                            borderColor: mainDarkColor,
                        }]}
                    >
                        <Animated.Image
                            entering={headerFadeIn}
                            exiting={headerFadeOut}
                            source={settings_icon}
                            style={{
                                width: (cd.loginBtnWidth * 0.8) * (378 / 602),
                                height: cd.loginBtnHeight * 0.8,
                            }}/>
                    </LinearGradient>
                </Pressable>
            </Hoverable>
        </Animated.View>
            </>
    );
}
