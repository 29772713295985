// Template for the "Scroll for More" button.

import {Platform, View} from "react-native";
import {LinearGradient} from 'expo-linear-gradient';
import {CompDimensions} from "../../util/Util";
import Animated, {Easing, FadeIn, FadeOut} from 'react-native-reanimated';


export function MoreButton(props: {
    dims: CompDimensions,
    areaWidth: any,
    areaHeight: any,
    loadingMoreCards: boolean,
}) {
    // Get cardArea dimensions.
    const cd = props.dims;
    // @ts-ignore
    const areaWidth = props.areaWidth;
    // @ts-ignore
    const areaHeight = props.areaHeight;

    // Calculate font size.
    const textWidth = areaWidth * 0.2;
    const textHeight = areaHeight * 0.9;
    // @ts-ignore
    let textFontSize = (cd.smallScreen ? 1.6 : 1.4) * Math.sqrt(textWidth * textHeight / "Scroll for More...".length);

    return (
        <View
            style={[
                {
                    width: areaWidth,
                    height: areaHeight,
                    //marginTop: "auto",
                    // @ts-ignore
                    cursor: "default",
                }]}>

            {/** @ts-ignore **/}
            <LinearGradient
                colors={[cd.smallScreen ? "#e6ebed00" : "#f7f9fa63", cd.smallScreen ? "#e5f3ff71" : "#e5f3ff71", cd.smallScreen ? "#e6edf2" : "#e6edf2",]}
                style={[{
                    width: areaWidth,
                    height: areaHeight,
                }]}
            >
                {!(props.loadingMoreCards && cd.smallScreen) &&
                    <Animated.Text
                        entering={FadeIn.duration(700).easing(Easing.exp)}
                        exiting={FadeOut.duration(200).easing(Easing.linear)}
                        style={{
                            marginTop: cd.smallScreen ? "auto" : null,
                            marginBottom: "auto",
                            textAlign: "center",
                            color: "#9babbad5",
                            fontSize: textFontSize,
                            fontWeight: "bold",
                            textShadowColor: "white",
                            textShadowRadius: cd.smallScreen ? areaHeight * 0.019 : areaHeight * 0.05,
                        }}
                        selectable={false}>{Platform.OS === 'web' ? "Scroll for More..." : "Swipe for More"}</Animated.Text>
                }
            </LinearGradient>
        </View>
    );
}
