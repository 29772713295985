// Template for the search button (search icon on top-left).

import {Platform, Pressable, StyleSheet, Text, View} from "react-native";
import Hoverable from "../reusable/Hoverable";
import {CompDimensions} from "../../util/Util";
// @ts-ignore
import search_icon from '../../../assets/search_icon.png';
import {Easing, FadeIn, useSharedValue} from "react-native-reanimated";
import {useState} from "react";
import {headerBtnColor} from "../../Main";

export function AboutBtnArea(props: {
    dims: CompDimensions,
    onPress: any,
}) {

    // Get and calculate component dimensions.
    const cd = props.dims;
    const aboutBtnFontSize = cd.smallScreen ? cd.aboutBtnAreaHeight * 0.57 : cd.aboutBtnAreaHeight * 0.48;

    // Create state vars.
    const [isHovered, setIsHovered] = useState(false);
    const borderRadius = useSharedValue(cd.headerSideBtnsWidth * 0.5);

    // Create reusable styles.
    const styles = StyleSheet.create({
        btnContainerArea: {
            // @ts-ignore
            position: cd.smallScreen ? "relative" : "absolute",
            width: cd.smallScreen ? cd.aboutBtnAreaWidth : cd.aboutBtnAreaWidth - cd.headerSideBtnsWidth * 1.2,
            height: cd.aboutBtnAreaHeight,
            marginTop: cd.smallScreen ? 0 : cd.aboutBtnAreaTopMargin,
            borderTopRightRadius: cd.loginBtnBorderRadius,
            borderBottomRightRadius: cd.loginBtnBorderRadius,
            backgroundColor: cd.smallScreen ? "#e6eaed" : headerBtnColor,
            shadowOpacity: cd.smallScreen ? 0.2 : 0,
            shadowColor: "#e1e9ed",
            shadowRadius: cd.aboutBtnAreaHeight * 0.06,
            shadowOffset: {width: 0, height: cd.aboutBtnAreaHeight * 0.005}
        },
        btnContainer: {
            marginLeft: cd.smallScreen ? "auto" : cd.searchBtnLeftMargin * 2,
            marginRight: cd.smallScreen ? "auto" : "auto",
            marginTop: "auto",
            marginBottom: "auto",
            justifyContent: "center",
            alignItems: "center",
        },
        aboutBtnText: {
            fontSize: aboutBtnFontSize,
            color: cd.smallScreen ? "#191a1c" : "#f7fafc",
            textDecorationLine: isHovered ? "underline" : "none",
        },
        hoveredDesktop: {
            //transform: [{scale: isHovered ? (cd.smallScreen ? 1.02 : 1.09) : 1}],
        },
    });

    return (
        <View
            style={styles.btnContainerArea}>
            <Hoverable
                onHoverIn={() => {
                    setIsHovered(true);
                }}
                onHoverOut={() => {
                    setIsHovered(false);
                }}
                onPressIn={() => {
                    setIsHovered(true);
                }}
                onPressOut={() => {
                    setTimeout(() => {
                        if (!cd.smallScreen && Platform.OS === 'web') return;
                        setIsHovered(false);
                    }, 75);
                }}
            >
                <Pressable
                    style={styles.btnContainer}
                    //hitSlop={{bottom: cd.headerSideBtnsHeight * 0.5, right: cd.headerSideBtnsWidth * 0.5}}
                    onPress={() => setTimeout(() => props.onPress(), 175)}>
                    <Text
                        style={[styles.aboutBtnText,
                            isHovered ? styles.hoveredDesktop : null]}>About Haya</Text>
                </Pressable>
            </Hoverable>
        </View>);
}
