import {Image, Platform, Pressable, View} from "react-native";
import {CompDimensions} from "../../util/Util";
import React, {Component} from "react";
import {SafeAreaView} from "react-native-safe-area-context";
import Hoverable from "../reusable/Hoverable";
// @ts-ignore
import up_arrow from "../../../assets/up_arrow_long.png";
const bgColor = '#282a2e';

export class FeedbackScreen extends Component<{
    dims: CompDimensions,
}> {
    state = {
        closeBtnHovered: false,
    }

    constructor(props) {
        super(props);
    }

    render() {

        // Calculate dimensions of each component.
        const cd = this.props.dims;
        const mobileBrowserExtraHeight = 400;
        const closeBtnHeight = cd.smallScreen ? cd.screenWidth * 0.1 : cd.screenWidth * 0.03;
        const closeBtnFontSize = Math.sqrt((closeBtnHeight * closeBtnHeight) / 2);

        // Show the home screen.
        return (
            /**
             Show Content otherwise
             */
            <>
                {/**
                 Page Container
                 **/}
                <SafeAreaView
                    edges={["left", "right",]}
                    style={[(cd.smallScreen && Platform.OS === 'web')
                        ? {
                            width: cd.screenWidth,
                            height: cd.screenHeight + mobileBrowserExtraHeight,
                            flexDirection: "column",
                        }
                        : {
                            flex: 1,
                            flexDirection: "column",
                        }, Platform.OS !== 'web' ? {} : null]}>
                    <View
                        style={{
                            flex: 1,
                            backgroundColor: bgColor,
                        }}>

                        {/**
                         Close Button
                         **/}
                        <View
                            style={{
                                width: cd.screenWidth,
                                height: closeBtnHeight,
                                marginTop: closeBtnHeight * 0.5,
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}>
                            <Hoverable
                                onHoverIn={() => {
                                    this.setState({closeBtnHovered: true})
                                }}
                                onHoverOut={() => {
                                    this.setState({closeBtnHovered: false})
                                }}
                                onPressIn={() => {
                                    this.setState({closeBtnHovered: true})
                                }}
                                onPressOut={() => setTimeout(() => {
                                    if (Platform.OS === 'web') return;
                                    this.setState({closeBtnHovered: false});
                                }, 25)}
                            >
                                <Pressable
                                    style={{
                                        marginLeft: closeBtnHeight * 0.65,
                                    }}
                                    onPress={() => {
                                        // @ts-ignore
                                        this.props.navigation.navigate('Home');
                                    }}
                                >
                                    <Image
                                        source={up_arrow}
                                        style={{
                                            transform: [{rotate: "270deg"}],
                                            width: closeBtnFontSize * (422/538),
                                            height: closeBtnFontSize,
                                            // Mobile browsers are chaotic with tinting, so they must be hardcoded with results of trial-and-error.
                                            tintColor: (Platform.OS === 'web' && cd.smallScreen)
                                                ? this.state.closeBtnHovered ? "#fafafa" : "#b5b5b5"
                                                : this.state.closeBtnHovered ? null : (!cd.smallScreen ? "#b5b5b5" : "#b5b5b5"),
                                        }}/>
                                </Pressable>
                            </Hoverable>
                        </View>
                    </View>

                </SafeAreaView>
            </>
        );
    }
}