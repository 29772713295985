import React, {Component} from "react";
import {Image, Modal, Platform, Pressable, ScrollView, StyleSheet, Text, View} from "react-native";
import Hoverable from "../../reusable/Hoverable";
import {LinearGradient} from "expo-linear-gradient";
import {CompDimensions, lightenHex} from "../../../util/Util";
// @ts-ignore
import close_icon from "../../../../assets/close_icon.png";
import {CatSearch} from "../../../types/CatSearch";
import {SavedSearchEntry} from "./SavedSearchEntry";
// @ts-ignore
import up_arrow from "../../../../assets/up_arrow.png";
import SquishyButton from "../../reusable/SquishyButton";

const noContentText = "Bookmark a Quote to Save It Here"
const defaultSearchTags = ['Popular', 'YouTube'];
const fillerSearchTags1 = ['YouTube', 'Science', 'Education', 'Scams', 'Motivation', 'Elon Musk'];
const fillerSearchTags2 = ['Popular', 'Twitter', 'YouTube', 'Celebrities', 'Politics', 'Comedy & Satire'];

export class SavedModal extends Component<{
    dims: CompDimensions,
    yOffset: number,
    visible: boolean,
    onClose: any,
}> {

    state = {
        closeBtnHovered: false,
        quotesSelected: true,
        searchesSelected: false,
        savedQuotes: [],
        savedSearches: [],
        selectedSearchHash: null,
        // Default sorting options for: date added, specificity, personalization.
        searchFilterDirections: ['descending', 'ascending', 'descending'],
        searchFilterSelected: 0,
        searchFilterIconHovered: -1,
        searchFilterTitleHovered: -1,
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.state.savedSearches.length === 0) {
            this.setState({
                savedSearches: [
                    new CatSearch(defaultSearchTags, 0),
                    new CatSearch(fillerSearchTags1, 9),
                    new CatSearch(fillerSearchTags2, 2),
                    new CatSearch(fillerSearchTags2, 7),
                    new CatSearch(fillerSearchTags2, 2),
                    new CatSearch(fillerSearchTags2, 5),
                ]
            });
        }
    }

    render() {

        // Get and calculate component dimensions.
        const cd = this.props.dims;
        const modalContainerBorderWidth = cd.loginBtnBorderRadius * 0.5;
        const containerWidth = cd.savedModalWidth - modalContainerBorderWidth * 2;
        const containerHeight = cd.savedModalHeight - modalContainerBorderWidth * 2;
        const headerWidth = cd.smallScreen ? containerWidth * 0.8 : containerWidth * 0.45;
        const headerHeight = cd.smallScreen ? headerWidth * 0.14 : headerWidth * 0.13;
        const headerTopMargin = headerHeight * 0.6;
        const headerHorizontalPadding = headerWidth * 0.03;
        const headerBorderRadius = headerHeight * 0.4;
        const scrollContentWidth = cd.smallScreen ? containerWidth : containerWidth;
        const scrollContentHeight = containerHeight - headerTopMargin - headerHeight * 2;
        const entrySoftHeight = cd.smallScreen ? containerHeight * 0.23 : containerHeight * 0.26;
        const scrollContainerLeftBorderWidth = modalContainerBorderWidth * 3;
        const entryRightBorderWidth = modalContainerBorderWidth * 0.7;
        const entryTopBottomBorderWidth = entrySoftHeight * 0.015;
        const entryFilterTopMargin = cd.smallScreen ? headerTopMargin * 1.1 : headerTopMargin * 0.8;
        const entryFilterBottomMargin = headerTopMargin * 0.1;
        const entryBottomMargin = entryFilterTopMargin;
        const filterSideMargins = entryTopBottomBorderWidth * 5;

        // Define font sizes.
        const headerFontSize = (cd.smallScreen ? 0.75 : 0.7) * Math.sqrt((headerWidth * headerHeight) / "Saved Quotes Saved Searches".length);
        const noContentFontSize = Math.sqrt(((cd.smallScreen ? containerWidth * 1.2 : containerWidth * 0.75) * headerHeight) / noContentText.length);
        const entryFilterFontSize = Math.sqrt((scrollContentWidth * headerTopMargin * (cd.smallScreen ? 0.3 : 0.15)) / "Filter Content Length".length);

        // Dependant vars.
        const filterIconWidth = entryFilterFontSize * 1.07;
        const filterIconRightMargin = filterIconWidth * 0.4;

        // Define color theme.
        const whitishColor = "#f2f7fa";
        const grayColor = "#e8eaeb"
        const lightColor = lightenHex("#a5d2fa", 13);
        const darkColor = "#9ccbf7";
        const contentBgColor = "#f5fafc";

        // Create reusable styles.
        const styles = StyleSheet.create({
            mainContainer: {
                width: containerWidth,
                height: containerHeight,
                backgroundColor: contentBgColor,
                flexDirection: "column",
            },
            headerOuter: {
                position: "absolute",
                width: headerWidth,
                height: headerHeight,
                marginTop: headerTopMargin,
                marginLeft: (containerWidth - headerWidth) * 0.5,
                backgroundColor: "#98abb8f3",
                borderRadius: headerBorderRadius,
                flexDirection: "row",
                justifyContent: "space-between",
            },
            headerTextContainer: {
                height: headerHeight,
                width: headerWidth * 0.5,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: headerBorderRadius,
            },
            headerTextContainerSelected: {
                backgroundColor: "#9bd2fa",
            },
            headerText: {
                fontSize: headerFontSize,
                fontWeight: "bold",
                color: "white",
            },
            scrollContentContainerOuter: {
                width: scrollContentWidth,
                height: Platform.OS === 'web' ? scrollContentHeight : null,
                marginTop: "auto",
                marginLeft: "auto",
                marginRight: "auto",
                borderLeftWidth: scrollContainerLeftBorderWidth,
                borderLeftColor: lightColor,
                shadowOpacity: cd.smallScreen ? 0.6 : 0.85,
                shadowColor: "#ebf6fc",
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? -containerWidth * 0.03 : -containerWidth * 0.02,
                },
                shadowRadius: cd.smallScreen ? containerWidth * 0.05 : containerWidth * 0.03,
                backgroundColor: "#ebf6fc",
            },
            scrollContentInner: {
                width: scrollContentWidth,
                height: scrollContentHeight,
                backgroundColor: "#ebf6fc",
            },
            scrollContentContainer: {
                width: scrollContentWidth,
                height: scrollContentHeight,
                flexDirection: "column",
                justifyContent: "flex-start",
                backgroundColor: "#ebf6fc",
            },
            noContentQuotes: {
                width: scrollContentWidth - scrollContainerLeftBorderWidth,
                marginTop: entryFilterTopMargin,
                flexDirection: "row",
                justifyContent: "center",
            },
            noContentQuotesText: {
                textAlign: "center",
                fontSize: noContentFontSize,
                color: "#353738ec",
            },
            filterTitlesContainer: {
                position: "absolute",
                width: "100%",
                marginBottom: "auto",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fafbfc",
                shadowColor: "#f7f7f7",
                shadowRadius: cd.smallScreen ? entryFilterFontSize * 1.4 : entryFilterFontSize * 0.3,
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? entryFilterFontSize * 0.5 : entryFilterFontSize * 0.1,
                },
            },
            filterBlock: {
                flex: 1 / 3,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: cd.smallScreen ? entryFilterFontSize * 1.4 : entryFilterFontSize * 1.2,
                marginBottom: cd.smallScreen ? entryFilterFontSize * 1.1 : entryFilterFontSize,
                marginRight: filterIconWidth + filterIconRightMargin,
            },
            filterBlockColumn: {
                marginBottom: entryFilterBottomMargin,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            filterIcon: {
                width: filterIconWidth,
                height: filterIconWidth,
                marginRight: filterIconRightMargin,
                // Mobile browsers are chaotic with tinting, so they must be hardcoded with results of trial-and-error.
                tintColor: (Platform.OS === 'web' && cd.smallScreen)
                    ? "#4c6b7d"
                    : (!cd.smallScreen ? "#4c6b7d" : "#4c6b7d"),
            },
            filterIconHovered: {
                tintColor: (Platform.OS === 'web' && cd.smallScreen)
                    ? "#5187a6"
                    : (!cd.smallScreen ? "#5187a6" : "#5187a6"),
            },
            filterIconInvisible: {
                height: 0,
            },
            filterTitleText: {
                fontSize: entryFilterFontSize * 1.04,
                fontWeight: "bold",
                color: "#3d3d3d",
            },
            filterTitleTextHovered: {
                textDecorationLine: "underline",
                color: "#1b1c1c",
            },
            closeBtnContainer: {
                position: "absolute",
                width: cd.headerSideBtnsWidth,
                height: cd.smallScreen ? cd.headerSideBtnsHeight * 1.01 : cd.headerSideBtnsHeight,
                marginTop: cd.smallScreen
                    ? cd.statusBarHeight + cd.headerSideBtnsTopMargin - this.props.yOffset
                    : cd.appBorderWidth + cd.headerSideBtnsTopMargin,
                marginLeft: (cd.smallScreen ? cd.savedBtnLeftMargin : cd.desktopSavedBtnLeftMargin),
                borderRadius: cd.headerSideBtnsWidth * 0.5,
                shadowColor: "#bbc3c9",
                shadowRadius: cd.smallScreen ? cd.headerSideBtnsWidth * 0.04 : cd.headerSideBtnsWidth * 0.03,
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? -cd.headerSideBtnsWidth * 0.001 : 0,
                },
            },
            closeBtnPressable: {
                width: cd.headerSideBtnsWidth,
                height: cd.headerSideBtnsHeight,
            },
            closeBtnGradient: {
                width: cd.headerSideBtnsWidth,
                height: cd.headerSideBtnsHeight,
                borderRadius: cd.headerSideBtnsWidth * 0.5,
                borderColor: "#f0f4f7",
                borderWidth: modalContainerBorderWidth,
                justifyContent: "center",
                alignItems: "center",
            },
            closeBtnImg: {
                width: cd.headerSideBtnsWidth * 0.7,
                height: cd.headerSideBtnsHeight * 0.7,
                borderRadius: cd.headerSideBtnsWidth * 0.5,
                // Mobile browsers are chaotic with tinting, so they must be hardcoded with results of trial-and-error.
                tintColor: (Platform.OS === 'web' && cd.smallScreen)
                    ? "#f7f8fa0f"
                    : (cd.smallScreen ? "#646566d9" : (this.state.closeBtnHovered ?  "#0a375750" : "#fcfcfc0d")),
            },
        });


        return <Modal animationType={cd.smallScreen ? "none" : "none"}
                      onDismiss={this.props.onClose}
                      onRequestClose={this.props.onClose}
                      transparent={true}
                      visible={this.props.visible}>
            <View>

                {/**
                 Gray Screen Content Behind Modal
                 */}
                <Pressable
                    onPress={() => {
                        this.setState({selectedSearchHash: null});
                        this.props.onClose();
                    }}
                    isTVSelectable={false}
                    // @ts-ignore
                    style={{
                        position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
                        width: cd.screenWidth,
                        height: cd.screenHeight,
                        backgroundColor: "rgba(192,194,207,0.94)",
                        // @ts-ignore
                        cursor: "default",
                    }}>
                </Pressable>


                {/**
                 Saved Modal with Border
                 */}
                <View nativeID={"savedModalContainer"}
                      style={{
                          width: cd.savedModalWidth,
                          height: cd.savedModalHeight,
                          marginTop: cd.smallScreen
                              ? cd.statusBarHeight + cd.headerSideBtnsTopMargin + (cd.headerSideBtnsHeight * 0.4) - this.props.yOffset
                              : cd.appBorderWidth + cd.headerSideBtnsTopMargin + (cd.headerSideBtnsHeight * 0.4),// + (cd.smallScreen ? 0 : cd.appBorderWidth),
                          marginLeft: cd.smallScreen
                              ? cd.searchBtnLeftMargin + cd.headerSideBtnsWidth * 0.4
                              : cd.desktopSavedBtnLeftMargin - cd.searchModalWidth + cd.headerSideBtnsWidth * 0.6,
                          backgroundColor: "#fcfffffc",
                          borderColor: "#e4edf293",
                          borderWidth: modalContainerBorderWidth,
                          borderRadius: cd.loginBtnBorderRadius,
                          shadowColor: "#e4edf293",
                          shadowRadius: cd.smallScreen ? cd.savedModalWidth * 0.1 : cd.savedModalWidth * 0.01,
                          elevation: 5,
                          overflow: "hidden",
                      }}>

                    {/**
                     Saved Modal Inner Container
                     */}
                    <View style={styles.mainContainer}>


                        {/**
                         Scrollable Content
                         */}
                        <View
                            style={{
                                width: scrollContentWidth,
                                height: scrollContentHeight,
                                marginTop: "auto",
                                backgroundColor: contentBgColor,
                                borderLeftWidth: scrollContainerLeftBorderWidth,
                                borderLeftColor: lightColor,
                                shadowOpacity: cd.smallScreen ? 0.6 : 0.85,
                                shadowColor: "#ebf6fc",
                                shadowOffset: {
                                    width: 0,
                                    height: cd.smallScreen ? -containerWidth * 0.03 : -containerWidth * 0.02,
                                },
                                shadowRadius: cd.smallScreen ? containerWidth * 0.05 : containerWidth * 0.03,
                            }}>
                            <ScrollView
                                style={{
                                    //width: scrollContentWidth,
                                    //height: scrollContentHeight,
                                    //marginTop: "auto",
                                    flex: 1,
                                }}
                                contentContainerStyle={{
                                    width: scrollContentWidth - scrollContainerLeftBorderWidth,
                                    //height: scrollContentHeight,
                                    //marginTop: "auto",
                                    //marginLeft: "auto",
                                    //marginRight: "auto",
                                    //alignSelf: "flex-end",
                                    backgroundColor: contentBgColor,
                                    flexDirection: "column",
                                }}
                                scrollEventThrottle={100}
                                bounces={false}
                                showsVerticalScrollIndicator={false}>

                                {/**
                                 "No content" for Quotes
                                 */}
                                {this.state.quotesSelected && this.state.savedQuotes.length === 0 &&
                                    <View
                                        style={styles.noContentQuotes}>
                                        <Text
                                            style={styles.noContentQuotesText}
                                            selectable={false}>{noContentText}</Text>
                                    </View>
                                }

                                {/**
                                 TODO Saved Quote Entries
                                 */}

                                {/**
                                 TODO "New Search" Button
                                 */}

                                {/**
                                 Saved Search Entries
                                 */}
                                {this.state.searchesSelected &&
                                    this.state.savedSearches.map((catSearch, idx) => (
                                        <SavedSearchEntry
                                            key={catSearch.hash}
                                            filterDirections={this.state.searchFilterDirections}
                                            filterSelected={this.state.searchFilterSelected}
                                            catSearch={catSearch}
                                            containerWidth={containerWidth}
                                            scrollContentWidth={scrollContentWidth}
                                            scrollContainerLeftBorderWidth={scrollContainerLeftBorderWidth}
                                            entryFilterTopMargin={idx == 0 ? entryFilterFontSize * 2.3 + entryFilterTopMargin + entryBottomMargin : entryFilterTopMargin}
                                            entryFilterBottomMargin={entryFilterBottomMargin}
                                            entryFilterFontSize={entryFilterFontSize}
                                            entrySoftHeight={entrySoftHeight}
                                            entryBottomMargin={entryBottomMargin}
                                            entryTopBottomBorderWidth={entryTopBottomBorderWidth}
                                            entryRightBorderWidth={entryRightBorderWidth}
                                            filterIconWidth={filterIconWidth}
                                            filterIconRightMargin={filterIconRightMargin}
                                            filterSideMargins={filterSideMargins}
                                            dims={cd}/>
                                    ))
                                }
                            </ScrollView>

                            {/**
                             Search Filter Titles Row
                             */}
                            {this.state.searchesSelected &&
                                <View
                                    style={styles.filterTitlesContainer}>

                                    {/**
                                     Left Search Filter Area
                                     */}
                                    <View
                                        style={styles.filterBlock}>

                                        {/**
                                         Left Search Filter Icon Column
                                         */}
                                        <Hoverable
                                            onHoverIn={() => {
                                                if (this.state.searchFilterSelected !== 0) return;
                                                this.setState({searchFilterIconHovered: 0})
                                            }}
                                            onHoverOut={() => {
                                                this.setState({searchFilterIconHovered: -1})
                                            }}
                                            onPressIn={() => {
                                                if (this.state.searchFilterSelected !== 0) return;
                                                this.setState({searchFilterIconHovered: 0})
                                            }}
                                            onPressOut={() => setTimeout(() => {
                                                if (Platform.OS === 'web') return;
                                                this.setState({searchFilterIconHovered: -1});
                                            }, 25)}>
                                            <Pressable
                                                onPress={() => {
                                                    // TODO Call props function to switch ascending and descending.
                                                }}
                                                style={styles.filterBlockColumn}>
                                                {this.state.searchFilterDirections[0] === 'ascending' &&
                                                    // @ts-ignore
                                                    <Image
                                                        style={[styles.filterIcon,
                                                            this.state.searchFilterIconHovered == 0 ? {transform: [{rotate: '180deg'}]} : null,
                                                            this.state.searchFilterIconHovered == 0 ? styles.filterIconHovered : null,
                                                            this.state.searchFilterSelected !== 0 ? styles.filterIconInvisible : null,
                                                        ]}
                                                        source={up_arrow}/>
                                                }
                                                {this.state.searchFilterDirections[0] === 'descending' &&
                                                    // @ts-ignore
                                                    <Image
                                                        style={[styles.filterIcon,
                                                            this.state.searchFilterIconHovered != 0 ? {transform: [{rotate: '180deg'}]} : null,
                                                            this.state.searchFilterIconHovered == 0 ? styles.filterIconHovered : null,
                                                            this.state.searchFilterSelected !== 0 ? styles.filterIconInvisible : null,
                                                        ]}
                                                        source={up_arrow}/>
                                                }
                                            </Pressable>
                                        </Hoverable>

                                        {/**
                                         Left Search Filter Title Column
                                         */}
                                        <View
                                            style={styles.filterBlockColumn}>
                                            <Hoverable
                                                onHoverIn={() => {
                                                    this.setState({searchFilterTitleHovered: 0})
                                                }}
                                                onHoverOut={() => {
                                                    this.setState({searchFilterTitleHovered: -1})
                                                }}
                                                onPressIn={() => {
                                                    this.setState({searchFilterTitleHovered: 0})
                                                }}
                                                onPressOut={() => setTimeout(() => {
                                                    if (Platform.OS === 'web') return;
                                                    this.setState({searchFilterTitleHovered: -1});
                                                }, 25)}>
                                                <Pressable
                                                    onPress={() => {
                                                        // TODO Select search filter 0. Sort accordingly.
                                                    }}>
                                                    <Text
                                                        style={[styles.filterTitleText,
                                                            (this.state.searchFilterSelected == 0 || this.state.searchFilterTitleHovered == 0) ? styles.filterTitleTextHovered : null,
                                                        ]}>Saved On</Text>
                                                </Pressable>
                                            </Hoverable>
                                        </View>
                                    </View>

                                    {/**
                                     Middle Search Filter Area
                                     */}
                                    <View
                                        style={styles.filterBlock}>

                                        {/**
                                         Middle Search Filter Icon Column
                                         */}
                                        <Hoverable
                                            onHoverIn={() => {
                                                if (this.state.searchFilterSelected !== 1) return;
                                                this.setState({searchFilterIconHovered: 1})
                                            }}
                                            onHoverOut={() => {
                                                this.setState({searchFilterIconHovered: -1})
                                            }}
                                            onPressIn={() => {
                                                if (this.state.searchFilterSelected !== 1) return;
                                                this.setState({searchFilterIconHovered: 1})
                                            }}
                                            onPressOut={() => setTimeout(() => {
                                                if (Platform.OS === 'web') return;
                                                this.setState({searchFilterIconHovered: -1});
                                            }, 25)}>
                                            <Pressable
                                                onPress={() => {
                                                    // TODO Sort searches ascending or descending.
                                                }}
                                                style={styles.filterBlockColumn}>
                                                {this.state.searchFilterDirections[1] === 'ascending' &&
                                                    // @ts-ignore
                                                    <Image
                                                        style={[styles.filterIcon,
                                                            this.state.searchFilterIconHovered == 1 ? {transform: [{rotate: '180deg'}]} : null,
                                                            this.state.searchFilterIconHovered == 1 ? styles.filterIconHovered : null,
                                                            this.state.searchFilterSelected !== 1 ? styles.filterIconInvisible : null,
                                                        ]}
                                                        source={up_arrow}/>
                                                }
                                                {this.state.searchFilterDirections[1] === 'descending' &&
                                                    // @ts-ignore
                                                    <Image
                                                        style={[styles.filterIcon,
                                                            this.state.searchFilterIconHovered != 1 ? {transform: [{rotate: '180deg'}]} : null,
                                                            this.state.searchFilterIconHovered == 1 ? styles.filterIconHovered : null,
                                                            this.state.searchFilterSelected !== 1 ? styles.filterIconInvisible : null,
                                                        ]}
                                                        source={up_arrow}/>
                                                }
                                            </Pressable>
                                        </Hoverable>

                                        {/**
                                         Middle Search Filter Title Column
                                         */}
                                        <View
                                            style={styles.filterBlockColumn}>
                                            <Hoverable
                                                onHoverIn={() => {
                                                    this.setState({searchFilterTitleHovered: 1})
                                                }}
                                                onHoverOut={() => {
                                                    this.setState({searchFilterTitleHovered: -1})
                                                }}
                                                onPressIn={() => {
                                                    this.setState({searchFilterTitleHovered: 1})
                                                }}
                                                onPressOut={() => setTimeout(() => {
                                                    if (Platform.OS === 'web') return;
                                                    this.setState({searchFilterTitleHovered: -1});
                                                }, 25)}>
                                                <Pressable
                                                    onPress={() => {
                                                        // TODO Select search filter 1. Sort accordingly.
                                                    }}>
                                                    <Text
                                                        style={[styles.filterTitleText,
                                                            (this.state.searchFilterSelected == 1 || this.state.searchFilterTitleHovered == 1) ? styles.filterTitleTextHovered : null,
                                                        ]}>Specificity</Text>
                                                </Pressable>
                                            </Hoverable>
                                        </View>
                                    </View>

                                    {/**
                                     Right Search Filter Area
                                     */}
                                    <View
                                        style={styles.filterBlock}>

                                        {/**
                                         Right Search Filter Icon Column
                                         */}
                                        <Hoverable
                                            onHoverIn={() => {
                                                if (this.state.searchFilterSelected !== 2) return;
                                                this.setState({searchFilterIconHovered: 2})
                                            }}
                                            onHoverOut={() => {
                                                this.setState({searchFilterIconHovered: -1})
                                            }}
                                            onPressIn={() => {
                                                if (this.state.searchFilterSelected !== 2) return;
                                                this.setState({searchFilterIconHovered: 2})
                                            }}
                                            onPressOut={() => setTimeout(() => {
                                                if (Platform.OS === 'web') return;
                                                this.setState({searchFilterIconHovered: -1});
                                            }, 25)}>
                                            <Pressable
                                                onPress={() => {
                                                    // TODO Sort searches ascending or descending.
                                                }}
                                                style={styles.filterBlockColumn}>
                                                {this.state.searchFilterDirections[2] === 'ascending' &&
                                                    // @ts-ignore
                                                    <Image
                                                        style={[styles.filterIcon,
                                                            this.state.searchFilterIconHovered == 2 ? {transform: [{rotate: '180deg'}]} : null,
                                                            this.state.searchFilterIconHovered == 2 ? styles.filterIconHovered : null,
                                                            this.state.searchFilterSelected !== 2 ? styles.filterIconInvisible : null,
                                                        ]}
                                                        source={up_arrow}/>
                                                }
                                                {this.state.searchFilterDirections[2] === 'descending' &&
                                                    // @ts-ignore
                                                    <Image
                                                        style={[styles.filterIcon,
                                                            this.state.searchFilterIconHovered != 2 ? {transform: [{rotate: '180deg'}]} : null,
                                                            this.state.searchFilterIconHovered == 2 ? styles.filterIconHovered : null,
                                                            this.state.searchFilterSelected !== 2 ? styles.filterIconInvisible : null,
                                                        ]}
                                                        source={up_arrow}/>
                                                }
                                            </Pressable>
                                        </Hoverable>

                                        {/**
                                         Right Search Filter Title Column
                                         */}
                                        <View
                                            style={styles.filterBlockColumn}>
                                            <Hoverable
                                                onHoverIn={() => {
                                                    this.setState({searchFilterTitleHovered: 2})
                                                }}
                                                onHoverOut={() => {
                                                    this.setState({searchFilterTitleHovered: -1})
                                                }}
                                                onPressIn={() => {
                                                    this.setState({searchFilterTitleHovered: 2})
                                                }}
                                                onPressOut={() => setTimeout(() => {
                                                    if (Platform.OS === 'web') return;
                                                    this.setState({searchFilterTitleHovered: -1});
                                                }, 25)}>
                                                <Pressable
                                                    onPress={() => {
                                                        // TODO Select search filter 2. Sort accordingly.
                                                    }}>
                                                    <Text
                                                        style={[styles.filterTitleText,
                                                            (this.state.searchFilterSelected == 2 || this.state.searchFilterTitleHovered == 2) ? styles.filterTitleTextHovered : null,
                                                        ]}>Personalization</Text>
                                                </Pressable>
                                            </Hoverable>
                                        </View>
                                    </View>
                                </View>
                            }
                        </View>

                        {/**
                         Header (free-floating)
                         */}
                        <View
                            style={styles.headerOuter}>

                            {/**
                             Bookmarked Quotes Header
                             */}
                            <Pressable
                                style={[styles.headerTextContainer, this.state.quotesSelected ? styles.headerTextContainerSelected : null]}
                                onPress={() => this.setState({
                                    quotesSelected: true,
                                    searchesSelected: false,
                                    selectedSearchHash: null,
                                })}>
                                <Text
                                    style={styles.headerText}
                                    selectable={false}>Saved Quotes</Text>
                            </Pressable>

                            {/**
                             Bookmarked Searches Header
                             */}
                            <Pressable
                                style={[styles.headerTextContainer, this.state.searchesSelected ? styles.headerTextContainerSelected : null]}
                                onPress={() => this.setState({
                                    quotesSelected: false,
                                    searchesSelected: true,
                                })}>
                                <Text
                                    style={styles.headerText}
                                    selectable={false}>Saved Searches</Text>
                            </Pressable>
                        </View>
                    </View>
                </View>


                {/**
                 "Close" Button
                 */}
                <SquishyButton
                    isSmallScreen={cd.smallScreen}
                    pressFunc={() => {
                        this.setState({selectedSearchHash: null});
                        this.props.onClose();
                    }}
                    isHovered={this.state.closeBtnHovered}
                    setHoveredFunc={(newBool) => this.setState({closeBtnHovered: newBool})}
                    scaleTo={0.93}
                    squishInDuration={150}
                    squishOutDelay={100}
                    squishOutDuration={100}
                    containerStyle={styles.closeBtnContainer}
                    buttonStyle={styles.closeBtnPressable}>
                    <LinearGradient
                        colors={this.state.closeBtnHovered && !cd.smallScreen ?
                            ["#fcfffffc", "white", "#fcfffffc",] :
                            ["#fcfffffc", "#fcfffffc",]}
                        style={styles.closeBtnGradient}>
                        <Image
                            source={close_icon}
                            // @ts-ignore
                            style={[styles.closeBtnImg, Platform.OS === 'web' ? {pointerEvents: "none"} : null]}/>
                    </LinearGradient>
                </SquishyButton>

            </View>
        </Modal>
    }
}