import React, {Component} from "react";
import {Platform, Pressable, Text, View} from "react-native";
import Hoverable from "../reusable/Hoverable";
import {CompDimensions} from "../../util/Util";

export class CategorySelTag extends Component<{
    itemName: string,
    itemHeight: number,
    containerWidth: number,
    marginTopBottom: number,
    dims: CompDimensions,
    selectFunc: any,
    unselectFunc: any,
    selected: boolean,
    selectionAllowed: boolean,
}> {

    state = {
        expanded: false,
        textHovered: false,
        btnHovered: false,
        leftMargin: 0,
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.state.leftMargin === 0) {
            const tagSoftWidth = this.props.containerWidth * 0.5;
            const padding = this.props.containerWidth * 0.1;
            let leftMargin = Math.max(0.2 * Math.random()) * (this.props.containerWidth - tagSoftWidth);
            if (this.props.itemName.length > 16) {
                leftMargin = Math.min(leftMargin, this.props.containerWidth - Math.max(0, tagSoftWidth * 1.5));
            }
            this.setState({leftMargin: this.props.dims.smallScreen ? leftMargin : padding});
        }
    }

    render() {
        /**
         * Returns an expandable text item for a quote tag.
         */

            // Calculate font size and element dimensions.
        const cd = this.props.dims;
        let tagSoftWidth = this.props.containerWidth * 0.45 + (this.props.itemName.length * 0.015);
        if (this.props.itemName.length > 14 && this.props.dims.smallScreen) {
            tagSoftWidth = this.props.containerWidth * 0.8;
        }
        const textPadding = this.props.dims.smallScreen ? this.props.containerWidth * 0.04 : this.props.containerWidth * 0.01;
        let tagFontSize = (this.props.dims.smallScreen ? 0.8 : 0.55) * Math.sqrt(tagSoftWidth * this.props.itemHeight / this.props.itemName.length);
        if (this.props.itemName.length <= 5) {
            tagFontSize = tagFontSize * 0.7;
        } else if (this.props.itemName.length > 13) {
            tagFontSize = tagFontSize * 1.1;
        }
        const removeHeight = this.props.itemHeight * 0.65;
        const removeWidth = removeHeight;
        const removeFontSize = 0.8 * Math.sqrt(removeWidth * removeHeight / "X".length);

        let tagTextColor = "#1f211a";
        let tagTextBg = "";
        if (this.props.selected) {
            tagTextColor = "black";
        }
        if (this.props.selected && this.state.textHovered) {
            tagTextBg = "white";
        }
        if (this.props.selected && !this.state.textHovered) {
            tagTextBg = "#faf7f7";
        }
        if (!this.props.selected && this.state.textHovered) {
            tagTextBg = "#e8f7ff";
        }
        if (!this.props.selected && !this.state.textHovered) {
            tagTextBg = "#d1eeff";
        }


        return (
            <Hoverable
                key={this.props.itemName}
                onHoverIn={() => this.setState({
                    textHovered: true,
                    expanded: this.props.selectionAllowed || this.props.selected
                })}
                onHoverOut={() => this.setState({textHovered: false, expanded: false})}
                onPressIn={() => this.setState({textHovered: true})}
                onPressOut={() => {
                    if (Platform.OS === 'web' && !cd.smallScreen) return;
                    setTimeout(() => {
                        this.setState({textHovered: false});
                    }, 120);
                }}
            >
                <View style={{
                    height: this.props.itemHeight,
                    marginTop: this.props.marginTopBottom,
                    marginBottom: this.props.marginTopBottom,
                    marginLeft: cd.smallScreen ? "auto" : (textPadding * 2),
                    marginRight: cd.smallScreen ? "auto" : ((this.state.expanded ? 0 : removeWidth + textPadding)),
                    backgroundColor: tagTextBg,
                    borderRadius: this.props.itemHeight * 0.4,
                    borderColor: "rgba(225,245,252,0.48)",
                    borderWidth: this.props.itemHeight * 0.05,
                    alignSelf: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    alignItems: "center",
                }}>

                    {/**
                     Tag Name
                     */}
                    <Hoverable
                        onPressIn={() => this.setState({textHovered: true})}
                        onPressOut={() => {
                            if (!cd.smallScreen) return;
                            setTimeout(() => {
                                this.setState({textHovered: false});
                            }, 120);
                        }}
                    >
                        <Pressable
                            onPress={() => setTimeout(() => {
                                // Pressing outside "+" button on mobile expands/un-expands the tag.
                                // Otherwise, add/remove the tag from current selection.
                                if (cd.smallScreen) {
                                    if (this.props.selected) {
                                        this.props.unselectFunc();
                                    } else {
                                        if (this.props.selectionAllowed) {
                                            this.props.selectFunc();
                                        }
                                    }
                                } else {
                                    if (this.props.selected) {
                                        this.setState({expanded: this.state.textHovered});
                                        this.props.unselectFunc();
                                    } else {
                                        if (this.props.selectionAllowed) {
                                            this.props.selectFunc();
                                        }
                                    }
                                }
                            }, 50)}
                            style={{
                                height: this.props.itemHeight,
                                marginTop: textPadding * 0.1,
                                marginBottom: textPadding * 0.1,
                                marginLeft: textPadding,
                                marginRight: textPadding,
                            }}>
                            <View style={{
                                height: this.props.itemHeight,
                                justifyContent: "center",
                            }}>
                                <Text style={{
                                    fontSize: tagFontSize,
                                    fontWeight: "normal",
                                    color: tagTextColor,
                                }}
                                      selectable={false}>{this.props.itemName}</Text>
                            </View>
                        </Pressable>
                    </Hoverable>

                    {/**
                     Plus or X button
                     */}
                    {!this.props.dims.smallScreen && this.state.expanded &&
                        <View style={{
                            width: removeWidth,
                            height: removeHeight,
                            marginRight: textPadding,
                        }}>
                            <Hoverable
                                onHoverIn={() => this.setState({btnHovered: true})}
                                onHoverOut={() => this.setState({btnHovered: false})}
                                onPressIn={() => this.setState({btnHovered: true})}
                                onPressOut={() => {
                                    if (!cd.smallScreen) return;
                                    setTimeout(() => this.setState({btnHovered: false}), 50);
                                }}
                            >
                                <Pressable
                                    onPress={() => {
                                        setTimeout(() => {
                                            if (this.props.selected) {
                                                this.props.unselectFunc();
                                                this.setState({expanded: this.state.textHovered});
                                            } else {
                                                if (this.props.selectionAllowed) {
                                                    this.props.selectFunc();
                                                }
                                            }
                                        }, 100);
                                    }}
                                    style={{
                                        transform: [{scale: this.state.btnHovered ? Platform.OS !== 'web' ? 1.1 : 1.06 : 1}],
                                        width: removeWidth,
                                        height: removeHeight,
                                        backgroundColor: this.props.selected ? (this.state.btnHovered ? "#ff4551" : "#fc3d49") : (this.state.btnHovered ? "#6ee550" : "#67e349"),
                                        borderRadius: removeWidth * 0.5,
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <Text style={{
                                        fontSize: this.props.selected ? removeFontSize : removeFontSize * 1.2,
                                        fontWeight: "bold",
                                        color: "white",
                                        paddingBottom: this.props.selected ? 0 : removeWidth * 0.17,
                                    }}
                                          selectable={false}>{this.props.selected ? "X" : "+"}</Text>

                                </Pressable>
                            </Hoverable>
                        </View>
                    }
                </View>
            </Hoverable>
        );
    }
}