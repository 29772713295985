// Area below the screen. Activated to load more quotes when user scrolls beyond the screen's bottom edge.
import {Component} from "react";
import Animated, {FadeIn, FadeOut} from "react-native-reanimated";
import {LinearGradient} from "expo-linear-gradient";
import {Platform, View} from "react-native";

const colors = ["#ead3ed", "#d3d1f0", "#d3e9f0", "#d0f2ec", "#c7f2d7", "#c3edaf", "#f2eab8", "#f7ddb0", "#f0afaa"];

export class DragArea extends Component <{
    width: number,
    height: number,
    smallScreen: boolean,
    borderWidth: number,
}> {

    state = {
        bgColor: colors[0],
        colorShiftTask: null,
    }

    constructor(props) {
        super(props);
        this.chooseNewColor = this.chooseNewColor.bind(this);
    }


    componentDidMount() {
        if (!this.state.colorShiftTask) {
            this.setState(
                {
                    colorShiftTask: setInterval(() => this.chooseNewColor(), 250)
                });
        }
    }

    componentWillUnmount() {
        if (this.state.colorShiftTask) {
            clearInterval(this.state.colorShiftTask);
        }
        this.setState({colorShiftTask: null});
    }

    chooseNewColor() {
        let newColorIdx = colors.indexOf(this.state.bgColor);
        if (newColorIdx === colors.length - 1) {
            newColorIdx = 0;
        } else {
            newColorIdx += 1;
        }
        const newColor = colors[newColorIdx];
        this.setState({bgColor: newColor});
    }

    render() {

        return (
            <>
                <View style={{
                    width: this.props.width,
                    height: this.props.height,
                }}>
                    <Animated.View
                        entering={FadeIn.duration(250)}
                        exiting={FadeOut.duration(250)}
                        style={{
                            width: this.props.width,
                            height: this.props.height,
                        }}>
                        <LinearGradient colors={["#e6edf2", this.state.bgColor]}
                                        style={{
                                            width: this.props.width,
                                            height: this.props.height,
                                        }}/>
                    </Animated.View>
                </View>
                {(this.props.smallScreen && Platform.OS === 'web') &&
                    <View
                        style={{
                            width: this.props.width,
                            height: this.props.height * 10,
                            backgroundColor: this.state.bgColor,
                        }}/>
                }
            </>
        );
    }
}
