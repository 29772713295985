import React, {Component} from "react";
import {Text, View} from "react-native";
import {CompDimensions} from "../../../util/Util";
import Animated from "react-native-reanimated";

export const borderColors = ["#ebdded", "#dedded", "#dde9ed", "#ddedea", "#ddede3", "#e2eddd", "#edebdd", "#ede7dd", "#eddedd"];

export class SavedCatTag extends Component<{
    itemName: string,
    itemHeight: number,
    containerWidth: number,
    marginTopBottom: number,
    fontSize: number,
    dims: CompDimensions,
    translateX: any,
    highlighted: boolean,
}> {

    state = {
    }

    constructor(props) {
        super(props);
    }

    render() {
        /**
         * Returns an expandable text item for a quote tag.
         */

            // Calculate font size and element dimensions.
        const cd = this.props.dims;
        const textPadding = cd.smallScreen ? this.props.containerWidth * 0.02 : this.props.containerWidth * 0.01;

        let tagTextColor = "#fafcff";
        let tagTextBg = this.props.highlighted ? "#88add1" : "#7894b0";


        return (
            // @ts-ignore
            <Animated.View style={{
                transform: [{translateX: this.props.translateX}],
                height: this.props.itemHeight,
                marginTop: this.props.marginTopBottom,
                marginBottom: this.props.marginTopBottom,
                marginLeft: cd.smallScreen ? textPadding : textPadding * 2,
                marginRight: cd.smallScreen ? textPadding : textPadding * 2,
                backgroundColor: tagTextBg,
                borderRadius: this.props.itemHeight * 0.4,
                borderColor: "#aac8e5",
                borderWidth: this.props.itemHeight * 0.06,
                //alignSelf: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "row",
                alignItems: "center",
            }}>

                {/**
                 Tag Name
                 */}
                <View
                    style={{
                        height: this.props.itemHeight,
                        marginTop: textPadding * 0.1,
                        marginBottom: textPadding * 0.1,
                        marginLeft: textPadding,
                        marginRight: textPadding,
                    }}>
                    <View style={{
                        height: this.props.itemHeight,
                        justifyContent: "center",
                    }}>
                        <Text style={{
                            fontSize: this.props.fontSize,
                            color: tagTextColor,
                            textShadowColor: "#a4c6d2",
                            textShadowRadius: this.props.itemHeight * 0.035,
                        }}
                              selectable={false}>{this.props.itemName}</Text>
                    </View>
                </View>
            </Animated.View>
        );
    }
}