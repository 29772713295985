import {Platform} from "react-native";
import Animated from 'react-native-reanimated';
import {createAnimatedSet as set} from 'react-native-reanimated/lib/reanimated1/core/AnimatedSet';
import {AnimatedValue} from 'react-native-reanimated/lib/reanimated1/core/AnimatedValue';
import interpolate from 'react-native-reanimated/lib/reanimated1/derived/interpolate';
import {evaluateOnce} from 'react-native-reanimated/lib/reanimated1/derived/evaluateOnce';
import ReanimatedModule from 'react-native-reanimated/lib/ReanimatedModule';
import {val} from 'react-native-reanimated/lib/reanimated1/val';


/**
 * Patches the default AnimatedValue.js by only calling this.__detachAnimation if platform is not web.
 * This single check enables Animated.Value to work on web.
 * To use the patch, simply replace all instances of Animated.Value with PatchedAnimatedValue.
 **/
/**
export default class PatchedAnimatedValue extends Animated.Value {
    constructor(value) {
        super(value);
    }
    setValue(value) {
        if (Platform.OS !== 'web') {
            this.__detachAnimation(this._animation);
        }
        if (
            Platform.OS === 'web' ||
            Platform.OS === 'windows' ||
            Platform.OS === 'macos'
        ) {
            this._updateValue(val(value));
        } else {
            if (ReanimatedModule.setValue && typeof value === 'number') {
                // FIXME Remove it after some time
                // For OTA-safety
                // FIXME handle setting value with a node
                ReanimatedModule.setValue(this.__nodeID, value);
            } else {
                evaluateOnce(set(this, value), this);
            }
        }
    }

    toString() {
        return `AnimatedValue, id: ${this.__nodeID}`;
    }

    interpolate(config) {
        return interpolate(this, config);
    }
}
 **/

export function createSafeAnimatedValue(value) {
    const animatedValue = new Animated.Value(value);
    animatedValue.setValue = (value) => {
        if (Platform.OS !== 'web') {
            // @ts-ignore
            animatedValue.__detachAnimation(animatedValue._animation);
        }
        if (
            Platform.OS === 'web' ||
            Platform.OS === 'windows' ||
            Platform.OS === 'macos'
        ) {
            // @ts-ignore
            animatedValue._updateValue(val(value));
        } else {
            if (ReanimatedModule.setValue && typeof value === 'number') {
                // FIXME Remove it after some time
                // For OTA-safety
                // FIXME handle setting value with a node
                // @ts-ignore
                ReanimatedModule.setValue(animatedValue.__nodeID, value);
            } else {
                try {
                    evaluateOnce(set(animatedValue, value), animatedValue);
                } catch (e) {
                }
            }
        }
    };
    return animatedValue;
}