import React, {Component} from "react";
import {Platform, Pressable, Text, View} from "react-native";
import Hoverable from "../reusable/Hoverable";
import {CompDimensions} from "../../util/Util";

export class CurrentlySelectedTag extends Component<{
    itemName: string,
    itemHeight: number,
    marginTop: number,
    dims: CompDimensions,
    removeFunc: any
}> {

    state = {
        expanded: false,
        textHovered: false,
        btnHovered: false,
    }

    constructor(props) {
        super(props);
    }

    render() {
        /**
         * Returns an expandable text item for a currently selected quote tag.
         */

            // Get and calculate component dimensions.
        const cd = this.props.dims;


        const padding = this.props.itemHeight * 0.2;
        const tagSoftWidth = this.props.itemHeight * 2.3;
        let tagFontSize = 0.9 * Math.sqrt(tagSoftWidth * this.props.itemHeight / this.props.itemName.length);
        if (this.props.itemName.length <= 5) {
            tagFontSize = tagFontSize * 0.8;
        }
        const removeHeight = this.props.itemHeight * 0.65;
        const removeWidth = removeHeight;
        const removeFontSize = 0.8 * Math.sqrt(removeWidth * removeHeight / "X".length);


        return (
            <Hoverable
                onHoverIn={() => this.setState({textHovered: true, expanded: true})}
                onHoverOut={() => this.setState({textHovered: false, expanded: false})}
                onPressIn={() => {
                    this.setState({textHovered: true});
                    if (Platform.OS === 'web' && !cd.smallScreen && !this.state.expanded) {
                        this.setState({expanded: true});
                    }
                }}
                onPressOut={() => {
                    if (Platform.OS === 'web' && !cd.smallScreen) return;
                    setTimeout(() => this.setState({textHovered: false}), 50);
                }}>
                <View style={{
                    height: this.props.itemHeight,
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: padding,
                    marginRight: this.state.expanded ? padding * 0.5 : removeWidth + padding * 0.6 + padding * 0.5,
                    backgroundColor: this.state.textHovered || this.state.expanded ? "#fafbfc" : "#f2faffec",
                    borderRadius: this.props.itemHeight * 0.4,
                    borderWidth: this.props.itemHeight * 0.03,
                    borderColor: "#ffffff2b",
                    alignSelf: "flex-start",
                    justifyContent: "center",
                    flexDirection: "row",
                    alignItems: "center",
                }}>

                    {/**
                     Tag Name
                     */}
                    <Hoverable
                        key={this.props.itemName}
                        onPressIn={() => {
                            this.setState({textHovered: true});
                            if (Platform.OS === 'web' && !this.state.expanded) {
                                this.setState({expanded: true});
                            }
                        }} onPressOut={() => {
                        if (!cd.smallScreen) return;
                        setTimeout(() => this.setState({textHovered: false}), 50);
                    }}>
                        <Pressable
                            onPress={() => {
                                // Pressing outside "X" button on mobile expands/un-expands the tag.
                                // On web, remove the tag from current selection.
                                setTimeout(() => {
                                    if (this.state.expanded) {
                                        if (cd.smallScreen) {
                                            this.setState({expanded: false});
                                        } else {
                                            this.props.removeFunc();
                                        }
                                    } else {
                                        if (cd.smallScreen) {
                                            this.setState({expanded: true});
                                        } else {
                                            this.props.removeFunc();
                                        }
                                    }
                                }, 100);
                            }}
                            style={{
                                height: this.props.itemHeight,
                                marginTop: padding * 0.4,
                                marginBottom: padding * 0.4,
                                marginLeft: padding,
                                marginRight: padding,
                            }}>
                            <View style={{
                                height: this.props.itemHeight,
                                justifyContent: "center",
                            }}>
                                <Text style={{
                                    fontSize: tagFontSize,
                                    color: "black",
                                }}
                                      selectable={false}>{this.props.itemName}</Text>
                            </View>
                        </Pressable>
                    </Hoverable>

                    {/**
                     "Remove" Button
                     */}
                    {this.state.expanded &&
                        <View style={{
                            width: removeWidth,
                            height: removeHeight,
                            marginRight: padding * 0.6,
                        }}>
                            <Hoverable
                                onHoverIn={() => this.setState({btnHovered: true})}
                                onHoverOut={() => this.setState({btnHovered: false})}
                                onPressIn={() => this.setState({btnHovered: true})}
                                onPressOut={() => this.setState({btnHovered: false})}
                            >
                                <Pressable
                                    onPress={() => setTimeout(() => {
                                        if (this.state.expanded) {
                                            this.setState({expanded: false});
                                            this.props.removeFunc();
                                        } else {
                                            this.setState({expanded: true});
                                        }
                                    }, 100)}
                                    style={{
                                        transform: [{scale: this.state.btnHovered ? Platform.OS !== 'web' ? 1.1 : 1.06 : 1}],
                                        width: removeWidth,
                                        height: removeHeight,
                                        backgroundColor: this.state.btnHovered ? "#ff4551" : "#fc3d49",
                                        borderRadius: removeWidth * 0.5,
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    hitSlop={{
                                        left: cd.smallScreen ? removeWidth * 0.3 : 0,
                                        right: cd.smallScreen ? removeWidth * 0.3 : 0,
                                        top: cd.smallScreen ? removeWidth * 0.3 : 0,
                                        bottom: cd.smallScreen ? removeWidth * 0.3 : 0,
                                    }}>
                                    <Text style={{
                                        fontSize: removeFontSize,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                          selectable={false}>X</Text>

                                </Pressable>
                            </Hoverable>
                        </View>
                    }
                </View>
            </Hoverable>
        );
    }
}