// Define function to render a news card.
import React from "react";
import {QuoteData} from "../types/QuoteData";
import {Platform, Text, View} from "react-native";
import Constants from "expo-constants";

// Define component to conditionally wrap content in itself.
export const ConditionalWrapper = ({condition, wrapper, children}) =>
    condition ? wrapper(children) : children;

const bgColorsDark = ["#ebdded", "#dedded", "#dde9ed", "#ddedea", "#ddede3", "#e2eddd", "#edebdd", "#ede7dd", "#eddedd"];
export const bgColorsLight = ["#f7e6e8", "#f7e6ef", "#f2e6f7", "#ebe6f7", "#e6edf7", "#e6f5f7", "#e6f7f2", "#e6f7eb", "#f3f7e6", "#f7f2e6", "#f7ede6", "#f7e9e6"];

// Default cards show as loading (null headline).
export const defaultLoadingCards: Array<QuoteData> = [
    {
        report_id: '0',
        speaker: '',
        headline: null,
        summary: '',
        full_report: '',
        quoter: '',
        snapshot: '',
        source_link: '',
        tags: []
    },
    {
        report_id: '1',
        speaker: '',
        headline: null,
        summary: '',
        full_report: '',
        quoter: '',
        snapshot: '',
        source_link: '',
        tags: []
    },
    {
        report_id: '2',
        speaker: '',
        headline: null,
        summary: '',
        full_report: '',
        quoter: '',
        snapshot: '',
        source_link: '',
        tags: []
    },
    {
        report_id: '3',
        speaker: '',
        headline: null,
        summary: '',
        full_report: '',
        quoter: '',
        snapshot: '',
        source_link: '',
        tags: []
    },
    {
        report_id: '4',
        speaker: '',
        headline: null,
        summary: '',
        full_report: '',
        quoter: '',
        snapshot: '',
        source_link: '',
        tags: []
    },
    {
        report_id: '5',
        speaker: '',
        headline: null,
        summary: '',
        full_report: '',
        quoter: '',
        snapshot: '',
        source_link: '',
        tags: []
    },
];

export function getPCQuotes(allQuotes: Array<QuoteData>) {
    const pcReportIndices = [9, 10, 8, 2, 0, 4,]
    const pcReports = [];
    for (let i of pcReportIndices) {
        let idx = i;
        let quoteData: QuoteData = {
            report_id: randomID(),
            speaker: allQuotes[idx].speaker,
            headline: allQuotes[idx].headline,
            summary: allQuotes[idx].summary,
            full_report: allQuotes[idx].full_report,
            quoter: allQuotes[idx].quoter,
            snapshot: '',
            source_link: allQuotes[idx].source_link,
            tags: allQuotes[idx].tags
        };
        pcReports.push(quoteData);
    }
    return pcReports;
}

export function getRandomQuotes(allQuotes: Array<QuoteData>, n: number) {
    const randomReports = [];
    for (let i = 0; i < n; i++) {
        let idx = Math.floor(Math.random() * allQuotes.length);
        let k = 0;
        let duplicated = false;
        for (let j = 0; j < randomReports.length; j++) {
            if (randomReports[j].headline == allQuotes[idx].headline) {
                duplicated = true;
            }
        }
        while (k < 10 && duplicated) {
            k++;
            idx = Math.floor(Math.random() * allQuotes.length);
            duplicated = false;
            for (let j = 0; j < randomReports.length; j++) {
                if (randomReports[j].headline == allQuotes[idx].headline) {
                    duplicated = true;
                }
            }
        }
        const quoteData: QuoteData = {
            report_id: randomID(),
            speaker: allQuotes[idx].speaker,
            headline: allQuotes[idx].headline,
            summary: allQuotes[idx].summary,
            full_report: allQuotes[idx].full_report,
            quoter: allQuotes[idx].quoter,
            snapshot: '',
            source_link: allQuotes[idx].source_link,
            tags: allQuotes[idx].tags
        };
        randomReports.push(quoteData);
    }
    return randomReports;
}

export function randomCardColors(n: number) {
    /**
     * Returns n random color code strings from bgColorsDark and bgColorsLight arrays.
     */

        // Init array.
    const randomColors = ["#e6edf7"];
    for (let i = 1; i < n; i++) {

        // 50% chance of light or dark color.
        let bgLightOrDark = Math.random() < 0.5;

        // 1/n chance to pick 1 from n colors.
        let randomColor = bgLightOrDark ?
            bgColorsLight[Math.floor(Math.random() * bgColorsLight.length)] :
            bgColorsLight[Math.floor(Math.random() * bgColorsLight.length)];
        while (randomColors.includes(randomColor)) {
            randomColor = bgLightOrDark ?
                bgColorsLight[Math.floor(Math.random() * bgColorsLight.length)] :
                bgColorsLight[Math.floor(Math.random() * bgColorsLight.length)];
        }
        randomColors.push(randomColor);
    }

    // Return the result.
    return randomColors;
}

export function randomID(): string {
    return Math.random().toString().replace("0.", "");
}

export const colorShade = (col, amt) => {
    col = col.replace(/^#/, '')
    if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

    let [r, g, b] = col.match(/.{2}/g);
    ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

    r = Math.max(Math.min(255, r), 0).toString(16)
    g = Math.max(Math.min(255, g), 0).toString(16)
    b = Math.max(Math.min(255, b), 0).toString(16)

    const rr = (r.length < 2 ? '0' : '') + r
    const gg = (g.length < 2 ? '0' : '') + g
    const bb = (b.length < 2 ? '0' : '') + b

    return `#${rr}${gg}${bb}`
}

export function lightenHex(col: string, amt: number): string {
    /**
     * Lightens a hex color 'col' by the amount 'amt' (a number between 1 and 255).
     */

    let usePound = false;

    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }

    let R = parseInt(col.substring(0, 2), 16);
    let G = parseInt(col.substring(2, 4), 16);
    let B = parseInt(col.substring(4, 6), 16);

    R = R + amt;
    G = G + amt;
    B = B + amt;

    if (R > 255) R = 255;
    else if (R < 0) R = 0;

    if (G > 255) G = 255;
    else if (G < 0) G = 0;

    if (B > 255) B = 255;
    else if (B < 0) B = 0;

    let RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    let GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    let BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

    return (usePound ? "#" : "") + RR + GG + BB;
}

export function addAlpha(col: string, alpha: number): string {
    /**
     * Adds alpha (0.0 to 1.0) to the hex string.
     */

    let usePound = false;

    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }

    let opacity = Math.round(Math.min(Math.max(alpha || 1, 0), 1) * 255);

    let R = parseInt(col.substring(0, 2), 16);
    let G = parseInt(col.substring(2, 4), 16);
    let B = parseInt(col.substring(4, 6), 16);

    let RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    let GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    let BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));
    let AA = opacity.toString(16).toUpperCase();

    return (usePound ? "#" : "") + RR + GG + BB + AA;
}

export function luma(color) {
    let rgb = (typeof color === 'string') ? hexToRGBArray(color.replace('#', '')) : color;
    const luminance = ((0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2])) / 255.0;
    return luminance; // SMPTE C, Rec. 709 weightings
}

function hexToRGBArray(color) {
    if (color.length === 3)
        color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
    else if (color.length !== 6)
        throw('Invalid hex color: ' + color);
    var rgb = [];
    for (var i = 0; i <= 2; i++)
        rgb[i] = parseInt(color.substr(i * 2, 2), 16);
    return rgb;
}

export function validatePassword(pass: string): string {
    /**
     * Returns '' if pass is valid. Otherwise, returns reason why pass is invalid.
     */

    // Validate length.
    if (pass.length < 7) {
        return 'Password must be at least 7 characters.';
    }

    // Validate lower-case character.
    if (pass.toUpperCase() == pass) {
        return 'Password must contain at least 1 lower-case letter.'
    }

    // Validate upper-case character.
    if (pass.toLowerCase() == pass) {
        return 'Password must contain at least 1 upper-case letter.'
    }

    return '';
}

function parseRawCsvStr(dataStr: string): Array<string> {
    let arr = [];
    let quote = false;  // 'true' means we're inside a quoted field

    // Iterate over each character, keep track of current row and column (of the returned array)
    for (let row = 0, col = 0, c = 0; c < dataStr.length; c++) {
        let cc = dataStr[c], nc = dataStr[c + 1];        // Current character, next character
        arr[row] = arr[row] || [];             // Create a new row if necessary
        arr[row][col] = arr[row][col] || '';   // Create a new column (start with empty string) if necessary

        // If the current character is a quotation mark, and we're inside a
        // quoted field, and the next character is also a quotation mark,
        // add a quotation mark to the current column and skip the next character
        if (cc == '"' && quote && nc == '"') {
            arr[row][col] += cc;
            ++c;
            continue;
        }

        // If it's just one quotation mark, begin/end quoted field
        if (cc == '"') {
            quote = !quote;
            continue;
        }

        // If it's a comma and we're not in a quoted field, move on to the next column
        if (cc == ',' && !quote) {
            ++col;
            continue;
        }

        // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
        // and move on to the next row and move to column 0 of that new row
        if (cc == '\r' && nc == '\n' && !quote) {
            ++row;
            col = 0;
            ++c;
            continue;
        }

        // If it's a newline (LF or CR) and we're not in a quoted field,
        // move on to the next row and move to column 0 of that new row
        if (cc == '\n' && !quote) {
            ++row;
            col = 0;
            continue;
        }
        if (cc == '\r' && !quote) {
            ++row;
            col = 0;
            continue;
        }

        // Otherwise, append the current character to the current column
        arr[row][col] += cc;
    }
    return arr;
}

export function loadQuoteDatasFromCsv() {
    return new Promise((success, error) => {
            // Parse csv data into an array.
            const quoteDataStrs = parseRawCsvStr(QUOTE_DATA_CSV);

            // Load string data into quote data.
            const quoteDatas = [];
            for (let quoteStr of quoteDataStrs) {
                try {
                    const quoteData: QuoteData = {
                        report_id: randomID(),
                        speaker: quoteStr[0],
                        headline: quoteStr[1],
                        summary: quoteStr[2],
                        full_report: quoteStr[3],
                        quoter: quoteStr[4],
                        snapshot: '',
                        source_link: quoteStr[6],
                        tags: quoteStr[7].split(', '),
                    };
                    quoteDatas.push(quoteData);
                } catch (err) {
                    console.log("Error parsing quote data for: " + quoteStr);
                    console.log("...");
                    console.log(quoteStr);
                    console.log(err);
                }
            }

            // Return the result.
            success(quoteDatas);
        }
    );
}


export function handleLineBreaks(textStr: string, textStyle?, breakHeight?: number) {
    /**
     * Returns a separate Text element for each line break ("\n").
     * The result must be placed inside of a Text or View element.
     */
    return (
        textStr.split('\\n').map((subText, idx) => {
            if (idx === 0) {
                return <Text key={subText} style={textStyle ? textStyle : null}>{subText}</Text>;
            } else {
                return <View key={subText}><Text key={'_' + subText}
                                                 style={breakHeight ? {marginTop: breakHeight * 0.65} : null}/><Text
                    key={subText} style={[textStyle ? textStyle : null]}>{subText}</Text></View>;
            }
        })
    );
}

export class CompDimensions {
    /**
     * Calculates dimensions of all the app's components based on screen dimensions.
     */

        // Screen dimensions.
    screenWidth: number;
    screenHeight: number;

    // Whether to display mobile/vertical or desktop/tablet view.
    smallScreen: boolean;

    screenMargin: number;

    // Width of the blue border around the top/left/right of the display.
    appBorderWidth: number;

    // Dimensions of the "scroll for more" button.
    moreBtnWidth: number;
    moreBtnHeight: number;

    // Dimensions of the top-center button for user controls.
    loginBtnWidth: number;
    loginBtnHeight: number;
    loginBtnTopMargin: number;
    loginBtnBorderRadius: number;

    // Dimensions for login/signup modal.
    loginModalWidth: number;
    loginModalHeight: number;

    // Dimensions of search modal.
    searchModalWidth: number;
    searchModalHeight: number;

    // Dimensions of saved modal.
    savedModalWidth: number;
    savedModalHeight: number;

    // Dimensions of quote modal.
    quoteModalWidth: number;
    quoteModalHeight: number;

    // Dimensions of the area containing quote cards.
    cardAreaWidth: number;
    cardAreaHeight: number;

    // Dimensions of each quote card.
    cardWidth: number;
    cardHeight: number;

    // Unsorted dimensions because I'm lazy.
    titleBoxWidth: number;
    titleBoxHeight: number;
    headerSideBtnsHeight: number;
    headerSideBtnsWidth: number;
    headerSideBtnsTopMargin: number;
    headerAreaHeight: number;
    titleBoxLeftMargin: number;
    statusBarHeight: number;
    scrollAreaHeight: number;
    cardTopMargin: number;
    maxHeaderYOffset: number;
    searchBtnLeftMargin: number;
    savedBtnLeftMargin: number;
    dragAreaHeight: number;
    aboutBtnAreaWidth: number;
    aboutBtnAreaHeight: number;
    aboutBtnAreaTopMargin: number;
    desktopSearchBtnLeftMargin: number;
    desktopSavedBtnLeftMargin: number;
    spaceBelowHeader: number;

    constructor(screenWidth: number, screenHeight: number) {

        /**
         * GENERAL APP DIMENSIONS
         */

        // Determine whether to show mobile or desktop/tablet display.
        this.screenWidth = screenWidth;
        this.screenHeight = screenHeight;
        this.smallScreen = screenWidth / screenHeight < 1.2;

        // Calculate border dimensions.
        this.appBorderWidth = this.smallScreen ? ((screenWidth + screenHeight) / 2.0) * 0.009 : screenWidth * 0.009;

        // Find status bar height.
        this.statusBarHeight = (Platform.OS === 'ios') ? Constants.statusBarHeight : 0;


        /**
         * TOP OF PAGE
         */

        // Calculate header dimensions.
        this.titleBoxWidth = this.smallScreen ? this.screenWidth * 0.55 : this.screenWidth * 0.3;
        this.titleBoxHeight = this.smallScreen
            ? screenHeight * 0.07 + (Platform.OS === 'web' ? screenHeight * 0.04 : Math.min(this.appBorderWidth * 4, this.statusBarHeight))
            : screenHeight * 0.12;
        this.loginBtnHeight = this.smallScreen ? this.titleBoxHeight * 0.53 : this.titleBoxHeight * 0.42;
        this.loginBtnWidth = this.smallScreen ? this.loginBtnHeight * 2.4 : this.loginBtnHeight * 2.7;
        this.loginBtnTopMargin = this.smallScreen
            ? this.titleBoxHeight - this.loginBtnHeight * 0.5
            : this.titleBoxHeight - this.loginBtnHeight * 0.5;
        this.loginBtnBorderRadius = this.smallScreen ? this.appBorderWidth : this.appBorderWidth * 0.45;
        //this.headerSideBtnsHeight = this.smallScreen ? this.screenWidth * 0.1 : this.titleBoxHeight + this.loginBtnHeight * 0.5;
        this.headerSideBtnsHeight = this.smallScreen ? this.loginBtnHeight * 1.26 : this.loginBtnHeight * 1.55;
        this.headerSideBtnsWidth = this.headerSideBtnsHeight;
        this.spaceBelowHeader = this.smallScreen ? this.loginBtnHeight * 2.6 : 0;
        this.headerAreaHeight = this.smallScreen ? this.titleBoxHeight + this.loginBtnHeight * 0.5 : this.titleBoxHeight + this.loginBtnHeight * 0.5;
        this.headerSideBtnsTopMargin = this.smallScreen
            ? this.titleBoxHeight + this.loginBtnHeight * 0.5 - this.headerSideBtnsHeight * 1.1
            : (this.headerAreaHeight - this.appBorderWidth - this.headerSideBtnsHeight) * 0.5;
        this.titleBoxLeftMargin = (this.screenWidth - (this.appBorderWidth * 2 + this.headerSideBtnsWidth * 2 + this.titleBoxWidth)) * 0.5;
        this.searchBtnLeftMargin = this.appBorderWidth * 1.5;
        this.savedBtnLeftMargin = this.appBorderWidth * 0.5 + this.titleBoxWidth + this.titleBoxLeftMargin * 2 + this.headerSideBtnsWidth;
        this.aboutBtnAreaWidth = this.smallScreen
            ? this.headerSideBtnsWidth * 2.55
            : this.headerSideBtnsWidth * 4.5;
        this.desktopSearchBtnLeftMargin = this.searchBtnLeftMargin + this.aboutBtnAreaWidth - this.headerSideBtnsWidth - this.headerSideBtnsWidth * 0.1;
        this.desktopSavedBtnLeftMargin = this.savedBtnLeftMargin - this.aboutBtnAreaWidth + this.headerSideBtnsWidth + this.headerSideBtnsWidth * 0.1;


        /**
         * MIDDLE OF PAGE
         */

        // Calculate quote card dimensions.
        this.screenMargin = this.smallScreen ? 0.02 * screenWidth : 0.005 * screenWidth;
        this.cardAreaWidth = screenWidth - this.screenMargin;
        this.cardWidth = this.smallScreen ? 0.98 * this.cardAreaWidth : 0.32 * this.cardAreaWidth;


        /**
         * POP-UPS
         */

        // Calculate login/signup modal dimensions.
        this.loginModalWidth = this.smallScreen ? screenWidth * 0.85 : screenWidth * 0.3;
        if (screenWidth / screenHeight < 1.5 && screenWidth / screenHeight > 1.0) {
            this.loginModalWidth = screenWidth * 0.4;
        } else if (screenWidth / screenHeight < 1.1 && screenWidth / screenHeight > 0.80) {
            this.loginModalWidth = screenWidth * 0.6;
        }
        this.loginModalHeight = this.smallScreen ? screenHeight * 0.75 : screenHeight * 0.7;

        // Calculate search modal dimensions.
        this.searchModalWidth = this.smallScreen ? screenWidth - 2 * (this.searchBtnLeftMargin + this.headerSideBtnsWidth * 0.5) + this.headerSideBtnsWidth * 0.2 : screenWidth * 0.6;
        this.searchModalHeight = this.smallScreen ? screenHeight * 0.8 : this.searchModalWidth * 0.7;

        // Calculate saved modal dimensions.
        this.savedModalWidth = this.searchModalWidth;
        this.savedModalHeight = this.searchModalHeight;

        // Calculate quote modal dimensions.
        this.quoteModalWidth = this.smallScreen ? screenWidth : screenWidth * 0.55;
        this.quoteModalHeight = this.smallScreen ? screenHeight * 0.7 : Math.min(screenHeight, this.quoteModalWidth * 0.9);
        if (this.smallScreen && Platform.OS === 'web') this.quoteModalHeight = screenHeight * 0.8;


        /**
         * BOTTOM OF PAGE
         */

        // Calculate "more" button dimensions.
        this.moreBtnWidth = screenWidth;
        this.moreBtnHeight = this.smallScreen ? screenHeight * 0.1 : screenHeight * 0.06;

        // Calculate height of area below the screen.
        this.dragAreaHeight = this.smallScreen
            ? (Platform.OS === 'web' ? this.screenWidth * 0.2 : this.screenHeight * 0.117)
            : this.screenHeight * 0.2;


        /**
         * DIMENSIONS BASED ON OTHER ELEMENTS
         */

        this.cardAreaHeight = this.smallScreen ? 6.85 * (0.7 * this.cardWidth) : 0.98 * this.screenHeight - (this.headerAreaHeight + this.moreBtnHeight + this.appBorderWidth);
        this.cardHeight = this.smallScreen ? 0.71 * this.cardWidth : Math.min(0.85 * this.cardWidth, 0.45 * this.cardAreaHeight);
        this.moreBtnHeight = this.smallScreen ? this.moreBtnHeight : this.screenHeight - (this.appBorderWidth + this.headerAreaHeight + this.cardAreaHeight);

        this.aboutBtnAreaHeight = this.smallScreen
            ? this.loginBtnHeight
            : this.headerSideBtnsHeight;

        this.scrollAreaHeight = this.smallScreen
            ? this.headerAreaHeight + this.spaceBelowHeader + this.cardAreaHeight + this.moreBtnHeight
            : screenHeight;

        this.cardTopMargin = this.smallScreen
            ? (this.cardAreaHeight - this.cardHeight * 6) / 6.0
            : (this.cardAreaHeight - this.cardHeight * 2) * 0.2;

        // Height to clip off of top of the header when minimized.
        this.maxHeaderYOffset = this.headerSideBtnsTopMargin * 1.5 - (Platform.OS === 'web' ? this.appBorderWidth * 6 : this.statusBarHeight * 0.85);
        if (Platform.OS === 'ios' && this.statusBarHeight < this.appBorderWidth * 5) {
            this.maxHeaderYOffset = this.headerSideBtnsTopMargin * 1.3 - this.statusBarHeight * 0.85;
        }
        this.maxHeaderYOffset = this.headerAreaHeight - this.headerSideBtnsHeight * 1.22;
        this.aboutBtnAreaTopMargin = this.smallScreen
            ? this.statusBarHeight + this.headerSideBtnsTopMargin + this.headerSideBtnsHeight + (this.spaceBelowHeader - this.aboutBtnAreaHeight) * 0.5
            : this.appBorderWidth + this.headerSideBtnsTopMargin;
    }
}


const QUOTE_DATA_CSV: string = `Elon Musk,I want Twitter to be America’s WeChat. “You basically live on WeChat in China.”,"Musk told Twitter staff that Americans don't have an equivalent to WeChat, which he says is ""so useful and so helpful to your daily life.""","During a company-wide Zoom conference at Twitter, Elon Musk told participants that Americans don't have an equivalent to China's WeChat, which he described as ""so useful and so helpful to your daily life.""\nElaborating on the specific features he would like to see on Twitter, Musk said that monetized videos ""should really be on Twitter."" ""Why aren't more people using Twitter, and why do people click away from Twitter?""",Maxwell Ilie,,https://www.youtube.com/watch?v=FLvTcRrzy20&t=488s,"Elon Musk, YouTube, Twitter, China"
Elon Musk,"""I gotta stop trolling people about the aliens stuff.""","""I'm not saying it's aliens, but, it's aliens,"" Musk joked before laughing and turning off his camera.","Elon Musk joked that, ""I'm not saying it's aliens, but, it's aliens"" during a company-wide Zoom conference at Twitter. After laughing and briefly turning off his camera, Musk clarified, saying, ""I gotta stop trolling people about the aliens stuff. To be clear… I've seen no actual evidence of aliens."" He then allowed the Twitter employee leading the call to continue asking her Twitter-related questions.",Maxwell Ilie,,https://www.youtube.com/watch?v=FLvTcRrzy20&t=2715,"Elon Musk, YouTube, Aliens, Twitter, Speeches, Interviews"
Peter Thiel,"""This is what they've been saying for 50 years: We're 5 years away from curing cancer.""","Thiel labeled the reply that ""cancer is hard to cure"" as ""propaganda"" that scientists tell in order to receive government research grants.","Author and PayPal co-founder Peter Thiel labeled the suggestion that ""cancer is hard to cure"" as ""propoganda"" that scientists tell in order to receive government research grants. His claims were made in the context of a debate on the topic: ""Technology Should Treat Death as an Enemy.""\nThiel's broader point was that non-digital innovation has slowed in recent decades due in large part to the research grant system that provides undesirable incentives for scientists. These incentives, he claimed, lead to scientists conducting ""fraudulent"" research in order to achieve as much public funding as possible.",Ash Salazar,,https://www.youtube.com/watch?v=qYSnVR7d4VE&t=4940,"Peter Thiel, YouTube, Debates, Science, Cancer"
Cenk Uygur,Iraq war could have funded America’s college for 20 years.,“It isn’t about big government or small government. It’s about what we do with the money.”,"In a 2017 Politicon debate with conservative figure and Daily Wire founder, Ben Shapiro, The Young Turks founder, Cenk Uygur took issue with what he claimed was a dichotomy Shapiro drew between ""big government and small government.""\nUygur said that “it isn’t about big government or small government; it’s about what we do with the money.” To further his point, Uygur argued that the ""big government"" war in Iraq, which he claimed Shapiro supported, could have been better used on a better ""big government"" expenditure such as free college: “That 1.5 trillion dollars could’ve gone to us, could’ve gone to the middle class, could’ve gone to a better economy.”\nUygur claimed $75B as the estimated annual cost of tuition in America, thus arriving at his conclusion that ""for the cost of 1 Iraq war, we could have gotten 20 years of free college for all those kids.""",Jeffrey Lee,,https://www.youtube.com/watch?v=SPqxcNRgL_8&t=3215s,"Cenk Uygur, YouTube, Politics, Left-Side Politics, Debates, Education"
Marc Andreesen,"“The most advanced form of medical science that understands consciousness is actually anesthesiology, because they know how to turn it off.”","""And then you have to power back on, which is very important. But they have no idea what's happening inside the black box.""","In a 2022 episode of the Joe Rogan Experience podcast, guest Marc Andreesen offered his take on Google's LaMDA chatbot, which one of Google's employees had claimed was conscious.\nAndreesen first explained how AI neural networks function on a broad level, before arguing that present-day AI is essentially a ""simulation"" of conversations had on the internet, and therefore not conscious. Andreesen explained the field of AI as ""basically training computers on how to understand, process, and then reflect back the real world.""\nDuring the podcast, Andreesen also explained the Turing Test, arguing that humans are easier fool than conscious AI is to create, and adding that humans don't even understand their own consciousness, much less that of a machine.",Christina Lakehart,,https://www.youtube.com/watch?v=RB-O0V9djEs&t=405s,"Marc Andreesen, YouTube, Podcasts, Joe Rogan, Science"
Matt Walsh,VR offers “most complete form of escape short of suicide.”,"“There’s nothing wrong with VR in small doses.” But, “people in our culture don’t engage in escapism and entertainment in small doses.”","Daily Wire commentator, Matt Walsh, said on his podcast that “these days we’re always out looking for any kind of reality but our actual reality.“ He added, “and that’s why our actual reality never improves.”\nBefore connecting virtual reality usage to his broader claim about escapism culture, Walsh said that “there’s nothing wrong with VR in small doses.”\nThe problem according to Walsh, however, is that “people in our culture don’t engage in escapism and entertainment in small doses. They turn it into a lifestyle.”",Joni Silky,,https://www.youtube.com/watch?v=V5f6wvcvu_o&t=464,"Matt Walsh, YouTube, Technology"
Jordan Peterson,“Fostered narcissism of the students” in Ivy League is “most intolerable.”,“All who gain entry to Yale or Harvard… are already part of the elite… but they are enticed… into identifying with the oppressed.”,"Jordan Peterson, in a YouTube video message to CEOs, said that “one of the things I found most intolerable about the Ivy League environment was the fostered narcissism of the students.”\n“Reflexive and thoughtless activism”, Peterson said, has “come to substitute for genuine apprenticeship and education,” enabling the “new elite” to “have their cake and eat it too.”\nPeterson further explained his thoughts, saying that Ivy League students, who are “already part of the elite” have “all the stature and opportunity” that comes with an Ivy League degree, while also having “all the moral high ground that accrues to the unjustly oppressed.”",Ransher Madison,,https://www.youtube.com/watch?v=e3d8qLkoYMk&t=1028,"Jordan Peterson, YouTube, Education, Politics"
Ro Khanna,“$18 wage on the ballot… a no brainer” for California.,"Gavin Newsom “has the complete authority to make this decision,” the US Congressional candidate tweeted.","Ro Khanna, a 2022 US Congressional candidate for California’s 17th district, tweeted his response to an article by The Young Turks, a popular left-wing media company.\n“We need Gavin Newsom to put an $18 wage on the ballot this fall,” Khanna said. “It’s a no brainer.”",Jeffrey Lee,,"<blockquote class=""twitter-tweet""><p lang=""en"" dir=""ltr"">We need Gavin Newsom to put an $18 wage on the ballot this fall. It’s a no brainer. He has the complete authority make this decision and stand with workers over big money. _<a href=""https://twitter.com/ryangrim?ref_src=twsrc%5Etfw"">@ryangrim</a>_ _<a href=""https://twitter.com/JosephNSanberg?ref_src=twsrc%5Etfw"">@JosephNSanberg</a>_ _<a href=""https://twitter.com/cenkuygur?ref_src=twsrc%5Etfw"">@cenkuygur</a>_ _<a href=""https://twitter.com/davidsirota?ref_src=twsrc%5Etfw"">@davidsirota</a>_ <a href=""https://t.co/TkWDhMrf9z"">https://t.co/TkWDhMrf9z</a></p>&mdash; Ro Khanna (@RoKhanna) <a href=""https://twitter.com/RoKhanna/status/1551208826711334912?ref_src=twsrc%5Etfw"">July 24, 2022</a></blockquote> <script async src=""https://platform.twitter.com/widgets.js"" charset=""utf-8""></script>","Ro Khanna, Twitter, Left-Side Politics, Politics, Economics"
Kurzgesagt,Best way to make friends is to spend time “in the real world.”,“Most important principle of making friends is to regularly spend time with people in the real world.”,"In a Kurzgesagt video, the narrator said that “the most important principle of making friends is to regularly spend time with people in the real world.”\nThe video offered a few more observations and pieces of advice on friendship, including the idea that “so many friendships fade for lack of attention, and often by accident.”\n“It’s not just adults who are too busy for friends,” the Kursgesagt narrator also noted. “The average American teenager spends more time on TikTok every day than socializing at parties, events, or on the phone with friends - combined.”",Leo Andrews,,https://www.youtube.com/watch?v=I9hJ_Rux9y0&t=163,"Kurzgesagt, YouTube, Motivation, Social Commentary, Relationships"
Vox,More people are using subtitles as tv has gotten harder to understand.,"“It feels like this hasn’t always been the case,” and there are technological explanations for it.","In a Vox video called “Why we all need subtitles now”, video producer Edward Vega explained why “57% of people said that they feel like they can’t understand the dialogue in the things that they watch unless they’re using subtitles.”\nVega blamed this on multiple causes: modern microphones enabling actors to speak softly, audio being mixed for high-contrast, 128-channel surround-sound speakers, and thinner laptops & TVs necessitating smaller speakers.",Leo Andrews,,https://www.youtube.com/watch?v=VYJtb2YXae8&t=16,"Vox, YouTube, Entertainment"
John McAfee,“Virus scan software is useless.”,The founder of McAfee Corp. said that “anti-virus software no longer works… It cannot protect us from modern threats.”,"In a 2018 interview with Patrick Bet-David, McAfee Corp. founder John McAfee discussed how the cybersecurity landscape had changed during the 30-plus years since he created the original McAfee antivirus software, saying that “anti-virus software no longer works.”\nWhen asked how to protect against modern hacking, McAfee explained that “most hacking is social engineering… it’s using people rather than hacking into a system.” In his estimation, “99% of all hacks today” involve a hacker using “a story” in order to trick people into giving them information.",Leo Andrews,,https://www.youtube.com/watch?v=WBgFGwJA1D0&t=560,"John McAfee, YouTube, Technology, Podcasts"
Destiny,"“Dating apps, I think, are generally overwhelmingly positive.”","“If it wasn’t for an online dating app, where would you even go to meet, like, a guy or a girl?”","Steven Bonnell, who goes by Destiny online, appeared on the Emily and Todd podcast to discuss dating. When asked about the “downsides” to dating apps, Destiny said that he has “thought about this a lot recently” and believes “online dating apps are… generally overwhelmingly positive.”\nIn order to explain why he considers dating apps to be a net positive, Destiny compared “the way that it used to work” to the modern internet age, when “you’ve stripped away all of the old community infrastructure.”\nWhile Destiny acknowledged that “what we had prior… would be preferable to what we have today,” the new reality according to him is that “we’ve kind of become more atomized and we’ve become more segregated”, and that “there isn’t really an opportunity… in the real world… to go out and find people.”\n“Once you get out of school - especially if you’re in a male-dominated industry - I have no idea where you go to find people.” This is why Destiny argued that “the dating apps can be good in that at least they give you a place to find and connect to other people… If it wasn’t for an online dating app, where would you even go to meet, like, a guy or a girl? I don’t know what the answer to that would be.”",Jeffrey Lee,,https://www.youtube.com/watch?v=bk0OQzaeZr4&t=72,"Destiny, YouTube, Relationships, Technology, Social Commentary"`;