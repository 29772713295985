import React, {Component} from "react";
import {Image, Platform, Pressable, Text, View} from "react-native";
import Hoverable from "../reusable/Hoverable";
import {CompDimensions, lightenHex} from "../../util/Util";
import {LinearGradient} from "expo-linear-gradient";
// @ts-ignore
import down_icon from '../../../assets/down_caret_new.png';

export class CategoryDropdown extends Component<{
    selectedCategory: string,
    categories: string[],
    dims: CompDimensions,
    width: number,
    optionHeight: number,
    selectCategoryFunc: any,
    expandCollapseFunc: any,
    shadow: boolean,
}> {

    state = {
        expanded: false,
        hoveredOption: null,
    }

    constructor(props) {
        super(props);
    }


    render() {
        /**
         * Returns a dropdown for selecting a category.
         */

            // Get and calculate component dimensions.
        const cd = this.props.dims;
        const contentMargin = 0.07 * this.props.width;
        const caretHeight = this.props.optionHeight * 0.7;
        const caretWidth = caretHeight;
        const textWidth = (this.props.width - caretWidth) * 0.8;
        const textHeight = this.props.optionHeight;


        return (
            <View nativeID={"searchBoxCategoryDropdown"}
                  style={{
                      position: "absolute",
                      width: this.props.width,
                      height: this.props.optionHeight * (this.state.expanded ? this.props.categories.length : 1),
                      borderRadius: this.props.optionHeight * 0.08,
                  }}>

                {/**
                 Dropdown rows
                 */}
                {this.props.categories.map((categoryName, index) => {

                    // Only show top row when dropdown not expanded.
                    if (!this.state.expanded && categoryName !== this.props.selectedCategory) return;

                    const isHovered = this.state.hoveredOption !== null && this.state.hoveredOption === categoryName;
                    const fontSize = 1.05 * Math.sqrt(textWidth * textHeight / categoryName.length);

                    return (
                        <View key={categoryName}
                              style={{
                                  width: this.props.width,
                                  height: this.props.optionHeight,
                              }}>
                            <Hoverable
                                onHoverIn={() => {
                                    this.setState({hoveredOption: categoryName});
                                }}
                                onHoverOut={() => {
                                    if (this.state.hoveredOption === categoryName) {
                                        setTimeout(() => this.setState({hoveredOption: null}), 75);
                                    }
                                }}
                                onPressIn={() => {
                                    this.setState({hoveredOption: categoryName});
                                }}
                                onPressOut={() => {
                                    if (Platform.OS === 'web' && !cd.smallScreen) return;
                                    if (this.state.hoveredOption === categoryName) {
                                        setTimeout(() => this.setState({hoveredOption: null}), 75);
                                    }
                                }}>
                                <Pressable
                                    onPress={() => setTimeout(() => {
                                        if (index === 0) {
                                            this.setState({expanded: !this.state.expanded});
                                            this.props.expandCollapseFunc();
                                        } else {
                                            this.props.selectCategoryFunc(categoryName);
                                            this.setState({expanded: false});
                                        }
                                    }, 150)}
                                    style={[
                                        {
                                            width: this.props.width,
                                            height: this.props.optionHeight,
                                            borderTopLeftRadius: index === 0 ? this.props.optionHeight * 0.08 : 0,
                                            borderTopRightRadius: index === 0 ? this.props.optionHeight * 0.08 : 0,
                                            borderBottomLeftRadius: (this.state.expanded && index === this.props.categories.length - 1) || (!this.state.expanded && index === 0) ? this.props.optionHeight * 0.08 : 0,
                                            borderBottomRightRadius: (this.state.expanded && index === this.props.categories.length - 1) || (!this.state.expanded && index === 0) ? this.props.optionHeight * 0.08 : 0,
                                            overflow: "hidden",
                                            borderWidth: this.props.optionHeight * 0.04,
                                            borderColor: lightenHex("#a5d2fa", 13),
                                            backgroundColor: isHovered ? "#c4e7ff" : "#d6eeff",
                                        }]}>
                                    <LinearGradient
                                        colors={isHovered
                                            ?  ["#c4e7ff", "#c4e7ff",]
                                            : ["#d6eeff", "#cfebff",]}
                                        start={[1, 0]}
                                        end={[0, 3.1]}
                                        style={[{
                                            width: this.props.width,
                                            height: this.props.optionHeight,
                                            overflow: this.state.expanded ? "visible" : "hidden",
                                        }]}
                                    >

                                        <View style={{
                                            width: this.props.width - contentMargin,
                                            height: this.props.optionHeight,
                                            marginLeft: contentMargin * 0.5,
                                            marginRight: contentMargin * 0.5,
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}>

                                            <Text style={{
                                                fontSize: fontSize,
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                color: isHovered
                                                    ? "#f2f7ff"
                                                    : "#333e47",
                                            }}
                                            selectable={false}>{categoryName}</Text>

                                            {index === 0 &&
                                                <Image source={down_icon}
                                                       style={{
                                                           width: caretWidth,
                                                           height: caretHeight,
                                                           marginLeft: "auto",
                                                           marginTop: caretHeight * 0.1,
                                                           marginRight: caretWidth * 0.1,
                                                           alignSelf: "center",
                                                           //marginBottom: isHovered && !this.state.expanded ? (this.props.optionHeight - caretHeight) / 3.3 : (this.props.optionHeight - caretHeight) * 0.32,
                                                       }}/>
                                            }
                                        </View>
                                    </LinearGradient>
                                </Pressable>
                            </Hoverable>
                        </View>
                    );
                })}
            </View>
        );
    }
}