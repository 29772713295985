import axios, {AxiosResponse} from "axios";
import {Platform} from "react-native";
import * as SecureStore from "expo-secure-store";
import {AuthTokens, getSecsSinceEpoch} from "../types/AuthTokens";

axios.defaults.withCredentials = (Platform.OS === 'web')

const BASE_URL = 'https://api.hayaquotes.com/';

export async function httpPost(endpoint: string,
                               parameters: object): Promise<AxiosResponse> {
    /**
     * endpoint: e.g. 'login'
     * parameters: e.g. {username: 'coolguy64', password: 'my4DOG399'}
     */

    // Format the url.
    if (endpoint.startsWith('/')) {
        endpoint = endpoint.substring(1);
    }
    let url = BASE_URL + endpoint;

    // Send the http request.
    let response = null;
    await axios.post(url, parameters)
        .then((resp) => {
            response = resp;
            console.log('Successfully fetched from /' + endpoint + ':');
            console.log(resp);
        }).catch((err) => {
            try {
                response = err.response;
            } catch (ignored) {
            }
            console.log('Error making POST request to /' + endpoint + ':');
            console.log(err);
        });
    return response;
}

export async function httpGet(endpoint: string,
                              parameters: object): Promise<AxiosResponse | null> {
    /**
     * endpoint: e.g. 'login'
     * parameters: e.g. {username: 'coolguy64', password: 'my4DOG399'}
     */
    // Format the url.
    if (endpoint.startsWith('/')) {
        endpoint = endpoint.substring(1);
    }
    let url = BASE_URL + endpoint;
    Object.keys(parameters).forEach((key, idx) => {
        if (idx === 0) {
            url = url + '?';
        } else {
            url = url + '&';
        }
        url = url + key + '=' + parameters[key];
    });

    // Send the http request.
    let response = null;
    console.log('requesting from: ' + url);
    await axios.get(url)
        .then((resp) => {
            response = resp;
            console.log('Successfully fetched from /' + endpoint + ':');
            console.log(resp);
        }).catch((err) => {
            try {
                response = err.response;
            } catch (ignored) {
            }
            console.log('Error making GET request to /' + endpoint + ':');
            console.log(err);
        });
    return response;
}

export async function authenticateUser(email: string,
                                       password: string): Promise<AuthTokens | string> {
    /**
     * Calls the /login endpoint.
     * Return AuthTokens, if successful, or string containing error message.
     */

        // Provide credentials to /login endpoint.
    const response = await httpGet('login', {
            email: email,
            password: password,
        });

    // Validate the response.
    if (response === null || response.status !== 200) {
        if (response !== null) {
            console.log('Error calling /login: ');
            console.log(response.data);
            return response.data;
        }
        return 'Technical difficulties. Please try again later.';
    }

    // Handle new auth tokens.
    try {

        // Create AuthTokens wrapper object.
        const authTokens: AuthTokens = decodeSerializableObject(response.data) as AuthTokens;

        // On mobile, store tokens locally and put them in all http headers.
        if (Platform.OS !== 'web') {
            await SecureStore.setItemAsync('auth_token', authTokens.auth_token);
            await SecureStore.setItemAsync('refresh_token', authTokens.refresh_token);
            axios.defaults.headers.common = {'Authorization': `Bearer ${authTokens.auth_token}`}
        }

        // Return the auth tokens.
        return authTokens;
    } catch (ignored) {
        console.log('Error calling /login: ');
        console.log(ignored);
        return 'Technical difficulties. Please try again later.';
    }
}

export async function registerUser(email: string,
                                   password: string,
                                   nickname: string): Promise<boolean | string> {
    /**
     * Calls the /signup endpoint.
     * Return true, if successful, or string containing error message.
     */

        // Provide credentials to /login endpoint.
    const response = await httpGet('signup', {
            email: email,
            password: password,
            nickname: nickname,
        });

    // Validate the response.
    if (response === null || response.status !== 200) {
        if (response !== null) {
            console.log('Error calling /signup: ');
            console.log(response.data);
            return response.data;
        }
        return 'Technical difficulties. Please try again later.';
    }

    // Handle temp auth token.
    try {

        // Create AuthTokens wrapper object.
        const data = response.data;

        // On mobile, store tokens locally and put them in all http headers.
        if (Platform.OS !== 'web') {
            await SecureStore.setItemAsync('temp_auth_token', data.temp_auth_token);
            axios.defaults.headers.common = {'Authorization': `Bearer ${data.temp_auth_token}`}
        }

        // Return success.
        return true;
    } catch (ignored) {
        console.log('Error calling /signup: ');
        console.log(ignored);
        return 'Technical difficulties. Please try again later.';
    }
}

// Define function to get new auth token using refresh token.
export async function getNewTokens(): Promise<AuthTokens> {
    /**
     * Calls either the /reauthenticate (web client) or /refresh_auth_token (mobile devices) endpoint.
     * Uses existing valid auth cookie/token to get a new one and extend the session.
     * Returns null if no/invalid auth cookie/token (user must log in with email & password).
     * Otherwise, returns an AuthTokens object containing valid auth tokens.
     */

    // Handle web clients (store tokens in browser cookie).
    if (Platform.OS === 'web') {

        // Query /reauthenticate with jwt cookie in the header.
        const response = await httpGet('reauthenticate', {});

        // Validate the response.
        if (!response) {
            return null;
        } else if (response.status !== 200) {
            console.log('Error calling /reauthenticate: ');
            console.log(response.data as string);
            return null;
        }

        // Return auth tokens in an AuthTokens object.
        try {
            const authTokens: AuthTokens = decodeSerializableObject(response.data) as AuthTokens;
        } catch (ignored) {
            console.log('Error calling /reauthenticate: ');
            console.log(ignored);
            return null;
        }
    }

    // Handle mobile clients (store tokens on device).
    else {

        // Get auth tokens from device storage.
        const authToken = await SecureStore.getItemAsync('auth_token');
        const tempAuthToken = await SecureStore.getItemAsync('temp_auth_token');
        const refreshToken = await SecureStore.getItemAsync('refresh_token');
        if (!tempAuthToken && !refreshToken) {
            return null;
        }

        // Query /refresh_auth_token with jwt tokens in the body.
        const response = await httpGet('refresh_auth_token', {
            auth_token: authToken,
            temp_auth_token: tempAuthToken,
            refresh_token: refreshToken,
        });

        // Validate the response.
        if (!response) {
            return null;
        } else if (response.status !== 200) {
            console.log('Error calling /reauthenticate: ');
            console.log(response.data as string);
            return null;
        }

        // Handle new auth tokens.
        try {

            // Create AuthTokens wrapper object.
            const authTokens: AuthTokens = decodeSerializableObject(response.data) as AuthTokens;

            // Store new auth tokens on the device.
            await SecureStore.setItemAsync('auth_token', authTokens.auth_token);
            await SecureStore.setItemAsync('refresh_token', authTokens.refresh_token);

            // Put new auth token in the header of all http requests.
            axios.defaults.headers.common = {'Authorization': `Bearer ${authTokens.auth_token}`}

            // Return the auth tokens.
            return authTokens;
        } catch (ignored) {
            console.log('Error calling /refresh_auth_token: ');
            console.log(ignored);
            return null;
        }
    }
}

export function decodeSerializableObject(data: string | object): object {
    /**
     * Decodes data responses from the backend into Objects which can be cast to Types.
     * For example: decodeSerializableObject(response.data) as QuoteData
     */
    if (typeof data == 'string') {
        data = JSON.parse(data);
    }
    for (const key in data as object) {
        if (key.endsWith('_serializable_object')) {
            data[key.substring(0, key.lastIndexOf('_serializable_object'))] = decodeSerializableObject(data[key]);
        }
    }
    return data as object;
}
