// Customizable settings.
import Animated from "react-native-reanimated";
import React from "react";

const BOLDIFY_SETTINGS = "- 0 1 1 2 0.4";

// Loaded settings.
const settings = loadSettings(BOLDIFY_SETTINGS);

// Function to parse settings, which  can be changed via 'BOLDIFY_SETTINGS'.
function loadSettings(boldifySettings) {

    try {
        let settings = {
            exclude: true,
            sizes: [],
            restRatio: 0.4
        };
        let comps = boldifySettings.split(' ');

        if (comps[0] == '+') {
            settings.exclude = false;
        }

        settings.restRatio = Number(comps[comps.length - 1]);

        for (let i = 1; i < comps.length - 1; i++) {
            settings.sizes.push(comps[i]);
        }
        return settings;
    } catch {
        let fallbackSettings = {
            exclude: true,
            sizes: [1, 1, 2],
            restRatio: 0.4
        };
        console.error("Boldify settings failed to parsed");
        return fallbackSettings;
    }
}

// Function to determine if a word is common (skips bolding).
function isCommon(word) {
    return commonWords.indexOf(word) != -1;
}

// Define common words (will not get bolded).
const commonWords = [
    'a',
    'an',
    'the',
    'and',
    'but',
    'be',
    'to',
    'for',
    'of',
    'on',
    'at',
    'he',
    'she',
    'my',
    'it',
    'is',
];

// Function to boldify an individual word.
function boldifyWord(word: string) {
    /**
     * Makes some of the word's first letters bold, according to settings.
     */

    const idx = word.length - 1;

    let numBold = 1;

    if ((word.length <= 3) && settings.exclude) {
        if (isCommon(word)) numBold = 0;
    } else if (idx < settings.sizes.length) {
        numBold = settings.sizes[idx];
    } else {

        numBold = Math.ceil(word.length * settings.restRatio);
    }

    let boldText = numBold > 0 ? ("<b>" + word.slice(0, numBold) + "</b>") : "";
    let normalText = word.slice(numBold);

    return boldText + normalText;
}

// Function to boldify a string (add <b></b> tags to it.
export function boldifyInput(input: string): string {
    /**
     * Boldens the first letter or few of uncommon words, using <b></b> tags.
     */

    let boldifiedInput = "";
    for (let word of input.split(" ")) {
        boldifiedInput += boldifyWord(word) + " ";
    }
    return boldifiedInput;
}

// Define function to handle text containing <b></b> tags.
export function renderBoldifiedText(input: string, normalFontFamily?: string, boldFontFamily?: string) {
    if (input.trim().length === 0) return null;

    // Find start of first bold tag.
    let startIdx = input.indexOf("<b>");

    // First, display any non-bold text that comes before the next bold tag.
    if (startIdx > 0) {
        return <Animated.Text
            style={normalFontFamily ? {
                    fontWeight: "normal",
                    fontFamily: normalFontFamily
                } : {fontWeight: "normal"}}
            >{input.slice(0, startIdx)}{renderBoldifiedText(input.slice(startIdx))}
        </Animated.Text>;
    } else if (startIdx === -1) {
        return <Animated.Text
            style={normalFontFamily ? {
                    fontWeight: "normal",
                    fontFamily: normalFontFamily
                } : {fontWeight: "normal"}}
            >{input}
            </Animated.Text>;
    }

    // Display bold text.
    input = input.slice(startIdx + "<b>".length);
    let endIdx = input.indexOf("</b>");
    let boldedText = input.slice(startIdx, endIdx);
    let nextText = input.slice(endIdx + "</b>".length);
    if (nextText.trim().length === 0) {
        return <Animated.Text
            style={boldFontFamily ? {fontWeight: "normal", fontFamily: boldFontFamily} : {fontWeight: "bold"}}
            >{boldedText}
            </Animated.Text>;
    } else {
        return <Animated.Text
            style={boldFontFamily ? {fontWeight: "normal", fontFamily: boldFontFamily} : {fontWeight: "bold"}}
            >{boldedText}{renderBoldifiedText(nextText)}
        </Animated.Text>;
    }
}