import {Component} from "react";
import {Image, Modal, Platform, Pressable, StyleSheet, Text, TextInput, View} from "react-native";
import Hoverable from "../reusable/Hoverable";
import {LinearGradient} from "expo-linear-gradient";
import {CompDimensions, validatePassword} from "../../util/Util";
// @ts-ignore
import verify_email from '../../../assets/verify_email.png';
import {httpGet} from "../../util/ApiUtil";
import SquishyButton from "../reusable/SquishyButton";

const emailCodeDescText = "Haya will email you a login code to enter on the next page."
const enterCodeDescText = "Check your inbox for the login code we sent. It expires in 7 minutes."

export class LoginModal extends Component<{
    dims: CompDimensions,
    yOffset: number,
    visible: boolean,
    onClose: any,
    authFunc: any,
    signUpFunc: any,
}> {

    state = {
        signingIn: true,
        signingUp: false,
        emailingCode: false,
        enteringCode: false,
        confirmingEmail: false,
        loginCodeInput: "",
        emailInput: "",
        firstNameInput: "",
        passwordInput: "",
        confirmPasswordInput: "",
        closeBtnHovered: false,
        forgotPassBtnHovered: false,
        emailCodeBtnHovered: false,
        enterCodeBtnHovered: false,
        backBtnHovered: false,
        rightSignUpBtnHovered: false,
        leftSignUpBtnHovered: false,
        signInBtnHovered: false,
        errorText: "",
    }

    constructor(props) {
        super(props);
        this.handleSignIn = this.handleSignIn.bind(this);
        this.handleSignUp = this.handleSignUp.bind(this);
        this.handleEmailNewCode = this.handleEmailNewCode.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    // Function called when modal closes.
    handleClose() {
        this.setState({
            signingIn: true,
            signingUp: false,
            emailingCode: false,
            enteringCode: false,
            confirmingEmail: false,
            errorText: "",
        });
        this.props.onClose();
    }

    // Function called when "send new login code" button pressed.
    async handleEmailNewCode() {
        // Sanitize email input.
        const email = this.state.emailInput.trim().toLowerCase();

        // Call the 'generate_login_code' API endpoint.
        const response = await httpGet('generate_login_code', {
            email: email.toLowerCase().trim(),
        });

        // Validate the response.
        if (response === null || response.status !== 200) {
            if (response !== null) {
                console.log('Error calling /generate_login_code: ');
                console.log(response.data);
                this.setState({errorText: response.data});
                return;
            }
            this.setState({errorText: 'Technical difficulties. Please try again later.'});
            return;
        }

        // TODO Show "Email Sent" alert.

        // Show page to enter login code.
        this.setState({
            signingIn: false,
            signingUp: false,
            emailingCode: false,
            enteringCode: true,
            confirmingEmail: false,
        })
    }

    // Function called when "verify login code" button pressed.
    async handleVerifyCode() {
        /**
         * Same logic as handleSignIn(), except password is replaced with login code.
         */
        // Try authenticating.
        this.setState({errorText: ""});
        const result = await this.props.authFunc(this.state.emailInput.trim().toLowerCase(), this.state.loginCodeInput.trim());

        // Return generic error message.
        if (result === null) {
            this.setState({errorText: 'Error verifying your login code.'})
            return;
        } else if (result !== true) {
            if (result.includes('Your email and password')) {
                this.setState({errorText: 'The code was incorrect.'});
                return;
            }
            this.setState({errorText: result});
            return;
        }

        // Close login modal if successfully authenticated.
        else {
            this.handleClose();
        }
    }

    // Function called when "sign in" button pressed.
    async handleSignIn() {
        // Try authenticating.
        this.setState({errorText: ""});
        const result = await this.props.authFunc(this.state.emailInput, this.state.passwordInput);

        // Return generic error message.
        if (result === null) {
            this.setState({errorText: 'Error signing in.'});
            return;
        }

        // If email needs verification, show "Confirm email" content until close.
        else if (result !== true) {
            if (result.includes('verify your email')) {
                this.setState({
                    signingIn: false,
                    signingUp: false,
                    emailingCode: false,
                    enteringCode: false,
                    confirmingEmail: true,
                    errorText: '',
                });
                return;
            } else {
                this.setState({
                    errorText: result,
                });
                return;
            }
        }

        // Close login modal if successfully authenticated.
        else {
            this.handleClose();
        }
    }

    // Function called when "sign up" button pressed.
    async handleSignUp() {
        let result = await this.props.signUpFunc(this.state.emailInput, this.state.passwordInput, this.state.firstNameInput);

        if (result !== true) {
            // Show error if signup could not be done.
            this.setState({errorText: result});
            return;
        } else {
            // Show "Confirm email" content until close.
            this.setState({
                signingIn: false,
                signingUp: false,
                emailingCode: false,
                enteringCode: false,
                confirmingEmail: true,
                errorText: '',
            });
        }
    }

    componentWillUnmount() {
        // Hide "confirm email" page when modal closes.
        this.setState({
            signingIn: true,
            signingUp: false,
            emailingCode: false,
            enteringCode: false,
            confirmingEmail: false,
        });
    }

    render() {
        // Get and calculate component dimensions.
        const cd = this.props.dims;
        const verticalMargin = cd.smallScreen ? cd.loginModalHeight * 0.03 : cd.loginModalHeight * 0.03;
        const headerTextWidth = cd.loginModalWidth * 0.85;
        const headerTextHeight = cd.smallScreen ? cd.loginModalHeight * 0.085 : cd.loginModalHeight * 0.1;
        const headerFontSize = cd.smallScreen
            ? 0.95 * Math.sqrt(headerTextWidth * headerTextHeight / "Sign-In or Sign-Up".length)
            : 0.99 * Math.sqrt(headerTextWidth * headerTextHeight / "Sign-In or Sign-Up".length);
        const inputFieldsWidth = cd.smallScreen ? cd.loginModalWidth * 0.65 : cd.loginModalWidth * 0.55;
        const inputFieldsHeight = cd.smallScreen ? cd.loginModalHeight * 0.08 : cd.loginModalHeight * 0.08;
        const inputFieldsFontSize = Math.sqrt(inputFieldsWidth * inputFieldsHeight / "longAssEmailAddress@gmail.com".length)
        const titlesTextWidth = inputFieldsWidth * 1.2;
        const titlesTextHeight = cd.smallScreen ? inputFieldsHeight * 0.5 : inputFieldsHeight * 0.5;
        const titlesTextTopMargin = cd.smallScreen ? titlesTextHeight * 0.03 : titlesTextHeight * 0.03;
        const titlesFontSize = cd.smallScreen
            ? 0.95 * Math.sqrt(titlesTextWidth * titlesTextHeight / "First Name / Nic".length)
            : 0.95 * Math.sqrt(titlesTextWidth * titlesTextHeight / "First Name / Nic".length);
        const titlesLeftMargin = cd.smallScreen
            ? (cd.loginModalWidth - inputFieldsWidth) * 0.35
            : (cd.loginModalWidth - inputFieldsWidth) * 0.35;
        const fieldsLeftMargin = cd.smallScreen
            ? (cd.loginModalWidth - inputFieldsWidth) * 0.5
            : (cd.loginModalWidth - inputFieldsWidth) * 0.5;
        const btnsAreaWidth = this.state.signingUp
            ? (cd.smallScreen ? headerTextWidth * 0.8 : headerTextWidth * 0.7)
            : (cd.smallScreen ? headerTextWidth : headerTextWidth * 0.8);
        const btnsAreaHeight = inputFieldsHeight * 1.3;
        const backBtnWidth = btnsAreaWidth * 0.37;
        const backBtnHeight = btnsAreaHeight * 0.65;
        const forgotPassBtnHeight = backBtnHeight;
        const signInBtnWidth = btnsAreaWidth * 0.5;
        const signInBtnHeight = btnsAreaHeight * 0.75;
        const signUpBtnWidth = btnsAreaWidth * 0.28;
        const signUpBtnHeight = btnsAreaHeight * 0.6;
        const emailCodeBtnWidth = btnsAreaWidth * 0.65;
        const emailCodeBtnHeight = btnsAreaHeight * 0.6;
        const emailCodeDescWidth = btnsAreaWidth;
        const emailCodeDescHeight = cd.loginModalHeight - (headerTextHeight + verticalMargin * 2 + titlesTextHeight * 2 + inputFieldsHeight * 2 + btnsAreaHeight + inputFieldsHeight) - (verticalMargin * 2 + emailCodeBtnHeight) - verticalMargin * 2.5;
        const signUpFontSize = 0.8 * Math.sqrt(signUpBtnWidth * signUpBtnHeight / "Sign Up".length);
        const signInFontSize = 1.6 * Math.sqrt(signInBtnHeight * signInBtnHeight / "Sign Up".length);
        const emailCodeBtnFontSize = (cd.smallScreen ? 0.85 : 0.95) * Math.sqrt((emailCodeBtnWidth * emailCodeBtnHeight) / "Send New Passcode".length);
        const emailCodeDescFontSize = 0.8 * Math.sqrt((emailCodeDescWidth * emailCodeDescHeight) / emailCodeDescText.length);
        const enterCodeDescFontSize = 0.8 * Math.sqrt((emailCodeDescWidth * emailCodeDescHeight) / enterCodeDescText.length);
        const orTextWidth = btnsAreaWidth - signInBtnWidth - signUpBtnWidth;
        const orTextHeight = btnsAreaHeight * 0.8;
        const orTextFontSize = 0.8 * Math.sqrt(orTextWidth * orTextHeight / " or ".length);
        const backBtnFontSize = (cd.smallScreen ? 0.8 : 1) * Math.sqrt(backBtnWidth * backBtnHeight / "<--  Sign In".length);
        const errorTextWidth = headerTextWidth;
        const errorTextHeight = inputFieldsHeight;
        let btnsAreaTopMargin = cd.loginModalHeight - (headerTextHeight + verticalMargin * 4 + titlesTextHeight * 4 + inputFieldsHeight * 4 + btnsAreaHeight + errorTextHeight) - verticalMargin * 2;
        if (this.state.signingIn) {
            btnsAreaTopMargin = btnsAreaTopMargin + verticalMargin + titlesTextHeight + inputFieldsHeight - (verticalMargin + forgotPassBtnHeight);
        } else if (this.state.emailingCode || this.state.enteringCode) {
            btnsAreaTopMargin = 0;
        }
        const errorFontSize = 0.9 * Math.sqrt(errorTextWidth * errorTextHeight / "This is the longest error one should hope to encounter when attempting to log in or sign up".length);

        let backBtnText = "<--  Sign In";
        if (this.state.emailingCode || this.state.enteringCode) {
            backBtnText = "<--  Sign In with Password";
        }
        let closeText = "> <";


        // Create reusable styles.
        const styles = StyleSheet.create({
            fieldContainer: {
                width: inputFieldsWidth,
                height: titlesTextHeight + titlesTextTopMargin + inputFieldsHeight,
                marginTop: verticalMargin,
            },
            titlesTextContainer: {
                width: titlesTextWidth,
                height: titlesTextHeight,
                marginBottom: titlesTextTopMargin,
                justifyContent: "flex-start",
            },
            titlesText: {
                marginLeft: titlesLeftMargin,
                fontSize: titlesFontSize,
            },
            inputField: {
                width: inputFieldsWidth,
                height: inputFieldsHeight,
                marginLeft: fieldsLeftMargin,
                fontSize: inputFieldsFontSize,
                borderWidth: inputFieldsHeight * 0.06,
                borderColor: "lightgray",
                borderRadius: inputFieldsHeight * 0.08,
            },
            emailCodeBtnDims: {
                width: emailCodeBtnWidth,
                height: emailCodeBtnHeight,
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: emailCodeBtnHeight * 0.5,
            },
            emailCodeBtnStyles: {
                borderWidth: emailCodeBtnHeight * 0.06,
                borderColor: this.state.emailCodeBtnHovered ? "#7fb2f0c2" : "#7fb2f0e6",
                justifyContent: "center",
                alignItems: "center",
            },
            emailCodeBtnShadow: {
                shadowOpacity: cd.smallScreen ? 0.6 : 0.8,
                shadowColor: "#7dbaff",
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? emailCodeBtnHeight * 0.001 : 0,
                },
                shadowRadius: cd.smallScreen ? signInBtnHeight * 0.03 : signInBtnHeight * 0.06,
            },
            enterCodeBtnDims: {
                width: emailCodeBtnWidth,
                height: emailCodeBtnHeight,
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: emailCodeBtnHeight * 0.5,
            },
            enterCodeBtnStyles: {
                borderWidth: emailCodeBtnHeight * 0.06,
                borderColor: this.state.enterCodeBtnHovered ? "#7fb2f0c2" : "#7fb2f0e6",
                justifyContent: "center",
                alignItems: "center",
            },
            enterCodeBtnShadow: {
                shadowOpacity: cd.smallScreen ? 0.6 : 0.8,
                shadowColor: "#7dbaff",
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? emailCodeBtnHeight * 0.001 : 0,
                },
                shadowRadius: cd.smallScreen ? signInBtnHeight * 0.03 : signInBtnHeight * 0.06,
            },
            signUpBtnDims: {
                width: signUpBtnWidth,
                height: signUpBtnHeight,
                marginTop: "auto",
                marginBottom: "auto",
                borderRadius: signUpBtnHeight * 0.5,
            },
            signUpBtnStyles: {
                borderWidth: signUpBtnHeight * 0.06,
                borderColor: "#7fb2f0e6",
                justifyContent: "center",
                alignItems: "center",
            },
            signUpBtnShadow: {
                shadowOpacity: cd.smallScreen ? 0.6 : 0.8,
                shadowColor: "#7dbaff",
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? signUpBtnHeight * 0.001 : signUpBtnHeight * 0.01,
                },
                shadowRadius: cd.smallScreen ? signUpBtnHeight * 0.03 : signUpBtnHeight * 0.06,
            },
            signUpBtnHover: {
                borderColor: "#7fb2f0c2",
            },
            signInBtnDims: {
                width: signInBtnWidth,
                height: signInBtnHeight,
                marginTop: "auto",
                marginBottom: "auto",
                borderRadius: signInBtnHeight * 0.5,
            },
            signInBtnStyles: {
                borderWidth: signInBtnHeight * 0.06,
                borderColor: this.state.signInBtnHovered ? "#7fb2f0c2" : "#7fb2f0e6",
                justifyContent: "center",
                alignItems: "center",
            },
            signInBtnShadow: {
                shadowOpacity: cd.smallScreen ? 0.6 : 0.8,
                shadowColor: "#7dbaff",
                shadowOffset: {
                    width: 0,
                    height: cd.smallScreen ? signInBtnHeight * 0.001 : 0,
                },
                shadowRadius: cd.smallScreen ? signInBtnHeight * 0.03 : signInBtnHeight * 0.06,
            },
            signInBtnHover: {},
            backBtnDims: {
                width: (this.state.emailingCode || this.state.enteringCode) ? btnsAreaWidth : backBtnWidth,
                height: backBtnHeight,
                marginTop: "auto",
                marginBottom: "auto",
            },
            backBtnStyles: {
                justifyContent: "center",
                alignItems: (this.state.emailingCode || this.state.enteringCode) ? "flex-start" : "center",
            },
            forgotPassBtnDims: {
                height: forgotPassBtnHeight,
                marginTop: verticalMargin,
                marginLeft: "auto",
                marginRight: "auto",
            },
            closeBtnContainer: {
                // @ts-ignore
                position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
                width: cd.loginBtnWidth,
                height: cd.smallScreen ? cd.loginBtnHeight * 1.01 : cd.loginBtnHeight,
                marginTop: cd.statusBarHeight - this.props.yOffset + cd.loginBtnHeight * 0.01 + cd.loginBtnTopMargin - (this.props.yOffset > 0 ? cd.loginBtnHeight * 0.3 : 0),
                marginLeft: cd.screenWidth * 0.5 - cd.loginBtnWidth * 0.5,
                borderRadius: cd.headerSideBtnsWidth * 0.5,
            },
            closeBtnPressable: {
                width: cd.loginBtnWidth,
                height: cd.loginBtnHeight,
            },
        });


        return <Modal animationType={cd.smallScreen ? "none" : "none"}
                      onDismiss={this.props.onClose}
                      onRequestClose={this.props.onClose}
                      transparent={true}
                      visible={this.props.visible}
        >
            <View>

                {/**
                 Gray Screen Content Behind Modal
                 */}
                <Pressable
                    onPress={this.handleClose}
                    isTVSelectable={false}
                    // @ts-ignore
                    style={{
                        position: (cd.smallScreen && Platform.OS === 'web') ? "fixed" : "absolute",
                        width: cd.screenWidth,
                        height: cd.screenHeight,
                        backgroundColor: "rgba(192,194,207,0.94)",
                        // @ts-ignore
                        cursor: "default",
                    }}>
                </Pressable>


                {/**
                 Login/Signup Form with Border
                 */}
                <View
                    nativeID={"loginFormContainer"}
                    // @ts-ignore
                    style={{
                        width: cd.loginModalWidth,
                        height: cd.loginModalHeight,
                        marginTop: cd.statusBarHeight - this.props.yOffset + cd.loginBtnTopMargin + cd.loginBtnHeight * 0.5 - (this.props.yOffset > 0 ? cd.loginBtnHeight * 0.3 : 0),
                        marginLeft: "auto",
                        marginRight: "auto",
                        backgroundColor: "#fcfffffc",
                        borderColor: "#e4edf293",
                        borderWidth: cd.loginBtnBorderRadius * 0.5,
                        borderRadius: cd.loginBtnBorderRadius,
                        shadowColor: "#e4edf293",
                        shadowRadius: cd.smallScreen ? cd.loginModalWidth * 0.1 : cd.loginModalWidth * 0.01,
                        elevation: 5,
                        overflow: "hidden",
                    }}>

                    {/**
                     Login/Signup Form Inner Container
                     */}
                    <View
                        style={{
                            width: cd.loginModalWidth,
                            height: cd.loginModalHeight,
                            marginTop: cd.smallScreen ? verticalMargin * 2 : verticalMargin * 2,
                            flex: 1,
                            flexDirection: "column",
                            justifyContent: "flex-start",
                        }}>

                        {/**
                         Header Text
                         */}
                        <View style={{
                            width: headerTextWidth,
                            height: headerTextHeight,
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}>
                            <Text style={{
                                width: headerTextWidth,
                                height: headerTextHeight,
                                paddingTop: headerTextHeight * 0.1,
                                marginLeft: "auto",
                                marginRight: "auto",
                                fontSize: headerFontSize,
                                fontWeight: "bold",
                                textAlign: "center",
                            }}>
                                {this.state.confirmingEmail ? "Check Your Email" : (this.state.emailingCode ? "Email Login Code" : (this.state.enteringCode ? "Enter Login Code" : "Sign-In or Sign-Up"))}
                            </Text>
                        </View>

                        {/**
                         Confirm Email Area
                         */}
                        {this.state.confirmingEmail &&
                            <>
                                <View
                                    style={{
                                        width: btnsAreaWidth,
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}>
                                    <Image
                                        source={verify_email}
                                        style={{
                                            width: (btnsAreaHeight * 2) * (492 / 512),
                                            height: btnsAreaHeight * 2,
                                            marginTop: verticalMargin * 2,
                                            marginBottom: verticalMargin * 2,
                                        }}/>
                                </View>
                                <View
                                    style={{
                                        width: btnsAreaWidth,
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}>
                                    <Text
                                        style={{
                                            fontSize: headerFontSize * 0.8,
                                            width: btnsAreaWidth,
                                            textAlign: "center",
                                            color: "#233157",
                                        }}>Please open our welcome email to confirm your account. You're one step away
                                        from
                                        activating your account with search and save features!</Text>
                                </View>
                            </>
                        }

                        {/**
                         First Name Area
                         */}
                        {this.state.signingUp &&
                            <View style={[styles.fieldContainer]}>
                                <View style={[styles.titlesTextContainer]}>
                                    <Text style={[styles.titlesText]}>First Name / Nickname</Text>
                                </View>
                                <TextInput
                                    secureTextEntry={false}
                                    textContentType={"username"}
                                    maxLength={12}
                                    allowFontScaling={false}
                                    autoCapitalize={"none"}
                                    autoComplete={"off"}
                                    clearButtonMode={"while-editing"}
                                    underlineColorAndroid={"transparent"}
                                    onChangeText={(input) => {
                                        this.setState({firstNameInput: input})
                                    }}
                                    value={this.state.firstNameInput}
                                    style={[styles.inputField]}>
                                </TextInput>
                            </View>
                        }

                        {/**
                         Email Area
                         */}
                        {(this.state.signingIn || this.state.signingUp || this.state.emailingCode) &&
                            <View style={[styles.fieldContainer, !this.state.signingUp ? {
                                marginTop: (verticalMargin + titlesTextTopMargin + titlesTextHeight + inputFieldsHeight) + verticalMargin,
                            } : null]}>
                                <View style={[styles.titlesTextContainer]}>
                                    <Text style={[styles.titlesText]}>Email Address</Text>
                                </View>
                                <TextInput
                                    secureTextEntry={false}
                                    textContentType={"username"}
                                    maxLength={48}
                                    allowFontScaling={false}
                                    autoCapitalize={"none"}
                                    autoComplete={"off"}
                                    clearButtonMode={"while-editing"}
                                    underlineColorAndroid={"transparent"}
                                    onChangeText={(input) => {
                                        this.setState({emailInput: input})
                                    }}
                                    value={this.state.emailInput}
                                    style={[styles.inputField]}>
                                </TextInput>
                            </View>
                        }

                        {/**
                         "Enter Login Code" Area
                         */}
                        {this.state.enteringCode &&
                            <View style={[styles.fieldContainer, !this.state.signingUp ? {
                                marginTop: (verticalMargin + titlesTextTopMargin + titlesTextHeight + inputFieldsHeight) + verticalMargin,
                            } : null]}>
                                <View style={[styles.titlesTextContainer]}>
                                    <Text style={[styles.titlesText]}>Login Code</Text>
                                </View>
                                <TextInput
                                    maxLength={7}
                                    allowFontScaling={false}
                                    autoCapitalize={"none"}
                                    autoComplete={"off"}
                                    clearButtonMode={"while-editing"}
                                    underlineColorAndroid={"transparent"}
                                    onChangeText={(input) => {
                                        this.setState({loginCodeInput: input})
                                    }}
                                    value={this.state.loginCodeInput}
                                    style={[styles.inputField]}>
                                </TextInput>
                            </View>
                        }

                        {/**
                         Password Area
                         */}
                        {(this.state.signingIn || this.state.signingUp) &&
                            <View style={[styles.fieldContainer]}>
                                <View style={[styles.titlesTextContainer]}>
                                    <Text style={[styles.titlesText]}>Password</Text>
                                </View>
                                <TextInput
                                    secureTextEntry={true}
                                    textContentType={"password"}
                                    maxLength={32}
                                    allowFontScaling={false}
                                    autoCapitalize={"none"}
                                    autoComplete={"off"}
                                    clearButtonMode={"while-editing"}
                                    underlineColorAndroid={"transparent"}
                                    onChangeText={(input) => {
                                        this.setState({passwordInput: input});
                                        // Ignore validation if field is empty.
                                        if (input.length === 0) {
                                            if (this.state.errorText.startsWith('Password ')) {
                                                this.setState({errorText: ""});
                                            }
                                            return;
                                        }

                                        // Remove matching message if passwords now match.
                                        if (this.state.errorText.startsWith('Confirm Password ') && input === this.state.confirmPasswordInput) {
                                            this.setState({errorText: ""});
                                        }

                                        // Validate password.
                                        const invalidPassReason = validatePassword(input);
                                        if (invalidPassReason !== '') {
                                            this.setState({errorText: invalidPassReason});
                                        }

                                        // Clear previous error message.
                                        else if (this.state.errorText.startsWith('Password ')) {
                                            this.setState({errorText: ""});
                                        }
                                    }}
                                    onSubmitEditing={(nativeEvent) => {
                                        if (this.state.signingIn) {
                                            this.handleSignIn();
                                        }
                                    }}
                                    value={this.state.passwordInput}
                                    style={[styles.inputField]}>
                                </TextInput>
                            </View>
                        }

                        {/**
                         Forgot Password Button
                         */}
                        {this.state.signingIn &&
                            <Hoverable
                                onHoverIn={() => this.setState({forgotPassBtnHovered: true})}
                                onHoverOut={() => this.setState({forgotPassBtnHovered: false})}
                                onPressIn={() => this.setState({forgotPassBtnHovered: true})}
                                onPressOut={() => {
                                    if (Platform.OS === 'web') return;
                                    setTimeout(() => this.setState({forgotPassBtnHovered: false}), 50)
                                }}
                            >
                                <Pressable style={styles.forgotPassBtnDims}
                                           onPress={() => setTimeout(() => this.setState({
                                               signingIn: false,
                                               emailingCode: true,
                                               errorText: "",
                                           }), 150)}>
                                    <View
                                        style={styles.forgotPassBtnDims}>
                                        <Text style={{
                                            fontSize: backBtnFontSize,
                                            textDecorationLine: "underline",
                                            color: this.state.forgotPassBtnHovered ? "#5a5b5b" : "#2e2e2e",
                                            fontWeight: "normal",
                                        }}>Forgot Password?</Text>
                                    </View>
                                </Pressable>
                            </Hoverable>
                        }

                        {/**
                         "Email Code" Button
                         */}
                        {this.state.emailingCode &&
                            <Hoverable
                                onHoverIn={() => this.setState({emailCodeBtnHovered: true})}
                                onHoverOut={() => this.setState({emailCodeBtnHovered: false})}
                                onPressIn={() => this.setState({emailCodeBtnHovered: true})}
                                onPressOut={() => setTimeout(() => {
                                    if (Platform.OS === 'web') return;
                                    this.setState({emailCodeBtnHovered: false})
                                }, 50)}
                            >
                                <Pressable style={[
                                    styles.emailCodeBtnDims,
                                    styles.emailCodeBtnShadow,
                                    {
                                        transform: [{scale: cd.smallScreen && this.state.emailCodeBtnHovered ? 1.01 : 1}],
                                        marginTop: verticalMargin * 1.5,
                                    }
                                ]}
                                           onPress={() => this.handleEmailNewCode()}>
                                    <LinearGradient
                                        colors={this.state.emailCodeBtnHovered ?
                                            ["#56b7fca3", "#65b1f7a3", "#55aafaa3", "#50a8faa1", "#61b3ffa1"] :
                                            ["#61abff84", "#67a9f586", "#5ca4f794", "#5db0fc97", "#53a2fca3"]}
                                        start={[1, 0]}
                                        end={[0, 3.5]}
                                        style={[styles.emailCodeBtnDims,
                                            styles.emailCodeBtnStyles]}>
                                        <Text style={{
                                            fontSize: emailCodeBtnFontSize,
                                            color: "#fafcff",
                                            fontWeight: "bold",
                                        }}>Send New Code</Text>
                                    </LinearGradient>
                                </Pressable>
                            </Hoverable>
                        }

                        {/**
                         "Enter Code" Button
                         */}
                        {this.state.enteringCode &&
                            <Hoverable
                                onHoverIn={() => this.setState({enterCodeBtnHovered: true})}
                                onHoverOut={() => this.setState({enterCodeBtnHovered: false})}
                                onPressIn={() => this.setState({enterCodeBtnHovered: true})}
                                onPressOut={() => setTimeout(() => {
                                    if (Platform.OS === 'web') return;
                                    this.setState({enterCodeBtnHovered: false})
                                }, 50)}
                            >
                                <Pressable style={[
                                    styles.enterCodeBtnDims,
                                    styles.enterCodeBtnShadow,
                                    {
                                        transform: [{scale: cd.smallScreen && this.state.enterCodeBtnHovered ? 1.01 : 1}],
                                        marginTop: verticalMargin * 1.5,
                                    }
                                ]}
                                           onPress={() => this.handleVerifyCode()}>
                                    <LinearGradient
                                        colors={this.state.enterCodeBtnHovered ?
                                            ["#56b7fca3", "#65b1f7a3", "#55aafaa3", "#50a8faa1", "#61b3ffa1"] :
                                            ["#61abff84", "#67a9f586", "#5ca4f794", "#5db0fc97", "#53a2fca3"]}
                                        start={[1, 0]}
                                        end={[0, 3.5]}
                                        style={[styles.enterCodeBtnDims,
                                            styles.enterCodeBtnStyles]}>
                                        <Text style={{
                                            fontSize: emailCodeBtnFontSize,
                                            color: "#fafcff",
                                            fontWeight: "bold",
                                        }}>Verify Code</Text>
                                    </LinearGradient>
                                </Pressable>
                            </Hoverable>
                        }

                        {/**
                         "Email Code" Information Text
                         */}
                        {this.state.emailingCode &&
                            <View
                                style={{
                                    width: emailCodeDescWidth,
                                    height: emailCodeDescHeight,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginTop: verticalMargin,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                <Text style={{
                                    textAlign: "center",
                                    fontSize: emailCodeDescFontSize,
                                    color: "#2e51ab",
                                }}>{emailCodeDescText}</Text>
                            </View>
                        }

                        {/**
                         "Enter Code" Information Text
                         */}
                        {this.state.enteringCode &&
                            <View
                                style={{
                                    width: emailCodeDescWidth,
                                    height: emailCodeDescHeight,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginTop: verticalMargin,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                <Text style={{
                                    textAlign: "center",
                                    fontSize: enterCodeDescFontSize,
                                    color: "#2e51ab",
                                }}>{enterCodeDescText}</Text>
                            </View>
                        }

                        {/**
                         Confirm Password Area
                         */}
                        {this.state.signingUp &&
                            <View style={[styles.fieldContainer]}>
                                <View style={[styles.titlesTextContainer]}>
                                    <Text style={[styles.titlesText]}>Confirm Password</Text>
                                </View>
                                <TextInput
                                    secureTextEntry={true}
                                    textContentType={"password"}
                                    maxLength={32}
                                    allowFontScaling={false}
                                    autoCapitalize={"none"}
                                    autoComplete={"off"}
                                    clearButtonMode={"while-editing"}
                                    underlineColorAndroid={"transparent"}
                                    onChangeText={(input) => {
                                        this.setState({confirmPasswordInput: input});
                                        // Disable validation if field is empty.
                                        if (input.length === 0) {
                                            if (this.state.errorText.startsWith('Confirm Password ')) {
                                                this.setState({errorText: ""});
                                            }
                                            return;
                                        }

                                        // Display error if passwords don't match.
                                        if (this.state.passwordInput !== input) {
                                            this.setState({errorText: "Confirm Password must be the same as Password"});
                                        }

                                        // Remove old error message if passwords now match.
                                        else if (this.state.errorText.startsWith('Confirm Password ') && this.state.passwordInput === input) {
                                            this.setState({errorText: ""});
                                        }
                                    }}
                                    value={this.state.confirmPasswordInput}
                                    style={[styles.inputField]}>
                                </TextInput>
                            </View>
                        }


                        {/**
                         Buttons Area
                         */}
                        <View nativeID={"signInUpBtnsArea"}
                              style={{
                                  width: btnsAreaWidth,
                                  height: btnsAreaHeight,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  marginTop: btnsAreaTopMargin,
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                              }}>

                            {/**
                             Back Button
                             */}
                            {(this.state.signingUp || this.state.emailingCode || this.state.enteringCode) &&
                                <Hoverable
                                    onHoverIn={() => this.setState({backBtnHovered: true})}
                                    onHoverOut={() => this.setState({backBtnHovered: false})}
                                    onPressIn={() => this.setState({backBtnHovered: true})}
                                    onPressOut={() => {
                                        if (Platform.OS === 'web') return;
                                        setTimeout(() => this.setState({backBtnHovered: false}), 50)
                                    }}
                                >
                                    <Pressable style={styles.backBtnDims}
                                               onPress={() => setTimeout(() => {
                                                   this.setState({
                                                       emailingCode: false,
                                                       enteringCode: false,
                                                       signingUp: false,
                                                       signingIn: true,
                                                       errorText: "",
                                                   });
                                               }, 150)}>
                                        <View
                                            style={[styles.backBtnDims,
                                                styles.backBtnStyles]}>
                                            <Text style={{
                                                fontSize: backBtnFontSize,
                                                color: this.state.backBtnHovered ? "#5a5b5b" : "#1c1c1c",
                                                fontWeight: "bold",
                                            }}>{backBtnText}</Text>
                                        </View>
                                    </Pressable>
                                </Hoverable>
                            }

                            {/**
                             Left Sign Up Button
                             */}
                            {this.state.signingIn &&
                                <Hoverable
                                    onHoverIn={() => this.setState({leftSignUpBtnHovered: true})}
                                    onHoverOut={() => this.setState({leftSignUpBtnHovered: false})}
                                    onPressIn={() => this.setState({leftSignUpBtnHovered: true})}
                                    onPressOut={() => {
                                        if (Platform.OS === 'web') return;
                                        setTimeout(() => this.setState({leftSignUpBtnHovered: false}), 50)
                                    }}
                                >
                                    <Pressable style={[
                                        styles.signUpBtnDims,
                                        styles.signUpBtnShadow,
                                        {
                                            transform: [{scale: cd.smallScreen && this.state.leftSignUpBtnHovered ? 1.015 : 1}],
                                        }
                                    ]}
                                               onPress={() => setTimeout(() => this.setState({
                                                   signingIn: false,
                                                   signingUp: true,
                                                   errorText: "",
                                               }), 150)}>
                                        <LinearGradient
                                            colors={this.state.leftSignUpBtnHovered ?
                                                ["#56b7fca3", "#65b1f7a3", "#55aafaa3", "#50a8faa1", "#61b3ffa1"] :
                                                ["#61abff84", "#67a9f586", "#5ca4f794", "#5db0fc97", "#53a2fca3"]}
                                            start={[1, 0]}
                                            end={[0, 3.5]}
                                            style={[styles.signUpBtnDims,
                                                styles.signUpBtnStyles,
                                                this.state.leftSignUpBtnHovered ? styles.signUpBtnHover : null]}>
                                            <Text style={{
                                                fontSize: signUpFontSize,
                                                color: "#fafcff",
                                                fontWeight: "bold",
                                            }}>Sign Up</Text>
                                        </LinearGradient>
                                    </Pressable>
                                </Hoverable>
                            }

                            {/**
                             "or" Text
                             */}
                            {this.state.signingIn &&
                                <View style={{
                                    width: orTextWidth,
                                    height: btnsAreaHeight,
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                }}>
                                    <View style={{
                                        width: orTextWidth,
                                        height: btnsAreaHeight,
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                        <Text style={{
                                            fontSize: orTextFontSize,
                                            color: "#5c5959",
                                            fontStyle: "italic",
                                        }}> or </Text>
                                    </View>
                                </View>
                            }

                            {/**
                             Right Sign In Button
                             */}
                            {this.state.signingIn &&
                                <Hoverable
                                    onHoverIn={() => this.setState({signInBtnHovered: true})}
                                    onHoverOut={() => this.setState({signInBtnHovered: false})}
                                    onPressIn={() => this.setState({signInBtnHovered: true})}
                                    onPressOut={() => setTimeout(() => {
                                        if (Platform.OS === 'web') return;
                                        this.setState({signInBtnHovered: false})
                                    }, 50)}
                                >
                                    <Pressable style={[
                                        styles.signInBtnDims,
                                        styles.signInBtnShadow,
                                        {
                                            transform: [{scale: cd.smallScreen && this.state.signInBtnHovered ? 1.01 : 1}],
                                        }
                                    ]}
                                               onPress={() => this.handleSignIn()}>
                                        <LinearGradient
                                            colors={this.state.signInBtnHovered ?
                                                ["#56b7fca3", "#65b1f7a3", "#55aafaa3", "#50a8faa1", "#61b3ffa1"] :
                                                ["#61abff84", "#67a9f586", "#5ca4f794", "#5db0fc97", "#53a2fca3"]}
                                            start={[1, 0]}
                                            end={[0, 3.5]}
                                            style={[styles.signInBtnDims,
                                                styles.signInBtnStyles,
                                                this.state.signInBtnHovered ? styles.signInBtnHover : null]}>
                                            <Text style={{
                                                fontSize: signInFontSize,
                                                color: "#fafcff",
                                                fontWeight: "bold",
                                            }}>Sign In</Text>
                                        </LinearGradient>
                                    </Pressable>
                                </Hoverable>
                            }

                            {/**
                             Right Sign Up Button
                             */}
                            {this.state.signingUp &&
                                <View style={[
                                    styles.signInBtnDims,
                                    styles.signUpBtnShadow,
                                    {
                                        transform: [{scale: cd.smallScreen && this.state.rightSignUpBtnHovered ? 1.015 : 1}],
                                    }
                                ]}>
                                    <Hoverable
                                        onHoverIn={() => this.setState({rightSignUpBtnHovered: true})}
                                        onHoverOut={() => this.setState({rightSignUpBtnHovered: false})}
                                        onPressIn={() => this.setState({rightSignUpBtnHovered: true})}
                                        onPressOut={() => setTimeout(() => {
                                            if (Platform.OS === 'web') return;
                                            this.setState({rightSignUpBtnHovered: false})
                                        }, 50)}
                                    >
                                        <Pressable style={styles.signInBtnDims}
                                                   onPress={() => setTimeout(() => this.handleSignUp(), 150)}>
                                            <LinearGradient
                                                colors={this.state.rightSignUpBtnHovered ?
                                                    ["#56b7fca3", "#65b1f7a3", "#55aafaa3", "#50a8faa1", "#61b3ffa1"] :
                                                    ["#61abff84", "#67a9f586", "#5ca4f794", "#5db0fc97", "#53a2fca3"]}
                                                start={[1, 0]}
                                                end={[0, 3.5]}
                                                style={[styles.signInBtnDims,
                                                    styles.signUpBtnStyles,
                                                    this.state.rightSignUpBtnHovered ? styles.signUpBtnHover : null]}>
                                                <Text style={{
                                                    fontSize: (cd.smallScreen ? 0.8 : 1) * signInFontSize,
                                                    color: "#fafcff",
                                                    fontWeight: "bold",
                                                }}>Sign Up</Text>
                                            </LinearGradient>
                                        </Pressable>
                                    </Hoverable>
                                </View>
                            }
                        </View>


                        {/**
                         Error Text Area
                         */}
                        <View style={{
                            width: errorTextWidth,
                            height: errorTextHeight,
                            marginLeft: "auto",
                            marginRight: "auto",
                            overflow: "visible",
                        }}>
                            <View style={{
                                width: errorTextWidth,
                                height: errorTextHeight,
                                paddingBottom: errorTextHeight * 0.2,
                                overflow: "visible",
                                justifyContent: "center",
                            }}>
                                <Text style={{
                                    fontSize: errorFontSize,
                                    textAlign: "center",
                                    color: "#fc6753",
                                    fontWeight: "bold",
                                }}>{this.state.errorText}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>


                {/**
                 "Close" Button
                 */}
                <SquishyButton
                    isSmallScreen={cd.smallScreen}
                    pressFunc={this.handleClose}
                    isHovered={this.state.closeBtnHovered}
                    setHoveredFunc={(newBool) => this.setState({closeBtnHovered: newBool})}
                    containerStyle={styles.closeBtnContainer}
                    buttonStyle={styles.closeBtnPressable}>
                    <LinearGradient
                        colors={this.state.closeBtnHovered && !cd.smallScreen ?
                            ["#fafcfcfc", "#fafcfcfc",] :
                            ["#fcfffffc", "#f0f4f7", "#fafcfcfc"]
                        }
                        style={{
                            width: cd.loginBtnWidth,
                            height: cd.loginBtnHeight,
                            borderRadius: cd.loginBtnBorderRadius,
                            borderColor: "#f0f4f7",
                            borderWidth: cd.loginBtnBorderRadius * 0.4,
                        }}>
                        <Text style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "auto",
                            marginBottom: "auto",
                            color: this.state.closeBtnHovered && !cd.smallScreen ? "#6b6d73" : "#383b45",
                            fontWeight: "bold",
                            fontSize: 0.7 * Math.sqrt((cd.loginBtnWidth * cd.loginBtnHeight) / closeText.length),
                        }}>{closeText}</Text>
                    </LinearGradient>
                </SquishyButton>
            </View>
        </Modal>
    }
}